import React from 'react';
import { Button, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';

interface MenuButtonProps {
  onClick: () => void;
  children: React.JSX.Element;
  isActive: boolean;
  title: string;
}

const CustomMenuButton = styled(Button)({
  width: isMobile ? 'fit-content' : '70%',
  minWidth: 0,
  padding: 10,
  borderRadius: 8,
  boxShadow: 'none',
});

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgb(33, 40, 38)',
    color: '#FFFFFF',
    fontSize: 14,
    fontWeight: 600,
    padding: 10,
    border: 'solid 1px rgb(255, 255, 255, 0.12)',
  },
}));

const MenuButton = (props: MenuButtonProps) => (
  <CustomTooltip title={props.title} placement="right">
    <CustomMenuButton
      variant="contained"
      onClick={props.onClick}
      sx={{
        backgroundColor: props.isActive ? 'rgb(68, 151, 126)' : '#0A1714',
        opacity: props.isActive ? 1 : 0.6,
        '&:hover': {
          backgroundColor: !props.isActive ? 'rgb(255, 255, 255, 0.12)' : 'rgb(68, 151, 126)',
          opacity: 1,
        },
      }}
    >
      {props.children}
    </CustomMenuButton>
  </CustomTooltip>
);

export default MenuButton;
