import { Box, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { formatTranscriptsFromOriginWords } from '../../controllers/utils/TranscriptUtils';
import { projectsState } from '../../models/ProjectsState';
import moment from 'moment';
import { UNPLAYED_WORD_COLOR } from '../transcript/CustomNode/WordCustomNode';

const MediaTranscripts = observer(() => {
  const { selectedMediaId, getSelectedOriginalTranscript, medias } = projectsState;

  const rawTranscript = useMemo(() => {
    const originalTranscripts = getSelectedOriginalTranscript();
    if (!String(selectedMediaId) || !originalTranscripts) return '';
    return formatTranscriptsFromOriginWords(JSON.parse(JSON.stringify(originalTranscripts))).reduce((acc, item) => {
      if (item.text.includes('.')) {
        const sentences = acc.split('.');
        if (sentences.length % 5 === 0) {
          return acc + item.text + '\n\n';
        }
      }
      return acc + item.text;
    }, '');
  }, [selectedMediaId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="custom-scrollbar"
      sx={{
        padding: '0.5rem',
        borderRadius: '3px',
        maxHeight: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} marginBottom={'20px'}>
        {medias?.[selectedMediaId] && (
          <Typography color={UNPLAYED_WORD_COLOR} fontSize={12}>
            0:00 - {moment.utc(Number(medias[selectedMediaId].durationInSeconds) * 1000).format('m:ss')}
          </Typography>
        )}
      </Stack>
      <Typography
        color={'black'}
        sx={{ color: '#fff', wordWrap: 'break-word', whiteSpace: 'pre-wrap', width: '100%', letterSpacing: 'normal' }}
      >
        {rawTranscript}
      </Typography>
    </Box>
  );
});

export default MediaTranscripts;
