import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { accountState } from '../../models/AccountState';
import { projectsState } from '../../models/ProjectsState';
import { updateFavoriteVideoEdit, updateVideoEditTitle } from '../serverClients/ProjectClient';
import { PromptResults } from '../../models/VideoEditItem';
import { getStrategyOutputs } from '../projectsCRUD/MediaUtils';
import { VideoEditItem } from '../../models/VideoEditItem';

export function onSearchParamChange(searchParams, setSearchParams) {
  const { setVideoEditSelected, videoEditSelected, medias, selectedMediaId, setSelectedMediaId } = projectsState;
  if (searchParams.get('clip')) {
    const videoEditTitle = decodeURI(searchParams.get('clip'));
    Object.values(medias[selectedMediaId].strategyOutputs).forEach((strategy) => {
      Object.values(strategy).forEach((result) => {
        const foundVideoEdit = result.videoEditItems?.find((item) => item.title === videoEditTitle);
        if (foundVideoEdit && foundVideoEdit.id !== videoEditSelected?.id) {
          setVideoEditSelected(foundVideoEdit);
          return;
        }
      });
    });
  } else if (searchParams.get('mediaId')) {
    const targetMedia = medias[Number(searchParams.get('mediaId'))];
    if (targetMedia && targetMedia.id !== selectedMediaId) {
      setSelectedMediaId(targetMedia.id);
    }
  } else {
    setSearchParams({ clip: encodeURI(videoEditSelected.title) });
  }
}

export async function handleUpdateFavoriteVideoEdit(e, videoEdit, isVideoEditSelected, user) {
  const account = accountState.currentAccount;
  const { videoEditSelected, activeProjectId } = projectsState;
  e.stopPropagation();
  const newFavoriteStatus = !videoEdit.isFavorite;
  try {
    videoEdit.isFavorite = newFavoriteStatus;
    if (isVideoEditSelected) videoEditSelected.isFavorite = newFavoriteStatus;
    const strategiesOutputFavStatus = projectsState.getStrategiesOutputFavStatus();
    if (projectsState.updateFavoriteDebounce) clearTimeout(projectsState.updateFavoriteDebounce);
    projectsState.setUpdateFavoriteDebounce(
      setTimeout(async () => {
        try {
          await updateFavoriteVideoEdit(
            activeProjectId,
            user.email,
            account?.isAdmin,
            strategiesOutputFavStatus,
            'token',
          );
          projectsState.setBackupStrategyOutputsFavStatus(strategiesOutputFavStatus);
        } catch {
          restoreStrategiesOutputFavStatus();
          const messageSnackKey = enqueueSnackbar('Could not update favorite status, please try again!', {
            variant: 'error',
            SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
          });
        }
      }, 3000),
    );
  } catch {
    restoreStrategiesOutputFavStatus();
    const messageSnackKey = enqueueSnackbar('Could not update favorite status, please try again!', {
      variant: 'error',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    return;
  }
}

export function displayVideoEditDuration(videoEditDuration) {
  return Math.floor(videoEditDuration / 60) > 0
    ? `| ${Math.floor(videoEditDuration / 60)}m${Math.round(videoEditDuration % 60)}s`
    : `| ${Math.round(videoEditDuration % 60)}s`;
}

export async function handleUpdateVideoEditName(projectId, videoEdit: VideoEditItem, updateTitle: string, isVideoEditSelected, setIsUpdate) {
  try {
    setIsUpdate(true);
    if (updateTitle.trim().length === 0) {
      const messageSnackKey = enqueueSnackbar('Video edit name can not be empty', {
        variant: 'error',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      return videoEdit.title;
    }
    await updateVideoEditTitle(projectId, videoEdit.id, updateTitle, videoEdit.strategy, 'token');
    projectsState.setVideoEditTitle(videoEdit, updateTitle, isVideoEditSelected);
  } catch {
    const messageSnackKey = enqueueSnackbar('Could not update video edit name, please try again!', {
      variant: 'error',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    return videoEdit.title;
  } finally {
    setIsUpdate(false);
  }
}

function restoreStrategiesOutputFavStatus() {
  const strategyOutputs = getStrategyOutputs();
  Object.keys(strategyOutputs).forEach((strategy) => {
    Object.values(strategyOutputs[strategy]).forEach((promptResults: PromptResults) => {
      promptResults.videoEditItems.forEach((videoEditItem) => {
        Object.keys(projectsState.backupStrategyOutputsFavStatus[strategy]).forEach((backupId) => {
          if (videoEditItem.id === backupId) {
            videoEditItem.isFavorite = projectsState.backupStrategyOutputsFavStatus[strategy][backupId];
          }
        });
      });
    });
  });
}
