import format from 'format-duration';

const formatDuration = (duration) => {
  const durationArray = format(Number(duration * 1000)).split(':');
  if (durationArray.length === 3) {
    return `${durationArray[0]}h${durationArray[1]}m`;
  }
  return `${durationArray[0]}m${durationArray[1]}s`;
};

export { formatDuration };
