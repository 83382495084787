import { action, makeObservable, observable } from 'mobx';
import Segment from './Segment';

class SegmentsState {
  segments: Segment[];

  constructor() {
    this.segments = [];
    makeObservable(this, {
      segments: observable,
      setSegments: action,
      clearSegments: action,
      setSegment: action,
    });
  }

  setSegments = (segmentData: any) => {
    this.segments = segmentData;
  };

  setSegment = (newSegment) => {
    const indexTargetSegment = this.segments.findIndex((segment) => segment.id === newSegment.id);
    this.segments[indexTargetSegment] = newSegment;
  };

  clearSegments = () => {
    this.segments = [];
  };
}

export const segmentsState = new SegmentsState();
