import path from 'path-browserify';
import moment from 'moment';
import { PromptResults, VideoEditItem } from '../../models/VideoEditItem';
import ProjectItem from '../../models/ProjectItem';
import Segment from '../../models/Segment';
import { serverAddress } from '../../config';
import { deleteOptions, getOptions, patchOptions, postOptions } from '../utils/FetchUtils';
import { getIngestProjectJSON, getVideoEditCaptionPresignedUrl, getVideoPresignedUrl } from './VideoIngestClient';
import { mediaState } from '../../models/MediaState';
import { segmentsState } from '../../models/SegmentsState';
import { projectsState } from '../../models/ProjectsState';
import { SegmentColor } from '../../models/SegmentColor';
import { Media } from '../../models/Media';
import { ProjectStatus } from '../../models/ProjectItem';
import { brollsState } from '../../models/BrollState';
import { EditStrategies, FormTypes } from '../../models/IngestState';
import { fetchLogoPresignedURL } from './LogoClient';
import { AI_SUPPORTED_STRATEGIES } from '../../models/IngestState';
import Broll from '../../models/Broll';
import { fetchCaptionStyles } from './CaptionsClient';
import { convertYtbShortUrlToNormalUrl } from '../utils/TranscriptUtils';
import { isProjectProcessing, getProjectDisplayStatus } from '../projectsCRUD/ProjectPageHandler';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { chatbotState } from '../../models/ChatbotState';

const PUNCTUATIONS = ['.', ',', '?', '!'];
const POLLING_TIME = 1000 * 5;
const STATUS_POLLING_TIMEOUT = 14400 * 1000; // 4 hours

export const pollSingleProjectStatus = (projectId: string, isChatbot: boolean, callback: (status) => void) => {
  if (chatbotState.pollingIntervals[projectId]) {
    return;
  }
  const projectItem = projectsState?.activeProject;
  const intervalId = setInterval(async () => {
    const res = await getProject(projectId, 'token');
    const isProcessing = isProjectProcessing(res.status);
    if (!isProcessing) {
      const status = getProjectDisplayStatus(res.status);
      if (!isChatbot) {
        projectItem.status = res.status;
      }
      delete chatbotState.pollingIntervals[projectId];
      callback(status);
      clearInterval(intervalId);
    } else {
      let lastModifiedMs = 0;
      if (isChatbot) {
        const messages = chatbotState.chatItems.find((i) => i.chatId === projectId)?.messages;
        if (messages?.length > 0) {
          lastModifiedMs = messages[messages.length - 1].timestamp;
        }
      } else {
        lastModifiedMs = projectItem.lastModifiedMs;
      }
      const totalPollingTime = Date.now() - lastModifiedMs;
      if (totalPollingTime >= STATUS_POLLING_TIMEOUT) {
        const status = await verifyHighlightCutStatus(projectId);
        if (status !== false) {
          if (!isChatbot) {
            Object.keys(projectItem.status).forEach((fileName) => {
              projectItem.status[fileName] = ProjectStatus.FAILED;
            });
            await updateProjectStatus(projectId, projectItem.status);
          }
          delete chatbotState.pollingIntervals[projectId];
          callback(ProjectStatus.FAILED);
          clearInterval(intervalId);
        }
      }
    }
  }, POLLING_TIME); // Each 10s
  chatbotState.pollingIntervals[projectId] = Number(intervalId);
  return intervalId;
};

export const pollProjectStatusUpdates = () => {
  const projectItems = projectsState?.projectItems;
  const processingProjects = projectItems?.filter((project) => project.displayStatus === ProjectStatus.PROCESSING);
  if (!processingProjects?.length) {
    return 0;
  }

  const intervalId = setInterval(() => {
    processingProjects.forEach(async (project) => {
      const res = await getProject(project.id, 'token');
      const isProcessing = isProjectProcessing(res.status);
      if (!isProcessing) {
        project.status = res.status;
        project.displayStatus = getProjectDisplayStatus(res.status);
      } else {
        const totalPollingTime = Date.now() - project.lastModifiedMs;
        if (totalPollingTime >= STATUS_POLLING_TIMEOUT) {
          const status = await verifyHighlightCutStatus(project.id);
          if (status !== false) {
            Object.keys(project.status).forEach((fileName) => {
              project.status[fileName] = ProjectStatus.FAILED;
            });
            await updateProjectStatus(project.id, project.status);
          }
        }
      }
    });
    if (processingProjects.every((project) => project.displayStatus !== ProjectStatus.PROCESSING)) {
      clearInterval(intervalId);
    }
  }, POLLING_TIME); // Each 10s

  return intervalId;
};

export const getProjects = async (
  accessToken: string,
  email: string,
  pageNumber: number,
  pageSize: number,
  isAdmin: boolean,
) => {
  const url = `${serverAddress}/api/projects/get?email=${email}&isAdmin=${isAdmin}&pageNumber=${pageNumber}&pageSize=${pageSize}`;

  const result = await fetch(url, getOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
    });

  return result;
};

export const getProject = async (projectID: string, accessToken: string) => {
  const url = `${serverAddress}/api/project/get/${encodeURIComponent(projectID)}`;

  const result = await fetch(url, getOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
    });

  return result;
};

export const postProject = async (projectItem: ProjectItem, accessToken: string) => {
  const url = `${serverAddress}/api/projects/post`;

  const result = fetch(url, postOptions(url, accessToken, projectItem))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
};

export const shareProject = async (projectId: string, email: string, accessToken: string) => {
  const url = `${serverAddress}/api/projects/share-project`;

  const result = fetch(url, postOptions(url, accessToken, { projectId, email }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the share project operation:', error.message);
      throw error;
    });
  return result;
};

export const getProjectJson = async (projectID: string, accessToken: string) => {
  const url = `${serverAddress}/api/project_json/${encodeURIComponent(projectID).replace(/\./g, '%2E')}`;

  const result = await fetch(url, getOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
};

export const postExportProject = async (
  projectID: string,
  exportOriginalMediaId,
  exportSelected,
  captionStyles,
  email?: string,
  videoEditSegments?: number[],
  corner?: string,
  humanCut?: boolean,
  middleBarColor?: string,
) => {
  const url = `${serverAddress}/api/export-project/post`;

  const result = await fetch(
    url,
    postOptions(url, 'token', {
      projectID,
      email,
      videoEditSegments,
      middleBarColor,
      corner,
      humanCut,
      exportOriginalMediaId,
      exportType: exportSelected.type,
      captionStyles: captionStyles,
      videoEditTitle: exportSelected.videoEditTitle,
      includeTransparent: exportSelected.includeTransparent,
      include584Resolution: exportSelected.include584Resolution,
      includeLandscape: exportSelected.includeLandscape,
      includeVertical: exportSelected.includeVertical,
      includeLogo: exportSelected.includeLogo,
      exportOption: exportSelected.exportOption,
      noCaption: exportSelected.noCaption,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
};

export async function fetchProjectData(projectId: string) {
  const projectResponse = await getProjectJson(projectId, 'token');
  fetchCaptionStyles(); // fetch captions styles must be called after 'getProjectJson' to ensure that the data is updated
  const projectActive = await getProject(projectId, 'token');
  const { surveyBy, displayName, enableCaption, thumbnail, status, owner, settings } = projectActive;
  const { name, fps, height, width, timecode } = projectResponse.content.project;

  const originalProjectData = await getIngestProjectJSON(projectId, 'accessToken');
  projectsState.setActiveProject({
    id: projectsState.activeProjectId,
    name: name ?? displayName,
    fps,
    width,
    height,
    timecode,
    owner,
    surveyBy: surveyBy,
    originalData: originalProjectData,
    displayName: displayName ?? name,
    enableCaption: enableCaption,
    thumbnail,
    status: status,
    displayStatus: getProjectDisplayStatus(status),
  });

  if (settings) {
    projectsState.setSettings(settings);
  }

  // convert youtube short url to normal url
  if (projectResponse.content?.brolls) {
    projectResponse.content?.brolls.map((bRoll) => {
      bRoll.candidates[0].url = convertYtbShortUrlToNormalUrl(bRoll.candidates[0].url);
      return bRoll;
    });
  }
  brollsState.setBrolls(projectResponse.content?.brolls ?? []);
  // Note: we must be set media list to state first and segments second
  await updateProjectState(projectResponse, projectId, originalProjectData);

  const captionPresignedUrl = await getVideoEditCaptionPresignedUrl(
    projectId,
    projectsState.videoEditSelected.id,
    projectsState.videoEditSelected.strategy,
  );
  mediaState.setCaptionUrl(captionPresignedUrl);
  if (!captionPresignedUrl) {
    videoPlayerState.clearSubtitleContent();
  }

  // fetch videoPlaybackUrl of media list
  fetchPresignedUrls(projectsState.medias);
  await fetchLogoPresignedURL(projectId);
  projectsState.setBackupStrategyOutputsFavStatus(projectsState.getStrategiesOutputFavStatus());
}

export function formatMediaResponse(mediaResponse): Record<number, Media> {
  const mediaMap: Record<number, Media> = {};
  mediaResponse.forEach((media: any) => {
    const { ext } = path.parse(media.mediaInfo.url);
    mediaMap[media.mediaInfo.id] = {
      id: media.mediaInfo.id,
      name: media.mediaInfo.name,
      url: media.mediaInfo.url,
      thumbnailURL: media.mediaInfo.thumbnail_uri,
      startTimeCode: media.mediaInfo.startTimeCode,
      durationInSeconds: media.mediaInfo.durationInSeconds,
      width: media.mediaInfo.width,
      height: media.mediaInfo.height,
      fps: media.mediaInfo.fps,
      fcpxml: media.mediaInfo.fcpxml,
      fileName: `${media.mediaInfo.name}${ext}`,
    };
  });
  return mediaMap;
}

export function mapJsonSegmentToClientSegment(segments: any[]): Segment[] {
  const segmentsMap = segments.map((segment: any): Segment => {
    const words = segment.words;
    return {
      id: segment.id,
      importedMediaId: segment.imported_media_id,
      start: segment.start,
      end: segment.end,
      text: segment.text,
      sortIndex: segment.sort_index,
      isChapter: segment.is_chapter,
      media: segment.imported_media,
      color: segment?.color || SegmentColor.ORANGE,
      words,
      strategy: segment.strategy,
      story_id: segment.story_id,
      end_card: segment.end_card,
    };
  });

  return segmentsMap;
}

export function getVideoEditDuration(videoEdit: VideoEditItem, segments: any[]): number {
  let videoEditDuration: number = 0;
  segments.forEach((segment) => {
    if (videoEdit.segmentIds.includes(segment.id)) {
      videoEditDuration += segment.end - segment.start;
    }
  });
  return videoEditDuration;
}

export function combinePunctuation(segment) {
  if (!segment?.words?.length) {
    return [];
  }
  const words = [];
  for (let i = 0; i < segment.words.length; i++) {
    const word = segment.words[i];
    if (PUNCTUATIONS.includes(word?.text?.trim())) {
      if (words.length > 0) {
        const lastIdx = words.length - 1;
        words[lastIdx].text = words[lastIdx].text?.trim() + word.text;
        words[lastIdx].endTime = word.endTime;
      }
    } else {
      words.push(word);
    }
  }
  return words;
}

function fetchPresignedUrls(mediaList): void {
  const presignedUrlPromises = [];
  const mediaIdOrder = [];
  let firstMediaUrl = '';
  for (const mediaId in mediaList) {
    const currMedia = mediaList[mediaId];
    const dir = path.basename(currMedia.name);
    const s3Key = `${projectsState.activeProjectId}/${dir}/${currMedia.name}`;
    const presignedUrlPromise = getVideoPresignedUrl(s3Key).catch((error) => {
      console.error(`Failed to get presigned url for media ${mediaId}`, error);
      return '';
    });
    presignedUrlPromises.push(presignedUrlPromise);
    mediaIdOrder.push(mediaId);
  }
  Promise.all(presignedUrlPromises)
    .then((presignedUrls) => {
      const mediaIdToUrl = {};
      for (let i = 0; i < presignedUrls.length; i++) {
        mediaIdToUrl[mediaIdOrder[i]] = presignedUrls[i];
        if (!firstMediaUrl) {
          firstMediaUrl = presignedUrls[i];
        }
      }
      mediaState.setMediaIdToUrl(mediaIdToUrl);
      videoPlayerState.setVideoUrl(firstMediaUrl);
    })
    .catch((error) => {
      console.error('Encountered error while fetching media urls.', error);
      throw error;
    });
}

export const deleteProject = async (projectID: string, owner: string, accessToken: string) => {
  const url = `${serverAddress}/api/project/delete?projectID=${projectID}&email=${owner}`;
  const result = await fetch(url, deleteOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      projectsState.setProjectItems(projectsState.projectItems.filter((pItem) => pItem.id !== projectID));
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
};

export const patchSegmentByProject = async (
  projectID: string,
  segment: Partial<Segment>,
  accessToken: string,
  videoEditId: string,
  segmentIds: number[],
  brolls?: Broll[],
) => {
  const url = `${serverAddress}/api/project/segments/patch/${projectID}`;
  const result = await fetch(url, patchOptions(url, accessToken, { segment, videoEditId, segmentIds, brolls }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
};

export const patchBulkSegmentsByProject = async (
  projectID: string,
  segments: Partial<Segment>[],
  accessToken: string,
  videoEditId: string,
  segmentIds: number[],
  brolls?: Broll[],
) => {
  const url = `${serverAddress}/api/project/segments/bulk/patch/${projectID}`;
  const result = await fetch(url, patchOptions(url, accessToken, { segments, videoEditId, segmentIds, brolls }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
};

export async function patchUpdateProjectOwner(projectId: string, newOwner: string[], accessToken: string) {
  const url = `${serverAddress}/api/project/owner/patch/${projectId}`;
  const result = await fetch(url, patchOptions(url, accessToken, newOwner))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
}

export async function createProject(filenames, formData) {
  const currentTime = new Date().getTime();
  const projectName = `Project ${moment.utc().format('MMM-DD-YYYY')}`;
  const status = {};
  filenames.forEach((fileName) => {
    status[fileName] = ProjectStatus.PROCESSING;
  });
  const projectItem = {
    id: formData.projectUUID,
    name: projectName,
    durationMs: 0,
    createdMs: currentTime,
    lastModifiedMs: currentTime,
    status: status,
    displayStatus: ProjectStatus.PROCESSING,
    owner: formData.email,
    surveyBy: [],
    displayName: projectName,
    enableCaption: formData.formType !== FormTypes.SOUNDBITES_FORM, // set false if form is soundbite
  };
  await postProject(projectItem, 'token');
  return projectItem;
}

export async function updateFavoriteVideoEdit(
  projectId: string,
  email,
  isAdmin,
  strategiesFavVideoEdit,
  accessToken: string,
) {
  const url = `${serverAddress}/api/project/video-edits/favorite/patch/${projectId}`;
  const result = await fetch(url, patchOptions(url, accessToken, { email, isAdmin, strategiesFavVideoEdit }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return true;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
}

export async function updateVideoEditTitle(
  projectId: string,
  videoEditId,
  videoEditTitle,
  strategy,
  accessToken: string,
) {
  const url = `${serverAddress}/api/project/video-edit/title/patch/${projectId}`;
  const result = await fetch(url, patchOptions(url, accessToken, { videoEditId, videoEditTitle, strategy }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return true;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
}

export async function updateProjectName(projectId: string, projectName: string) {
  const endpoint = `${serverAddress}/api/project/update-name/patch`;
  const accessToken = 'token';

  return await fetch(
    endpoint,
    patchOptions(endpoint, accessToken, {
      projectId,
      projectName,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    });
}

export async function updateProjectStatus(projectId: string, status: any) {
  const endpoint = `${serverAddress}/api/project/update-status/patch`;
  const accessToken = 'token';

  return await fetch(
    endpoint,
    patchOptions(endpoint, accessToken, {
      projectId,
      status,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    });
}

export async function getOriginalTranscripts(projectId: string, fileName: string) {
  const endpoint = `${serverAddress}/api/projects/original-transcript/get?projectId=${projectId}&fileName=${encodeURIComponent(
    fileName,
  )}`;
  const transcriptFilePresignedUrl = await fetch(endpoint)
    .then((res) => res.text())
    .then((data) => data);
  const rawTranscripts = await fetch(transcriptFilePresignedUrl)
    .then((res) => res.json())
    .then((data) => data);
  return rawTranscripts.tokens.map((token) => ({
    text: token.content,
    startTime: token.start,
    endTime: token.end,
  }));
}

export async function addDemoProjects(ownerEmail: string, accessToken: string) {
  const endpoint = `${serverAddress}/api/projects/add-demo-project`;

  return await fetch(
    endpoint,
    postOptions(endpoint, accessToken, {
      ownerEmail,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    });
}

export async function updateProjectEnableCaption(projectId: string, enableCaption: boolean) {
  const endpoint = `${serverAddress}/api/project/enable-caption/patch`;
  const accessToken = 'token';

  return await fetch(
    endpoint,
    patchOptions(endpoint, accessToken, {
      projectId,
      enableCaption,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem update enable caption:', error.message);
      throw error;
    });
}

async function verifyHighlightCutStatus(projectId: string) {
  try {
    const projectResponse = await getProjectJson(projectId, 'token');
    const storyGPTStatus = projectResponse['storygpt_status'];
    storyGPTStatus.forEach((gpt_status) => {
      if (gpt_status?.highlights === false) return false;
    });
    return true;
  } catch {
    return false;
  }
}

export async function updateBrolls(projectId: string, brolls: Broll[], updatedBrollInfo: any) {
  const endpoint = `${serverAddress}/api/project/brolls/${projectId}`;
  const accessToken = 'token';

  return await fetch(
    endpoint,
    patchOptions(endpoint, accessToken, {
      brolls,
      updatedBrollInfo,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem update brolls:', error.message);
      throw error;
    });
}

export async function cloneProject(projectId: string, targetOwner: string) {
  const endpoint = `${serverAddress}/api/project/clone/`;
  const accessToken = 'token';

  return await fetch(
    endpoint,
    postOptions(endpoint, accessToken, {
      projectId,
      targetOwner,
    }),
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem update brolls:', error.message);
      throw error;
    });
}

async function fetchMediaSize(projectId: string, fileName: string) {
  const endpoint = `${serverAddress}/api/video-ingest/video-size/get?projectId=${projectId}&fileName=${encodeURIComponent(
    fileName,
  )}`;
  const accessToken = 'token';

  return await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem fetch media size:', error.message);
      throw error;
    });
}

export async function getThumbnailPresignURL(thumbnailURI: string) {
  if (!thumbnailURI) return;

  const urlInfo = thumbnailURI.split('/');
  const endpoint = `${serverAddress}/api/project/presign-url/get?bucket=${urlInfo[2]}&key=${urlInfo
    .slice(3)
    .join('/')}`;
  const accessToken = 'token';

  return await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem fetch media size:', error.message);
      throw error;
    });
}

export async function updateProjectState(projectJSONData, projectId, originalProjectData?: any) {
  const mediasFormatted = await formatMediaResponse(projectJSONData.content.media);
  const segmentsFormatted = mapJsonSegmentToClientSegment(projectJSONData.content.segments);
  segmentsState.setSegments(segmentsFormatted);

  const promptsDataTemplate = {};
  let firstVideoEdit;
  if ('prompts' in projectJSONData.content) {
    projectJSONData.content['prompts'].forEach((prompt) => {
      promptsDataTemplate[prompt.id] = {
        prompt: {
          id: prompt.id,
          storyAbout: prompt.value,
        },
        videoEditItems: [],
      };
    });
  } else if (originalProjectData) {
    promptsDataTemplate['0'] = {
      prompt: originalProjectData.storyAbout,
      videoEditItems: [],
    };
  }

  const formatMediaData = async (media) => {
    const strategyOutputs = {};
    AI_SUPPORTED_STRATEGIES.forEach((key) => {
      if (!(key in projectJSONData.content)) return;

      const isStoriesEdit = key === EditStrategies.STORIES;
      const promptData = JSON.parse(JSON.stringify(promptsDataTemplate));
      const videoEdits = isStoriesEdit ? projectJSONData.content[key].reverse() : projectJSONData.content[key];

      videoEdits.forEach((videoEdit: any) => {
        if (media.mediaInfo.id !== videoEdit.mediaId) return;

        const promptId = 'promptId' in videoEdit ? videoEdit.promptId : 0;
        const item = {
          ...videoEdit,
          duration: getVideoEditDuration(videoEdit, projectJSONData.content.segments),
          strategy: key,
        };
        promptData[promptId]['videoEditItems'].push(item);
        if (!firstVideoEdit) {
          firstVideoEdit = item;
        }
      });
      if (isStoriesEdit) {
        Object.values(promptData).forEach((prompt: PromptResults) => {
          if (prompt.videoEditItems.length === 1) {
            prompt.videoEditItems[0].isShowPrompt = true;
          }
        });
      }
      const mappedKey = key;
      strategyOutputs[mappedKey] = promptData;
    });
    const storyGPTStatus = projectJSONData['storygpt_status'].find(
      (status) => status['media_id'] === media.mediaInfo.id,
    );
    mediasFormatted[media.mediaInfo.id] = {
      ...mediasFormatted[media.mediaInfo.id],
      thumbnailURL: await getThumbnailPresignURL(media.mediaInfo.thumbnail_uri),
      fileSize: Number(await fetchMediaSize(projectId, media.mediaInfo.name)),
      strategyOutputs: strategyOutputs,
      storyGPTStatus: {
        highlights: storyGPTStatus?.highlights ?? false,
        soundbites: storyGPTStatus?.soundbites ?? false,
        stories: storyGPTStatus?.stories ?? false,
      },
      originalTranscript: await getOriginalTranscripts(projectId, media.mediaInfo.name).catch((err) => {
        console.log(err);
        return null;
      }),
    };
  };

  await Promise.all(projectJSONData.content.media.map((media) => formatMediaData(media)));

  projectsState.setMedias(mediasFormatted);
  projectsState.setVideoEditSelected(firstVideoEdit);
  projectsState.setSelectedMediaId(0);
}

export async function updateProjectSettings(projectId, settings) {
  const endpoint = `${serverAddress}/api/project/update-settings`;
  const accessToken = 'token';

  return await fetch(endpoint, postOptions(endpoint, accessToken, { projectId, settings }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => data)
    .catch((error) => {
      console.error('There was a problem update project settings:', error.message);
      throw error;
    });
}

export async function getPresignedUrl(accessToken: string, s3Uri: string): Promise<string> {
  const endpoint = `${serverAddress}/api/project/presigned-url?s3Uri=${encodeURIComponent(s3Uri)}`;

  return await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the update chat operation:', error.message);
      throw error;
    });
}

export async function publishMetric(metricName: string, status: number, email?: string) {
  const endpoint = `${serverAddress}/api/publish-metric`;
  const accessToken = 'token';
  return await fetch(endpoint, postOptions(endpoint, accessToken, { metricName, status, email }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then(data => data)
    .catch((error) => {
      console.error('There was a problem to push metric:', error.message);
      throw error;
    })
}
