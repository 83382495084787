export enum IngestStatusEnum {
  USER_IMPORT_FILE = 'import file begun',
  FILE_UPLOADING = 'file uploading',
  FILE_IMPORT_FAILED = 'file import failed',
}

export type TNotifyIngest = {
  projectUUID: string;
  email: string;
  status: string;
  isChatBot: boolean;
  message?: string;
};
