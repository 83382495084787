import { action, makeObservable, observable, runInAction } from 'mobx';
import { TFileUpload, TForm } from '../views/ingest/FormIngestInterface';

export enum FormTypes {
  SOCIAL_MEDIA_FORM = 'SocialMediaForm',
  SOUNDBITES_FORM = 'SoundbitesForm',
  SHARE_PROJECT_FORM = 'ShareProjectForm',
  HUMAN_CUT_FORM = 'HumanCutForm',
  STORIES_FORM = 'StoriesForm',
  CHATBOT_FORM = 'ChatForm',
}

export enum EditStrategies {
  HIGHLIGHTS = 'highlights',
  SOUNDBITES = 'soundbites',
  STORIES = 'stories',
  HUMAN_CUTS = 'human_cuts',
  DEFAULT = 'default',
}

export const AI_SUPPORTED_STRATEGIES: string[] = Object.values(EditStrategies);

export enum IngestFormOptions {
  HIGHLIGHTS = 'Highlight video for social media (~1min. long)',
  SOUNDBITES = 'AI selected soundbites',
  STORIES = 'Stories',
  PROJECT_ID_OPTION = 'Ingest from project',
  POLISHED_VIDEO_OPTION = 'Upload polished video for customer',
}

class IngestState {
  formData = {
    projectUUID: '',
    storyAbout: '',
    duration: '',
    email: '',
    editStrategy: '',
    editType: '',
    formType: '',
    projectName: '',
    pendingJobs: [],
    inputFiles: [],
    inputYoutubeData: [],
    inputDriveData: [],
    inputDropboxData: [],
    excludeCaptions: false,
    aiGeneratedTitle: true,
  } as TForm;

  isSubmitting = false as boolean;

  formFeedbacks = [];
  imageUpload: File;
  fileUploads: TFileUpload[] = [];

  constructor() {
    makeObservable(this, {
      formData: observable,
      isSubmitting: observable,
      formFeedbacks: observable,
      imageUpload: observable,
      fileUploads: observable,
      setFormFeedbacks: action,
      setFormData: action,
      setFileUploads: action,
    });
  }

  setFormData = (data: Partial<TForm>) => {
    runInAction(() => {
      this.formData = {
        ...this.formData,
        ...data,
      };
    });
  };

  setIsSubmitting = (status: boolean) => {
    this.isSubmitting = status;
  };

  setFormFeedbacks = (formFeedbacks) => {
    this.formFeedbacks = formFeedbacks;
  };

  setImageUpload = (imageUpload: File) => {
    this.imageUpload = imageUpload;
  };

  setFileUploads = (fileUploads: TFileUpload[]) => {
    this.fileUploads = fileUploads;
  };
}

export const ingestState = new IngestState();
