import React from 'react';
import { IconButton } from '@mui/material';
import AttachFileIcon from '../icons/AttachFileIcon';

interface AttachFileButtonProps {
  onClick: () => void;
  color: string;
}

const AttachFileButton = (props: AttachFileButtonProps) => (
  <IconButton onClick={props.onClick}>
    <AttachFileIcon style={{ borderRadius: '6px', width: '32px', height: '32px' }} />
  </IconButton>
);

export default AttachFileButton;
