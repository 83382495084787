import { Box, Stack, Typography, Button } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import '../modals/Modal.css';
import ShareGreenIcon from '../ui_controls/icons/ShareGreenIcon';
import { copyToClipboard } from '../../controllers/share/ShareModalHandler';

type TProps = {
  closeMenu: any;
  isUpdating: boolean;
  setUpdating: any;
  url: string;
};

const ShareBody = observer((props: TProps) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Box className="modal-container" sx={{ padding: 3, textAlign: 'left', position: 'relative' }}>
      <Stack direction="row" alignItems="left" gap={1} justifyContent="left">
        <Typography>Share</Typography>
      </Stack>

      {isCopied && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            mt: 2,
            backgroundColor: '#1e2726',
            borderRadius: 1,
            padding: '5px !important',
          }}
        >
          <Typography sx={{ color: 'white', fontSize: '11px', fontWeight: 'bold' }}>URL Copied!</Typography>
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        width={300}
        sx={{
          marginTop: '10px',
          height: '40px',
          justifyContent: 'center',
          backgroundColor: '#292c2c',
          borderRadius: '5px',
          paddingLeft: '5px !important',
        }}
      >
        <ShareGreenIcon style={{ width: 20, height: 20 }} share_color="green" />
        <Typography
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '14px',
            maxWidth: '80%',
          }}
        >
          <span style={{ color: '#079A7C' }}>{props.url.split('//')[0]}</span>
          <span style={{ color: 'white' }}>{'//' + props.url.split('//')[1]}</span>
        </Typography>
        <Button
          onClick={() => copyToClipboard(props.url, setIsCopied)}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: '#292c2c',
            color: 'white',
            fontSize: '13px',
            fontWeight: 'bold',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'gray',
            },
          }}
        >
          Copy
        </Button>
      </Stack>
    </Box>
  );
});

export default ShareBody;
