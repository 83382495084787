import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';

import { ONBOARD_PATH } from '../config/constants';
import { createAccount, getAccount } from '../controllers/serverClients/AccountClient';
import { accountState } from '../models/AccountState';
import MobileWarningModal from './modals/MobileWarningModal';
import { addDemoProjects, getProjects } from '../controllers/serverClients/ProjectClient';
import { projectsState } from '../models/ProjectsState';
import IncognitoModeWarningModal from './modals/IncognitoModeWarningModal';

const MainLayout = () => {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const { setIsAddingDemoProjects, setProjectItems } = projectsState;

  async function setDemoProjects(email: string) {
    setIsAddingDemoProjects(true);
    await addDemoProjects(email, 'token');
    const projectsResponse = await getProjects('token', user.email, 1, 11, false);

    const mapProjectToStoreSchema = projectsResponse.data.map((project) => {
      project.id = project.projectID;
      project.surveyBy = project.surveyBy ?? [];
      project.displayName = project.displayName ?? project.name;
      delete project.projectID;
      return project;
    });
    setProjectItems(mapProjectToStoreSchema);

    setIsAddingDemoProjects(false);
  }

  async function getCurrentAccount() {
    let isExistingUser = true;
    let currentUser = await getAccount(user.email, 'token');
    if (!currentUser) {
      navigate(ONBOARD_PATH);
      currentUser = await createAccount(user.email, 'accessToken');
      isExistingUser = false;
    }
    accountState.setCurrentAccount(currentUser);
    return isExistingUser;
  }

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentAccount().then((res) => {
        if (!res) setDemoProjects(user.email);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Check if a crash flag is set in localStorage
    const hasCrashed = localStorage.getItem('crashed');

    if (hasCrashed) {
      // Clear the crash flag
      localStorage.removeItem('crashed');
      setTimeout(() => {
        const messageSnackKey = enqueueSnackbar('Oops! There was an error, please try again.', {
          variant: 'error',
          SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
        });
      }, 1000);
    }
  }, []);

  return (
    <ErrorBoundary
      fallback={<></>}
      onError={() => {
        localStorage.setItem('crashed', 'true');
        window.location.href = '/';
      }}
    >
      <SnackbarProvider preventDuplicate anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
      <Container fluid className="noMarginPadding main-layout">
        <Outlet />
        <MobileWarningModal open={isMobile} />
        <IncognitoModeWarningModal />
      </Container>
    </ErrorBoundary>
  );
};

export default withAuthenticationRequired(MainLayout);
