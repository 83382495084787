import React, { useState } from 'react';
import { List, ListInlineItem } from 'reactstrap';
import ExportButton from '../ui_controls/buttons/ExportButton';
import { observer } from 'mobx-react';
import ExportModal from '../modals/ExportModal';
import { projectsState } from '../../models/ProjectsState';
import { mediaState } from '../../models/MediaState';
import CaptionStylesMenu from '../popovers/CaptionStylesDropdownMenu';
import RePromptModal from '../modals/RePromptModal';
import { useLocation } from 'react-router-dom';
import { SHARE_PATH } from '../../config/constants';
import ShareModal from '../modals/ShareModal';
import './Navigation.css';

type TProps = {
  hiddenStoryForm?: boolean;
};

const MenuBar = observer(({ hiddenStoryForm }: TProps) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { pathname } = useLocation();
  const isSharing = pathname.startsWith(SHARE_PATH);

  return (
    <List className="nav-item-project-actions__menu-bar">
      {!isSharing && !hiddenStoryForm && (
        <ListInlineItem className="menu-bar__action-item">
          <RePromptModal />
        </ListInlineItem>
      )}
      {!isSharing && projectsState.activeProject && mediaState.captionStyles && (
        <ListInlineItem className="menu-bar__action-item">
          <CaptionStylesMenu />
        </ListInlineItem>
      )}
      {!isSharing && projectsState.activeProject && (
        <ListInlineItem className="menu-bar__action-item">
          <ShareModal />
        </ListInlineItem>
      )}
      {!isSharing && (
        <ListInlineItem className="menu-bar__action-item">
          <ExportButton showIcon onClick={toggle} />
          <ExportModal isActive={modal} toggle={toggle} />
        </ListInlineItem>
      )}
    </List>
  );
});

export default MenuBar;
