import { action, makeObservable, observable } from 'mobx';
import { TCaptionStyles } from './CaptionsStyle';

export interface AccountItem {
  userID: string;
  isAdmin: boolean;
  logoCorner?: string;
  captionStyles?: TCaptionStyles;
  middleBarColor: string;
}

class AccountState {
  currentAccount: AccountItem = null;

  constructor() {
    makeObservable(this, {
      currentAccount: observable,
      setCurrentAccount: action,
      setCaptionStyles: action,
    });
  }

  setCaptionStyles = (styles: any) => {
    this.currentAccount.captionStyles = styles;
  };

  setCurrentAccount = (accountItem: AccountItem): void => {
    this.currentAccount = accountItem;
  };
}

export const accountState = new AccountState();
