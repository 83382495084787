import { Box, FormControlLabel, RadioGroup } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { accountState } from '../../models/AccountState';
import IncludeLogoOption from './IncludeLogoOption';
import ExportCheckbox from '../ui_controls/checkboxs/ExportCheckbox';
import ExportRadio from '../ui_controls/radios/ExportRadio';
import { ProjectExportOptions } from '../../models/ProjectItem';
import { projectsState } from '../../models/ProjectsState';

const ExportOptions = observer(
  ({
    selectedOption,
    onOptionChange,
    onIncludeTransparent,
    onInclude584Resolution,
    onIncludeLandscape,
    onIncludeVertical,
    exportSelected,
    onIncludeLogo,
    onNoCaptionChange,
  }) => {
    const isShowIncludeOption = exportSelected.extension === 'xml' || exportSelected.extension === 'fcpxml';
    const account = accountState.currentAccount;
    const isAdmin = account && account.isAdmin;
    const isExportVideo = exportSelected.extension === 'mp4';
    const disableExportFavorite = getFavoriteVideoEditCount() === 0 ? true : false;
    if (disableExportFavorite && selectedOption === ProjectExportOptions.FAVORITES) {
      selectedOption = ProjectExportOptions.ACTIVE;
    }

    useEffect(() => {
      if (!['premiere', 'fcpxml', 'davinci'].includes(exportSelected.type)) {
        onIncludeTransparent(false);
        onInclude584Resolution(false);
      }
      if (!isAdmin || exportSelected.type === 'davinci') {
        onInclude584Resolution(false);
      }
    }, [exportSelected.type, isAdmin]);

    function getFavoriteVideoEditCount() {
      const videoEdits = projectsState.getVideoEditsOfStrategyOutputs();
      return videoEdits.reduce((acc, videoEdit) => {
        if (videoEdit.isFavorite) {
          acc += 1;
        }
        return acc;
      }, 0);
    }

    return (
      <Box>
        {projectsState.videoEditSelected?.strategy && (
          <RadioGroup
            aria-label="export-options"
            name="export-options"
            value={selectedOption}
            style={{ flexDirection: 'row', padding: '0 10px' }}
            onChange={(e) => {
              onOptionChange(e.target.value);
            }}
          >
            <FormControlLabel
              style={{ opacity: selectedOption === ProjectExportOptions.ACTIVE ? 1 : 0.6 }}
              value={ProjectExportOptions.ACTIVE}
              control={<ExportRadio />}
              label="Export only active video edit"
            />
            {!isExportVideo && (
              <FormControlLabel
                style={{ opacity: selectedOption === ProjectExportOptions.ALL ? 1 : 0.6, marginLeft: 10 }}
                value={ProjectExportOptions.ALL}
                control={<ExportRadio />}
                label="Export all edits"
              />
            )}
            <FormControlLabel
              style={{
                opacity: selectedOption === ProjectExportOptions.FAVORITES ? 1 : disableExportFavorite ? 0.3 : 0.6,
                marginLeft: 10,
                pointerEvents: disableExportFavorite ? 'none' : 'auto',
              }}
              value={ProjectExportOptions.FAVORITES}
              control={<ExportRadio />}
              label="Export all favorites"
            />
          </RadioGroup>
        )}

        <Box style={{ display: 'inline-flex', flexDirection: 'column', justifyContent: 'center', padding: '0 10px' }}>
          {isShowIncludeOption && (
            <>
              <Box className="d-flex align-items-center justify-content-between row-gap-2">
                <FormControlLabel
                  control={
                    <ExportCheckbox
                      checked={exportSelected?.includeLandscape}
                      onChange={(e) => {
                        onIncludeLandscape(e.target.checked);
                      }}
                    />
                  }
                  label="Landscape"
                  className="flex-grow-1"
                />

                <FormControlLabel
                  control={
                    <ExportCheckbox
                      checked={exportSelected?.includeVertical}
                      onChange={(e) => {
                        onIncludeVertical(e.target.checked);
                      }}
                    />
                  }
                  label="Vertical"
                />
              </Box>
            </>
          )}

          {isShowIncludeOption && (
            <FormControlLabel
              control={
                <ExportCheckbox
                  checked={exportSelected.includeTransparent}
                  onChange={(e) => onIncludeTransparent(e.target.checked)}
                />
              }
              label="Include animated captions"
            />
          )}
          {isAdmin && isShowIncludeOption && exportSelected.type !== 'davinci' && (
            <>
              <FormControlLabel
                control={
                  <ExportCheckbox
                    checked={exportSelected.include584Resolution}
                    onChange={(e) => onInclude584Resolution(e.target.checked)}
                  />
                }
                label="Include 380x584 resolution"
              />
            </>
          )}
          {isExportVideo && (
            <>
              {isAdmin && (
                <IncludeLogoOption exportSelected={exportSelected} onChange={(e) => onIncludeLogo(e.target.checked)} />
              )}
              <FormControlLabel
                control={
                  <ExportCheckbox
                    checked={exportSelected.noCaption}
                    onChange={(e) => onNoCaptionChange(e.target.checked)}
                  />
                }
                label="No Caption"
              />
            </>
          )}
        </Box>
      </Box>
    );
  },
);

export default ExportOptions;
