import { action, makeObservable, observable } from 'mobx';
import Broll from './Broll';

class BrollsState {
  brolls: Broll[];

  constructor() {
    this.brolls = [];
    makeObservable(this, {
      brolls: observable,
      setBrolls: action,
      clearBrolls: action,
      addBrolls: action,
      insertBrolls: action,
      updateBrollsColorByGroupId: action,
      updateBrollsUrlByGroupId: action,
      deleteBrollsByGroupId: action,
      deleteBrollsById: action,
      updateBrollsWordIndexRangeById: action,
    });
  }

  setBrolls = (brolls: Broll[]) => {
    this.brolls = brolls;
  };

  addBrolls = (brolls: Broll[]) => {
    this.brolls.push(...brolls);

    return this.brolls;
  };

  insertBrolls = (index: number, brolls: Broll[]) => {
    this.brolls.splice(index, 0, ...brolls);
    return this.brolls;
  };

  updateBrollsColorByGroupId = (brollGroupId: string, color: string) => {
    this.brolls = this.brolls.map((broll) => {
      if (broll.brollGroupId === brollGroupId) {
        return { ...broll, color: color };
      }
      return broll;
    });

    return this.brolls;
  };

  updateBrollsUrlByGroupId = (brollGroupId: string, url: string, start: number, media_id: string) => {
    const brollsInGroupId = this.brolls.filter((broll) => broll.brollGroupId === brollGroupId);

    let candidateStart = start;
    for (const broll of brollsInGroupId) {
      const duration = parseFloat((broll.end - broll.start).toFixed(3));
      const end = parseFloat((duration + candidateStart).toFixed(3));
      broll.candidates[0] = {
        start: parseFloat(candidateStart.toFixed(3)),
        end: end,
        media_id: media_id,
        url: url,
      };
      candidateStart = end;
    }

    return this.brolls;
  };

  updateBrollsWordIndexRangeById = (
    updatedBrollsRange: { id: string; startIdx: number; endIdx: number; startTime: number; endTime: number }[],
  ) => {
    for (const updatedBroll of updatedBrollsRange) {
      const broll = this.brolls.find((broll) => broll.id === updatedBroll.id);
      const { startIdx, endIdx, startTime, endTime } = updatedBroll;
      broll.wordIndexRange = [startIdx, endIdx];
      broll.start = startTime;
      broll.end = endTime;
      const duration = parseFloat((endTime - startTime).toFixed(3));
      broll.candidates[0].end = parseFloat((broll.candidates[0].start + duration).toFixed(3));
    }
    return this.brolls;
  };

  deleteBrollsByGroupId = (id: string) => {
    this.brolls = this.brolls.filter((broll: Broll) => broll.brollGroupId !== id);
    return this.brolls;
  };

  deleteBrollsById = (ids: string[]) => {
    this.brolls = this.brolls.filter((broll: Broll) => !ids.includes(broll.id));
    return this.brolls;
  };

  clearBrolls = () => {
    this.brolls = [];
  };
}

export const brollsState = new BrollsState();
