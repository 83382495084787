import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { v4 as uuidV4 } from 'uuid';
import { MAX_STORY_ABOUT_LEN, MIN_STORY_ABOUT_LEN } from '../../config';
import { CHATBOT_PATH } from '../../config/constants';
import { chatbotState } from '../../models/ChatbotState';
import { ChatItem, ChatProject, TPagination } from '../../models/ChatItem';
import {
  AI_AUTHOR,
  CHAT_STATUS,
  ChatMessage,
  ExportAsset,
  ExportChatMessage,
  MESSAGE_STATUS,
  MESSAGE_TYPE,
  TextChatMessage,
  VideoChatMessage,
} from '../../models/ChatMessage';
import { ProjectStatus } from '../../models/ProjectItem';
import { greatestCommonDivisor } from '../projectsCRUD/ProjectPageHandler';
import { createChatApi, deleteChatApi, getChatDetailApi, getChatsApi } from '../serverClients/ChatClient';
import { getMessagesApi } from '../serverClients/MessageClient';
import { getProjectJson, pollSingleProjectStatus } from '../serverClients/ProjectClient';
import { getVideoPresignedUrl, postProjectRetry } from '../serverClients/VideoIngestClient';
import { createDefaultChats } from '../utils/ChatUtil';
import { addChatSuccessMessages, handleChatNotFoundError } from './ChatbotPageHandler';
import { EditStrategies, FormTypes } from '../../models/IngestState';
import { pollJob } from '../serverClients/JobsClient';

/**
 * Handle Chat actions
 */
export async function deleteChat(chatId: string, owner: string, navigate, scrollToTop?: () => void) {
  try {
    chatbotState.clearChatState(chatId);
    delete chatbotState.pollingIntervals[chatId];
    chatbotState.setChatItems(chatbotState.chatItems.filter((chat) => chat.chatId !== chatId));
    if (chatbotState.selectedChatId === chatId) {
      navigate(`${CHATBOT_PATH}`);
      if (scrollToTop) {
        scrollToTop();
      }
    }
    await deleteChatApi('token', chatId, owner);
  } catch (err) {
    console.log(err);
    const messageSnackKey = enqueueSnackbar("Couldn't delete chat ", {
      variant: 'warning',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
  }
}

export async function createChat(
  userEmail: string,
  navigate: any,
  intercomTrackEvent: (...args) => void,
  scrollToTop?: () => void,
) {
  const chat: ChatItem = createDefaultChats(userEmail)[0];
  try {
    await chatbotState.createChat(chat);
    navigate(`${CHATBOT_PATH}/${chat.chatId}`);
    if (scrollToTop) {
      scrollToTop();
    }
    intercomTrackEvent('chatbot-new', {
      userEmail,
      chat,
    });
  } catch (err) {
    console.log(err);
    const messageSnackKey = enqueueSnackbar("Couldn't create ", {
      variant: 'warning',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
  }
}

export async function getChats(setFetching, pagination: TPagination, setPagination, owner: string) {
  try {
    setFetching(true);
    const response = await getChatsApi('token', owner, pagination.lastEvaluatedKey);
    if (response.data.length === 0) {
      const defaultChats = createDefaultChats(owner);
      await Promise.all(defaultChats.map((chat) => createChatApi('token', chat)));
      response.data = defaultChats;
      chatbotState.setDisabledIngestChats(defaultChats[0].chatId, true);
    }
    const newChats = response.data.filter((chat) => !chatbotState.chatItems.find((i) => i.chatId === chat.chatId));
    chatbotState.setChatItems(chatbotState.chatItems.concat(newChats));
    setPagination({
      isEnd: response.lastEvaluatedKey ? false : true,
      lastEvaluatedKey: response.lastEvaluatedKey,
    });
  } catch (err) {
    console.log(err);
  } finally {
    setFetching(false);
  }
}

export async function handleSelectChat(chatId: string) {
  chatbotState.clearChatMessages(chatId);
  chatbotState.setChatProjects(chatId, null);
  chatbotState.setDisabledIngestChats(chatId, true);
  chatbotState.setSelectedChatId(chatId);
  const chat: ChatItem | null = await getChatDetailApi('token', chatId);
  if (!chat) {
    return;
  }
  chatbotState.addChatToChatItems(chat);
  if (chat.status === CHAT_STATUS.SUCCESS) {
    await getChatProject(chatId);
  }

  if (chat.status === CHAT_STATUS.PROCESSING) {
    const processingMessage: VideoChatMessage = {
      chatId,
      messageId: uuidV4(),
      author: AI_AUTHOR,
      type: MESSAGE_TYPE.VIDEO,
      status: MESSAGE_STATUS.PROCESSING,
      content: chatId,
      timestamp: Date.now() + 500,
    };
    pollSingleProjectStatus(processingMessage.chatId, true, (status) => {
      if (status === ProjectStatus.CREATED) {
        addChatSuccessMessages(processingMessage);
      }
    });
  }
}

export async function getShareChat(chatId: string) {
  const chat = await getChatDetailApi('token', chatId);
  if (chat) {
    chatbotState.setSelectedChatId(chatId);
    chatbotState.addChatToChatItems(chat);
  }
}

export async function sendRePromptMessage(chatId: string, storyAbout: string, email: string) {
  const repromtingMessage: TextChatMessage = {
    messageId: uuidV4(),
    chatId,
    author: AI_AUTHOR,
    timestamp: Date.now() + 200,
    type: MESSAGE_TYPE.TEXT,
    content: 'Editing started…',
  };
  const message: VideoChatMessage = {
    messageId: uuidV4(),
    chatId,
    author: AI_AUTHOR,
    timestamp: Date.now() + 200,
    type: MESSAGE_TYPE.VIDEO,
    content: '<video_edit_id>',
    status: MESSAGE_STATUS.PROCESSING,
  };

  try {
    await chatbotState.sendMessageToChat(repromtingMessage);
    await chatbotState.updateChatStatus(message.chatId, CHAT_STATUS.PROCESSING);
    await postProjectRetry(
      chatbotState.selectedChatId,
      '',
      'generate-ai-content',
      email,
      storyAbout,
      EditStrategies.STORIES,
      FormTypes.CHATBOT_FORM,
      undefined,
      true,
      false,
      true,
    );

    pollSingleProjectStatus(message.chatId, true, (status) => {
      handleChatRepromptCallback(status, message);
    });
  } catch (err) {
    chatbotState.getSelectedChat().messages.find((i) => i.messageId === message.messageId).status =
      MESSAGE_STATUS.REJECT;
    console.error('Error sending re-prompt messages', err);
  }
}

/**
 * Handle Message actions
 */
export async function sendTextMessage(
  chatId: string,
  inputText: string,
  author: string,
  setInputValue: (newValue: string) => void,
  setIsSendingMessage: (status: boolean) => void,
) {
  console.info('Submitting message', inputText);
  if (inputText.length < MIN_STORY_ABOUT_LEN || inputText.length > MAX_STORY_ABOUT_LEN) {
    const messageSnackKey = enqueueSnackbar(
      `Please enter your story about that must have from ${MIN_STORY_ABOUT_LEN} to ${MAX_STORY_ABOUT_LEN} characters`,
      {
        variant: 'warning',
        SnackbarProps: {
          onClick: () => closeSnackbar(messageSnackKey),
        },
      },
    );
    return;
  }
  setIsSendingMessage(true);
  const chat = chatbotState.chatItems.find((i) => i.chatId === chatId);

  const message: ChatMessage = {
    messageId: uuidV4(),
    chatId,
    author,
    timestamp: Date.now(),
    type: MESSAGE_TYPE.TEXT,
    content: inputText,
  };
  try {
    await Promise.all([
      chatbotState.sendMessageToChat(message),
      chatbotState.updateChatStatus(chatId, CHAT_STATUS.PROCESSING),
    ]);
    scrollToChatWindowBottom();
    if (chatbotState.disabledIngestChats[chatId]) {
      await sendRePromptMessage(chatId, inputText, author);
    }
    setInputValue('');
  } catch (err) {
    chat.messages.find((i) => i.messageId === message.messageId).status = MESSAGE_STATUS.REJECT;
    console.log(err);
  } finally {
    setIsSendingMessage(false);
  }
}

export async function getMessagesInfinity(
  chatId: string,
  appendBottom: boolean,
  setFetching,
  pagination: TPagination,
  setPagination,
) {
  try {
    setFetching(true);
    const response = await getMessagesApi('token', chatId, pagination.lastEvaluatedKey);
    // reverse message
    response.data.reverse();
    const chat = chatbotState.chatItems.find((i) => i.chatId === chatId);
    if (!chat) {
      return;
    }
    const newMessages: ChatMessage[] = response.data.filter(
      (i) => !chat?.messages.find((m) => m.messageId === i.messageId),
    );
    chatbotState.addMessagesToChat(newMessages, appendBottom);
    // check a message is processing
    if (chat.messages.length > 0) {
      let isDisabledIngest = false;
      // add messages to messagePlayers
      chat.messages.forEach((newMessage) => {
        if (newMessage.type === MESSAGE_TYPE.VIDEO) {
          addMessagePlayers(newMessage);
          isDisabledIngest = true;
        }
        if (chat.status === CHAT_STATUS.PROCESSING) {
          isDisabledIngest = true;
        }
        chatbotState.setDisabledIngestChats(chatId, isDisabledIngest);
      });
    }
    setPagination({
      isEnd: response.lastEvaluatedKey ? false : true,
      lastEvaluatedKey: response.lastEvaluatedKey,
    });
  } catch (err) {
    console.log(err);
  } finally {
    setFetching(false);
  }
}

// getChatProject function will handle get project data and update chatProject state
export async function getChatProject(chatId: string) {
  const projectJSON = await getProjectJson(chatId, 'token').catch((err) => console.log(err));
  const chat = chatbotState.chatItems.find((i) => i.chatId === chatId);
  if (!projectJSON || !chat) {
    handleChatNotFoundError(chatId);
  }
  const chatProject = chatbotState.chatProjects[chatId];
  const newChatProject: ChatProject = {
    segments: projectJSON['content']['segments'],
    videoEdits: projectJSON['content']['stories'],
    messagePlayers: chatProject?.messagePlayers || {}, // this value is Map type
    videoPlayerInfo: chatProject?.videoPlayerInfo,
  };

  // get presigned url
  if (!newChatProject?.videoPlayerInfo) {
    const videoPlayerS3Key = `${chatId}/${projectJSON['content']['project']['name']}/output.m3u8`;
    const presignedUrl = await getVideoPresignedUrl(videoPlayerS3Key).catch((error) => {
      console.error(`Failed to get presigned url for media key ${videoPlayerS3Key}`, error);
      return '';
    });
    newChatProject.videoPlayerInfo = {
      presignedUrl,
      aspectRatio: getVideoAspectRatio(
        Number(projectJSON['content']['project']['width']),
        Number(projectJSON['content']['project']['height']),
      ),
    };
  }
  chatbotState.setChatProjects(chatId, newChatProject);
  addVideoMessageListPlayers(chatId);
  return newChatProject;
}

function getVideoAspectRatio(videoWidth, videoHeight) {
  const gcd = greatestCommonDivisor(videoWidth, videoHeight);
  return `${videoWidth / gcd}:${videoHeight / gcd}`;
}

export function addMessagePlayers(message: VideoChatMessage) {
  const videoEditId = message.content;
  const chatProject = chatbotState.chatProjects[message.chatId];
  if (chatProject) {
    const videoEdit = chatProject.videoEdits.find((i) => i.id === videoEditId);
    const playerIntervals = videoEdit.segmentIds.map((segmentId) => {
      const segment = chatProject.segments.find((item) => item.id === segmentId);
      return {
        startTimeMs: segment.start * 1000,
        endTimeMs: segment.end * 1000,
        segmentId: segment.id,
        color: segment.color,
        words: segment.words,
        text: segment.text,
      };
    });
    chatbotState.setMessagePlayerInChatProject(message.chatId, message.messageId, {
      playerIntervals,
      player: null,
    });
  }
}

export function addVideoMessageListPlayers(chatId: string) {
  const chat = chatbotState.chatItems.find((i) => i.chatId === chatId);
  if (!chat) {
    return;
  }
  const videoMessages = chat.messages.filter(
    (message) => message.type === MESSAGE_TYPE.VIDEO && message.status === MESSAGE_STATUS.SUCCESS,
  ) as VideoChatMessage[];
  if (videoMessages.length > 0) {
    videoMessages.forEach((message) => addMessagePlayers(message));
  }
}

export function scrollToChatWindowBottom() {
  const { chatWindowUIElement } = chatbotState;
  if (chatWindowUIElement?.endElement && chatWindowUIElement?.messageListElement) {
    const scrollIntervalId = setInterval(() => {
      chatWindowUIElement.endElement?.scrollIntoView({ behavior: 'smooth' });
      if (
        chatWindowUIElement.messageListElement?.scrollHeight - chatWindowUIElement.messageListElement?.clientHeight >=
        chatWindowUIElement.messageListElement?.scrollTop
      ) {
        clearInterval(scrollIntervalId);
      }
    }, 500);
  }
}

async function handleChatRepromptCallback(status, message) {
  if (status === ProjectStatus.CREATED) {
    const chat = await getChatDetailApi('token', message.chatId);
    if (chat.status === CHAT_STATUS.SUCCESS) {
      await addChatSuccessMessages(message);
      return;
    }
  }
  const chat = chatbotState.chatItems.find((i) => i.chatId === message.chatId);
  if (chat) {
    const repromptFailedMessage: TextChatMessage = {
      chatId: chat.chatId,
      messageId: uuidV4(),
      type: MESSAGE_TYPE.TEXT,
      author: AI_AUTHOR,
      content: 'Shoot. There was an annoying error. Can you retry your prompt so I can attempt the edit again?',
      timestamp: Date.now(),
    };
    await chatbotState.sendMessageToChat(repromptFailedMessage);
    await chatbotState.updateChatStatus(repromptFailedMessage.chatId, CHAT_STATUS.REPROMPT_FAILED);
  }
  return;
}

export async function pollingExportJob(message: ChatMessage, exportJobId: string, exportType: string) {
  const { chatId } = message;

  try {
    const exportStartMessage: TextChatMessage = {
      messageId: uuidV4(),
      type: MESSAGE_TYPE.TEXT,
      timestamp: Date.now(),
      author: AI_AUTHOR,
      chatId,
      content: `Starting your export to ${exportType}`,
    };
    await chatbotState.sendMessageToChat(exportStartMessage);

    const exportResponse = await pollJob(exportJobId, 1000);
    if (exportResponse.status !== 'Success') {
      throw new Error("Export response was not 'success'.");
    } else {
      const exportAsset: ExportAsset = {
        type: exportType,
        assetLink: exportResponse.response.exportUri,
      };
      const chat = chatbotState.chatItems.find((i) => i.chatId === chatId);
      if (!chat) {
        handleChatNotFoundError(chatId);
        return;
      }
      const exportCompleteMessage: ExportChatMessage = {
        messageId: uuidV4(),
        type: MESSAGE_TYPE.EXPORT_ASSET,
        timestamp: Date.now(),
        author: AI_AUTHOR,
        chatId,
        content: JSON.stringify(exportAsset),
      };
      await chatbotState.sendMessageToChat(exportCompleteMessage);
    }
  } catch {
    const exportErrorMessage: TextChatMessage = {
      messageId: uuidV4(),
      type: MESSAGE_TYPE.TEXT,
      timestamp: Date.now(),
      author: AI_AUTHOR,
      chatId,
      content: `Your export to ${exportType} failed.`,
    };
    await chatbotState.sendMessageToChat(exportErrorMessage);
  }
}
