import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { projectsState } from '../../models/ProjectsState';
import { updateProjectName } from '../serverClients/ProjectClient';

export function handleKeyDown(
  event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  handleSaveProjectNameCallback: any,
) {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault(); // Prevents adding a new line
    event.currentTarget?.blur();
    handleSaveProjectNameCallback(event);
    return;
  }

  if (event.key === 'Escape') {
    event.currentTarget?.blur();
    event.currentTarget.value = projectsState.activeProject.displayName;
  }
}

export async function handleSaveProjectName(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
  const inputTarget = event.currentTarget ?? event.target;
  const value = inputTarget.value?.trim();
  if (!value) {
    const messageSnackKey = enqueueSnackbar('Project name is not empty', {
      variant: 'error',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    inputTarget.value = projectsState.activeProject.displayName;
    return;
  }
  if (value !== projectsState.activeProject.displayName) {
    try {
      await updateProjectName(projectsState.activeProject.id, value);
      projectsState.activeProject.displayName = value;
    } catch {
      const messageSnackKey = enqueueSnackbar('Oops! Could not update project name. Please try again.', {
        variant: 'error',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      inputTarget.value = projectsState.activeProject.displayName; // Revert to original name in case of error
    }
  }
}
