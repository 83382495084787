import { Box, Divider, Popover } from '@mui/material';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import '../navigation/Navigation.css';
import { useLocation } from 'react-router-dom';
import { SHARE_PATH } from '../../config/constants';
import ShareButton from '../ui_controls/buttons/ShareButton';
import ShareBody from '../menus/ShareBody';
import CollaborationMenu from '../menus/CollaborationMenu';
import { handleShareButtonClick, handleCloseShareModel } from '../../controllers/share/ShareModalHandler';
import { accountState } from '../../models/AccountState';

const ShareModal = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [updating, setUpdating] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'share-popover' : undefined;
  const { pathname } = useLocation();
  const isShare = pathname.startsWith(SHARE_PATH);
  const shareUrl = window.location.href.replace('/edit/', '/share/');
  const isAdmin = accountState.currentAccount.isAdmin;

  return (
    <Box>
      <ShareButton onClick={(e) => handleShareButtonClick(e, isShare, setAnchorEl)} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleCloseShareModel(setAnchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: 'project-settings-popover' }}
      >
        <Box sx={{ position: 'relative' }}>
          <ShareBody closeMenu={setAnchorEl} isUpdating={updating} setUpdating={setUpdating} url={shareUrl} />
          {isAdmin && (
            <>
              <Divider className="modal-share-devider" />
              <CollaborationMenu />
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
});

export default ShareModal;
