import React, { useRef } from 'react';
import { AI_AUTHOR, ChatMessage, ExportAsset, MESSAGE_STATUS, MESSAGE_TYPE } from '../../models/ChatMessage';
import { Avatar, Box, Typography } from '@mui/material';
import './ChatMessageView.css';
import EddyLogoIcon from '../ui_controls/icons/EddyLogoIcon';
import ChatVideoPlayer from '../players/ChatVideoPlayer';
import { useAuth0 } from '@auth0/auth0-react';
import { Error } from '@mui/icons-material';
import { observer } from 'mobx-react';
import { chatbotState } from '../../models/ChatbotState';
import VideoPlayerPlaceholder from './VideoPlayerPlaceholder';
import MessagePlayerFooter from './MessagePlayerFooter';
import { getPlayerDimension } from '../../controllers/players/ChatVideoPlayerHandler';
import { handleClickDownloadExportAsset } from '../../controllers/chat/ChatMessageViewHandler';
import DownloadExportButton from '../ui_controls/buttons/DownloadExportButton';

interface ChatMessageViewProps {
  message: ChatMessage;
  isReadOnly: boolean;
}

const ChatMessageView = observer((props: ChatMessageViewProps) => {
  const { user } = useAuth0();
  const rejectClassName = props.message.status === MESSAGE_STATUS.REJECT ? 'reject' : '';
  const isAIMessage = props.message.author === AI_AUTHOR;

  const currentIntervalIndex = useRef<number>(0);

  let messageContent = <></>;
  if (props.message.type === MESSAGE_TYPE.TEXT) {
    messageContent = (
      <Box className={`chat-message-view-content ${rejectClassName}`}>
        <Typography>{props.message.content}</Typography>
      </Box>
    );
  } else if (props.message.type === MESSAGE_TYPE.VIDEO) {
    if (props.message.status === MESSAGE_STATUS.SUCCESS) {
      const chatProject = chatbotState.chatProjects[props.message.chatId];
      const chat = chatbotState.chatItems.find((i) => i.chatId === props.message.chatId);
      const isLastVideoMessage =
        chat.messages.findIndex((i) => i.messageId === props.message.messageId) === chat.messages.length - 1;
      messageContent = (
        <Box className={`chat-message-view-content chat-message-view-video-container ${rejectClassName}`}>
          {chatProject?.videoPlayerInfo && chatProject.messagePlayers[props.message.messageId] ? (
            <ChatVideoPlayer
              url={chatProject.videoPlayerInfo.presignedUrl}
              aspectRatio={chatProject.videoPlayerInfo.aspectRatio}
              messageId={props.message.messageId}
              chatId={props.message.chatId}
              isLastVideoMessage={isLastVideoMessage}
              playerDimension={getPlayerDimension(chatProject.videoPlayerInfo.aspectRatio)}
              currentIntervalIndex={currentIntervalIndex}
            />
          ) : (
            <VideoPlayerPlaceholder />
          )}
          {chatProject?.videoPlayerInfo && chatProject.messagePlayers[props.message.messageId] && (
            <MessagePlayerFooter
              isReadOnly={props.isReadOnly}
              message={props.message}
              playerDimension={getPlayerDimension(chatProject.videoPlayerInfo.aspectRatio)}
              currentIntervalIndex={currentIntervalIndex}
            />
          )}
        </Box>
      );
    }
  } else if (props.message.type === MESSAGE_TYPE.EXPORT_ASSET) {
    const exportContent = JSON.parse(props.message.content) as ExportAsset;
    messageContent = (
      <Box className={`chat-message-view-content chat-message-view-video-container ${rejectClassName}`}>
        <DownloadExportButton
          exportType={exportContent.type}
          onClick={() => handleClickDownloadExportAsset(exportContent.assetLink)}
        />
      </Box>
    );
  }

  if (isAIMessage) {
    return (
      <Box className={`chat-message-view ai`}>
        <Box sx={{ flexShrink: 0 }}>
          <EddyLogoIcon width="32px" height="32px" fill="#079A7C" />
        </Box>
        {messageContent}
      </Box>
    );
  } else {
    return (
      <Box className={`chat-message-view author`}>
        {props.message.status === MESSAGE_STATUS.REJECT && <Error sx={{ marginLeft: 'auto', color: '#600303a0' }} />}
        {messageContent}
        <Box sx={{ flexShrink: 0, padding: '0 8px 0 16px' }}>
          <Avatar src={user?.picture} alt={user?.name} style={{ width: 32, height: 32 }} />
        </Box>
      </Box>
    );
  }
});

export default ChatMessageView;
