export function copyToClipboard(url, setIsCopied) {
  navigator.clipboard
    .writeText(url)
    .then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide the "Copied" text after 2 seconds
    })
    .catch((err) => {
      console.error('Failed to copy: ', err);
    });
}

export function handleShareButtonClick(event: React.MouseEvent<HTMLButtonElement>, isShare, setAnchorEl) {
  if (isShare) return;
  setAnchorEl(event.currentTarget);
}

export function handleCloseShareModel(setAnchorEl) {
  setAnchorEl(null);
}
