import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { fetchLogoPresignedURL } from '../serverClients/LogoClient';
import { getVideoEditCaptionPresignedUrl, uploadVideoFromLocalDevice } from '../serverClients/VideoIngestClient';
import { CaptionStylesEnum, SupportedStyles } from '../../models/CaptionsStyle';
import { mediaState } from '../../models/MediaState';
import { updateCaptionStyles } from '../serverClients/CaptionsClient';
import { pollJob } from '../serverClients/JobsClient';
import { accountState } from '../../models/AccountState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { projectsState } from '../../models/ProjectsState';
import { saveLogoCornerToProfile, saveMiddleBarColor } from '../serverClients/AccountClient';

async function updateStylesChanges() {
  const originalStyles = { ...mediaState.captionStyles }; // update origin style
  try {
    const projectId = projectsState.activeProject.id;
    const email = accountState.currentAccount.userID;
    const captionJob = await updateCaptionStyles(projectId, email, mediaState.captionStyles, 'token');
    const restult = await pollJob(captionJob.job_id, 1000);
    if (restult.response.status !== 'Success') throw new Error('job failed.');
    accountState.setCaptionStyles(mediaState.captionStyles);
  } catch (error) {
    console.log('update caption styles fail', error);
    mediaState.captionStyles = originalStyles;
  }
  if (videoPlayerState.overlayCaptionInstance) {
    const videoEditPresignedUrl = await getVideoEditCaptionPresignedUrl(
      projectsState.activeProjectId,
      projectsState.videoEditSelected.id,
      projectsState.videoEditSelected.strategy,
    ); // fetch new presigned url of captions
    const newSubContent = await fetch(videoEditPresignedUrl).then((response) => response.text());
    videoPlayerState.replaceSubtitleByAssContent(newSubContent); // reload overlay caption
    mediaState.setCaptionUrl(videoEditPresignedUrl);
  }
}

export async function handleStylesChange(value, style_name, debounceUpdateTimeoutRef) {
  if (!SupportedStyles.includes(style_name)) return;
  mediaState.captionStyles[style_name] = value;
  if (debounceUpdateTimeoutRef.current) clearTimeout(debounceUpdateTimeoutRef.current);
  // update caption style after 3s
  debounceUpdateTimeoutRef.current = setTimeout(async () => {
    await updateStylesChanges();
  }, 3000);
}

export async function handleValidateFontSize(
  value,
  setFontSize,
  debounceValidateFontSizeTimeoutRef,
  debounceUpdateTimeoutRef,
  minFontSize,
  maxFontSize,
) {
  setFontSize(value);
  if (debounceValidateFontSizeTimeoutRef.current) clearTimeout(debounceValidateFontSizeTimeoutRef.current);
  debounceValidateFontSizeTimeoutRef.current = setTimeout(async () => {
    if (Number(value) > maxFontSize || Number(value) < minFontSize) {
      const messageSnackKey = enqueueSnackbar(`Font size must be between ${minFontSize} and ${maxFontSize}.`, {
        variant: 'warning',
        SnackbarProps: {
          onClick: () => closeSnackbar(messageSnackKey),
        },
      });
      return;
    }
    await handleStylesChange(Number(value), CaptionStylesEnum.FONT_SIZE, debounceUpdateTimeoutRef);
  }, 1000);
}

const handleUploadEvent = async ({ event }, projectId, activeProject, setIsUploading) => {
  if (event !== 'onProgress') {
    const isError = event === 'onError';
    if (!isError) {
      await fetchLogoPresignedURL(projectId);
    }
    setIsUploading(false);
    const messageSnackKey = enqueueSnackbar(
      isError ? 'Oops! There was an error, please try again.' : 'Upload logo successfully. Please choose corner!',
      {
        variant: isError ? 'error' : 'success',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      },
    );
  }
};

export const handleUploadLogo = async (imageUpload, projectId, activeProject, setIsUploading) => {
  if (imageUpload) {
    const imageExt = imageUpload.name.split('.').pop();
    await uploadVideoFromLocalDevice(
      imageUpload,
      `${projectId}/logo_${activeProject.name}.${imageExt}`,
      'token',
      async (e) => await handleUploadEvent(e, projectId, activeProject, setIsUploading),
    );
  }
};

export const handleValidateLogo = (imageUpload) => {
  const imageAllowTypes = ['image/png', 'image/jpeg', 'image/gif'];
  if (imageUpload) {
    if (!imageUpload.type.startsWith('image/')) {
      const messageSnackKey = enqueueSnackbar('Image file is invalid!', {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      return false;
    }
  }

  if (imageUpload && !imageAllowTypes.includes(imageUpload.type)) {
    const messageSnackKey = enqueueSnackbar(
      'Image file type is not supported. Please choose another image (png, jpeg, gif).',
      {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      },
    );
    return false;
  }
  return true;
};

export const handleChooseCorner = async (newCorner, email, debounceUpdateCornerTimeoutRef) => {
  accountState.setCurrentAccount({
    ...accountState.currentAccount,
    logoCorner: newCorner,
  });
  if (debounceUpdateCornerTimeoutRef.current) clearTimeout(debounceUpdateCornerTimeoutRef.current);
  debounceUpdateCornerTimeoutRef.current = setTimeout(async () => {
    await saveLogoCornerToProfile(email, newCorner, 'token');
  }, 2000);
};

export const handleMiddleBarColorChange = async (newColor, email, debounceUpdateCornerTimeoutRef) => {
  accountState.setCurrentAccount({
    ...accountState.currentAccount,
    middleBarColor: newColor,
  });
  if (debounceUpdateCornerTimeoutRef.current) clearTimeout(debounceUpdateCornerTimeoutRef.current);
  debounceUpdateCornerTimeoutRef.current = setTimeout(async () => {
    await saveMiddleBarColor(email, newColor, 'token');
  }, 2000);
};
