import React from 'react';
import { Button } from 'reactstrap';

interface ConfirmButtonProps {
  label: string;
  onClick: () => void;
  isDisabled: boolean;
}

const ConfirmButton = (props: ConfirmButtonProps) => (
  <Button color="danger" style={{ border: 0 }} onClick={props.onClick} disabled={props.isDisabled}>
    {props.label}
  </Button>
);

export default ConfirmButton;
