import { Node, mergeAttributes } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { observer } from 'mobx-react';
import React from 'react';
import '../transcriptTextEditor.css';
import { UNPLAYED_WORD_COLOR } from './WordCustomNode';

export interface WordCustomOptions {
  HTMLAttributes: Record<string, any>;
}

const WordComponentView = observer(() => {
  return (
    <NodeViewWrapper>
      <NodeViewContent
        className="content"
        style={{ opacity: 0.4, fontSize: '0.9rem', color: UNPLAYED_WORD_COLOR }}
        as="span"
        contentEditable={false}
      />
    </NodeViewWrapper>
  );
});

export const WORD_EXTEND_NODE_NAME = 'word-extend';

export const WordExtendMoreNode = Node.create<WordCustomOptions>({
  name: WORD_EXTEND_NODE_NAME,

  priority: 1000,

  group: 'block',

  content: 'inline*',

  addAttributes() {
    return {
      segmentId: {
        default: 0,
      },
      wordIndex: {
        default: 0,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['react-component', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(WordComponentView);
  },
});
