import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, Container, List, ListInlineItem } from 'reactstrap';
import FinalCutProIcon from '../ui_controls/icons/FinalCutProIcon';
import AdobePremiereIcon from '../ui_controls/icons/AdobePremiereIcon';
import ExportMp4Icon from '../ui_controls/icons/ExportMp4Icon';
import ExportMp4IconLandscape from '../ui_controls/icons/ExportMp4IconLandscape';
import './Modal.css';
import { projectsState } from '../../models/ProjectsState';
import { Box, Divider, Typography } from '@mui/material';
import DavinciResolveIcon from '../ui_controls/icons/DavinciResolveIcon';
import SRTSubtitleIcon from '../ui_controls/icons/SRTSubtitleIcon';
import WordIcon from '../ui_controls/icons/WordIcon';
import { useAuth0 } from '@auth0/auth0-react';
import ExportMP4Snackbar from '../snackbars/ExportMP4Snackbar';
import { accountState } from '../../models/AccountState';
import { observer } from 'mobx-react';
import ExportOptions from '../export/ExportOptions';
import OtioIcon from '../ui_controls/icons/OtioIcon';
import { ProjectExportOptions } from '../../models/ProjectItem';
import {
  exportProject,
  hanldeExportOptionChange as handleExportOptionChange,
} from '../../controllers/export/ExportModalHandlers';

interface ExportModalProps {
  isActive: boolean;
  toggle: () => void;
}

export type ExportSelected = {
  type: string;
  extension: string;
  videoEditSegments?: number[];
  videoEditTitle?: string;
  includeTransparent: boolean;
  includeLandscape: boolean;
  includeVertical: boolean;
  include584Resolution: boolean;
  includeLogo: boolean;
  noCaption: boolean;
  exportOption: string;
};

const betaLabelStyle = {
  fontWeight: 700,
  color: '#39FF14',
  fontStyle: 'italic',
};

const defaultExportSettings = {
  type: null,
  extension: null,
  includeTransparent: true,
  include584Resolution: false,
  includeLogo: false,
  includeLandscape: true,
  includeVertical: false,
  noCaption: false,
  exportOption: ProjectExportOptions.ACTIVE, // default
};

const ExportModal = observer((props: ExportModalProps) => {
  const { user } = useAuth0();
  const account = accountState.currentAccount;
  const isInternalUser = account && account.isAdmin;
  const { isCallingUpdateSegment, activeProject, videoEditSelected, medias } = projectsState;
  const [exportSelected, setExportSelected] = useState<ExportSelected>(defaultExportSettings);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [toast, setToast] = useState<{
    isOpen: boolean;
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
  }>({
    isOpen: false,
    type: 'info',
    message: '',
  });
  const shouldShowExportOptions = projectsState.videoEditSelected?.strategy;

  useEffect(() => {
    if (shouldShowExportOptions) {
      setExportSelected((prev) => {
        return { ...prev, videoEditSegments: projectsState.videoEditSelected.segmentIds };
      });
    }
  }, [videoEditSelected]);

  useEffect(() => {
    if (activeProject) {
      exportSelected.noCaption = !activeProject.enableCaption;
    }
  }, [activeProject?.enableCaption]);

  useEffect(() => {
    if (exportSelected && exportSelected.exportOption && medias) {
      handleExportOptionChange(exportSelected.exportOption, exportSelected, setExportSelected);
    }
  }, [props.isActive]);

  function renderExportOptions() {
    return (
      <Container>
        <List
          type="inline"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            opacity: shouldShowExportOptions ? 1 : 0.5,
            pointerEvents: shouldShowExportOptions ? 'auto' : 'none',
          }}
        >
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'davinci' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'davinci', extension: 'xml' })}
          >
            <DavinciResolveIcon />
            <Typography>DaVinci Resolve</Typography>
          </ListInlineItem>
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'premiere' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'premiere', extension: 'xml' })}
          >
            <AdobePremiereIcon />
            <Typography>Premiere Pro</Typography>
          </ListInlineItem>
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'fcpxml' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'fcpxml', extension: 'fcpxml' })}
          >
            <FinalCutProIcon />
            <Typography>Final Cut Pro</Typography>
          </ListInlineItem>
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'srt' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'srt', extension: 'srt' })}
          >
            <SRTSubtitleIcon />
            <Typography>SRT Subtitles</Typography>
          </ListInlineItem>
        </List>
        <List
          type="inline"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {isInternalUser && (
            <>
              <ListInlineItem
                className={`export-item ${exportSelected.type === 'word' ? 'active' : ''}`}
                onClick={() => setExportSelected({ ...exportSelected, type: 'word', extension: 'docx' })}
                style={{
                  opacity: shouldShowExportOptions ? 1 : 0.5,
                  pointerEvents: shouldShowExportOptions ? 'auto' : 'none',
                }}
              >
                <WordIcon />
                <Typography>Word</Typography>
              </ListInlineItem>
              <ListInlineItem
                className={`export-item ${exportSelected.type === 'otio' ? 'active' : ''}`}
                onClick={() => setExportSelected({ ...exportSelected, type: 'otio', extension: 'otio' })}
                style={{
                  opacity: shouldShowExportOptions ? 1 : 0.5,
                  pointerEvents: shouldShowExportOptions ? 'auto' : 'none',
                }}
              >
                <OtioIcon />
                <Typography>OTIO</Typography>
              </ListInlineItem>
            </>
          )}
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'mp4_vertical' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'mp4_vertical', extension: 'mp4' })}
          >
            <ExportMp4Icon />
            <Typography>MP4 Video</Typography>
            <Typography>
              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} gap={0.5}>
                Vertical
                <Box style={betaLabelStyle}>beta</Box>
              </Box>
            </Typography>
          </ListInlineItem>
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'mp4' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'mp4', extension: 'mp4' })}
          >
            <ExportMp4IconLandscape />
            <Typography>MP4 Video</Typography>
            <Typography>Original</Typography>
          </ListInlineItem>
        </List>
      </Container>
    );
  }

  function resetDefaultModalState() {
    setExportSelected(defaultExportSettings);
    setErrorMessage('');
    setDownloading(false);
    setToast({
      isOpen: false,
      type: 'info',
      message: '',
    });
  }

  return (
    <Modal
      isOpen={props.isActive}
      toggle={() => {
        resetDefaultModalState();
        props.toggle();
      }}
      contentClassName="modal-export-content"
      size="lg"
      centered
    >
      <Box className="modal-export-header">
        <Typography sx={{ color: 'white', fontSize: 24 }}>
          Export {exportSelected.exportOption === 'active' && videoEditSelected?.title}
        </Typography>
      </Box>
      <ModalBody>
        {renderExportOptions()}
        <Divider
          variant="middle"
          sx={{
            backgroundColor: '#fff',
          }}
        />
      </ModalBody>
      <ModalFooter style={{ border: 0, justifyContent: 'flex-start' }}>
        <ExportOptions
          exportSelected={exportSelected}
          selectedOption={exportSelected.exportOption}
          onOptionChange={(value) => handleExportOptionChange(value, exportSelected, setExportSelected)}
          onIncludeTransparent={(value) =>
            setExportSelected((prevData) => ({ ...prevData, includeTransparent: value }))
          }
          onInclude584Resolution={(value) =>
            setExportSelected((prevData) => ({ ...prevData, include584Resolution: value }))
          }
          onIncludeLandscape={(value) => setExportSelected((prevData) => ({ ...prevData, includeLandscape: value }))}
          onIncludeVertical={(value) => setExportSelected((prevData) => ({ ...prevData, includeVertical: value }))}
          onIncludeLogo={(value) => setExportSelected((prevData) => ({ ...prevData, includeLogo: value }))}
          onNoCaptionChange={(value) => setExportSelected((prevData) => ({ ...prevData, noCaption: value }))}
        />
        <Button
          className="export-download-button"
          disabled={downloading}
          onClick={() =>
            exportProject(
              isCallingUpdateSegment,
              setErrorMessage,
              setDownloading,
              exportSelected,
              user,
              setToast,
              false,
            )
          }
        >
          {downloading ? 'Downloading...' : 'Download'}
        </Button>
        {errorMessage && <Typography color={'red'}>{errorMessage}</Typography>}
      </ModalFooter>
      <ExportMP4Snackbar toast={toast} setToast={setToast} />
    </Modal>
  );
});

export default ExportModal;
