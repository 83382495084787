import { useAuth0 } from '@auth0/auth0-react';
import { Box, Divider, FormControlLabel, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Container, List, ListInlineItem, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { exportProject } from '../../controllers/export/ExportModalHandlers';
import { ProjectExportOptions } from '../../models/ProjectItem';
import ExportMP4Snackbar from '../snackbars/ExportMP4Snackbar';
import ExportCheckbox from '../ui_controls/checkboxs/ExportCheckbox';
import AdobePremiereIcon from '../ui_controls/icons/AdobePremiereIcon';
import DavinciResolveIcon from '../ui_controls/icons/DavinciResolveIcon';
import ExportMp4IconLandscape from '../ui_controls/icons/ExportMp4IconLandscape';
import FinalCutProIcon from '../ui_controls/icons/FinalCutProIcon';
import './Modal.css';
import { VideoChatMessage } from '../../models/ChatMessage';
import { chatbotState } from '../../models/ChatbotState';
import { ExportSelected } from './ExportModal';
import { pollingExportJob, scrollToChatWindowBottom } from '../../controllers/chat/ChatWindowHandler';

interface ChatbotExportModalProps {
  message: VideoChatMessage;
  isActive: boolean;
  toggle: () => void;
}

const ChatbotExportModal = observer((props: ChatbotExportModalProps) => {
  const { user } = useAuth0();
  const [exportSelected, setExportSelected] = useState<ExportSelected>({
    type: null,
    extension: null,
    includeTransparent: false,
    include584Resolution: false,
    includeLogo: false,
    noCaption: true,
    exportOption: ProjectExportOptions.ACTIVE,
    includeLandscape: true,
    includeVertical: false,
  });
  const [downloading, setDownloading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [toast, setToast] = useState<{
    isOpen: boolean;
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
  }>({
    isOpen: false,
    type: 'info',
    message: '',
  });
  const isExportVideo = exportSelected.extension === 'mp4';

  useEffect(() => {
    const chatProject = chatbotState.chatProjects[props.message.chatId];
    if (exportSelected && exportSelected.exportOption && chatProject) {
      const segmentIds = chatProject.videoEdits.find((edit) => edit.id === props.message.content)?.segmentIds ?? [];
      setExportSelected({
        ...exportSelected,
        videoEditSegments: segmentIds,
        exportOption: ProjectExportOptions.ACTIVE,
      });
    }
  }, [props.isActive]);

  function renderExportOptions() {
    return (
      <Container>
        <List
          type="inline"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'davinci' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'davinci', extension: 'xml' })}
          >
            <DavinciResolveIcon />
            <Typography>DaVinci Resolve</Typography>
          </ListInlineItem>
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'premiere' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'premiere', extension: 'xml' })}
          >
            <AdobePremiereIcon />
            <Typography>Premiere Pro</Typography>
          </ListInlineItem>
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'fcpxml' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'fcpxml', extension: 'fcpxml' })}
          >
            <FinalCutProIcon />
            <Typography>Final Cut Pro</Typography>
          </ListInlineItem>
        </List>
        <List
          type="inline"
          style={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <ListInlineItem
            className={`export-item ${exportSelected.type === 'mp4' ? 'active' : ''}`}
            onClick={() => setExportSelected({ ...exportSelected, type: 'mp4', extension: 'mp4' })}
          >
            <ExportMp4IconLandscape />
            <Typography>MP4 Video</Typography>
            <Typography>Original</Typography>
          </ListInlineItem>
        </List>
      </Container>
    );
  }

  async function handleExport() {
    const exportJobId = await exportProject(
      false,
      setErrorMessage,
      setDownloading,
      exportSelected,
      user,
      setToast,
      true,
    );
    pollingExportJob(props.message, exportJobId, exportSelected.type);
  }

  return (
    <Modal
      isOpen={props.isActive}
      toggle={() => {
        props.toggle();
        scrollToChatWindowBottom();
      }}
      contentClassName="modal-export-content"
      size="lg"
      centered
    >
      <Box className="modal-export-header">
        <Typography sx={{ color: 'white', fontSize: 24 }}>Export</Typography>
      </Box>
      <ModalBody>
        {renderExportOptions()}
        <Divider
          variant="middle"
          sx={{
            backgroundColor: '#fff',
          }}
        />
      </ModalBody>
      <ModalFooter style={{ border: 0, justifyContent: 'flex-start' }}>
        {isExportVideo && (
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <ExportCheckbox
                checked={exportSelected.noCaption}
                onChange={(e) => setExportSelected((prevData) => ({ ...prevData, noCaption: e.target.checked }))}
              />
            }
            label="No Caption"
          />
        )}
        <Button className="export-download-button" disabled={downloading} onClick={handleExport}>
          {downloading ? 'Downloading...' : 'Download'}
        </Button>
        {errorMessage && <Typography color={'red'}>{errorMessage}</Typography>}
      </ModalFooter>
      <ExportMP4Snackbar toast={toast} setToast={setToast} />
    </Modal>
  );
});

export default ChatbotExportModal;
