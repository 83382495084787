import { Alert, Snackbar } from '@mui/material';
import React from 'react';

type TProps = {
  toast: {
    isOpen: boolean;
    type: 'info' | 'success' | 'warning' | 'error';
    message: string;
  };
  setToast: (args) => void;
};

function ExportMP4Snackbar({ toast, setToast }: TProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={toast.isOpen}
      autoHideDuration={6000}
      onClose={() => setToast((prev) => ({ ...prev, isOpen: false }))}
    >
      <Alert onClose={() => setToast((prev) => ({ ...prev, isOpen: false }))} severity={toast.type}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
}

export default ExportMP4Snackbar;
