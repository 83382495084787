import React from 'react';
import { Box } from '@mui/material';
import EddyLogoIcon from '../ui_controls/icons/EddyLogoIcon';
import './ChatMessageView.css';

function ProcessingMessageView() {
  return (
    <Box className={`chat-message-view ai`}>
      <Box sx={{ flexShrink: 0 }}>
        <EddyLogoIcon width="32px" height="32px" fill="#079A7C" />
      </Box>
      <Box className="chat-message-view-content">
        <Box className="typing-content">
          <Box id="dot1" className="typing-dots circle" />
          <Box id="dot2" className="typing-dots circle" />
          <Box id="dot3" className="typing-dots circle" />
        </Box>
      </Box>
    </Box>
  );
}

export default ProcessingMessageView;
