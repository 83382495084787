import { Box, ClickAwayListener, IconButton, Popper, Stack, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import FavoriteIcon from '../ui_controls/icons/FavoriteIcon';
import AIVideoEditStrategy from '../menus/AIVideoEditStrategy';
import { observer } from 'mobx-react';
import { projectsState } from '../../models/ProjectsState';
import {
  displayVideoEditDuration,
  handleUpdateFavoriteVideoEdit,
  handleUpdateVideoEditName,
} from '../../controllers/verticalVideoEditorCRUD/AIVideoEditItemHandler';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import ArrowRightIcon from '../ui_controls/icons/ArrowRightIcon';
import TextEditable from './TextEditable';
import './Navigation.css';
import { handleChangeMedia } from '../../controllers/verticalVideoEditorCRUD/VerticalVideoEditorHandler';
import { AI_EDIT_PATH } from '../../config/constants';
import { useLocation } from 'react-router-dom';
import SourceMediaIcon from '../ui_controls/icons/SourceMediaIcon';

const VideoEditBreadcrumb = observer(() => {
  const durationRef = useRef<HTMLDivElement>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { medias, videoEditSelected, getSelectedPrompt, activeProject, getVideoEditBySelected } = projectsState;
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const prompt = getSelectedPrompt();
  const isEdit = pathname.startsWith(AI_EDIT_PATH);

  const onTitleChange = (event, setEditValue) => {
    const value = event.target.value
      .replace(/<\/?[^>\s]+(?:\s[^>]*>|\/?>)/g, '')
      .replace(/&nbsp;/g, '')
      .replace(/\s+/g, ' ');
    setEditValue(value);
  };

  const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const updateVideoEditTitle = (value) => {
    handleUpdateVideoEditName(activeProject.id, getVideoEditBySelected(videoEditSelected), value, true, setIsUpdate);
  };

  const handleSelectMediaSource = (mediaId) => {
    handleChangeMedia(mediaId, setSearchParams);
  };

  return (
    <Stack className="nav-video-edit" direction={'row'}>
      <IconButton
        style={{ padding: 0,  pointerEvents: isEdit ? "auto" : "none"}}
        onClick={(e) => handleUpdateFavoriteVideoEdit(e, getVideoEditBySelected(videoEditSelected), true, user)}
      >
        <FavoriteIcon outline={!videoEditSelected.isFavorite} />
      </IconButton>
      <Box sx={{ flexGrow: 1 }}>
        <TextEditable
          value={videoEditSelected.isShowPrompt ? prompt?.prompt.storyAbout : videoEditSelected.title}
          onSaveValue={(value) => updateVideoEditTitle(value)}
          onChange={(event, setEditValue) => onTitleChange(event, setEditValue)}
          isUpdate={isUpdate}
          readOnly={!isEdit}
        />
      </Box>
      <Typography
        ref={durationRef}
        marginLeft={1}
        sx={{ padding: '5px 0px', opacity: '0.6', maxWidth: '80px', minWidth: '30px' }}
      >
        {displayVideoEditDuration(videoEditSelected.duration)}
      </Typography>
      <ClickAwayListener
        onClickAway={() => {
          if (open) setAnchorEl(null);
        }}
      >
        <Stack>
          <IconButton
            onClick={handleClickPopper}
            sx={{
              rotate: open && '90deg',
              width: '32px',
              height: '32px',
              '&:hover': {
                scale: '1.4',
              },
            }}
          >
            <ArrowRightIcon style={{ color: 'white', width: '100%', height: '100%' }} />
          </IconButton>
          <Popper id={id} open={open} anchorEl={anchorEl} className="nav-video-edit__popper" placement="bottom-end">
            {Object.values(medias).map((media, index) => {
              const mediaId = index;
              return (
                <Box className="nav-video-edit__popper-content">
                  <Stack direction={'row'} alignItems={'center'} gap={1} marginBottom={2}>
                    <SourceMediaIcon style={{ width: 24, height: 24 }} />
                    <Box onClick={() => handleSelectMediaSource(mediaId)} className="nav-video-edit__media-source">
                      <Typography style={{ fontSize: 18, fontWeight: 700 }}>{media.fileName}</Typography>
                    </Box>
                  </Stack>
                  {Object.keys(medias[mediaId].strategyOutputs).map((key) => (
                    <AIVideoEditStrategy strategy={key} mediaId={mediaId} showCheckIcon />
                  ))}
                </Box>
              );
            })}
          </Popper>
        </Stack>
      </ClickAwayListener>
    </Stack>
  );
});

export default VideoEditBreadcrumb;
