import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { Container } from 'reactstrap';

import { CHATBOT_PATH } from '../config/constants';
import { chatbotState } from '../models/ChatbotState';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getAccount } from '../controllers/serverClients/AccountClient';
import { accountState } from '../models/AccountState';

// Note: Auth is not enabled yet.
const ChatbotAuthLayout = () => {
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    const hasCrashed = localStorage.getItem('crashed');
    if (hasCrashed) {
      localStorage.removeItem('crashed');
      setTimeout(() => {
        const messageSnackKey = enqueueSnackbar('Oops! There was an error, please try again.', {
          variant: 'error',
          SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
        });
      }, 1000);
    }
  }, []);

  useEffect(() => {
    chatbotState.setIsPreview(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getCurrentAccount();
    }
  }, [isAuthenticated]);

  async function getCurrentAccount() {
    const currentUser = await getAccount(user.email, 'token');
    if (!currentUser) {
      return false;
    }
    accountState.setCurrentAccount(currentUser);
    return true;
  }

  return (
    <ErrorBoundary
      fallback={<></>}
      onError={() => {
        localStorage.setItem('crashed', 'true');
        window.location.href = CHATBOT_PATH;
      }}
    >
      <SnackbarProvider preventDuplicate anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
      <Container fluid className="noMarginPadding main-layout">
        <Outlet />
      </Container>
    </ErrorBoundary>
  );
};

export default withAuthenticationRequired(ChatbotAuthLayout);
