import React from 'react';
import { IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';

const UserDropdownMenu = () => {
  const { shutdown } = useIntercom();
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    if (!user) return;
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    shutdown();
    handleMenuClose();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <IconButton onClick={handleMenuOpen} color="inherit" style={{ marginRight: 10, padding: '3px 0' }}>
        <Avatar
          alt="Profile"
          src={user?.picture || '/logo.png'}
          sx={{
            width: 36,
            height: 36,
            boxShadow: '0 0 0 1px white',
            padding: 0,
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          style: {
            backgroundColor: '#1d253e',
            color: 'white',
          },
        }}
      >
        <MenuItem onClick={handleLogout} style={{ fontSize: '13px' }}>
          Sign out
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserDropdownMenu;
