import { serverAddress } from '../../config';
import { deleteOptions, getOptions, patchOptions, postOptions } from '../utils/FetchUtils';
import qs from 'qs';

export async function getChatsApi(accessToken: string, owner: string, lastEvaluatedKey?: any) {
  let endpoint = `${serverAddress}/api/chat?owner=${owner}`;
  if (lastEvaluatedKey) {
    endpoint += `&${qs.stringify({ lastEvaluatedKey: lastEvaluatedKey })}`;
  }

  const result = await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the get chat listing operation:', error.message);
    });

  return result;
}

export async function getChatDetailApi(accessToken: string, chatId: string) {
  const endpoint = `${serverAddress}/api/chat/${chatId}`;
  const result = await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the get chat listing operation:', error.message);
    });

  return result;
}

export async function createChatApi(accessToken: string, chat) {
  const endpoint = `${serverAddress}/api/chat`;

  const result = await fetch(endpoint, postOptions(endpoint, accessToken, chat))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the create chat operation:', error.message);
    });

  return result;
}

export async function deleteChatApi(accessToken, chatId: string, owner: string) {
  const endpoint = `${serverAddress}/api/chat/${chatId}?owner=${owner}`;
  const result = await fetch(endpoint, deleteOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the create chat operation:', error.message);
    });

  return result;
}

export async function updateChatStatusApi(accessToken: string, chatId: string, status: string) {
  const endpoint = `${serverAddress}/api/chat/${chatId}`;

  const result = await fetch(endpoint, patchOptions(endpoint, accessToken, { status }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the update chat operation:', error.message);
    });

  return result;
}

export const generateAIContent = async (
  projectId: string,
  newStoryAbout: string,
  email: string,
  runType: string,
  editStrategy: string,
) => {
  const url = `${serverAddress}/api/chat/generate-ai-content`;
  const storyGptReponse = await fetch(url, {
    method: 'POST',
    headers: {
      authorization: `Bearer {token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      projectId,
      newStoryAbout,
      email,
      runType,
      editStrategy,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error('There was a problem with the generate AI content:', error.message);
      throw error;
    });

  return storyGptReponse;
};
