import { Button } from '@mui/material';
import React from 'react';

interface Props {
  onClick: (e) => void;
  text: string;
  icon?: React.JSX.Element;
  style?: React.CSSProperties;
}

const MenuActionButton = (props: Props) => {
  return (
    <Button
      startIcon={props.icon}
      onClick={props.onClick}
      sx={
        props.style || {
          color: '#fff',
          textTransform: 'none',
          fontSize: 16,
          padding: '6px 12px',
          opacity: 0.6,
          '&:hover': {
            opacity: 1,
          },
        }
      }
    >
      {props.text}
    </Button>
  );
};

export default MenuActionButton;
