import { makeObservable, observable, action } from 'mobx';
import { TimelineState } from '@xzdarcy/react-timeline-editor';

export const defaultScale: number = 15;
export const delta = 20; // in ms

class VideoEditorState {
  constructor() {
    makeObservable(this, {
      timelineState: observable,
      setTimelineState: action,
      timelineScale: observable,
      setTimelineScale: action,

      // show
      showEditor: observable,
      toggleShowEditor: action,

      // set time
      setTimeCallbacks: observable,
      addSetTimeCallback: action,
      enableSetTimeCallbacks: observable,
      setEnableSetTimeCallbacks: action,
      setDisableSetTimeCallbacks: action,

      // play
      playCallbacks: observable,
      addPlayCallback: action,
      enablePlayCallbacks: observable,
      setEnablePlayCallbacks: action,
      setDisablePlayCallbacks: action,

      // pause
      pauseCallbacks: observable,
      addPauseCallback: action,
      enablePauseCallbacks: observable,
      setEnablePauseCallbacks: action,
      setDisablePauseCallbacks: action,
    });
  }

  showEditor: boolean = true;
  toggleShowEditor = () => {
    this.showEditor = !this.showEditor;
  };

  timelineState: TimelineState;
  timelineScale: number = defaultScale;

  // set time
  setTimeCallbacks: any[] = [];
  enableSetTimeCallbacks: string[] = [];
  setTimeCallbackReady: boolean = false;

  // play
  playCallbacks: any[] = [];
  enablePlayCallbacks: string[] = [];
  playCallbackReady: boolean = false;

  // pause
  pauseCallbacks: any[] = [];
  enablePauseCallbacks: string[] = [];
  pauseCallbackReady: boolean = false;

  setTimelineState = (state: TimelineState) => {
    this.timelineState = state;
  };

  setTimelineScale = (scale: number) => {
    this.timelineScale = scale;
  };

  addSetTimeCallback = (id, func) => {
    if (
      this.setTimeCallbacks.filter(({ idElem }) => {
        return id === idElem;
      }).length > 0
    ) {
      return;
    }

    this.initSetTimeCallback();
    this.setTimeCallbacks.push({ id, func });
  };

  addPlayCallback = (id, func) => {
    if (
      this.playCallbacks.filter(({ idElem }) => {
        return id === idElem;
      }).length > 0
    ) {
      return;
    }

    this.initPlayCallback();
    this.playCallbacks.push({ id, func });
  };

  addPauseCallback = (id, func) => {
    if (
      this.pauseCallbacks.filter(({ idElem }) => {
        return id === idElem;
      }).length > 0
    ) {
      return;
    }

    this.initPauseCallback();
    this.pauseCallbacks.push({ id, func });
  };

  setEnableSetTimeCallbacks = (id) => {
    this.enableSetTimeCallbacks.push(id);
  };

  setDisableSetTimeCallbacks = (id) => {
    const temp = this.enableSetTimeCallbacks.filter((elem) => elem !== id);
    this.enableSetTimeCallbacks = temp;
  };

  setEnablePlayCallbacks = (id) => {
    this.enablePlayCallbacks.push(id);
  };

  setDisablePlayCallbacks = (id) => {
    const temp = this.enablePlayCallbacks.filter((elem) => elem !== id);
    this.enablePlayCallbacks = temp;
  };

  setEnablePauseCallbacks = (id) => {
    this.enablePauseCallbacks.push(id);
  };

  setDisablePauseCallbacks = (id) => {
    const temp = this.enablePauseCallbacks.filter((elem) => elem !== id);
    this.enablePauseCallbacks = temp;
  };

  initSetTimeCallback = () => {
    if (this.timelineState && !this.setTimeCallbackReady) {
      //console.log("added setTime callback");
      this.timelineState.listener.on('afterSetTime', ({ time }) => {
        this.setTimeCallbacks.map(({ id, func }) => {
          if (this.enableSetTimeCallbacks.includes(id)) {
            func(time);
          }
          return func;
        });
      });
      this.setTimeCallbackReady = true;
    }
  };

  initPlayCallback = () => {
    if (this.timelineState && !this.playCallbackReady) {
      //console.log("added play callback");
      this.timelineState.listener.on('play', () => {
        this.playCallbacks.map(({ id, func }) => {
          if (this.enablePlayCallbacks.includes(id)) {
            func();
          }
          return func;
        });
      });
      this.playCallbackReady = true;
    }
  };

  initPauseCallback = () => {
    if (this.timelineState && !this.pauseCallbackReady) {
      //console.log("added pause callback");
      this.timelineState.listener.on('paused', () => {
        this.pauseCallbacks.map(({ id, func }) => {
          if (this.enablePauseCallbacks.includes(id)) {
            func();
          }
          return func;
        });
      });
      this.pauseCallbackReady = true;
    }
  };
}

export const videoEditorState = new VideoEditorState();
