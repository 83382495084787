export const AI_AUTHOR = 'AI_AUTHOR';
export enum MESSAGE_TYPE {
  TEXT = 'text',
  VIDEO = 'video',
  EXPORT_ASSET = 'export_asset',
}

export enum MESSAGE_STATUS {
  REJECT = 'reject',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum CHAT_STATUS {
  CREATED = 'created',
  SUCCESS = 'success',
  PROCESSING = 'processing',
  INGEST_FAILED = 'ingest_failed',
  REPROMPT_FAILED = 'reprompt-failed',
}

interface BaseChatMessage {
  chatId: string;
  timestamp: number;
  messageId: string;
  author: string;
  type: MESSAGE_TYPE;
  content: string;
  status?: MESSAGE_STATUS;
}

export interface VideoChatMessage extends BaseChatMessage {
  type: MESSAGE_TYPE.VIDEO;
}

export interface TextChatMessage extends BaseChatMessage {
  type: MESSAGE_TYPE.TEXT;
}

export interface ExportChatMessage extends BaseChatMessage {
  type: MESSAGE_TYPE.EXPORT_ASSET;
}

export interface ExportAsset {
  type: string;
  assetLink: string;
}

export type ChatMessage = VideoChatMessage | TextChatMessage | ExportChatMessage;
