import React from 'react';
const icon = require('../../../assets/icons/file-video-landscape.png');

const ExportMp4IconLandscape = () => (
  <img
    src={icon}
    alt="mp4 file landscape"
    className="mp4-landscape"
    style={{ height: 70, width: 100, borderRadius: 5 }}
  />
);

export default ExportMp4IconLandscape;
