import React, { useState, useEffect } from 'react';
import { Button, Popover, Box } from '@mui/material';
import { SketchPicker } from 'react-color';
import { observer } from 'mobx-react';
import { PresetColorsEnum, PresetColors } from '../../models/CaptionsStyle';
import './Modal.css';

const DISABLE_COLOR = '#CECECE';

type TProps = {
  color: string; // hex value
  setColor: any;
  showDisableOption?: boolean;
  name?: string;
  disableAlpha?: boolean;
  disabled?: boolean;
};

const CustomColorPicker = observer((props: TProps) => {
  const [dislayColor, setDisplayColor] = useState(props.color);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newColor) => {
    props.setColor(newColor.hex, props.name);
  };

  const handleDisable = () => {
    props.setColor(PresetColorsEnum.disable, props.name);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.color) {
      if (props.color.includes(PresetColorsEnum.disable)) {
        setDisplayColor(DISABLE_COLOR.toUpperCase());
      } else {
        setDisplayColor(props.color.toUpperCase());
      }
    }
  }, [props.color]);

  return (
    <div>
      <Button
        onClick={handleClick}
        className="modal-color-picker-button"
        disabled={props.disabled}
        style={{
          color: props.disabled ? 'gray' : dislayColor,
          textTransform: 'none',
          backgroundColor: dislayColor,
        }}
      ></Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <SketchPicker
            color={props.color}
            onChange={handleChange}
            disableAlpha={props.disableAlpha}
            presetColors={PresetColors}
          />
        </Box>
        {props.showDisableOption && (
          <Box>
            <Button onClick={handleDisable} style={{ width: '100%', height: '100%', textTransform: 'none' }}>
              disable
            </Button>
          </Box>
        )}
      </Popover>
    </div>
  );
});

export default CustomColorPicker;
