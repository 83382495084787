import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { chatbotState } from '../../models/ChatbotState';
import { VideoChatMessage } from '../../models/ChatMessage';
import { PLAYING_WORD_COLOR, UNPLAYED_WORD_COLOR } from '../transcript/CustomNode/WordCustomNode';
import ExpandLessIcon from '../ui_controls/icons/ExpandLessIcon';
import ExpandMoreIcon from '../ui_controls/icons/ExpandMoreIcon';
import ExportButton from '../ui_controls/buttons/ExportButton';
import ChatbotExportModal from '../modals/ChatbotExportModal';
import { displayVideoEditDuration } from '../../controllers/verticalVideoEditorCRUD/AIVideoEditItemHandler';
import { getTotalDurationOfPlayerIntervals } from '../../controllers/players/ChatVideoPlayerHandler';

interface MessagePlayerFooterProps {
  playerDimension: any;
  message: VideoChatMessage;
  isReadOnly?: boolean;
  currentIntervalIndex?: React.MutableRefObject<number>;
}

const MessagePlayerFooter = observer((props: MessagePlayerFooterProps) => {
  const chatProject = chatbotState.chatProjects[props.message.chatId];
  const { playerIntervals, player } = chatProject.messagePlayers[props.message.messageId];
  const [isViewMore, setIsViewMore] = useState<boolean>(false);
  const displayViewMore = playerIntervals.length > 2;
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const toggleExportModal = () => setOpenExportModal(!openExportModal);
  const [playerTime, setPlayerTime] = useState<number>(0);

  useMemo(() => {
    if (player) {
      player.on('timeupdate', () => {
        setPlayerTime(player.currentTime());
      });
    }
  }, [player]);

  const handleClickSegment = (playerIntervalIndex: number) => {
    if (player) {
      if (typeof props.currentIntervalIndex.current === 'number') {
        props.currentIntervalIndex.current = playerIntervalIndex;
      }
      player.currentTime(playerIntervals[playerIntervalIndex].startTimeMs / 1000);
      player.trigger('custom-event-jump-segment');
    }
  };

  const renderTranscriptSegments = () => {
    return playerIntervals.slice(0, isViewMore ? playerIntervals.length : 2).map((messagePlayer, idx) => {
      const isPlayingSegment =
        playerTime >= messagePlayer.startTimeMs / 1000 &&
        playerTime <= messagePlayer.endTimeMs / 1000 &&
        idx === props.currentIntervalIndex.current;
      return (
        <Box sx={{ marginBottom: '8px', cursor: 'pointer' }} key={idx} onClick={() => handleClickSegment(idx)}>
          <Typography fontSize={12} color={isPlayingSegment ? PLAYING_WORD_COLOR : UNPLAYED_WORD_COLOR}>
            {moment.utc(messagePlayer.startTimeMs).format('m:ss')} -{' '}
            {moment.utc(messagePlayer.endTimeMs).format('m:ss')}
          </Typography>
          <Typography color={isPlayingSegment ? PLAYING_WORD_COLOR : UNPLAYED_WORD_COLOR}>
            {messagePlayer.text}
          </Typography>
        </Box>
      );
    });
  };

  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        width: props.playerDimension.width,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        {!props.isReadOnly && (
          <>
            <ExportButton
              showIcon={false}
              onClick={toggleExportModal}
              style={{
                backgroundColor: '#079A7C',
                border: 'none',
                marginTop: '0.5rem',
                textTransform: 'none',
                color: '#fff',
              }}
            />
            <ChatbotExportModal message={props.message} isActive={openExportModal} toggle={toggleExportModal} />
          </>
        )}
        <Typography fontSize={14} sx={{ whiteSpace: 'nowrap', marginLeft: '3px' }}>
          {displayVideoEditDuration(getTotalDurationOfPlayerIntervals(playerIntervals))}
        </Typography>
      </Box>
      {renderTranscriptSegments()}
      {displayViewMore && (
        <span
          style={{ fontStyle: 'italic', cursor: 'pointer' }}
          onClick={() => {
            setIsViewMore(!isViewMore);
          }}
        >
          {!isViewMore && <Typography color={UNPLAYED_WORD_COLOR}>.....</Typography>}
          <>View transcript {isViewMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>
        </span>
      )}
    </Box>
  );
});

export default MessagePlayerFooter;
