import { Typography } from '@mui/material';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

type TProps = {
  label?: string;
  labelStyle?: any;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  name: string;
  onChange?: (arg) => void;
  placeholder?: string;
  type?: InputType;
  multiple?: boolean;
};

function FormInput({
  label,
  labelStyle,
  minLength,
  maxLength,
  required,
  name,
  onChange,
  placeholder,
  type,
  multiple,
}: TProps) {
  return (
    <FormGroup>
      <Label style={labelStyle}>
        {label}
        {required && <Typography style={{ display: 'inline-block' }}>*</Typography>}
      </Label>
      <Input
        className="input"
        style={{
          backgroundColor: '#1F2726',
          border: 0,
          color: 'white',
          maxHeight: 100,
        }}
        type={type}
        name={name}
        onInputCapture={(e) => {
          const target = e.target as HTMLInputElement;
          onChange(target.value);
        }}
        minLength={minLength || 0}
        maxLength={maxLength || 255}
        required={required ? true : false}
        placeholder={placeholder}
        multiple={multiple}
      />
    </FormGroup>
  );
}

export default FormInput;
