import React from 'react';
import CaptionIcon from '../icons/CaptionIcon';
import MenuActionButton from './MenuActionButton';

type Props = {
  onClick: (event) => void;
};

const CaptionStyleButton = ({ onClick }: Props) => {
  return <MenuActionButton onClick={onClick} text="Captions" icon={<CaptionIcon color="#fff" />} />;
};

export default CaptionStyleButton;
