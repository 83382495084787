import { useCallback, useMemo } from 'react';

export function useDebounce(callback, timeout) {
  const debouncedCallback = useMemo(() => {
    let timeoutId = null;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback(...args);
      }, timeout);
    };
  }, []);

  return useCallback(debouncedCallback, [debouncedCallback]);
}
