import SubtitlesOctopus from 'libass-wasm';
import { ListFontFiles } from '../../models/CaptionsStyle';
import { projectsState } from '../../models/ProjectsState';
import { videoPlayerState } from '../../models/VideoPlayerState';

export function updateCaptionTime(captionOverlayRef) {
  try {
    captionOverlayRef.current.setCurrentTime(videoPlayerState.currentTime);
  } catch (err) {
    console.log('Error updating current time of the captions overlay', err);
  }
}

export async function createOverlayCaptionInstance(
  playerContainerRef,
  captionOverlayParentRef,
  captionUrl,
  canvasRef,
  captionOverlayRef,
  videoElementAttribute,
) {
  try {
    playerContainerRef.current.insertAdjacentElement('afterend', captionOverlayParentRef.current);
    const subContent = await fetch(captionUrl).then((response) => response.text());
    const options = {
      canvas: canvasRef.current, // canvas element
      subContent: subContent,
      fonts: ListFontFiles,
      workerUrl: '/libass/subtitles-octopus-worker.js',
    };
    captionOverlayRef.current = new SubtitlesOctopus(options);
    videoPlayerState.setOverlayInstance(captionOverlayRef.current);
    adjustCaptionPosition(videoElementAttribute, captionOverlayParentRef, canvasRef, captionOverlayRef);
  } catch (err) {
    console.error(`Error creating Overlay Caption instance: ${err}`);
  }
}

function getVideoPosition(aspectRatio, videoElementAttribute) {
  const videoRatio =
    projectsState.activeProject.width / projectsState.activeProject.height || eval(aspectRatio?.replace(':', '/') || 1);
  const { width, height } = videoElementAttribute;
  const elementRatio = width / height;
  let realWidth = width;
  let realHeight = height;
  if (elementRatio > videoRatio) realWidth = Math.floor(height * videoRatio);
  else realHeight = Math.floor(width / videoRatio);

  const x = (width - realWidth) / 2;
  const y = (height - realHeight) / 2;

  return {
    width: realWidth,
    height: realHeight,
    x: x,
    y: y,
  };
}

function computeCanvasSize(width, height) {
  const defaultSettings = {
    prescaleFactor: 1,
    prescaleHeightLimit: 1080,
    maxRenderHeight: 0,
  };
  const scalefactor = defaultSettings.prescaleFactor <= 0 ? 1.0 : defaultSettings.prescaleFactor;

  if (height <= 0 || width <= 0) {
    width = 0;
    height = 0;
  } else {
    const sgn = scalefactor < 1 ? -1 : 1;
    let newH = height;
    if (sgn * newH * scalefactor <= sgn * defaultSettings.prescaleHeightLimit) newH *= scalefactor;
    else if (sgn * newH < sgn * defaultSettings.prescaleHeightLimit) newH = defaultSettings.prescaleHeightLimit;

    if (defaultSettings.maxRenderHeight > 0 && newH > defaultSettings.maxRenderHeight)
      newH = defaultSettings.maxRenderHeight;

    width *= newH / height;
    height = newH;
  }

  return { width: width, height: height };
}

function adjustCaptionPosition(videoElementAttribute, captionOverlayParentRef, canvasRef, captionOverlayRef) {
  let videoSize = null;
  let top = 0;
  let left = 0;
  const aspectRatio = videoPlayerState.player?.aspectRatio();
  videoSize = getVideoPosition(aspectRatio, videoElementAttribute);
  const pixelRatio = window.devicePixelRatio || 1;
  const newSize = computeCanvasSize(videoSize.width * pixelRatio, videoSize.height * pixelRatio);
  const width = newSize.width;
  const height = newSize.height;
  const offset = captionOverlayParentRef.current.getBoundingClientRect().top - videoElementAttribute.top;
  top = videoSize.y - offset;
  left = videoSize.x;

  if (
    canvasRef.current.width != width ||
    canvasRef.current.height != height ||
    canvasRef.current.style.top != top + 'px' ||
    canvasRef.current.style.left != left + 'px'
  ) {
    canvasRef.current.width = width;
    canvasRef.current.height = height;
    if (videoSize) {
      captionOverlayParentRef.current.style.position = 'relative';
      canvasRef.current.style.display = 'block';
      canvasRef.current.style.position = 'absolute';
      canvasRef.current.style.width = videoSize.width + 'px';
      canvasRef.current.style.height = videoSize.height + 'px';
      canvasRef.current.style.top = top + 'px';
      canvasRef.current.style.left = left + 'px';
      canvasRef.current.style.pointerEvents = 'none';
    }
    captionOverlayRef.current.worker.postMessage({
      target: 'canvas',
      width: canvasRef.current.width,
      height: canvasRef.current.height,
    });
  }
}
