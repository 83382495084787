import { Box, Card, Stack, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AI_EDIT_PATH } from '../../config/constants';
import ProjectItem from '../../models/ProjectItem';
import TrashBinButton from '../ui_controls/buttons/TrashBinButton';
import DeleteProjectModal from '../modals/DeleteProjectModal';
import { accountState } from '../../models/AccountState';
import ProjectRetryButton from '../ui_controls/buttons/ProjectRetryButton';
import { postProjectRetry } from '../../controllers/serverClients/VideoIngestClient';
import { ProjectStatus } from '../../models/ProjectItem';
import { projectsState } from '../../models/ProjectsState';
import { useAuth0 } from '@auth0/auth0-react';
import { isIngestFileFailed, isProjectProcessing } from '../../controllers/projectsCRUD/ProjectPageHandler';
import ProjectProcessingIcon from '../ui_controls/icons/ProjectProcessingIcon';

const ProjectsTableRow = observer((props: { projectItem: ProjectItem }) => {
  const { projectItem } = props;
  const navigate = useNavigate();
  const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
  const [disableRetry, setDisableRetry] = useState(false);
  const isAdmin = accountState?.currentAccount?.isAdmin;
  const { user } = useAuth0();

  const onClickHandler = (e) => {
    e.stopPropagation();
    if (projectItem?.displayStatus === ProjectStatus.PROCESSING) {
      const messageSnackKey = enqueueSnackbar('Project is processing!', {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      return;
    }
    if (projectItem?.displayStatus === ProjectStatus.FAILED) {
      const messageSnackKey = enqueueSnackbar('Project failed. Please contact support.', {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      return;
    }
    navigate(`${AI_EDIT_PATH}/${projectItem.id}`);
  };

  const onRetryProject = async (e, status) => {
    e.stopPropagation();
    setDisableRetry(true);
    const runType = isIngestFileFailed(status) ? 'retry' : 'rerun';
    const retryProject = await postProjectRetry(projectItem.id, projectItem.name, runType, user.email);
    projectsState.updateProjectItem({
      ...projectItem,
      status: retryProject.status,
      displayStatus: ProjectStatus.PROCESSING,
      lastModifiedMs: new Date().valueOf(),
    });
  };

  const onDeleteProjectModalClick = (e) => {
    e.stopPropagation();
    setIsDeleteModalActive(true);
  };

  return (
    <Box sx={{ height: '35vh', maxWidth: '420px', maxHeight: '321px', margin: 'auto' }}>
      <Card className="project-item-card" onClick={onClickHandler}>
        {projectItem.thumbnail && (
          <img
            src={projectItem.thumbnail}
            alt="thumbnail"
            style={{ height: '100%', width: '100%', objectFit: 'cover', opacity: 0.6 }}
          />
        )}
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          width={'100%'}
          position={'absolute'}
          bottom={0}
          padding={'10px 20px'}
        >
          <Typography
            color={'white'}
            fontFamily={'Geist'}
            fontSize={24}
            textOverflow={'ellipsis'}
            paddingRight={'40px'}
          >
            {projectItem.displayName}
          </Typography>
          <Stack direction={'row'}>
            {isAdmin && (
              <>
                <ProjectRetryButton
                  onClick={(e) => {
                    onRetryProject(e, projectItem.status);
                  }}
                  disable={disableRetry || isProjectProcessing(projectItem.status)}
                  style={{
                    color: 'white',
                    opacity: disableRetry || isProjectProcessing(projectItem.status) ? 0.2 : 1,
                  }}
                />
              </>
            )}
            <TrashBinButton
              onClick={onDeleteProjectModalClick}
              style={{
                backgroundColor: '#12121280',
                borderRadius: 12,
                padding: 10,
              }}
            />
          </Stack>
        </Stack>
        {projectItem.displayStatus === ProjectStatus.PROCESSING && (
          <Box className="project-processing">
            <ProjectProcessingIcon className={'project-processing-icon-spinning'} />
          </Box>
        )}
      </Card>
      <DeleteProjectModal
        isActive={isDeleteModalActive}
        toggle={() => setIsDeleteModalActive((isOpen) => !isOpen)}
        projectItem={projectItem}
      />
    </Box>
  );
});

export default ProjectsTableRow;
