interface ProjectIngestPayload {
  projectUUID: string;
  projectName: string;
  storyAbout: string;
  email: string;
  inputFiles: Array<{
    fileURI: string;
    filePath: string;
    fileName: string;
  }>;
  formType: string;
  editType: string;
  editStrategy: string;
  duration: string;
  youtubeUrl: string;
}

interface ProjectItem {
  id: string;
  name?: string;
  displayName?: string;
  durationMs: number;
  lastModifiedMs: number;
  createdMs: number;
  owner: string;
  fps?: number;
  width?: number;
  height?: number;
  timecode?: string;
  status: any;
  displayStatus?: string;
  surveyBy: Array<string>;
  originalData?: ProjectIngestPayload;
  enableCaption?: boolean;
  thumbnail?: string;
}

export enum ProjectStatus {
  PENDING = 'pending',
  PROCESSING = 'processing',
  CREATED = 'created',
  FAILED = 'failed',
  RE_PROMPTING = 're-prompting',
}

export enum ProjectExportOptions {
  ALL = 'all', // all segmentIDs
  ACTIVE = 'active', // segmentIDs of active videoEditItem
  FAVORITES = 'favorites', // segmentIDs of favorited videoEditItem
}

export interface ProjectSettings {
  menu: {
    popper: {
      mediaId: number;
      promptId: number;
      strategy: string;
      status: 'open' | 'collapse';
    }[];
  };
}

export const DEFAULT_SETTINGS: ProjectSettings = {
  menu: {
    popper: [],
  },
};

export default ProjectItem;
