import { action, makeObservable, observable } from 'mobx';
import { CHAT_STATUS, ChatMessage } from './ChatMessage';
import { ChatItem, ChatProject, MessagePlayer } from './ChatItem';
import { handleChatNotFoundError } from '../controllers/chat/ChatbotPageHandler';
import { createChatApi, updateChatStatusApi } from '../controllers/serverClients/ChatClient';
import { createMessageApi } from '../controllers/serverClients/MessageClient';
import { scrollToChatWindowBottom } from '../controllers/chat/ChatWindowHandler';

class ChatbotState {
  selectedChatId: string;
  chatItems: ChatItem[];
  shouldShowRequestAccessModal: boolean;
  isPreview: boolean;
  shouldShowIngestModal: boolean;
  disabledIngestChats: Record<string, boolean>; // <chatId, boolean>
  chatProjects: Record<string, ChatProject>; // <chatId, ChatProject>
  pollingIntervals: Record<string, number>; // <chatId, intervalId>

  chatWindowUIElement: {
    messageListElement: any;
    endElement: any;
  };

  constructor() {
    this.chatItems = [];
    this.selectedChatId = null;
    this.shouldShowRequestAccessModal = false;
    this.shouldShowIngestModal = false;
    this.disabledIngestChats = {};
    this.chatWindowUIElement = null;
    this.chatProjects = {};
    this.pollingIntervals = {};

    makeObservable(this, {
      chatItems: observable,
      selectedChatId: observable,
      shouldShowRequestAccessModal: observable,
      isPreview: observable,
      getSelectedChat: observable,
      shouldShowIngestModal: observable,
      disabledIngestChats: observable,
      chatProjects: observable,
      chatWindowUIElement: observable,
      pollingIntervals: observable,
      setShouldShowRequestAccessModal: action,
      addMessagesToChat: action,
      setChatItems: action,
      setIsPreview: action,
      setShouldShowIngestModal: action,
      setSelectedChatId: action,
      removeMessageInChat: action,
      setDisabledIngestChats: action,
      setChatProjects: action,
      setChatWindowUIElement: action,
      addChatToChatItems: action,
      updateChatStatus: action,
      setMessagePlayerInChatProject: action,
      sendMessageToChat: action,
      createChat: action,
      clearChatMessages: action,
      clearChatState: action,
    });
  }

  setShouldShowRequestAccessModal = (shouldShowRequestAccessModal: boolean) => {
    this.shouldShowRequestAccessModal = shouldShowRequestAccessModal;
  };

  createChat = async (chat: ChatItem) => {
    try {
      await createChatApi('token', chat);
      this.chatItems.unshift(chat);
      return chat;
    } catch (err) {
      console.error(`Failed to create chat: ${chat.chatId}:`, err);
    }
  };

  getSelectedChat = () => {
    if (this.chatItems.length === 0 || this.selectedChatId === null) {
      return undefined;
    }
    return this.chatItems.find((i) => i.chatId === this.selectedChatId);
  };

  addMessagesToChat = (messages: ChatMessage[], appendBottom: boolean = true) => {
    if (messages.length === 0) {
      return;
    }
    const targetChat = this.chatItems.find((i) => i.chatId === messages[0].chatId);
    if (!targetChat) {
      return;
    }
    if (appendBottom) {
      targetChat.messages.push(...messages);
    } else {
      targetChat.messages.unshift(...messages);
    }
  };

  /**
   * Insert message to bottom of message list in state and also call API to add message in chat
   * @param message : ChatMessage
   */
  sendMessageToChat = async (message: ChatMessage) => {
    try {
      await createMessageApi('token', message);
      this.addMessagesToChat([message]);
      scrollToChatWindowBottom();
    } catch (err) {
      console.error(`Failed to send message for chatId: ${message.chatId}: `, err);
    }
  };

  setChatItems = (chatItems: ChatItem[]) => {
    this.chatItems = chatItems;
  };

  addChatToChatItems = (chat: ChatItem) => {
    let targetChat = chatbotState.chatItems.find((i) => i.chatId === chat.chatId);
    if (!targetChat) {
      chatbotState.setChatItems([chat, ...chatbotState.chatItems]);
    } else {
      targetChat = { ...targetChat, ...chat };
    }
  };

  setIsPreview = (isPreview: boolean) => {
    this.isPreview = isPreview;
  };

  setShouldShowIngestModal(shouldShowIngestModal: boolean) {
    this.shouldShowIngestModal = shouldShowIngestModal;
  }

  setSelectedChatId = (chatId: string) => {
    this.selectedChatId = chatId;
  };

  removeMessageInChat = (message: ChatMessage) => {
    const targetChat = this.chatItems.find((i) => i.chatId === message.chatId);
    if (!targetChat) {
      handleChatNotFoundError(message.chatId);
    }
    const messageIndex = targetChat.messages.findIndex((mes) => mes.messageId === message.messageId);
    if (messageIndex > -1) {
      targetChat.messages.splice(messageIndex, 1);
    }
  };

  setDisabledIngestChats = (chatId: string, disabled: boolean) => {
    this.disabledIngestChats[chatId] = disabled;
  };

  setChatProjects = (chatId: string, chatProject: ChatProject) => {
    this.chatProjects[chatId] = chatProject;
  };

  setChatWindowUIElement = ({ messageListElement, endElement }) => {
    this.chatWindowUIElement = {
      messageListElement,
      endElement,
    };
  };

  /**
   * Updates the status of a chat in the state and calls the API to update the status.
   *
   * @param chatId - The ID of the chat to update.
   * @param status - The new status of the chat.
   */
  updateChatStatus = async (chatId: string, status: CHAT_STATUS) => {
    const chat = this.chatItems.find((i) => i.chatId === chatId);
    if (chat) {
      try {
        await updateChatStatusApi('token', chatId, status);
        chat.status = status;
      } catch (err) {
        console.error(`Failed to update chat status for chatId: ${chatId}: `, err);
      }
    }
  };

  clearChatMessages = (chatId: string) => {
    const targetChat: ChatItem | null = this.chatItems.find((i) => i.chatId === chatId);
    if (targetChat) {
      targetChat.messages = [];
    }
  };

  clearChatState = (chatId: string) => {
    delete this.chatProjects[chatId];
    this.disabledIngestChats[chatId] = true;
    this.clearChatMessages(chatId);
  };

  setMessagePlayerInChatProject = (chatId: string, messageId: string, messagePlayer: MessagePlayer) => {
    this.chatProjects[chatId] = {
      ...this.chatProjects[chatId],
      messagePlayers: {
        ...this.chatProjects[chatId].messagePlayers,
        [messageId]: messagePlayer,
      },
    };
  };
}

export const chatbotState = new ChatbotState();
