import { brollsState } from '../../models/BrollState';
import Segment from '../../models/Segment';
import { segmentsState } from '../../models/SegmentsState';
import { timeStringToSeconds } from './TranscriptUtils';
import { getYoutubeID } from './YoutubeUtils';
import { v4 as uuid } from 'uuid';
import { COLORS } from '../../views/menus/ColorSelect';
import { projectsState } from '../../models/ProjectsState';
import { handleSaveBulkSegments } from '../verticalVideoEditorCRUD/VideoEditSegmentsHandler';
import { TYoutubeInfo } from '../../views/menus/YoutubeHighlightMenu';

export async function getBRollsUpdated(segments: Segment[], youtubeInfo: TYoutubeInfo, fromAttrs, toAttrs) {
  let startTime = timeStringToSeconds(youtubeInfo.startTime);
  // add bRoll for End card
  if (toAttrs.endCard && fromAttrs.endCard) {
    const broll = await addBRollEndCard(youtubeInfo);
    return [broll];
  }

  const brolls = [];
  const listSegmentRange = [];
  const segmentsOfVideoEdit = projectsState.videoEditSelected.segmentIds.map((segmentId) =>
    segments.find((segment) => segment.id === segmentId),
  );
  for (const segment of segmentsOfVideoEdit) {
    if (fromAttrs.segmentId === segment.id && fromAttrs.segmentId === toAttrs.segmentId) {
      listSegmentRange.push(segment);
      break;
    }
    if (segment.id === fromAttrs.segmentId) {
      listSegmentRange.push(segment);
      continue;
    }
    if (listSegmentRange.length > 0 && segment.id !== toAttrs.segmentId) {
      listSegmentRange.push(segment);
    }
    if (segment.id === toAttrs.segmentId) {
      listSegmentRange.push(segment);
      break;
    }
  }

  for (const segment of listSegmentRange) {
    let startWordIndex = 0;
    let endWordIndex = toAttrs.wordIndex;
    if (fromAttrs.segmentId === segment.id) {
      startWordIndex = fromAttrs.wordIndex;
    }
    if (toAttrs.segmentId !== segment.id || !toAttrs?.endTime) {
      endWordIndex = segment.words?.length - 1;
    }
    brolls.push(createBroll(segment, youtubeInfo, startTime, startWordIndex, endWordIndex));
    const words = segment.words?.slice(startWordIndex, endWordIndex + 1);
    const duration = parseFloat((words[words.length - 1].endTime - words[0].startTime).toFixed(3));
    startTime = parseFloat((startTime + duration).toFixed(3));
    if (startTime > youtubeInfo.duration) {
      return [];
    }
  }

  return brolls;
}

function createBroll(
  segment: Segment,
  youtubeInfo: any,
  startTime: number,
  startWordIndex: number,
  endWordIndex: number,
) {
  const words = segment.words?.slice(startWordIndex, endWordIndex + 1);
  const youtubeId = getYoutubeID(youtubeInfo.link);
  const duration = parseFloat((words[words.length - 1].endTime - words[0].startTime).toFixed(3));
  const newBroll = {
    start: words[0].startTime,
    end: words[words.length - 1].endTime,
    wordIndexRange: [startWordIndex, endWordIndex],
    segmentId: segment.id,
    color: COLORS[0].value,
    story_id: '',
    brollGroupId: '',
    candidates: [
      {
        media_id: youtubeId,
        url: youtubeInfo.link,
        start: startTime,
        end: startTime + duration,
      },
    ],
  };

  // validate broll overlay
  JSON.parse(JSON.stringify(brollsState.brolls)).forEach((broll) => {
    splitBrolls(broll, segment, startWordIndex, endWordIndex, false);
    newBroll.color = getBrollDifferColor(broll);
  });
  return newBroll;
}

export function getSegmentNodes(editorJson) {
  const segmentNodes = {};
  for (const content of editorJson.content) {
    if (content.type === 'segment-custom') {
      // Filter to retrieve word-custom nodes that have content.
      segmentNodes[content.attrs.segmentId] = content.content.filter(
        (node) => node.type === 'word-custom' && node.content.find((i) => i.type === 'text')?.text.trim() !== '',
      );
    }
  }
  return segmentNodes;
}

export function updateBrollsBySegmentNode(segmentNode, segments, segmentId) {
  const brollGroupNodes = {};
  let wordIndex = 0;
  for (const node of segmentNode as any) {
    const marks = node?.content[0]?.marks;
    const underlineMark = marks?.find((mark) => mark?.type === 'underline') as any;
    if (underlineMark) {
      const brollGroupId = underlineMark?.attrs?.brollGroupId;
      if (!brollGroupNodes[brollGroupId]) {
        brollGroupNodes[brollGroupId] = [];
      }
      node.attrs.wordIndex = wordIndex;
      brollGroupNodes[brollGroupId].push(node);
    }
    wordIndex++;
  }
  const segment = segments.find((segment) => segment.id === parseInt(segmentId));
  updateBrollByGroupNodes(brollGroupNodes, segment);
}

function updateBrollByGroupNodes(brollGroupNodes, segment) {
  const words = segment?.words;
  for (const [brollGroupId, nodes] of Object.entries(brollGroupNodes)) {
    if (!(nodes as any)?.length) {
      continue;
    }
    const firstNodeAttrs = nodes[0].attrs;
    const lastNodeAttrs = nodes[(nodes as any).length - 1].attrs;
    const brollInGroupId = brollsState.brolls.find(
      (broll) => broll.brollGroupId === brollGroupId && broll.segmentId === segment.id,
    );
    const candidate = brollInGroupId.candidates[0];
    const start = words[firstNodeAttrs.wordIndex].startTime;
    const end = words[lastNodeAttrs.wordIndex].endTime;
    const duration = parseFloat((end - start).toFixed(3));
    brollInGroupId.start = start;
    brollInGroupId.end = end;
    brollInGroupId.wordIndexRange = [firstNodeAttrs.wordIndex, lastNodeAttrs.wordIndex];
    brollInGroupId.candidates = [{ ...candidate, end: parseFloat((candidate.start + duration).toFixed(3)) }];
  }
}

export function activateBrollsWords(segmentId, originWords, isBegin, videoEditSelectedIndex) {
  const segmentBrolls = brollsState.brolls.filter(
    (broll) => broll.segmentId === segmentId && broll.story_id === videoEditSelectedIndex,
  );

  if (segmentBrolls?.length) {
    for (const broll of segmentBrolls) {
      if (isBegin) {
        broll.wordIndexRange[0] = broll.wordIndexRange[0] + originWords.length;
        broll.wordIndexRange[1] = broll.wordIndexRange[1] + originWords.length;
      }
    }
  }
}

export function deactivateBrollsWords(editor, brolls, segment, videoEditSelectedIndex, wordIndexAtFrom, wordIndexAtTo) {
  const { $from } = editor.state.selection;
  const { segmentId } = $from.node().attrs;
  const segmentBrolls = brolls.filter(
    (broll) => broll.segmentId === segmentId && broll.story_id === videoEditSelectedIndex,
  );

  if (segmentBrolls?.length) {
    for (const broll of segmentBrolls) {
      const [wordStart, wordEnd] = broll.wordIndexRange;

      if (wordIndexAtFrom <= wordStart && wordIndexAtTo >= wordEnd) {
        brollsState.deleteBrollsById([broll.id]);
      } else if (wordIndexAtFrom === 0) {
        broll.wordIndexRange[0] = Math.max(wordStart - wordIndexAtTo - 1, 0);
        broll.wordIndexRange[1] = Math.max(wordEnd - wordIndexAtTo - 1, 0);
      } else if (wordIndexAtTo >= segment.words?.length - 1 && wordIndexAtFrom <= wordEnd) {
        broll.wordIndexRange[1] = Math.max(wordIndexAtFrom - 1, 0);
      } else if (wordIndexAtTo < segment.words?.length - 1) {
        splitBrolls(broll, segment, wordIndexAtFrom, wordIndexAtTo);
      }
    }
  }
}

function splitBrolls(broll, segment, checkStartIndex, checkEndIndex, splitSegment = true) {
  const [currentStartIndex, currentEndIndex] = broll.wordIndexRange;

  const notOverlap = currentEndIndex < checkStartIndex || currentStartIndex > checkEndIndex;
  if (notOverlap) {
    return;
  }
  const overlapEntirely = checkStartIndex <= currentStartIndex && checkEndIndex >= currentEndIndex;
  if (overlapEntirely) {
    brollsState.deleteBrollsById([broll.id]);
    return;
  }

  let insertIdx = brollsState.brolls.findIndex((item) => item.id === broll.id);
  handleOverlapEndIfAny(segment, broll, checkStartIndex, ++insertIdx);
  handleOverlapStartIfAny(segment, broll, checkEndIndex, ++insertIdx, splitSegment);
  brollsState.deleteBrollsById([broll.id]);
}

function handleOverlapEndIfAny(segment, broll, checkStartIndex, insertIdx) {
  const [currentStartIndex] = broll.wordIndexRange;
  const overlapEnd = currentStartIndex < checkStartIndex;
  const candidate = broll.candidates[0];
  if (overlapEnd) {
    const cutEndIndex = Math.max(checkStartIndex - 1, 0);
    const cutBroll = JSON.parse(JSON.stringify(broll));
    cutBroll.id = uuid();
    cutBroll.wordIndexRange[1] = cutEndIndex;
    cutBroll.end = segment.words[checkStartIndex].startTime;
    const duration = cutBroll.end - cutBroll.start;
    cutBroll.candidates = [
      {
        url: candidate.url,
        media_id: candidate.media_id,
        start: candidate.start,
        end: Math.max(parseFloat((candidate.start + duration).toFixed(3)), 0),
      },
    ];
    brollsState.insertBrolls(insertIdx, [cutBroll]);
  }
}

function handleOverlapStartIfAny(segment, broll, checkEndIndex, insertIdx, splitSegment) {
  const [currentStartIndex, currentEndIndex] = broll.wordIndexRange;
  const words = segment.words;
  const overlapStart = currentEndIndex > checkEndIndex;
  if (overlapStart) {
    let segmentId = segment.id;
    let cutStartIdx = Math.min(checkEndIndex + 1, words.length - 1);
    let start = words[checkEndIndex].endTime;
    let cutEndIdx = currentEndIndex;

    if (splitSegment) {
      segmentId = segmentsState.segments.length;
      cutStartIdx = Math.max(currentStartIndex - checkEndIndex - 1, 0);
      cutEndIdx = Math.max(currentEndIndex - checkEndIndex - 1, 0);
      start = words[cutStartIdx].startTime;
    }
    const candidate = broll.candidates[0];
    const cutBroll = JSON.parse(JSON.stringify(broll));
    cutBroll.id = uuid();
    cutBroll.segmentId = segmentId;
    cutBroll.wordIndexRange[0] = cutStartIdx;
    cutBroll.wordIndexRange[1] = cutEndIdx;
    cutBroll.start = start;
    cutBroll.end = words[cutEndIdx].endTime;
    const duration = cutBroll.end - cutBroll.start;
    cutBroll.candidates = [
      {
        url: candidate.url,
        media_id: candidate.media_id,
        start: Math.max(parseFloat((candidate.end - duration).toFixed(3)), 0),
        end: candidate.end,
      },
    ];
    brollsState.insertBrolls(insertIdx, [cutBroll]);
  }
}

function getBrollDifferColor(broll) {
  const differColors = COLORS.filter((color) => color.value !== broll.color);
  return differColors[0].value;
}

async function addBRollEndCard(youtubeInfo: TYoutubeInfo) {
  const startTimeSec = timeStringToSeconds(youtubeInfo.startTime);
  const { videoEditSelected } = projectsState;
  let segment = segmentsState.segments
    .filter((i) => videoEditSelected.segmentIds.includes(i.id))
    .find((i) => i.end_card);
  if (!segment) {
    const lastSegment = segmentsState.segments.find(
      (segment) => segment.id === videoEditSelected.segmentIds[videoEditSelected.segmentIds.length - 1],
    );
    segment = {
      id: segmentsState.segments.length,
      strategy: videoEditSelected.strategy,
      story_id: lastSegment.story_id,
      importedMediaId: lastSegment.importedMediaId,
      media: lastSegment.media,
      color: lastSegment.color,
      start: lastSegment.end,
      end: lastSegment.end + (youtubeInfo.duration ? youtubeInfo.duration - startTimeSec : 0),
      text: '[End card]',
      words: [
        {
          startTime: lastSegment.end,
          endTime: lastSegment.end + (youtubeInfo.duration ? youtubeInfo.duration - startTimeSec : 0),
          text: '[End card]',
        },
      ],
      end_card: true,
    };
    const selectedSegmentIds = [...videoEditSelected.segmentIds, segment.id];
    await handleSaveBulkSegments(selectedSegmentIds, { ...videoEditSelected, segmentIds: selectedSegmentIds }, [
      ...segmentsState.segments,
      segment,
    ]);
    segmentsState.setSegments([...segmentsState.segments, segment]);
    projectsState.setSegmentIdsOfVideoEditSelected(selectedSegmentIds);
  }

  const bRoll = createBroll(segment, youtubeInfo, startTimeSec, 0, 0);
  return bRoll;
}
