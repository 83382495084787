import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { getPlayerOptions, handleTimeUpdateMessageVideo } from '../../controllers/players/ChatVideoPlayerHandler';
import '../chat/ChatMessageView.css';
import { scrollToChatWindowBottom } from '../../controllers/chat/ChatWindowHandler';
import { observer } from 'mobx-react';
import { chatbotState } from '../../models/ChatbotState';
import Player from 'video.js/dist/types/player';

const ChatVideoPlayer = observer(
  ({ url, aspectRatio, messageId, chatId, isLastVideoMessage, playerDimension, currentIntervalIndex }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
      if (!url) return;
      const playerOptions = getPlayerOptions(url, aspectRatio);

      if (!playerRef.current) {
        const videoElement = document.createElement('video-js');
        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);
        const player = (playerRef.current = videojs(videoElement, playerOptions, () => {
          if (process.env.NODE_ENV === 'development') {
            videojs.log('[DEBUG] player is ready');
          }
        }));
        handlePlayerReady(player);
      } else {
        const player = playerRef.current;
        player.src(playerOptions.sources);
        player.aspectRatio(aspectRatio);
      }
    }, [url, videoRef, playerRef, aspectRatio]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player = playerRef.current;
      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    const handlePlayerReady = (player: Player) => {
      if (isLastVideoMessage) {
        scrollToChatWindowBottom();
      }
      const chatProject = chatbotState.chatProjects[chatId];
      // set player state
      if (chatProject.messagePlayers[messageId].playerIntervals.length > 0) {
        player.currentTime(chatProject.messagePlayers[messageId].playerIntervals[0].startTimeMs / 1000);
      }
      chatbotState.setMessagePlayerInChatProject(chatId, messageId, {
        ...chatProject.messagePlayers[messageId],
        player: player,
      });

      player.on('loadedmetadata', () => {});

      player.on('timeupdate', () => {
        handleTimeUpdateMessageVideo(player, messageId, chatId, currentIntervalIndex.current, (newIndex) => {
          currentIntervalIndex.current = newIndex;
        });
      });

      player.on('custom-event-jump-segment', () => {
        const currentPlayertime = player.currentTime();
        const newIntervalIndex = chatProject.messagePlayers[messageId].playerIntervals.findIndex(
          (playerInterval) =>
            playerInterval.startTimeMs / 1000 >= currentPlayertime &&
            currentPlayertime <= playerInterval.endTimeMs / 1000,
        );
        if (currentIntervalIndex.current !== newIntervalIndex) {
          currentIntervalIndex.current = newIntervalIndex;
        }
      });
    };

    return (
      <Box style={{ width: playerDimension.width, height: playerDimension.height }} id={messageId}>
        <Box data-vjs-player>
          <Box ref={videoRef} className="chat-video-js vjs-default-skin" />
        </Box>
      </Box>
    );
  },
);

export default ChatVideoPlayer;
