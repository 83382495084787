import React from 'react';

type Props = {
  style?: React.CSSProperties;
};

function SourceMediaIcon({ style }: Props) {
  return <img src={require('../../../assets/icons/video-vertical-source.png')} alt="source" style={style} />;
}

export default SourceMediaIcon;
