import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import IngestRadio from '../ui_controls/radios/IngestRadio';

type TProps = {
  label?: string;
  labelStyle?: any;
  required?: boolean;
  name: string;
  onChange?: (arg) => void;
  options?: any[];
  selected?: string;
};

function FormRadioGroup({ label, labelStyle, required, name, onChange, options, selected }: TProps) {
  const [optionSelected, setOptionSelected] = useState<string>(selected);

  const handleChange = (event) => {
    setOptionSelected(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormGroup required>
      <Label style={labelStyle}>
        {label}
        {required && <Typography style={{ display: 'inline-block' }}>*</Typography>}
      </Label>
      <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
        {options.map((option: string, idx: number) => {
          return (
            <IngestRadio
              key={idx}
              name={name}
              label={option}
              value={option}
              optionSelected={optionSelected}
              setSelected={(e) => handleChange(e)}
            />
          );
        })}
      </Stack>
    </FormGroup>
  );
}

export default FormRadioGroup;
