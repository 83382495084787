import React from 'react';
import { Button } from 'reactstrap';

interface LoginButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

const LoginButton = (props: LoginButtonProps) => {
  const style = props.style ? props.style : { border: 'none', backgroundColor: 'rgb(68, 151, 126)', color: 'white' };
  return (
    <Button onClick={props.onClick} color="secondary" style={style}>
      Login
    </Button>
  );
};

export default LoginButton;
