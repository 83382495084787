import React from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { observer } from 'mobx-react';
import { projectsState } from '../../../models/ProjectsState';
import { videoPlayerState } from '../../../models/VideoPlayerState';
import '../transcriptTextEditor.css';

export const PLAYING_WORD_COLOR = '#fff';
export const UNPLAYED_WORD_COLOR = '#999D9C';

export interface WordCustomOptions {
  HTMLAttributes: Record<string, any>;
}

const WordComponentView = observer((props) => {
  const { startTime, segmentId, wordIndex } = props.node.attrs;
  const { videoEditSelected } = projectsState;
  const isCurrentSegmentPlaying =
    videoEditSelected.segmentIds[videoPlayerState.currentIntervalIdx] === segmentId ? true : false;

  function handleClickWord() {
    const playIntervalIndexFromSegmentIndex = videoEditSelected?.segmentIds.findIndex((i) => i === segmentId);
    videoPlayerState.setCurrentIntervalIdx(playIntervalIndexFromSegmentIndex, startTime, true);
  }
  return (
    <NodeViewWrapper>
      <NodeViewContent
        onClick={handleClickWord}
        className={`content ${wordIndex === 0 ? 'start-word' : ''}`}
        style={{
          fontWeight: 400,
          fontSize: 16,
          color:
            startTime < videoPlayerState.currentTime && isCurrentSegmentPlaying
              ? PLAYING_WORD_COLOR
              : UNPLAYED_WORD_COLOR,
        }}
        as="span"
      />
    </NodeViewWrapper>
  );
});

export const WORD_CUSTOM_NODE_NAME = 'word-custom';

export const WordCustomNode = Node.create<WordCustomOptions>({
  name: WORD_CUSTOM_NODE_NAME,

  priority: 1000,

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ];
  },

  addAttributes() {
    return {
      startTime: {
        default: 0,
      },
      endTime: {
        default: 0,
      },
      segmentId: {
        default: 0,
      },
      wordIndex: {
        default: 0,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['react-component', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(WordComponentView);
  },
});
