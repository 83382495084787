import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Outlet } from 'react-router-dom';

function PublicLayout() {
  return (
    <>
      <SnackbarProvider preventDuplicate anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
      <Outlet />
    </>
  );
}

export default PublicLayout;
