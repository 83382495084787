import React from 'react';
import { Box } from '@mui/material';
const EddyAILogoImageAsset = require('../../assets/images/eddy-logo.png');

interface EddyAILogoImageProps {
  styles: any;
}

const EddyAILogoImage = (props: EddyAILogoImageProps) => {
  return <Box component="img" src={EddyAILogoImageAsset} alt="Eddy AI - Powered by Storylines" sx={props.styles} />;
};

export default EddyAILogoImage;
