import { makeObservable, observable, action } from 'mobx';
import { PlayerInterval } from './PlayerInterval';
import { findBRollClip } from '../controllers/players/VideoPlayerHandler';

export const defaultScale: number = 15;
export const delta = 20; // in ms

export class VideoPlayerState {
  playerIntervals: PlayerInterval[] = [];
  currentIntervalIdx: number = 0;
  player: any = null;
  videoUrl: string = '';
  currentTime: number = null;
  overlayCaptionInstance: any = null;
  bRollPlayer: any = null;
  bRollStartTime: number = null;
  aspectRatio: string = '16:9';
  logo: string = '';
  videoElementAttribute: {
    width: number;
    height: number;
    top: number;
    left: number;
  };

  constructor() {
    makeObservable(this, {
      playerIntervals: observable,
      currentIntervalIdx: observable,
      player: observable,
      videoUrl: observable,
      currentTime: observable,
      overlayCaptionInstance: observable,
      bRollPlayer: observable,
      bRollStartTime: observable,
      logo: observable,
      aspectRatio: observable,
      setPlayerIntervals: action,
      setCurrentIntervalIdx: action,
      setPlayer: action,
      setVideoUrl: action,
      setCurrentTime: action,
      clearVideoPlayerState: action,
      setOverlayInstance: action,
      replaceSubtitleByAssContent: action,
      setLogo: action,
      setVideoElementAttribute: action,
      updatePlayerTime: action,
      clearSubtitleContent: action,
      setAspectRatio: action,
    });
    this.playerIntervals = [];
  }

  /**
   * Set multiple intervals.
   */
  setPlayerIntervals = (playerIntervals: PlayerInterval[]) => {
    this.playerIntervals = playerIntervals;
  };

  setCurrentIntervalIdx = (idx: number, specificTime?: number, isJumpWord?: boolean) => {
    this.currentIntervalIdx = idx;
    if (specificTime) {
      this.updatePlayerTime(specificTime, isJumpWord);
    } else {
      if (this.playerIntervals[idx]) {
        this.updatePlayerTime(this.playerIntervals[idx]['startTimeMs'] / 1000, isJumpWord);
      }
    }
  };

  updatePlayerTime = (specificTime?: number, isJumpWord?: boolean) => {
    try {
      const currentInterval = this.playerIntervals[this.currentIntervalIdx];
      const currentPlayerTime = this.player?.currentTime(); // second
      if (specificTime) {
        // for the jump to word case
        if (isJumpWord) this.player?.currentTime(specificTime);
        // jump bRoll
        const currentWordIndex = currentInterval.words.findIndex((word) => word.startTime === specificTime);
        const currentSegment = {
          id: currentInterval.segmentId,
          start: currentInterval.startTimeMs / 1000,
          end: currentInterval.endTimeMs / 1000,
          importedMediaId: Number(currentInterval.mediaId),
        } as any;

        const bRollClip = findBRollClip(currentSegment, currentWordIndex);
        if (bRollClip) {
          const bRollStartTime = bRollClip.candidates[0].start;
          // calculate the jump time for the b-roll player.
          const currentARollWord = currentInterval.words[currentWordIndex];
          const startWordOfBRoll = currentInterval.words[bRollClip.wordIndexRange[0]];
          const deltaTime = currentARollWord.startTime - startWordOfBRoll.startTime;
          const shouldStartTime = bRollStartTime + deltaTime;
          videoPlayerState.bRollPlayer?.currentTime(shouldStartTime);
        }
        return;
      }
      if (currentPlayerTime < currentInterval.startTimeMs / 1000) {
        const deltaTime = currentInterval.startTimeMs / 1000 - currentPlayerTime;
        // check condition for setting player time when jumping to a new segment (if 2 segments are consecutive but they are the one sentence)
        if (deltaTime > 1.5) {
          this.player?.currentTime(currentInterval.startTimeMs / 1000);
        }
      }
      if (currentPlayerTime > currentInterval.endTimeMs / 1000) {
        // check condition for setting player time when jumping to a new segment (if 2 segments are consecutive but they are the one sentence)
        const deltaTime = currentPlayerTime - currentInterval.startTimeMs / 1000;
        if (deltaTime > 1.5) {
          this.player?.currentTime(currentInterval.startTimeMs / 1000);
        }
      }
    } catch {
      console.warn('Player is removed');
    }
  };

  setPlayer = (player: any) => {
    this.player = player;
  };

  setVideoUrl = (videoUrl: string) => {
    this.videoUrl = videoUrl;
  };

  setCurrentTime = (value: number) => {
    this.currentTime = value;
  };

  clearVideoPlayerState = () => {
    this.playerIntervals = [];
    this.player = null;
    this.currentTime = null;
    this.bRollPlayer = null;
    this.bRollStartTime = null;
    this.currentIntervalIdx = 0;
    this.videoUrl = null;
  };

  setOverlayInstance = (instance) => {
    this.overlayCaptionInstance = instance;
  };

  replaceSubtitleByAssContent = (assContent: string) => {
    if (this.overlayCaptionInstance?.worker) {
      this.overlayCaptionInstance.freeTrack();
      this.overlayCaptionInstance.setTrack(assContent);
    }
  };

  clearSubtitleContent = () => {
    if (this.overlayCaptionInstance?.worker) {
      this.overlayCaptionInstance.freeTrack();
    }
  };

  setLogo = (logo: string) => {
    this.logo = logo;
  };

  setVideoElementAttribute = (videoElementAttribute: typeof this.videoElementAttribute) => {
    this.videoElementAttribute = videoElementAttribute;
  };

  setAspectRatio = (ratio: string) => {
    this.aspectRatio = ratio;
  };
}

export const videoPlayerState = new VideoPlayerState();
