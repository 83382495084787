import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
interface AuthProps {
  component: React.ReactNode;
}

const Auth0ProviderWrapper = (props: AuthProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    const callbackUrl = localStorage.getItem('callbackUrl');
    if (callbackUrl) {
      navigate(callbackUrl);
      localStorage.removeItem('callbackUrl');
      return;
    }
    navigate(appState.returnTo);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {props.component}
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
