import { Checkbox } from '@mui/material';
import React from 'react';

function ExportCheckbox({ checked, onChange }) {
  return (
    <Checkbox
      sx={{
        color: 'white',
        '&.Mui-checked': {
          color: '#079A7C',
        },
      }}
      checked={checked}
      onChange={onChange}
    />
  );
}

export default ExportCheckbox;
