import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import DropboxChooser from 'react-dropbox-chooser';
import DropboxButton from '../ui_controls/buttons/DropboxButton';
import { REACT_APP_DROPBOX_APP_KEY } from '../../config';
import { accountState } from '../../models/AccountState';
import { handImportDropbox } from '../../controllers/ingest/UploadHandlers';

const DropboxUpload = observer(() => {
  const { user } = useAuth0();
  const isAdmin = accountState.currentAccount && accountState.currentAccount.isAdmin;

  return (
    <DropboxChooser
      appKey={REACT_APP_DROPBOX_APP_KEY}
      success={files => handImportDropbox(user.email, files)}
      linkType="direct"
      multiselect={isAdmin}
      extensions={['.mp4', '.mov']}
    >
      <Box style={{ cursor: 'pointer' }}>
        <DropboxButton />
      </Box>
    </DropboxChooser>
  );
});

export default DropboxUpload;
