import { Box, List, ListItem, Divider } from '@mui/material';
import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { segmentsState } from '../../models/SegmentsState';
import './ProjectSettings.css';
import { Button } from 'reactstrap';
import { downloadOriginalVideo } from '../../controllers/serverClients/VideoIngestClient';
import DownloadIcon from '@mui/icons-material/Download';
import YoutubeIcon from '../ui_controls/icons/YoutubeIcon';
import { projectsState } from '../../models/ProjectsState';

const ProjectSettings = observer(({ defaultSettings, media }) => {
  const { segments } = segmentsState;

  function formatFramerate(fps) {
    if (!isNaN(fps)) {
      return parseFloat(Number(fps).toFixed(3).toString()).toString();
    } else {
      return fps;
    }
  }

  const totalPlaytimeOfSegments = useMemo(() => {
    let total = 0; // miliseconds
    segments.forEach((segment) => {
      if (segment.media.id === defaultSettings.mediaSelected.id) {
        total += Math.ceil(segment.end) * 1000 - Math.floor(segment.start) * 1000;
      }
    });
    return total;
  }, [segments]);

  const convertMsToHHMMSS = (ms: number): string => new Date(ms).toISOString().substring(11, 19);

  const handleDownloadOriginalVideo = () => {
    const { project } = defaultSettings;
    downloadOriginalVideo(project.id, media.name, 'token')
      .then((res) => {
        window.open(res.presignedUrl);
      })
      .catch((error) => {
        console.error('Unable to fetch original video url.');
        throw error;
      });
  };

  function displayOriginalYoutubeSource() {
    const { youtubeUrl, inputYoutubeData } = defaultSettings.project.originalData;

    // Legacy version (not support multiple youtube files)
    if (youtubeUrl) {
      return (
        <YoutubeIcon
          style={{ paddingLeft: 5, height: 16, borderRadius: 5, cursor: 'pointer' }}
          onClick={() => window.open(defaultSettings.project.originalData.youtubeUrl, '_blank')}
        />
      );
    }

    if (inputYoutubeData?.length > 0) {
      const { selectedMediaId, medias } = projectsState;
      const selectedMedia = medias[selectedMediaId];
      if (selectedMedia) {
        const inputYoutube = inputYoutubeData.find((youtubeFile) => youtubeFile.fileName === selectedMedia.fileName);
        if (inputYoutube) {
          return (
            <YoutubeIcon
              style={{ paddingLeft: 5, height: 16, borderRadius: 5, cursor: 'pointer' }}
              onClick={() => window.open(inputYoutube.fileUrl, '_blank')}
            />
          );
        }
      }
    }
  }

  return (
    <>
      <Box className="project-info--container">
        <List>
          <ListItem className="project-info--list-item">
            <Box className="project-info--label">Project Info</Box>
            {defaultSettings.mediaSelected.name}
            {defaultSettings.project.originalData && displayOriginalYoutubeSource()}
          </ListItem>
          <ListItem className="project-info--list-item">
            <Box className="project-info--label">Media</Box>
            <Button color="primary" onClick={handleDownloadOriginalVideo}>
              <DownloadIcon />
            </Button>
          </ListItem>
          <Box display={'flex'}>
            <ListItem className="project-info--list-item">
              <Box className="project-info--label">Frame Rate</Box>
              {formatFramerate(defaultSettings.mediaSelected.fps)}
            </ListItem>
            <ListItem className="project-info--list-item">
              <Box className="project-info--label">Timecode</Box>
              {defaultSettings.mediaSelected.startTimeCode}
            </ListItem>
          </Box>
          <Box display={'flex'}>
            <ListItem className="project-info--list-item">
              <Box className="project-info--label">Width</Box>
              {defaultSettings.mediaSelected.width}
            </ListItem>
            <ListItem className="project-info--list-item">
              <Box className="project-info--label">Height</Box>
              {defaultSettings.mediaSelected.height}
            </ListItem>
          </Box>
          <ListItem className="project-info--list-item">
            <Box className="project-info--label">Total playtime</Box>
            {convertMsToHHMMSS(totalPlaytimeOfSegments)}
          </ListItem>
          {defaultSettings.project.originalData && (
            <Box>
              <Divider
                variant="middle"
                sx={{
                  backgroundColor: '#fff',
                }}
              />
              <ListItem className="project-info--list-item">
                <Box className="project-info--label">Story about</Box>
                {defaultSettings.project.originalData.storyAbout}
              </ListItem>
              <ListItem className="project-info--list-item">
                <Box className="project-info--label">Edit strategy</Box>
                {defaultSettings.project.originalData.editStrategy}
              </ListItem>
            </Box>
          )}
        </List>
      </Box>
    </>
  );
});

export default ProjectSettings;
