import React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';

type TExpandLessIconProps = React.ComponentProps<typeof ExpandLess>;

function ExpandLessIcon(props: TExpandLessIconProps) {
  return <ExpandLess {...props} />;
}

export default ExpandLessIcon;
