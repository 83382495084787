import { Button, Stack } from '@mui/material';
import React, { useRef } from 'react';
import FormCustomFileInput from '../ingest/FormCustomFileInput';
import UploadIcon from '../ui_controls/icons/UploadIcon';

type Props = {
  setFileUpload: React.Dispatch<React.SetStateAction<File>>;
  isUploading: boolean;
};

function LocalUploadImage({ setFileUpload, isUploading }: Props) {
  const fileUploadRef = useRef<any>();
  return (
    <Stack direction="row">
      <Button
        size="small"
        component="label"
        variant="contained"
        startIcon={<UploadIcon />}
        sx={{
          backgroundColor: '#2e855a',
          ':hover': {
            backgroundColor: '#2e855a',
          },
        }}
        disabled={isUploading}
      >
        {isUploading ? 'Uploading...' : 'Upload'}
        <FormCustomFileInput
          type="file"
          accept="image/gif,image/png,image/jpeg"
          onChange={(e) => {
            if (e.target.files.length === 0) {
              return;
            }
            setFileUpload(e.target.files[0]);
          }}
          ref={fileUploadRef}
        />
      </Button>
    </Stack>
  );
}

export default LocalUploadImage;
