import React from 'react';
import { Button } from 'reactstrap';

interface CancelButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CancelButton = (props: CancelButtonProps) => {
  return (
    <Button color="secondary" onClick={props.onClick}>
      Cancel
    </Button>
  );
};

export default CancelButton;
