import WarningIcon from '@mui/icons-material/Warning';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import detectIncognito from 'detectincognitojs';

function IncognitoModeWarningModal() {
  const [isWarningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  useEffect(() => {
    detectIncognito().then((result) => {
      if (result.browserName === 'Chrome') {
        setWarningModalOpen(result.isPrivate);
      }
    });
  }, []);

  const handleClose = () => {
    setWarningModalOpen(false);
  };

  return (
    <Dialog
      open={isWarningModalOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: 'rgb(30, 37, 62)',
          color: 'white',
        },
      }}
    >
      <DialogTitle>
        <WarningIcon />
        Warning
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={'white'}>
          <Typography>
            You are using Incognito Mode, enable third-party cookies on your browser to improve your experience.
          </Typography>
          <Typography>1. In Chrome, click the Chrome menu icon (three dots on the top right).</Typography>
          <Typography>2. Click Settings.</Typography>
          <Typography>3. In the Privacy and Security section, click Third-party cookies.</Typography>
          <Typography>4. Select an option: Allow third-party cookies.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default IncognitoModeWarningModal;
