import { NavigateFunction } from 'react-router-dom';
import { SHARE_PATH } from '../../config/constants';
import { PROJECTS_PATH } from '../../config/constants';
import { AI_EDIT_PATH } from '../../config/constants';
import { MenuItem } from '../../views/pages/ProjectPage';
import { cloneProject, fetchProjectData, updateProjectSettings } from '../serverClients/ProjectClient';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { User } from '@auth0/auth0-react';
import React, { SetStateAction } from 'react';
import { getVideoEditCaptionPresignedUrl, getMediaFullCaptionPresignedUrl } from '../serverClients/VideoIngestClient';
import { ProjectStatus } from '../../models/ProjectItem';
import { projectsState } from '../../models/ProjectsState';
import { mediaState } from '../../models/MediaState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { segmentsState } from '../../models/SegmentsState';

const toggleDisplayVideoEditList = (setSelectedMenuItem: React.Dispatch<React.SetStateAction<MenuItem>>) => {
  setSelectedMenuItem((prev) => ({
    ...prev,
    isOpen: false,
  }));
};

const handleChangeMenuItem = (
  item: MenuItem,
  setSelectedMenuItem: React.Dispatch<SetStateAction<MenuItem>>,
  setIsModalActive?: React.Dispatch<SetStateAction<boolean>>,
) => {
  setSelectedMenuItem((prev) => {
    if (prev?.title === item.title) {
      return {
        ...prev,
        isOpen: !prev.isOpen,
      };
    }
    return {
      ...item,
      isOpen: true,
    };
  });
  if (item.title === 'Import' && setIsModalActive) {
    setIsModalActive(true);
  }
};

const renderLoadingText = (isCloningProject) => {
  if (isCloningProject) return 'Cloning project...';
};

async function handleCloneProject(
  user: User,
  projectId: string,
  setIsCloningProject: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
) {
  let status;
  try {
    if (user) {
      setIsCloningProject(true);
      await cloneProject(projectId, user.email);
      status = 'success';
    } else {
      handleLogin(true, projectId, navigate);
    }
  } catch (error) {
    console.log(error);
    status = 'error';
  } finally {
    setIsCloningProject(false);
    const messageSnackKey = enqueueSnackbar(`Clone project ${status}.`, {
      variant: status,
      SnackbarProps: {
        onClick: () => closeSnackbar(messageSnackKey),
      },
    });
  }
}

function handleLogin(isClone: boolean, projectId: string, navigate: NavigateFunction) {
  if (!isClone) {
    localStorage.setItem('callbackUrl', `${SHARE_PATH}/${projectId}`);
  } else {
    localStorage.setItem('cloneProjectId', projectId);
  }
  navigate('/');
}

function calculateTranscriptEditorHeight(containerRef, playerRef, setTranscriptHeight) {
  if (containerRef.current && playerRef.current) {
    setTranscriptHeight(containerRef.current.offsetHeight - playerRef.current.offsetHeight);
  }
}

async function fetchProject(projectId: string, setIsLoadProject: React.Dispatch<SetStateAction<boolean>>) {
  setIsLoadProject(true);
  await fetchProjectData(projectId);
  setIsLoadProject(false);
}

function getProjectDisplayStatus(status: any) {
  let displayStatus = ProjectStatus.PROCESSING;
  for (const fileName of Object.keys(status)) {
    displayStatus = status[fileName];
    if (displayStatus === ProjectStatus.CREATED || displayStatus === ProjectStatus.RE_PROMPTING) break;
  }
  return displayStatus;
}

function isIngestFileFailed(status: any) {
  for (const fileName of Object.keys(status)) {
    if (status[fileName] === ProjectStatus.FAILED) return true;
  }
  return false;
}

function isProjectProcessing(status: any) {
  for (const fileName of Object.keys(status)) {
    if (status[fileName] === ProjectStatus.PROCESSING || status[fileName] === ProjectStatus.RE_PROMPTING) return true;
  }
  return false;
}

async function updateCaptionOverlay(projectId, videoEditSelected, mediaState, videoPlayerState) {
  const isVideoMediaSelected = projectsState.videoEditSelected && projectsState.videoEditSelected.strategy === null;
  const captionPresignedUrl = isVideoMediaSelected
    ? await getMediaFullCaptionPresignedUrl(
        projectsState.activeProjectId,
        projectsState.selectedMediaId,
        mediaState.captionStyles,
      )
    : await getVideoEditCaptionPresignedUrl(projectId, videoEditSelected.id, videoEditSelected.strategy);

  mediaState.setCaptionUrl(captionPresignedUrl);
  if (captionPresignedUrl) {
    const newSubContent = await fetch(captionPresignedUrl).then((response) => response.text());
    videoPlayerState.replaceSubtitleByAssContent(newSubContent); // reload overlay caption
  } else {
    videoPlayerState.clearSubtitleContent();
  }
}

function calculateResolutionUI(width, height, aspectRatio) {
  const [widthRatio, heightRatio] = aspectRatio.split(':').map((item) => Number(item));
  if (width && width < 350) width = 350;
  if (!width) width = (height * widthRatio) / heightRatio;
  if (!height) height = (width * heightRatio) / widthRatio;
  return { width: width + 'px', height: height + 'px' };
}

const ingestCallback = (status: string, projectsState, navigate: NavigateFunction, projectId: string) => {
  if (status === ProjectStatus.CREATED) {
    const messageSnackKey = enqueueSnackbar('Your edit is ready.', {
      variant: 'success',
      hideIconVariant: false,
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    projectsState.clearActiveProjectState();
    navigate(`${AI_EDIT_PATH}/${projectId}`);
  }
  if (status === ProjectStatus.FAILED) {
    navigate(PROJECTS_PATH);
  }
};

function notifyFeedbacks(formFeedbacks, setFormFeedbacks) {
  const isSuccessFeedback = formFeedbacks[0]?.type === 'success';
  if (formFeedbacks.length > 0) {
    if (!isSuccessFeedback) {
      const messageSnackKey = enqueueSnackbar(formFeedbacks[0].message, {
        variant: 'warning',
        hideIconVariant: false,
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
        onExited: () => setFormFeedbacks([]),
      });
    }
  }
}

export function greatestCommonDivisor(x, y) {
  x = Math.abs(x);
  y = Math.abs(y);
  while (y) {
    const t = y;
    y = x % y;
    x = t;
  }
  return x;
}

function calculatePlayerDimension(width, height, setPlayerWidth, setPlayerHeight, setAspectRatio) {
  const ratio = width / height;
  if (ratio > 1) {
    setPlayerWidth(650);
    setPlayerHeight(null);
  }
  if (ratio < 1) {
    setPlayerWidth(null);
    setPlayerHeight(650);
  }
  const gcd = greatestCommonDivisor(width, height);
  setAspectRatio(`${width / gcd}:${height / gcd}`);
}

function getPopperSettings(mediaId, promptId, strategy) {
  return projectsState.settings?.menu?.popper?.find(
    (popperSettings) =>
      popperSettings.mediaId === mediaId &&
      popperSettings.promptId === promptId &&
      popperSettings.strategy === strategy,
  );
}

function handleUpdateProjectSettings(settingType: 'popper', value, deboundSaveDataRef) {
  const foundSetting = getPopperSettings(value.mediaId, value.promptId, value.strategy);
  switch (settingType) {
    case 'popper':
      if (foundSetting) {
        foundSetting.status = value.status;
      } else {
        projectsState.settings.menu.popper.push(value);
      }
      break;

    default:
      break;
  }
  if (deboundSaveDataRef.current) {
    clearTimeout(deboundSaveDataRef.current);
  }
  deboundSaveDataRef.current = setTimeout(() => {
    updateProjectSettings(projectsState.activeProject.id, projectsState.settings);
  }, 3000);
}

function clearProjectState() {
  videoPlayerState.clearVideoPlayerState();
  segmentsState.clearSegments();
  mediaState.clearMedia();
  projectsState.clearActiveProjectState();
}

export {
  toggleDisplayVideoEditList,
  handleChangeMenuItem,
  renderLoadingText,
  handleLogin,
  handleCloneProject,
  calculateTranscriptEditorHeight,
  fetchProject,
  updateCaptionOverlay,
  calculateResolutionUI,
  ingestCallback,
  notifyFeedbacks,
  calculatePlayerDimension,
  getProjectDisplayStatus,
  isIngestFileFailed,
  isProjectProcessing,
  handleUpdateProjectSettings,
  getPopperSettings,
  clearProjectState,
};
