import { serverAddress } from '../../config';
import { videoPlayerState } from '../../models/VideoPlayerState';

export const getLogoPresignedUrl = async (projectId: string, accessToken: string) => {
  const endpoint = `${serverAddress}/api/logo/get?projectId=${projectId}`;

  const baseOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return await fetch(endpoint, baseOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    })
    .then(({ presignedUrl }) => presignedUrl);
};

export async function deleteLogo(projectId: string, accessToken: string) {
  const url = `${serverAddress}/api/logo/delete?projectId=${projectId}`;
  const baseOptions = {
    method: 'DELETE',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return await fetch(url, baseOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((rs) => rs.json())
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });
}

export async function fetchLogoPresignedURL(projectId: string): Promise<any> {
  try {
    const response = await getLogoPresignedUrl(projectId, 'token');
    videoPlayerState.setLogo(response);
  } catch (error) {
    console.error('Unable to fetch logo url.');
    throw error;
  }
}
