import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { projectsState } from '../../models/ProjectsState';
import { Box, Input, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ProjectStatus } from '../../models/ProjectItem';
import {
  handleKeyDown,
  handleSaveProjectName,
} from '../../controllers/verticalVideoEditorCRUD/ProjectNameEditableHandlers';
import { useLocation } from 'react-router-dom';
import { SHARE_PATH } from '../../config/constants';
import './Navigation.css';
import { useDebounce } from '../../hooks/UseDebounceHook';
import styled from '@emotion/styled';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0D0F0F',
    color: '#999D9C',
    fontSize: 14,
    padding: 10,
    border: 'solid 1px rgb(255, 255, 255, 0.12)',
    borderRadius: 8,
    maxWidth: '360px',
    boxShadow: '0px 0px 20px -8px #079A7C',
  },
}));

const ProjectNameEditable = observer(() => {
  const contentEditableRef = useRef<any>();
  const inputRef = useRef<any>();
  const { pathname } = useLocation();
  const isShare = pathname.startsWith(SHARE_PATH);
  const spanRef = useRef<any>();
  const [inputWidth, setInputWidth] = useState('auto');

  const [isFocused, setIsFocused] = useState(false);

  const debounceHandleSaveProject = useDebounce(handleSaveProjectName, 1000);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.scrollTop = 0;
    }
    setIsFocused(false);
  };

  return (
    <CustomTooltip title={projectsState.activeProject?.displayName} placement="bottom-start">
      <Box style={{ display: 'flex', alignItems: 'center', width: '100%', pointerEvents: !isShare ? 'auto' : 'none' }}>
        <span
          ref={spanRef}
          style={{
            position: 'absolute',
            visibility: 'hidden',
            height: 0,
            whiteSpace: 'pre',
            padding: 0,
            margin: 0,
            border: 0,
          }}
        >
          {projectsState.activeProject?.displayName}
        </span>
        <Input
          inputRef={inputRef}
          ref={contentEditableRef}
          fullWidth
          defaultValue={projectsState.activeProject?.displayName}
          disabled={projectsState.activeProject?.status === ProjectStatus.PROCESSING}
          onChange={(e) => {
            if (spanRef.current) {
              spanRef.current.innerText = e.target.value;
              setInputWidth(spanRef.current.offsetWidth + (10 % 200)); // Adding some padding
            }
          }}
          onBlur={(e) => debounceHandleSaveProject(e)}
          onKeyDown={(e) => handleKeyDown(e, debounceHandleSaveProject)}
          className={`navigation__project-name ${!projectsState.videoEditSelected?.strategy && 'fullwidth'}`}
          style={{ width: inputWidth }}
          multiline
          maxRows={2}
          onFocus={handleFocus}
          onBlurCapture={handleBlur}
          sx={[
            !isFocused && {
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              },
            },
            {
              padding: '0px 10px',
              '&:hover': {
                backgroundColor: '#1F2726',
              },
            },
          ]}
        />
      </Box>
    </CustomTooltip>
  );
});

export default ProjectNameEditable;
