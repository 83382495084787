import React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { REACT_APP_INTERCOM_APP_ID, REACT_APP_INTERCOM_API_BASE } from '../../config';
type TProps = {
  children: React.ReactNode;
  autoBoot: boolean;
};

function IntercomProviderWrapper({ children, autoBoot }: TProps) {
  return (
    <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID} apiBase={REACT_APP_INTERCOM_API_BASE} autoBoot={autoBoot}>
      {children}
    </IntercomProvider>
  );
}

export default IntercomProviderWrapper;
