import { Box, Container, IconButton, List, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { projectsState } from '../../models/ProjectsState';
import './VerticalEditor.css';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { formatDuration } from '../../controllers/verticalVideoEditorCRUD/AIVideoEditsHandler';
import filesize from 'filesize';
import { MenuItem } from '../pages/ProjectPage';
import AIVideoEditStrategy from './AIVideoEditStrategy';
import { useSearchParams, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ProjectInfoMenuItem from '../popovers/ProjectInfoMenuItem';
import { SHARE_PATH } from '../../config/constants';
import { UNPLAYED_WORD_COLOR } from '../transcript/CustomNode/WordCustomNode';
import { handleChangeMedia } from '../../controllers/verticalVideoEditorCRUD/VerticalVideoEditorHandler';

type Props = {
  menuItem?: MenuItem;
  toggle?: () => void;
};

const AIVideoEdits = observer(({ menuItem, toggle }: Props) => {
  const { selectedMediaId, activeProject, medias } = projectsState;
  const [, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isSharing = pathname.startsWith(SHARE_PATH);

  const renderStrategyOutputs = (mediaId) => {
    return Object.keys(medias[mediaId].strategyOutputs).map((key) => {
      return <AIVideoEditStrategy strategy={key} mediaId={mediaId} />;
    });
  };

  const onMediaClick = (mediaId) => {
    handleChangeMedia(mediaId, setSearchParams);
  };

  return (
    <Box className={`${isMobile ? 'left-menu-drawer-mobile' : 'left-menu-drawer'} ${menuItem?.isOpen && 'open'}`}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'16px 32px'}
        width={'300px'}
      >
        <Typography color={'white'} fontSize={30}>
          Bin
        </Typography>
        {isMobile && (
          <IconButton onClick={toggle}>
            <HighlightOffRoundedIcon style={{ color: UNPLAYED_WORD_COLOR }} />
          </IconButton>
        )}
      </Stack>
      {activeProject && medias && (
        <Container
          className={
            isMobile ? 'left-menu-drawer-list-media-container-mobile' : 'left-menu-drawer-list-media-container'
          }
        >
          {Object.values(medias).map((media, index) => (
            <Stack className={`left-menu-drawer-list-media ${selectedMediaId === index && 'selected'}`}>
              <Box sx={{ height: '150px', cursor: 'pointer' }} onClick={() => onMediaClick(media.id)}>
                <img
                  src={media.thumbnailURL ? media.thumbnailURL : activeProject.thumbnail}
                  alt="thumbnail"
                  className="left-menu-drawer-thumbnai"
                />
              </Box>
              <Box>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography
                    color={'white'}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                      lineHeight: '1.5em',
                      maxHeight: '4.5em',
                      wordBreak: 'break-word',
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    {media.fileName}
                  </Typography>
                  {!isSharing && !isMobile && <ProjectInfoMenuItem media={media} />}
                </Stack>
                <Typography color={UNPLAYED_WORD_COLOR} fontSize={14}>
                  {filesize(media.fileSize, { standard: 'jedec' })} | {formatDuration(media.durationInSeconds)}
                </Typography>
              </Box>
              <List className="edit-box__listing">{renderStrategyOutputs(index)}</List>
            </Stack>
          ))}
        </Container>
      )}
    </Box>
  );
});

export default AIVideoEdits;
