import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { chatbotState } from '../../models/ChatbotState';
import ChatSidebarItem from './ChatSidebarItem';
import { ChatItem, TPagination } from '../../models/ChatItem';
import { observer } from 'mobx-react';
import EddyAILogoImage from '../images/EddyAILogoImage';
import { Button, CircularProgress } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { createChat, getChats } from '../../controllers/chat/ChatWindowHandler';
import ProfileCard from './ProfileCard';
import './ChatSidebar.css';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

type Props = {
  isReadOnly?: boolean;
};

const ChatSidebar = observer(({ isReadOnly }: Props) => {
  const { user } = useAuth0();
  const isFetchingRef = useRef<boolean>(false);
  const paginationRef = useRef<TPagination>({ isEnd: false, lastEvaluatedKey: null });
  const debounceRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { isPreview } = chatbotState;
  const navigate = useNavigate();
  const beginListElementRef = useRef<HTMLElement>();
  const { trackEvent } = useIntercom();

  useEffect(() => {
    if (!isPreview && user?.email) {
      fetchChats();
    }
  }, [user?.email]);

  useEffect(() => {
    if (!user?.email) return;
    const scrollableElement = document.querySelector('.chat-sidebar-items-container');
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll);
      }
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [user?.email]);

  const fetchChats = () => {
    if (!isPreview && user?.email && !isFetchingRef.current && !paginationRef.current.isEnd) {
      getChats(
        (fetchingStatus) => {
          isFetchingRef.current = fetchingStatus;
        },
        paginationRef.current,
        (newPagination) => {
          paginationRef.current = newPagination;
        },
        user.email,
      );
    }
  };

  const handleScroll = () => {
    const scrollableElement = document.querySelector('.chat-sidebar-items-container');
    if (
      scrollableElement &&
      scrollableElement.scrollTop + scrollableElement.clientHeight >= scrollableElement.scrollHeight - 100
    ) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        fetchChats();
      }, 500);
    }
  };

  const scrollToTop = () => {
    // wrap scroll function in timeout to avoid the scroll function executing immediately but the DOM hasn't been updated yet
    setTimeout(() => {
      beginListElementRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  const chatSidebarItems = chatbotState.chatItems.map((chatItem: ChatItem) => (
    <ChatSidebarItem
      key={chatItem.chatId}
      isSelected={chatItem.chatId === chatbotState.selectedChatId}
      chatItem={chatItem}
      isReadOnly={isReadOnly}
      scrollToTop={scrollToTop}
    />
  ));

  async function handleClickNewProjectBtn() {
    if (user) {
      await createChat(user.email, navigate, trackEvent, scrollToTop);
    }
  }

  return (
    <Box className="chat-sidebar">
      <Box className="chat-sidebar-header">
        <Box>
          <EddyAILogoImage styles={{ height: '25px' }} />
          <Typography style={{ marginTop: 8, fontSize: 12, color: '#3D4C49', whiteSpace: 'nowrap', fontWeight: 500 }}>
            Your Video Editor
          </Typography>
        </Box>
      </Box>
      <Divider className="chat-sidebar-divider" sx={{ margin: '0 1rem', color: '#DFEDEA' }} />
      <Button
        className={`chat-sidebar__new-project-btn ${isReadOnly && 'disabled'}`}
        onClick={handleClickNewProjectBtn}
        disabled={isReadOnly}
      >
        + New Edit
      </Button>
      <Box className="chat-sidebar-items-container" sx={{ flexGrow: 1, padding: '0.5rem 0', overflowY: 'auto' }}>
        <Box style={{ float: 'left', clear: 'both' }} ref={beginListElementRef} />
        {chatSidebarItems}
        {isFetchingRef.current && (
          <CircularProgress
            size={20}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', color: '#079A7C' }}
          />
        )}
      </Box>
      {user && <ProfileCard />}
    </Box>
  );
});

export default ChatSidebar;
