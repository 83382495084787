import * as React from 'react';
import ShareIcon from '../icons/ShareIcon';
import MenuActionButton from './MenuActionButton';

type Props = {
  onClick: (event) => void;
};

export default function ShareButton({ onClick }: Props) {
  return <MenuActionButton onClick={onClick} text="Share" icon={<ShareIcon color="#fff" />} />;
}
