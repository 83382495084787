import { FormControlLabel, Stack } from '@mui/material';
import React from 'react';
import ExportCheckbox from '../ui_controls/checkboxs/ExportCheckbox';

type Props = {
  exportSelected: any;
  onChange: (e) => void;
};

function IncludeLogoOption({ exportSelected, onChange }: Props) {
  return (
    <Stack direction="row">
      <FormControlLabel
        control={<ExportCheckbox checked={exportSelected.includeLogo} onChange={onChange} />}
        label="Include your logo"
      />
    </Stack>
  );
}

export default IncludeLogoOption;
