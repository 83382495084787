import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, Pagination, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { projectsState } from '../../models/ProjectsState';
import Navigation from '../navigation/Navigation';
import ProjectList from '../projects/ProjectList';
import CreateProjectModalButton from '../ui_controls/buttons/CreateProjectModalButton';
import { cloneProject, getProjects } from '../../controllers/serverClients/ProjectClient';
import '../projects/Projects.css';
import { accountState } from '../../models/AccountState';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';
import { NEW_PROJECT_PATH } from '../../config/constants';
import { AI_EDIT_PATH } from '../../config/constants';
import { mediaState } from '../../models/MediaState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { segmentsState } from '../../models/SegmentsState';
import { getProjectDisplayStatus } from '../../controllers/projectsCRUD/ProjectPageHandler';

type PrjectFetchState = {
  isFetching: boolean;
  pagination: { pageNumber: number; pageSize: number; total: number };
};

const ProjectsDashboardPage = observer(() => {
  const { user } = useAuth0();
  const { update } = useIntercom();
  const [fetchProjectState, setFetchProjectState] = useState<PrjectFetchState>({
    isFetching: false,
    pagination: { pageNumber: 1, pageSize: 11, total: 0 },
  });
  const account = accountState.currentAccount;
  const navigate = useNavigate();
  const [isCloningProject, setIsCloningProject] = useState<boolean>(false);
  const { clearMedia } = mediaState;
  const { clearVideoPlayerState } = videoPlayerState;
  const { clearSegments } = segmentsState;

  useEffect(() => {
    if (!fetchProjectState.isFetching && account) {
      fetchProjectData();
    }
  }, [fetchProjectState.pagination.pageNumber, account]);

  useEffect(() => {
    if (user) {
      update({
        email: user.email,
        name: user.name,
        userId: user.sub,
      });
    }
  }, [user]);

  useLayoutEffect(() => {
    const cloneProjectId = localStorage.getItem('cloneProjectId');
    if (cloneProjectId && user) {
      setIsCloningProject(true);
      cloneProject(cloneProjectId, user.email)
        .then((newProjectId) => navigate(`${AI_EDIT_PATH}/${newProjectId}`))
        .catch((err) => console.log(err))
        .finally(() => {
          setIsCloningProject(false);
          localStorage.removeItem('cloneProjectId');
        });
    }
  }, [user]);

  async function fetchProjectData() {
    let projectsResponse;
    try {
      setFetchProjectState((prev) => ({ ...prev, isFetching: true }));
      projectsResponse = await getProjects(
        'token',
        user.email,
        fetchProjectState.pagination.pageNumber,
        fetchProjectState.pagination.pageSize,
        account.isAdmin,
      );
      // add to state
      const mapProjectToStoreSchema = projectsResponse.data.map((project) => {
        project.id = project.projectID;
        project.surveyBy = project.surveyBy ?? [];
        project.displayName = project.displayName ?? project.name;
        project.displayStatus = getProjectDisplayStatus(project.status);
        delete project.projectID;
        return project;
      });
      projectsState.setProjectItems(mapProjectToStoreSchema);
      setFetchProjectState((prev) => ({ ...prev, pagination: { ...prev.pagination, ...projectsResponse.meta } }));
    } catch (error) {
      console.error(error);
      const messageSnackKey = enqueueSnackbar('Could not load projects', {
        variant: 'error',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
    } finally {
      setFetchProjectState((prev) => ({ ...prev, isFetching: false }));
    }
  }

  return (
    <>
      <Helmet title="Storylines" />
      <LoadingOverlay
        active={isCloningProject || fetchProjectState.isFetching || projectsState.isAddingDemoProject}
        spinner
        className="main-layout"
        text={isCloningProject && 'Cloning project...'}
      >
        <Navigation hiddenMenuBar hiddenStoryForm />
        <Container className="main-layout__content">
          <Box sx={{ padding: '5px 20px', height: '90px' }}>
            <Typography
              color={'white'}
              fontFamily={'Geist'}
              fontSize={20}
              borderBottom={'1px white solid'}
              width={'fit-content'}
              marginBottom={'20px'}
            >
              VideoEdits
            </Typography>
            <Typography color={'white'} fontFamily={'Geist'} fontSize={16} style={{ opacity: 0.6 }} margin={'20px 0'}>
              {fetchProjectState.pagination.total} videos
            </Typography>
          </Box>
          <Grid
            container
            columns={12}
            overflow={'auto'}
            height={'calc(100vh - 260px)'}
            maxWidth={'1300px'}
            margin={'auto'}
          >
            <Grid item xs={4} padding={'10px'}>
              <CreateProjectModalButton
                onClick={() => {
                  clearVideoPlayerState();
                  clearSegments();
                  clearMedia();
                  projectsState.clearActiveProjectState();
                  navigate(NEW_PROJECT_PATH);
                }}
              />
            </Grid>
            <ProjectList />
          </Grid>

          {fetchProjectState.pagination.total > 0 && (
            <Pagination
              className="dashboard-projects-pagination"
              count={Math.ceil(fetchProjectState.pagination.total / fetchProjectState.pagination.pageSize)}
              onChange={(event, pageNumber) =>
                setFetchProjectState((prev) => ({
                  ...prev,
                  pagination: { ...prev.pagination, pageNumber: pageNumber },
                }))
              }
              hideNextButton
              hidePrevButton
            />
          )}
        </Container>
      </LoadingOverlay>
    </>
  );
});

export default ProjectsDashboardPage;
