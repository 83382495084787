import { action, makeObservable, observable } from 'mobx';
import ProjectItem, { DEFAULT_SETTINGS, ProjectSettings } from './ProjectItem';
import { VideoEditItem, PromptResults } from './VideoEditItem';
import { Media } from './Media';
import { getStrategyOutputs } from '../controllers/projectsCRUD/MediaUtils';
import { EditStrategies } from './IngestState';

class ProjectsState {
  projectItems: ProjectItem[] = [];
  activeProjectId: string = '';
  activeProject: ProjectItem = null;
  isCallingUpdateSegment: boolean = false;
  videoEditSelected?: VideoEditItem = null;
  isAddingDemoProject: boolean = false;
  updateFavoriteDebounce: any;
  backupStrategyOutputsFavStatus: any;
  medias: Record<number, Media>;
  selectedMediaId: number;
  settings: ProjectSettings = DEFAULT_SETTINGS;

  constructor() {
    this.selectedMediaId = -1;
    makeObservable(this, {
      projectItems: observable,
      activeProjectId: observable,
      isCallingUpdateSegment: observable,
      activeProject: observable,
      videoEditSelected: observable,
      isAddingDemoProject: observable,
      medias: observable,
      selectedMediaId: observable,
      settings: observable,
      setIsCallingUpdateSegment: action,
      setProjectItems: action,
      setActiveProjectId: action,
      setActiveProject: action,
      setVideoEditSelected: action,
      clearActiveProjectState: action,
      updateProjectItem: action,
      setIsAddingDemoProjects: action,
      setEnableCaption: action,
      getVideoEditsOfStrategyOutputs: action,
      setUpdateFavoriteDebounce: action,
      getStrategiesOutputFavStatus: action,
      setMedias: action,
      setSelectedMediaId: action,
      setActiveProjectStatus: action,
      setSettings: action,
      addProcessingPrompt: action,
      getSelectedPrompt: action,
      setVideoEditTitle: action,
    });
  }

  setProjectItems = (projectItems: ProjectItem[]): void => {
    this.projectItems = projectItems;
  };

  setActiveProjectId = (projectId: string): void => {
    this.activeProjectId = projectId;
  };

  setIsCallingUpdateSegment = (flag: boolean) => {
    this.isCallingUpdateSegment = flag;
  };

  setActiveProject = (project) => {
    this.activeProject = project;
  };

  setVideoEditSelected = (videoEditSelected) => {
    this.videoEditSelected = videoEditSelected;
  };

  setSegmentIdsOfVideoEditSelected = (listIds: number[]) => {
    this.videoEditSelected = {
      ...this.videoEditSelected,
      segmentIds: listIds,
    };
  };

  clearActiveProjectState = () => {
    this.activeProject = null;
    this.videoEditSelected = null;
    this.activeProjectId = '';
    this.medias = {};
    this.selectedMediaId = -1;
    this.settings = DEFAULT_SETTINGS;
  };

  setActiveProjectStatus = (status: string) => {
    const new_stastus = {};
    Object.keys(this.activeProject.status).forEach((fileName) => {
      new_stastus[fileName] = status;
    });
    this.activeProject = { ...this.activeProject, status: new_stastus, displayStatus: status };
  };

  updateProjectItem = (projectItem: ProjectItem) => {
    const itemIndex = this.projectItems.findIndex((obj) => obj.id === projectItem.id);
    this.projectItems[itemIndex] = projectItem;
  };

  setIsAddingDemoProjects = (status: boolean) => {
    this.isAddingDemoProject = status;
  };

  setEnableCaption = (enable: boolean) => {
    this.activeProject.enableCaption = enable;
  };

  getVideoEditSelectedIndex = () => {
    let videoEditSelectedIndex = 0;
    if (this.videoEditSelected?.strategy) {
      const strategyOutputs = this.getSelectedStrategyOutputs();
      const selectedStrategy: PromptResults = strategyOutputs[this.videoEditSelected.strategy];
      Object.values(selectedStrategy).forEach((strategy) => {
        const index = strategy.videoEditItems?.findIndex((videoEdit) => videoEdit.id === this.videoEditSelected.id);
        if (index !== -1) {
          videoEditSelectedIndex = index;
          return;
        }
      });
    }
    return videoEditSelectedIndex;
  };

  getVideoEditsOfStrategyOutputs = () => {
    const videoEditItems = [];
    const strategyOutputs = this.getSelectedStrategyOutputs();
    Object.keys(strategyOutputs).forEach((strategy) => {
      Object.values(strategyOutputs[strategy]).forEach((promptResults: PromptResults) => {
        promptResults.videoEditItems.forEach((videoEditItem) => {
          videoEditItems.push(videoEditItem);
        });
      });
    });
    return videoEditItems;
  };

  setUpdateFavoriteDebounce = (updateFavoriteDebound: any) => {
    this.updateFavoriteDebounce = updateFavoriteDebound;
  };

  setBackupStrategyOutputsFavStatus = (strategiesOutputFavStatus) => {
    this.backupStrategyOutputsFavStatus = strategiesOutputFavStatus;
  };

  getStrategiesOutputFavStatus = () => {
    const strategiesOutputFavStatus = {};
    const strategyOutputs = getStrategyOutputs();
    Object.keys(strategyOutputs).forEach((strategy) => {
      strategiesOutputFavStatus[strategy] = {};
      Object.values(strategyOutputs[strategy]).forEach((promptResults: PromptResults) => {
        promptResults.videoEditItems?.forEach((videoEditItem) => {
          strategiesOutputFavStatus[strategy][videoEditItem.id] = videoEditItem.isFavorite;
        });
      });
    });
    return strategiesOutputFavStatus;
  };

  setMedias = (mediaData: any) => {
    this.medias = mediaData;
  };

  setSelectedMediaId = (mediaId: number) => {
    this.selectedMediaId = mediaId;
  };

  getSelectedStrategyOutputs = () => {
    return this.medias?.[this.selectedMediaId]?.strategyOutputs;
  };

  getSelectedOriginalTranscript = () => {
    return this.medias?.[this.selectedMediaId]?.originalTranscript;
  };

  setSettings = (settings: ProjectSettings) => {
    this.settings = settings;
  };

  addProcessingPrompt = () => {
    let strategy = this.activeProject?.originalData?.editStrategy;
    if (strategy === EditStrategies.DEFAULT) {
      strategy = EditStrategies.HIGHLIGHTS;
    }
    Object.values(this.medias).forEach((media) => {
      const newPromptId = media.strategyOutputs[strategy] ? Object.values(media.strategyOutputs[strategy]).length : 0;
      media.strategyOutputs[strategy] = {
        ...media.strategyOutputs[strategy],
        [newPromptId]: {
          prompt: {
            id: newPromptId,
            storyAbout: '',
          },
          videoEditItems: null,
        },
      };
      media.storyGPTStatus[strategy] = null;
    });
  };

  getLatestEdit = (): any => {
    const strategy = this.activeProject?.originalData?.editStrategy;
    const finalEdit = Object.values(this.medias[0].strategyOutputs[strategy])
      .filter((item: PromptResults) => item.videoEditItems.length > 0)
      .pop();
    return finalEdit;
  };

  getSelectedPrompt = (): PromptResults => {
    const selectedPromptId = this.videoEditSelected?.promptId;
    const selectedStrategy = this.videoEditSelected?.strategy;
    if (typeof selectedPromptId === 'number') {
      const selectedStrategyOutputs = this.getSelectedStrategyOutputs();
      return selectedStrategyOutputs?.[selectedStrategy]?.[selectedPromptId];
    }
  };

  getVideoEditBySelected = (videoEdit: VideoEditItem) => {
    return this.medias[videoEdit.mediaId].strategyOutputs[videoEdit.strategy][videoEdit.promptId].videoEditItems.find(edit => edit.id === videoEdit.id)
  }

  setVideoEditTitle = (videoEdit, newTitle, isSelected) => {
    videoEdit.title = newTitle;
    if (isSelected) {
      this.videoEditSelected.title = newTitle;
    }
  }
}

export const projectsState = new ProjectsState();
