import { Box, Divider, Stack, Typography, ListItem, Grid, styled } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormGroup } from 'reactstrap';
import DriveUpload from './DriveUpload';
import { ingestState } from '../../models/IngestState';
import LocalUpload from './LocalUpload';
/**
 * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
 * import YoutubeUpload from './YoutubeUpload';
 * */
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import DropboxUpload from './DropboxUpload';
import { isDuplicateFileName } from '../../controllers/ingest/UploadHandlers';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { accountState } from '../../models/AccountState';
import { TSubmitState } from './FormIngestInterface';
import { publishMetric } from '../../controllers/serverClients/ProjectClient';
import { IMPORT_TYPE } from './IngestUploadStep';

type TProps = {
  submitState: TSubmitState;
  isChatBot?: boolean;
};

const SUPPORTED_MEDIA_CATEGORY = [
  'Video Podcasts',
  'Vlogs',
  'Educational Videos',
  'Speeches',
  'Video Essays',
  'Audio in English',
];
const MEDIA_MAY_FAIL_CATEGORY = ['Gaming Videos', 'Animals Videos', 'Music Videos', 'Videos without dialogue'];

const YES_VIDEO_GIF = require('../../assets/gifs/yes_videos.gif');
const NO_VIDEO_GIF = require('../../assets/gifs/no_videos.gif');

const ScrollingFormGroup = styled(FormGroup)(() => ({
  overflow: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&-ms-overflow-style:': {
    display: 'none',
  },
}));

const FormUpload = observer(({ submitState, isChatBot }: TProps) => {
  const { user } = useAuth0();
  const isAdmin = accountState.currentAccount && accountState.currentAccount.isAdmin;
  const { fileUploads, formData, setFileUploads } = ingestState;
  const isShowCheckboxTip =
    fileUploads.length === 0 && formData.inputYoutubeData.length === 0 && formData.inputDriveData.length === 0;
  const isUploadFileExist =
    fileUploads.length > 0 ||
    formData.inputYoutubeData.length > 0 ||
    formData.inputDriveData.length > 0 ||
    formData.inputDropboxData.length > 0;

  const isDisabledYoutube = (isChatBot || !isAdmin) && isUploadFileExist;
  const isDisabledLocalUpload = (isChatBot || !isAdmin) && isUploadFileExist;
  const isDisabledDrive = !isAdmin && isUploadFileExist;
  /**
   * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
   * Pass ESLINT
   * */
  if (isDisabledYoutube && submitState) {
    console.log('.');
  }

  return (
    <ScrollingFormGroup>
      <LocalUpload
        setFileUpload={(files) => {
          if (isChatBot || !isAdmin) {
            files = [files[0]];
          }
          const newFileUploads = files.map((file) => ({
            file: file,
            percentProgress: 0,
            timeRemaining: 0,
            status: 'imported',
          }));
          const filteredFiles = newFileUploads.filter((file) => !isDuplicateFileName(file.file.name, IMPORT_TYPE.LOCAL, user.email));
          if (filteredFiles.length > 0) {
            publishMetric('ingest_local_input_file', 1, user.email);
            setFileUploads(fileUploads.concat(filteredFiles));
          } else {
            publishMetric('ingest_local_input_file', 0, user.email);
            const messageSnackKey = enqueueSnackbar('File is duplicated. Please choose a file with a different name.', {
              variant: 'warning',
              SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
            });
          }
        }}
        disabled={isDisabledLocalUpload}
      />
      <Box sx={{ margin: '1rem 0' }}>
        <Typography style={{ fontSize: 20 }}>Import from Cloud</Typography>
      </Box>
      <Stack direction="row" gap={'15px'}>
        {/* 
          [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
          <YoutubeUpload submitState={submitState} disabled={isDisabledYoutube} /> 
        */}
        {!isChatBot && !isDisabledDrive && (
          <>
            <DriveUpload />
            <DropboxUpload />
          </>
        )}
      </Stack>
      {isShowCheckboxTip && (
        <Box style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }} gap={'8px'}>
          <Box flex={1}>
            <Box display={'flex'} justifyContent={'center'} marginBottom={'10px'}>
              <img width={200} src={YES_VIDEO_GIF} alt="" />
            </Box>
            <Grid container style={{ flex: 1 }}>
              {SUPPORTED_MEDIA_CATEGORY.map((category, index) => {
                return (
                  <Grid item xs={6} key={index}>
                    <ListItem style={{ gap: '8px', padding: '10px 0px' }}>
                      <CheckCircleRoundedIcon style={{ color: '#85DB6E' }} />
                      <Typography style={{ color: '#fff', fontSize: '14px' }}>{category}</Typography>
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Divider orientation="vertical" flexItem style={{ opacity: 1, borderWidth: 1.5, borderColor: '#fff' }} />
          <Box flex={1}>
            <Box display={'flex'} justifyContent={'center'} marginBottom={'10px'}>
              <img width={200} src={NO_VIDEO_GIF} alt="" />
            </Box>
            <Grid container style={{ flex: 1 }}>
              {MEDIA_MAY_FAIL_CATEGORY.map((category, index) => {
                return (
                  <Grid item xs={6} key={index}>
                    <ListItem style={{ gap: '8px', padding: '10px 0px' }}>
                      <HighlightOffRoundedIcon style={{ color: '#DB3D1C' }} />
                      <Typography style={{ color: '#fff', fontSize: '14px' }}>{category}</Typography>
                    </ListItem>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      )}
    </ScrollingFormGroup>
  );
});

export default FormUpload;
