import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid, OutlinedInput, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, {useState } from 'react';
import LogoIcon from '../ui_controls/icons/LogoIcon';
import OnboardButton from '../ui_controls/buttons/OnboardButton';
import { useNavigate } from 'react-router-dom';
import { handleSendFeatureRequest } from '../../controllers/featureRequest/featureRequestHandler';
import { PROJECTS_PATH } from '../../config/constants';
import './page.css';

const FeatureRequestPage = observer(() => {
  const { user } = useAuth0();
  const [featureRequest, setFeatureRequest] = useState<string>("");
  const navigate = useNavigate();

  const onFeatureRequestChange = (event) => {
    setFeatureRequest(event.target.value);
  }

  const onSendFeatureRequest = async () => {
    const isSucceed = await handleSendFeatureRequest(featureRequest, user.email);
    if(isSucceed) {
      navigate(PROJECTS_PATH);
    }
  }

  return (
    <Grid container className='feature-request-page'>
      <Grid item xs={8} className='feature-request-page__left-pane'>
        <Box sx={{ width: '100%' }}>
          <Stack spacing={2}>
            <Typography className='feature-request-page__label'>Describe your feature request here!</Typography>
            <OutlinedInput
              className='feature-request-page__input'
              value={featureRequest}
              onChange={onFeatureRequestChange}
              multiline
              minRows={2}
              maxRows={15}
              placeholder="Your answers will help us improve your experience."
            />
            <OnboardButton
              title='Send'
              onClick={onSendFeatureRequest}
            />
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={3} sx={{
        alignSelf: 'center',
        textAlign: 'center'
      }}>
        <LogoIcon />
      </Grid>
    </Grid>
  )
});

export default FeatureRequestPage;