// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import loadScript from 'load-script';
import { Box } from '@mui/material';
import { REACT_APP_GOOGLE_DRIVE_CLIENT_ID } from '../../config';

const GOOGLE_CLIENT_ID = REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const GOOGLE_SCOPE = 'https://www.googleapis.com/auth/drive.readonly';

const GooglePicker = ({ onChange, onAuthenticate, onAuthFailed, children }) => {
  const [, setPickerInited] = useState(false);
  const [, setGisInited] = useState(false);
  const [scriptLoadingStarted, setScriptLoadingStarted] = useState(false);
  const tokenClientRef = useRef();
  const accessTokenRef = useRef();

  const isGoogleReady = () => !!window.gapi;
  const isGoogleAuthReady = () => !!window.gapi.auth2;
  const isGooglePickerReady = () => !!window.google.picker;

  const onApiLoad = () => {
    window.gapi.load('client:picker', onPickerApiLoad);
  };

  const onGisLoad = () => {
    tokenClientRef.current = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: GOOGLE_SCOPE,
    });
    setGisInited(true);
  };

  const onPickerApiLoad = async () => {
    await window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    setPickerInited(true);
  };

  const onChoose = () => {
    if (!isGoogleReady() || !isGoogleAuthReady() || !isGooglePickerReady()) return;

    createPicker();
  };

  const pickerCallback = (data) => {
    onChange(data);
  };

  const createPicker = () => {
    const showPicker = () => {
      const view = new window.google.picker.View(window.google.picker.ViewId.DOCS);
      view.setMimeTypes('video/mp4,video/mov,video/quicktime,audio/mp3,audio/mpeg,audio/wav,audio/x-wav');
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setOAuthToken(accessTokenRef.current)
        .setCallback(pickerCallback)
        .build();

      picker.setVisible(true);
    };

    tokenClientRef.current.callback = (response) => {
      if (response.error !== undefined) {
        onAuthFailed(response);
        throw response;
      }
      accessTokenRef.current = response.access_token;
      onAuthenticate(response.access_token);
      showPicker();
    };

    if (accessTokenRef.current === null) {
      tokenClientRef.current.requestAccessToken({ prompt: 'consent' });
    } else {
      tokenClientRef.current.requestAccessToken({ prompt: '' });
    }
  };

  useEffect(() => {
    if (isGoogleReady()) {
      onApiLoad();
      onGisLoad();
    } else if (!scriptLoadingStarted) {
      setScriptLoadingStarted(true);
      loadScript('https://apis.google.com/js/api.js', onApiLoad);
      loadScript('https://accounts.google.com/gsi/client', onGisLoad);
    }
  }, []);

  return (
    <Box style={{ display: 'inline-block', verticalAlign: 'middle' }} onClick={onChoose}>
      {children ? children : <button>Open google chooser</button>}
    </Box>
  );
};

export default GooglePicker;
