import React from 'react';
import { Button } from 'reactstrap';

interface DownloadExportButtonProps {
  onClick: () => void;
  exportType: string;
}

const DownloadExportButton = (props: DownloadExportButtonProps) => {
  return (
    <Button onClick={props.onClick} style={{ backgroundColor: '#079A7C', border: 'none' }}>
      Download your export {props.exportType} file
    </Button>
  );
};

export default DownloadExportButton;
