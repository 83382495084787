import { Popover } from '@mui/material';
import React, { useMemo } from 'react';
import ProjectInfoButton from '../ui_controls/buttons/ProjectInfoButton';
import ProjectSettings from '../projectSettings/ProjectSettings';
import { projectsState } from '../../models/ProjectsState';
import '../navigation/Navigation.css';
import { getDefaultSettings } from '../../controllers/verticalVideoEditorCRUD/ProjectInfoMenuItemHandlers';

const ProjectInfoMenuItem = ({ media }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { medias, activeProject, selectedMediaId } = projectsState;

  const defaultSettings = useMemo(() => {
    return getDefaultSettings(activeProject, medias, selectedMediaId);
  }, [activeProject?.id, medias, selectedMediaId]);

  return (
    <div>
      <ProjectInfoButton aria-describedby={id} variant="contained" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        classes={{ paper: 'project-settings-popover' }}
        slotProps={{
          paper: {
            style: {
              maxWidth: '30%',
            },
          },
        }}
      >
        {defaultSettings && <ProjectSettings defaultSettings={defaultSettings} media={media} />}
      </Popover>
    </div>
  );
};

export default ProjectInfoMenuItem;
