import React from 'react';
const icon = require('../../../assets/icons/project-processing.png');

const ProjectProcessingIcon = (props) => {
  return (
    <img
      src={icon}
      alt="project-processing"
      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      {...props}
    />
  );
};

export default ProjectProcessingIcon;
