import React, { useRef, useEffect, useMemo, useState } from 'react';
import { videoEditorState } from '../videoeditor/VideoEditorState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { mediaState } from '../../models/MediaState';
import { observer } from 'mobx-react';
import ARollVideoJS from './VideoJS';
import BRollVideoJS from './VideoJS';
import { Box } from '@mui/material';
import './VideoPlayer.css';
import CaptionOverlayCanvas from './CaptionOverlayCanvas';
import {
  displayARollPlayer,
  handleARollPause,
  handleARollPlay,
  handleBRollPause,
  handleBRollPlay,
  handleDispose,
  handleEndedPlayer,
  handleLoadedMetaData,
  handleTimeUpdate,
} from '../../controllers/players/VideoPlayerHandler';
import LogoOverlay from './LogoOverlay';
import { accountState } from '../../models/AccountState';
import { projectsState } from '../../models/ProjectsState';
import { isMobile } from 'react-device-detect';

interface VideoPlayerProps {
  isMainPlayer: boolean;
  aspectRatio?: string;
}

export enum PLAYER_TYPE {
  A_ROLL = 'player-a-roll',
  B_ROLL = 'player-b-roll',
}

const VideoPlayer = observer((props: VideoPlayerProps) => {
  const videoRef = useRef(null);
  const { addSetTimeCallback } = videoEditorState;
  const { videoUrl, player, setPlayer, setVideoElementAttribute, playerIntervals, currentIntervalIdx } =
    videoPlayerState;
  const { captionUrl } = mediaState;
  const { videoEditSelected } = projectsState;
  const playerContainerRef = useRef<any>();
  const aRollPlayerBoxRef = useRef<any>();
  const bRollPlayerBoxRef = useRef<any>();
  const requestARollStatusRef = useRef<boolean>(false);
  const [bRollVideoJsOptions, setBRollVideoJsOptions] = useState<any>();
  const isVideoMediaSelected = videoEditSelected && videoEditSelected.strategy === null;
  const videoJsOptions = useMemo(
    () => ({
      autoplay: true,
      controls: true,
      responsive: false,
      playsinline: isMobile,
      sources: [
        {
          src: videoUrl,
        },
      ],
      techOrder: ['youtube', 'html5'],
      aspectRatio: videoPlayerState.aspectRatio,
      canplaythrough: 5,
      playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      controlBar: {
        fullscreenToggle: false,
        pictureInPictureToggle: false,
        showProgressBar: isVideoMediaSelected,
      },
      doubleClick: false,
    }),
    [videoUrl, videoPlayerState.aspectRatio, isVideoMediaSelected, playerIntervals],
  );

  useEffect(() => {
    if (props.isMainPlayer) {
      addSetTimeCallback('VideoPlayer', (time) => {
        videoRef.current.currentTime = time;
      });
    }
  }, [videoRef]);

  useEffect(() => {
    if (isVideoMediaSelected) {
      displayARollPlayer(aRollPlayerBoxRef, bRollPlayerBoxRef);
      if (bRollVideoJsOptions) {
        setBRollVideoJsOptions({ ...bRollVideoJsOptions, aspectRatio: props.aspectRatio });
      }
    }
  }, [isVideoMediaSelected]);

  return (
    <>
      <CaptionOverlayCanvas captionUrl={captionUrl} playerContainerRef={playerContainerRef} />
      <Box className="video-player-box" ref={playerContainerRef} position={'relative'}>
        <LogoOverlay
          playerContainerRef={playerContainerRef}
          isReady={player !== null}
          corner={accountState.currentAccount?.logoCorner}
        />
        <ARollVideoJS
          className={PLAYER_TYPE.A_ROLL}
          setPlayer={setPlayer}
          setVideoElementAttribute={setVideoElementAttribute}
          options={videoJsOptions}
          onPlay={handleARollPlay}
          onPause={handleARollPause}
          onTimeUpdate={(time) => {
            handleTimeUpdate(time, aRollPlayerBoxRef, bRollPlayerBoxRef, setBRollVideoJsOptions, videoRef);
          }}
          onDispose={() => handleDispose(requestARollStatusRef)}
          onLoadedMetaData={() => {
            if (
              playerIntervals.length > 0 &&
              currentIntervalIdx >= 0 &&
              currentIntervalIdx <= playerIntervals.length - 1
            ) {
              handleLoadedMetaData(requestARollStatusRef, document);
            }
          }}
          onEnded={() => handleEndedPlayer(PLAYER_TYPE.A_ROLL)}
          playerBoxRef={aRollPlayerBoxRef}
        />
        <BRollVideoJS
          className={PLAYER_TYPE.B_ROLL}
          setPlayer={(player) => (videoPlayerState.bRollPlayer = player)}
          setVideoElementAttribute={setVideoElementAttribute}
          options={bRollVideoJsOptions}
          onPlay={handleBRollPlay}
          onPause={handleBRollPause}
          onTimeUpdate={() => {}}
          onDispose={() => {
            videoPlayerState.bRollPlayer = null;
          }}
          onLoadedMetaData={() => {
            videoPlayerState.bRollPlayer.currentTime(bRollVideoJsOptions.startTime);
            videoPlayerState.bRollPlayer.play();
          }}
          onEnded={() => handleEndedPlayer(PLAYER_TYPE.B_ROLL)}
          playerBoxRef={bRollPlayerBoxRef}
        />
      </Box>
    </>
  );
});

export default VideoPlayer;
