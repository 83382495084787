import { getYoutubeID, YOUTUBE_REGEX } from '../utils/YoutubeUtils';
import { ingestState } from '../../models/IngestState';
import { youtubeVideoInfo } from '../serverClients/VideoIngestClient';
import { filterFileName } from '../utils/UploaderUtils';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import path from 'path-browserify';
import { accountState } from '../../models/AccountState';
import { publishMetric } from '../serverClients/ProjectClient';
import { InputDrive } from '../../views/ingest/FormIngestInterface';
import { IMPORT_TYPE } from '../../views/ingest/IngestUploadStep';

const GOOGLE_DRIVE_PICKER_ACTIONS = {
  LOADED: 'loaded',
  PICKED: 'picked',
};

export const MAX_FILE_NAME_LENGTH = 200;
export const MIN_FILE_NAME_LENGTH = 2;

export async function handlePickedDriveFiles(callbackData, driveTokenRef, email: string) {
  if (callbackData.action === GOOGLE_DRIVE_PICKER_ACTIONS.PICKED) {
    let driveFiles: InputDrive[] = [];
    const isAdmin = accountState.currentAccount && accountState.currentAccount.isAdmin;
    const documents = isAdmin ? callbackData.docs : [callbackData.docs[0]];

    for (const document of documents) {
      const { name, id, driveFileUrl, sizeBytes } = document;
      if (!isValidFileNameLength(path.parse(name).name, IMPORT_TYPE.DRIVE, email)) {
        return false;
      }
      const driveEndpointDownload = `https://www.googleapis.com/drive/v3/files/${id}?alt=media`;
      driveFiles.push({
        fileId: id,
        fileName: filterFileName(name),
        driveFileUrl,
        fileSize: sizeBytes,
        driveEndpointDownload,
        driveToken: driveTokenRef.current,
      });
    }
    driveFiles = driveFiles.filter((driveFile) => !isDuplicateFileName(driveFile.fileName, IMPORT_TYPE.DRIVE, email));
    if (driveFiles.length > 0) {
      await publishMetric('ingest_drive_input_file', 1, email);
      ingestState.setFormData({
        inputDriveData: ingestState.formData.inputDriveData
          ? ingestState.formData.inputDriveData.concat(driveFiles)
          : driveFiles,
      });
    } else {
      await publishMetric('ingest_drive_input_file', 0, email);
    }
  }
}

export async function handleAddYoutubeLink(
  email,
  inputLink,
  setInputLink,
  setIsFetchYotubeInfo,
  setFormData,
  enqueueSnackbar,
  closeSnackbar,
) {
  const { formData } = ingestState;
  if (!YOUTUBE_REGEX.test(inputLink)) {
    await publishMetric('ingest_youtube_input_valid_url', 0, email);
    const messageSnackKey = enqueueSnackbar('Youtube link is not valid!', {
      variant: 'warning',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
  } else {
    // Check for existing YouTube ID
    const foundYoutubeVideo = formData.inputYoutubeData.find(
      (i) => getYoutubeID(i.fileUrl) === getYoutubeID(inputLink),
    );
    if (foundYoutubeVideo) {
      await publishMetric('ingest_youtube_input_dup_url', 1, email);
      const messageSnackKey = enqueueSnackbar('Youtube link is existed!', {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      setInputLink('');
      return false;
    }
    await publishMetric('ingest_youtube_input_valid_url', 1, email);
    let inputYoutube;
    // fetch youtube info
    try {
      setIsFetchYotubeInfo(true);
      const youtubeInfo = await youtubeVideoInfo(inputLink, '');
      inputYoutube = {
        fileUrl: inputLink,
        fileName: `${filterFileName(youtubeInfo.title)}.mp4`,
      };
    } catch (err) {
      await publishMetric('ingest_youtube_input_get_file', 0, email);
      console.log('Error while fetching youtube info: ', inputLink);
      throw err;
    } finally {
      setIsFetchYotubeInfo(false);
    }
    if (!isValidFileNameLength(inputYoutube.fileName, IMPORT_TYPE.YOUTUBE, email)) {
      return;
    }

    if (!isDuplicateFileName(inputYoutube.fileName, IMPORT_TYPE.YOUTUBE, email)) {
      await publishMetric('ingest_youtube_input_file', 1, email);
      setFormData({
        inputYoutubeData: [...formData.inputYoutubeData, inputYoutube],
      });
    } else {
      await publishMetric('ingest_youtube_input_file', 0, email);
    }
    setInputLink('');
    return true;
  }
}

export async function handImportDropbox(email, files) {
  const { setFormData, formData } = ingestState;
  let dropboxFiles = []
  for (const newFile of files) {
    const { name, ext } = path.parse(newFile.name);
    const filename = `${filterFileName(name)}${ext.toLowerCase()}`;
    if (!isValidFileNameLength(filename, IMPORT_TYPE.DROPBOX, email)) {
      return;
    }
    if (!isDuplicateFileName(filename, IMPORT_TYPE.DROPBOX, email)) {
      const { name, ...fileInfo } = newFile;
      dropboxFiles.push({ ...fileInfo, fileName: filename });
    }
  }
  if (dropboxFiles.length > 0) {
    await publishMetric('ingest_dropbox_input_file', 1, email);
    setFormData({ inputDropboxData: [...formData.inputDropboxData, ...dropboxFiles] });
  } else {
    await publishMetric('ingest_dropbox_input_file', 0, email);
  } 
}

export function isDuplicateFileName(fileName, importType: string, email: string, notify: boolean = true) {
  const { formData, fileUploads } = ingestState;
  const isDriveFileDuplicate = formData.inputDriveData.find((driveFile) => driveFile.fileName === fileName)
    ? true
    : false;
  const isYoutubeFileDuplicate = formData.inputYoutubeData.find((youtubeFile) => youtubeFile.fileName === fileName)
    ? true
    : false;
  const isDropboxFileDuplicate = formData.inputDropboxData.find((dropboxFile) => dropboxFile.fileName === fileName)
    ? true
    : false;
  const isLocalFileDuplicate = fileUploads.find((file) => file.file.name === fileName) ? true : false;
  const isDup = isDriveFileDuplicate || isYoutubeFileDuplicate || isLocalFileDuplicate || isDropboxFileDuplicate;
  if (isDup && notify) {
    const messageSnackKey = enqueueSnackbar('File is duplicated. Please choose a file with a different name.', {
      variant: 'warning',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
  }
  publishMetric(`ingest_${importType}_import_dup_file`, isDup ? 1 : 0, email);
  return isDup;
}

export function isValidFileNameLength(fileName: string, importType: string, email?: string, notify: boolean = true) {
  const isValid = fileName.length <= MAX_FILE_NAME_LENGTH && fileName.length >= MIN_FILE_NAME_LENGTH;
  if (!isValid && notify) {
    const messageSnackKey = enqueueSnackbar(
      `File name must be between ${MIN_FILE_NAME_LENGTH} and ${MAX_FILE_NAME_LENGTH} characters.`,
      { variant: 'warning', SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) } },
    );
  }
  publishMetric(`ingest_${importType}_import_valid_file`, isValid ? 1 : 0, email);
  return isValid
}
