import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormHandlers } from './FormHandlers';
import FormUpload from './FormUpload';
import IngestFileImports from './IngestFileImports';
import { ingestState } from '../../models/IngestState';

export enum IMPORT_TYPE {
  DRIVE = 'drive',
  YOUTUBE = 'youtube',
  DROPBOX = 'dropbox',
  LOCAL = 'local',
}

export type TFileImport = {
  type: IMPORT_TYPE;
  fileInfo: any;
};

type Props = {
  isChatBot?: boolean;
};

const IngestUploadStep = observer(({ isChatBot }: Props) => {
  const { submitState } = FormHandlers();
  const { fileUploads, formData, setFormData } = ingestState;
  const [fileImports, setFileImports] = useState<TFileImport[]>(combineFileImports());

  useEffect(() => {
    setFileImports(combineFileImports());
  }, [
    fileUploads.length,
    formData.inputYoutubeData.length,
    formData.inputDriveData.length,
    formData.inputDropboxData.length,
  ]);

  const handleRemoveUploadFile = (fileImport: TFileImport) => {
    const fileIndex = fileUploads.findIndex((i) => i.file.name === fileImport.fileInfo.file.name);
    switch (fileImport.type) {
      case IMPORT_TYPE.LOCAL:
        if (fileIndex !== -1) {
          fileUploads.splice(fileIndex, 1);
        }
        break;
      case IMPORT_TYPE.DRIVE:
        setFormData({
          inputDriveData: formData.inputDriveData.filter(
            (driveFile) => driveFile.fileName !== fileImport.fileInfo.fileName,
          ),
        });
        break;
      case IMPORT_TYPE.YOUTUBE:
        setFormData({
          inputYoutubeData: formData.inputYoutubeData.filter(
            (youtubeFile) => youtubeFile.fileName !== fileImport.fileInfo.fileName,
          ),
        });
        break;
      case IMPORT_TYPE.DROPBOX:
        setFormData({
          inputDropboxData: formData.inputDropboxData.filter((file) => file.fileName !== fileImport.fileInfo.fileName),
        });
        break;
      default:
        break;
    }
    setFileImports(combineFileImports());
  };

  function combineFileImports() {
    const fileImports: TFileImport[] = [];
    fileUploads.forEach((fileUpload) => {
      fileImports.push({
        type: IMPORT_TYPE.LOCAL,
        fileInfo: fileUpload,
      });
    });

    formData.inputYoutubeData.forEach((youtubeFile) => {
      fileImports.push({
        type: IMPORT_TYPE.YOUTUBE,
        fileInfo: youtubeFile,
      });
    });

    formData.inputDriveData.forEach((youtubeFile) => {
      fileImports.push({
        type: IMPORT_TYPE.DRIVE,
        fileInfo: youtubeFile,
      });
    });

    formData.inputDropboxData.forEach((file) => {
      fileImports.push({
        type: IMPORT_TYPE.DROPBOX,
        fileInfo: file,
      });
    });

    return fileImports;
  }

  return (
    <Box>
      <Typography fontSize={30}>Import media</Typography>
      <FormUpload submitState={submitState} isChatBot={isChatBot} />

      {fileImports.length > 0 && (
        <Box>
          <Typography fontSize={20}>Uploaded videos</Typography>
          <IngestFileImports fileImports={fileImports} onRemoveFile={handleRemoveUploadFile} />
        </Box>
      )}
    </Box>
  );
});

export default IngestUploadStep;
