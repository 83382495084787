import React from 'react';
import './VideoPlayerPlaceholder.css';

function VideoPlayerPlaceholder() {
  return (
    <div className="placeholder-container">
      <div className="video-container">
        <div className="play-button"></div>
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayerPlaceholder;
