import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DriveButton from '../ui_controls/buttons/DriveButton';
import GooglePicker from './GooglePicker';
import { handlePickedDriveFiles } from '../../controllers/ingest/UploadHandlers';

const DriveUpload = observer(() => {
  const { user } = useAuth0();
  const driveTokenRef = useRef();

  async function onAuthenticateGD(driveTokenCallback) {
    driveTokenRef.current = driveTokenCallback;
  }

  return (
    <GooglePicker
      onChange={(e) => handlePickedDriveFiles(e, driveTokenRef, user.email)}
      onAuthenticate={onAuthenticateGD}
      onAuthFailed={(data: any) => console.log('on auth failed:', data)}
    >
      <Box style={{ cursor: 'pointer' }}>
        <DriveButton />
      </Box>
    </GooglePicker>
  );
});

export default DriveUpload;
