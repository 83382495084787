import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { ProjectExportOptions } from '../../models/ProjectItem';
import { projectsState } from '../../models/ProjectsState';
import { accountState } from '../../models/AccountState';
import { FormTypes } from '../../models/IngestState';
import { postExportProject } from '../serverClients/ProjectClient';
import { segmentsState } from '../../models/SegmentsState';
import { chatbotState } from '../../models/ChatbotState';
import { mediaState } from '../../models/MediaState';

function getAllFavoritesSegments(strategyOutputs) {
  let favoritesSegments: number[] = [];
  Object.values(strategyOutputs).forEach((strategy) => {
    Object.values(strategy).forEach((result) => {
      result.videoEditItems?.forEach((editItem) => {
        if (editItem.isFavorite === true) {
          favoritesSegments = favoritesSegments.concat(editItem.segmentIds);
        }
      });
    });
  });
  return favoritesSegments;
}

function getAllEditSegmentIds(strategyOutputs) {
  let allEdits = [];
  if (strategyOutputs) {
    Object.values(strategyOutputs).forEach((strategy) => {
      Object.values(strategy)
        .reverse()
        .forEach((result) => {
          result.videoEditItems?.forEach((editItem) => {
            allEdits = allEdits.concat(editItem.segmentIds);
          });
        });
    });
  }
  return allEdits;
}

export const hanldeExportOptionChange = (value, exportSelected, setExportSelected) => {
  const { activeProject, videoEditSelected, getSelectedStrategyOutputs } = projectsState;
  const strategyOutputs = getSelectedStrategyOutputs();
  if (value === ProjectExportOptions.ACTIVE && videoEditSelected) {
    setExportSelected({
      ...exportSelected,
      videoEditSegments: videoEditSelected.segmentIds,
      videoEditTitle: videoEditSelected.title,
      exportOption: value,
    });
  } else if (value === ProjectExportOptions.FAVORITES) {
    setExportSelected({
      ...exportSelected,
      videoEditSegments: getAllFavoritesSegments(strategyOutputs),
      exportOption: value,
      videoEditTitle: null,
    });
  } else {
    // all segmentIDs
    setExportSelected({
      ...exportSelected,
      videoEditSegments: getAllEditSegmentIds(strategyOutputs),
      exportOption: value,
      videoEditTitle: activeProject?.name,
    });
  }
};

export async function exportProject(
  isCallingUpdateSegment,
  setErrorMessage,
  setDownloading,
  exportSelected,
  user,
  setToast,
  isChatBot,
) {
  try {
    const { currentAccount } = accountState;
    if (isCallingUpdateSegment) {
      const messageSnackKey = enqueueSnackbar('Please wait for your transcripts update', {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      return;
    }
    if (!exportSelected.type) {
      setErrorMessage('Choose a download option');
      return;
    }

    if (!exportSelected?.includeVertical && !exportSelected?.includeLandscape) {
      setErrorMessage(`Please choose the resolution ('Landscape' and/or 'Vertical')`);
      return;
    }

    setDownloading(true);
    setErrorMessage(null);

    let videoEditSegmentIds = [];
    let exportOriginalMediaId;
    if (!isChatBot) {
      exportOriginalMediaId = projectsState.videoEditSelected?.strategy ? undefined : projectsState.selectedMediaId;
      if (exportOriginalMediaId === undefined || exportOriginalMediaId === null) {
        videoEditSegmentIds = JSON.parse(JSON.stringify(exportSelected.videoEditSegments));
        if (!['mp4', 'mp4_vertical'].includes(exportSelected.type)) {
          // [End card] doesn't support export non-mp4 yet
          videoEditSegmentIds = videoEditSegmentIds.filter(
            (segmentId) => !segmentsState.segments.find((i) => i.id === segmentId).end_card,
          );
        }
      }
    } else {
      videoEditSegmentIds = exportSelected.videoEditSegments;
    }
    const isHumanCut = isChatBot
      ? false
      : projectsState.activeProject.originalData.formType === FormTypes.HUMAN_CUT_FORM;
    const projectId = isChatBot ? chatbotState.getSelectedChat()?.chatId : projectsState.activeProjectId;
    const captionStyles =
      isChatBot && exportSelected.type !== 'mp4'
        ? currentAccount?.captionStyles
        : exportSelected.noCaption
        ? null
        : mediaState.captionStyles;

    const exportJobId = await postExportProject(
      projectId,
      exportOriginalMediaId,
      exportSelected,
      captionStyles,
      user.email,
      videoEditSegmentIds,
      accountState.currentAccount.logoCorner,
      isHumanCut,
      accountState.currentAccount.middleBarColor,
    );
    if (!isChatBot) {
      setToast((prev) => ({
        ...prev,
        isOpen: true,
        message: "🙌 We'll send you an email with a link to your exported file in a few minutes",
      }));
    }
    return exportJobId;
  } catch (err) {
    console.log(err);
    setErrorMessage("Couldn't export your project, please try again!");
  } finally {
    setDownloading(false);
  }
}
