import { v4 as uuidV4 } from 'uuid';
import { AI_AUTHOR, CHAT_STATUS, MESSAGE_TYPE } from '../../models/ChatMessage';

export function createDefaultChats(owner: string) {
  const NUMBER_OF_LISTING = 1;
  return Array.from({ length: NUMBER_OF_LISTING }, () => {
    const dateTime = new Date(); // updatedMs is the sortKey in Dynamo Schema, so we need to add an offset value to avoid duplicate data issues
    const chatId = uuidV4();
    return {
      chatId,
      title: `Project ${formatMilisecondToDate(dateTime)}`,
      owner,
      createdMs: dateTime.getTime(),
      updatedMs: dateTime.getTime(),
      status: CHAT_STATUS.CREATED,
      messages: [
        {
          chatId,
          timestamp: Date.now(),
          messageId: uuidV4(),
          author: AI_AUTHOR,
          type: MESSAGE_TYPE.TEXT,
          content: 'Wsup! I’m Eddy 🎥, your AI video editor. Import your footage and I’ll start cutting it.',
        },
      ],
    };
  });
}

function formatMilisecondToDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are zero indexed
  const year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  day = formatNumberWithLeadingZero(day);
  month = formatNumberWithLeadingZero(month);
  hours = formatNumberWithLeadingZero(hours);
  minutes = formatNumberWithLeadingZero(minutes);
  seconds = formatNumberWithLeadingZero(seconds);

  const formattedDate = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;
  return formattedDate;
}

function formatNumberWithLeadingZero(number) {
  return (number < 10 ? '0' : '') + number;
}
