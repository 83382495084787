import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form } from 'reactstrap';
import { FormHandlers } from './FormHandlers';
import IngestInfoStep from './IngestInfoStep';
import IngestUploadStep from './IngestUploadStep';
import { ingestState } from '../../models/IngestState';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import BackToHomeButton from '../ui_controls/buttons/BackToHomeButton';
import { useLocation } from 'react-router-dom';
import { CHATBOT_PATH, PREVIEW_CHATBOT_PATH, PROJECTS_PATH } from '../../config/constants';
import { accountState } from '../../models/AccountState';

const Ingest = observer(() => {
  const navigate = useNavigate();
  const { formElementRef } = FormHandlers();
  const { formData, fileUploads, isSubmitting } = ingestState;
  const [isInputExist, setIsInputExist] = useState<boolean>(false);
  const { pathname } = useLocation();
  const isChatBot = pathname.startsWith(PREVIEW_CHATBOT_PATH) || pathname.startsWith(CHATBOT_PATH);
  const isAdmin = accountState.currentAccount && accountState.currentAccount.isAdmin;

  useEffect(() => {
    if (
      fileUploads.length > 0 ||
      formData.inputYoutubeData.length > 0 ||
      formData.inputDropboxData.length > 0 ||
      formData.inputDriveData.length > 0
    ) {
      setIsInputExist(true);
    } else {
      setIsInputExist(false);
    }
  }, [fileUploads.length, formData]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (ingestState.isSubmitting) {
        const warningMessage = 'You have unsaved changes. Are you sure you want to leave this page?';
        event.returnValue = warningMessage;
        return warningMessage;
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [ingestState.isSubmitting, ingestState.fileUploads.length]);

  return (
    <Form className="form-video-ingest" innerRef={formElementRef}>
      <Stack
        direction={'row'}
        justifyContent={isAdmin && !isChatBot ? 'space-between' : 'flex-end'}
        alignItems={'center'}
        sx={{
          backgroundColor: '#3D4C49',
          color: 'white',
          padding: '10px 20px',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        {isAdmin && !isChatBot && (
          <Typography style={{ opacity: 0.7 }}>Please import all your files for this edit at this step.</Typography>
        )}
        {!isChatBot && <BackToHomeButton onClick={() => navigate(PROJECTS_PATH)} disabled={isSubmitting} />}
      </Stack>
      <Box
        style={{
          backgroundColor: '#000A08',
          padding: 20,
          color: 'white',
        }}
      >
        <IngestUploadStep isChatBot={isChatBot} />
        <Divider
          sx={{
            backgroundColor: '#fff',
          }}
        />
        <IngestInfoStep isInputExist={isInputExist} isChatBot={isChatBot} />
      </Box>
    </Form>
  );
});

export default Ingest;
