export enum CaptionFontsEnum {
  'Arial Black' = '/fonts/arialblack.ttf', // default font
  'Bangers Regular' = '/fonts/Bangers-Regular.ttf',
  'Bebas Neue Regular' = '/fonts/BebasNeue-Regular.ttf',
  'Madimi One Regular' = '/fonts/MadimiOne-Regular.ttf',
  'Poppins Regular' = '/fonts/Poppins-Regular.ttf',
  'Russo One' = '/fonts/RussoOne-Regular.ttf',
  'Lucida Grande Bold' = '/fonts/LucidaGrande-Bold.ttf',
  'EB Garamond Regular' = '/fonts/EBGaramond-Regular.ttf',
  'Montserrat Regular' = '/fonts/Montserrat-Regular.ttf',
}

export enum PresetColorsEnum {
  disable = 'disable',
  white = '#FFFFFF',
  black = '#000000',
  dark_indigo = '#2C2C54',
  cool_gray = '#AAABB8',
  light_gray = '#ECECEC',
  antique_white = '#F4F1DE',
  terra_cotta = '#E07A5F',
  cerulean_blue = '#0F2143',
  grass_green = '#43572E',
  pacific_blue = '#354E56',
  yarrow_gold = '#8B6212',
  deep_slate_blue = '#3D405B',
  muted_sage_green = '#81B29A',
  deep_purple_gray = '#474787',
}

export enum CaptionStylesEnum {
  ALIGNMENT = 'alignment',
  FONT = 'font',
  PRIMARY_COLOR = 'primaryColor',
  ACTIVE_COLOR = 'activeColor',
  INACTIVE_COLOR = 'inactiveColor',
  OUTLINE_COLOR = 'outlineColor',
  FONT_SIZE = 'fontSize',
}

export enum CaptionAlignments {
  BOTTOM = 2,
  MIDDLE = 5,
  TOP = 8,
}

export const PresetColors = Object.values(PresetColorsEnum).filter((key) => key !== 'disable');
export const SupportedStyles = Object.values(CaptionStylesEnum);
export const SupportedFonts = Object.keys(CaptionFontsEnum);
export const ListFontFiles = Object.values(CaptionFontsEnum);

export type TCaptionStyles = {
  alignment: number;
  font: string;
  primaryColor: string;
  activeColor: string;
  inactiveColor: string;
  outlineColor: string;
  fontSize: number;
};
