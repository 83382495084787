import React from 'react';

interface EddyLogoIconProps {
  width: string;
  height: string;
  fill: string;
}

const EddyLogoIcon = (props: EddyLogoIconProps) => (
  <svg width={props.width} height={props.height} viewBox={`0 0 32 32`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 12.7179C32 13.3744 31.4927 13.9487 30.8293 13.9487H26.5366V11.8974C26.5366 11.7744 26.5366 11.6103 26.5366 11.4872H30.8293C31.4927 11.4872 32 12.0205 32 12.7179ZM3.90244 15.5897H23.4146V21.7436C23.4146 27.4051 19.0439 32 13.6585 32C8.27317 32 3.90244 27.4051 3.90244 21.7436V15.5897ZM0 17.2308V18.8718C0 19.7744 0.702439 20.5128 1.56098 20.5128H2.34146V15.5897H1.56098C0.702439 15.5897 0 16.3282 0 17.2308ZM25.7561 15.5897H24.9756V20.5128H25.7561C26.6146 20.5128 27.3171 19.7744 27.3171 18.8718V17.2308C27.3171 16.3282 26.6146 15.5897 25.7561 15.5897ZM8.97561 10.6667C8.97561 10.2154 9.32683 9.84615 9.7561 9.84615H17.561C17.9902 9.84615 18.3415 10.2154 18.3415 10.6667V13.9487H24.9756V11.8974C24.9756 5.33333 19.9024 0 13.6585 0C7.41463 0 2.34146 5.33333 2.34146 11.8974V13.9487H8.97561V10.6667ZM16.7805 13.9487V11.4872H10.5366V13.9487H16.7805Z"
      fill={props.fill || '#7F57F1'}
    />
  </svg>
);

export default EddyLogoIcon;
