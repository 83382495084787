import React, { useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import { Box, Typography } from '@mui/material';
import { Modal } from 'reactstrap';
import RequestAccessButton from '../ui_controls/buttons/RequestAccessButton';
import { observer } from 'mobx-react';
import { chatbotState } from '../../models/ChatbotState';
import './Modal.css';
import { REACT_APP_TYPE_FORM_URL } from '../../config';

const RequestAccessModal = observer(() => {
  const [isTypeformOpen, setIsTypeformOpen] = useState<boolean>(false);
  const onCloseModal = () => {
    chatbotState.setShouldShowRequestAccessModal(false);
    setIsTypeformOpen(false);
  };

  const requestAccessBody = (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <ChatIcon sx={{ marginRight: 1, width: '40px', height: '40px' }} />
        <Typography variant="h3">Eddy</Typography>
      </Box>
      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
        Add Eddy to your Post team
      </Typography>
      <Typography align={'center'} style={{ color: '#A6A3AE', marginBottom: '1rem' }}>
        Augment your post production team with Eddy, your AI video editor who can swiftly cut and craft videos
      </Typography>
      <Box sx={{ width: 160 }}>
        <RequestAccessButton
          sx={{
            textTransform: 'none',
            backgroundColor: '#079A7C',
            color: 'white',
            fontSize: '16px',
            padding: '0.5rem',
            width: '100%',
            border: '1px solid #373247',
          }}
          onClick={() => setIsTypeformOpen(true)}
        />
      </Box>
    </>
  );

  const typeformBody = (
    <iframe
      src={REACT_APP_TYPE_FORM_URL}
      width="100%"
      height="100%"
      title="Request Access Form"
      allow="camera; microphone; autoplay; encrypted-media;"
    ></iframe>
  );

  return (
    <Modal
      onClosed={onCloseModal}
      isOpen={chatbotState.shouldShowRequestAccessModal}
      toggle={() => chatbotState.setShouldShowRequestAccessModal(!chatbotState.shouldShowRequestAccessModal)}
      centered={true}
      className="request-access-modal"
      contentClassName={isTypeformOpen ? 'request-access-modal-typeform-content' : 'request-access-modal-info-content'}
    >
      {isTypeformOpen ? typeformBody : requestAccessBody}
    </Modal>
  );
});

export default RequestAccessModal;
