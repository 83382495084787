import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import './Button.css';
import PromptIcon from '../icons/PromptIcon';

type Props = {
  onClick: () => void;
};

function RePromptButton({ onClick }: Props) {
  return (
    <Box className="reprompt-button-container">
      <Button
        className="reprompt-button"
        startIcon={<PromptIcon style={{ width: '24px', height: '24px', objectFit: 'contain' }} />}
        onClick={onClick}
      >
        <Typography>Prompt</Typography>
      </Button>
    </Box>
  );
}

export default RePromptButton;
