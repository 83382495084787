import React, { SetStateAction } from 'react';
import { deleteAccount, getAccount } from '../serverClients/AccountClient';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { AccountItem } from '../../models/AccountState';
import { LogoutOptions } from '@auth0/auth0-react';

const validateEmail = (email) => {
  if (!email) {
    return true;
  }
  const re = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  return re.test(email);
};

const handleDeleteAccount = async (
  email: string,
  error: boolean,
  loading: boolean,
  setLoading: React.Dispatch<SetStateAction<boolean>>,
  currentAccount: AccountItem,
  logout: (options?: LogoutOptions) => Promise<void>,
) => {
  if (error || loading) {
    return;
  }
  setLoading(true);
  try {
    const targetAccount = await getAccount(email, 'token');
    if (!targetAccount) {
      const messageSnackKey = enqueueSnackbar('This account does not exist.', {
        variant: 'error',
        SnackbarProps: {
          onClick: () => closeSnackbar(messageSnackKey),
        },
      });
      return;
    }
    await deleteAccount(email, 'token');
    const messageSnackKey = enqueueSnackbar('Delete account success.', {
      variant: 'success',
      SnackbarProps: {
        onClick: () => closeSnackbar(messageSnackKey),
      },
    });
    if (email === currentAccount.userID) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  } catch (error) {
    console.log(`Delete account error: `, JSON.stringify(error, null, 2));
    const messageSnackKey = enqueueSnackbar('Unable to delete this account.', {
      variant: 'error',
      SnackbarProps: {
        onClick: () => closeSnackbar(messageSnackKey),
      },
    });
  } finally {
    setLoading(false);
  }
};

const handleChangeEmailInput = (event, setError, setEmail) => {
  setError(!validateEmail(event.target.value));
  setEmail(event.target.value);
};

export { validateEmail, handleDeleteAccount, handleChangeEmailInput };
