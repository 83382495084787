import { observer } from 'mobx-react';
import React from 'react';
import { projectsState } from '../../models/ProjectsState';
import { Box } from '@mui/material';
import TranscriptEditor from '../transcript/TranscriptEditor';
import { saveSegments } from '../../controllers/verticalVideoEditorCRUD/VideoEditSegmentsHandler';

const VideoEditSegmentsEditor = observer(() => {
  const { videoEditSelected, activeProject } = projectsState;
  const isShowTranscript = videoEditSelected && activeProject;

  return <Box>{isShowTranscript && <TranscriptEditor onSaveSegments={saveSegments} />}</Box>;
});

export default VideoEditSegmentsEditor;
