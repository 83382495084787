import React, { useEffect } from 'react';
import { ListItem, Typography, Stack, IconButton, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react';
import FavoriteIcon from '../ui_controls/icons/FavoriteIcon';
import { projectsState } from '../../models/ProjectsState';
import { VideoEditItem } from '../../models/VideoEditItem';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  displayVideoEditDuration,
  handleUpdateFavoriteVideoEdit,
  onSearchParamChange,
} from '../../controllers/verticalVideoEditorCRUD/AIVideoEditItemHandler';
import { NEW_PROJECT_PATH } from '../../config/constants';
import { AI_EDIT_PATH } from '../../config/constants';
import './VerticalEditor.css';
import CheckCircleIcon from '../ui_controls/icons/CheckCircleIcon';

type TProps = {
  videoEdit: VideoEditItem;
  videoEditIndex: number;
  isShowSelected: boolean;
  title?: string;
  showCheckIcon?: boolean;
};

const AIVideoEditItem = observer(({ videoEdit, isShowSelected, title, showCheckIcon }: TProps) => {
  const { user } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const { videoEditSelected } = projectsState;
  const favoriteCssClassName = videoEdit.isFavorite ? 'favorite' : '';
  const isVideoEditSelected = JSON.stringify(videoEdit.segmentIds) === JSON.stringify(videoEditSelected.segmentIds);
  const videoEditSelectedCssClassName = isVideoEditSelected ? 'selected' : '';
  const { pathname } = useLocation();
  const isEdit = pathname.startsWith(AI_EDIT_PATH);
  const isNewProjectPage = pathname.startsWith(NEW_PROJECT_PATH);

  useEffect(() => {
    if (!isNewProjectPage) onSearchParamChange(searchParams, setSearchParams);
  }, [searchParams, isNewProjectPage]);

  return (
    <ListItem
      className={`media-item ${isShowSelected && videoEditSelectedCssClassName}`}
      onClick={() => {
        setSearchParams({ clip: encodeURI(videoEdit.title) });
      }}
    >
      <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} width={'100%'} flexWrap={'nowrap'}>
        <IconButton
            style={{ padding: '0px 6px', pointerEvents: isEdit ? "auto" : "none"}}
            onClick={(e) => handleUpdateFavoriteVideoEdit(e, videoEdit, isVideoEditSelected, user)}
          >
          <FavoriteIcon
            className={`media-item__icon ${favoriteCssClassName}`}
            outline={!videoEdit.isFavorite}
          />
        </IconButton>
        <Typography
          fontSize={14}
          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flexGrow: '1' }}
        >
          {title ? title : videoEdit.title}
        </Typography>
        <Typography fontSize={14} sx={{ whiteSpace: 'nowrap', margin: '0px 3px' }}>
          {displayVideoEditDuration(videoEdit.duration)}
        </Typography>
        <Box width={24}>{showCheckIcon && isVideoEditSelected && <CheckCircleIcon />}</Box>
      </Stack>
    </ListItem>
  );
});

export default AIVideoEditItem;
