import { observer } from 'mobx-react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { SetStateAction, useEffect } from 'react';
import { Modal } from 'reactstrap';
import Ingest from '../ingest/Ingest';
import { ingestState } from '../../models/IngestState';

interface IngestModalProps {
  isActive: boolean;
  toggle: () => void;
  onClosed: () => void;
  isChatBot?: boolean;
  setShowSuccessMessage?: React.Dispatch<SetStateAction<boolean>>;
  onChatbotIngest?: () => void;
}

const IngestModal = observer((props: IngestModalProps) => {
  const { formFeedbacks, setFormFeedbacks } = ingestState;

  useEffect(() => {
    if (formFeedbacks.length > 0) {
      const isSuccessFeedback = formFeedbacks[0]?.type === 'success';
      if (!isSuccessFeedback) {
        const messageSnackKey = enqueueSnackbar(formFeedbacks[0].message, {
          variant: 'warning',
          hideIconVariant: false,
          SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
          onExited: () => setFormFeedbacks([]),
        });
      } else if (props.isChatBot && isSuccessFeedback) {
        setFormFeedbacks([]);
        props.toggle();
        props.onChatbotIngest();
      }
    }
  }, [formFeedbacks]);

  return (
    <>
      <Modal
        backdrop={!props.isChatBot ? 'static' : true}
        onClosed={props.onClosed}
        isOpen={props.isActive}
        toggle={props.toggle}
        zIndex={2}
        size="lg"
        contentClassName="modal-ingest-content"
      >
        <Ingest />
      </Modal>
    </>
  );
});

export default IngestModal;
