import React from 'react';
import { Button } from 'reactstrap';

type TProps = {
  onClick?: () => void;
  backgroundColor?: string;
};

function CloseModalButton({ onClick, backgroundColor }: TProps) {
  return (
    <Button
      style={{
        color: 'white',
        backgroundColor: backgroundColor || '#1E253E',
        border: 'none',
        padding: 5,
        fontSize: '1.8rem',
      }}
      onClick={onClick}
    >
      &times;
    </Button>
  );
}

export default CloseModalButton;
