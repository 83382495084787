import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { getProjectJson, updateProjectState } from '../serverClients/ProjectClient';
import { projectsState } from '../../models/ProjectsState';
import { ProjectStatus } from '../../models/ProjectItem';
import { getIngestProjectJSON } from '../serverClients/VideoIngestClient';

const handleToggleRePromptModal = (isSubmitting, isModalActive, setIsModalActive, setFormFeedbacks) => {
  if (!isSubmitting) {
    setIsModalActive(!isModalActive);
    setFormFeedbacks([]);
  }
};

const notifyRePromptFeedbacks = (formFeedbacks, isSuccessFeedback, setFormFeedbacks) => {
  if (formFeedbacks.length > 0) {
    if (!isSuccessFeedback) {
      const messageSnackKey = enqueueSnackbar(formFeedbacks[0].message, {
        variant: 'warning',
        hideIconVariant: false,
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
        onExited: () => setFormFeedbacks([]),
      });
    }
  }
};

const showRePromptingModalAndCloseForm = (
  isSubmitting,
  isSuccessFeedback,
  isModalActive,
  showRepromptFeedback,
  setFormFeedbacks,
  setIsModalActive,
  setShowRepromptFeedback,
) => {
  if (!isSubmitting && isSuccessFeedback) {
    setFormFeedbacks([]);
    setIsModalActive(!isModalActive);
    setShowRepromptFeedback(!showRepromptFeedback);
  }
};

const rePromptCallback = async (projectId: string, setIsShowSuccess, setShowRepromptFeedback) => {
  try {
    const { activeProject } = projectsState;
    const projectReponse = await getProjectJson(projectId, 'token');
    const originalProjectData = await getIngestProjectJSON(projectId, 'accessToken');
    await updateProjectState(projectReponse, projectId);
    setIsShowSuccess(true);
    setShowRepromptFeedback(true);
    if (activeProject) {
      activeProject.displayStatus = ProjectStatus.CREATED;
      activeProject.originalData = originalProjectData;
    }
  } catch (error) {
    console.log('Re-prompt callback failed: ', error);
  }
};

const handleOpenPromptModal = (setIsModalActive, status) => {
  if (status === ProjectStatus.RE_PROMPTING) {
    const messageSnackKey = enqueueSnackbar('Your prompt is processing.', {
      variant: 'warning',
      hideIconVariant: false,
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    return;
  }
  setIsModalActive(true);
};

const handleFocusNewPromptEdit = (setSearchParams, setShowRepromptFeedback) => {
  const edit = projectsState.getLatestEdit();
  setSearchParams({ clip: encodeURIComponent(edit.videoEditItems[0].title) });
  setShowRepromptFeedback(false);
};

export {
  handleToggleRePromptModal,
  notifyRePromptFeedbacks,
  showRePromptingModalAndCloseForm,
  rePromptCallback,
  handleOpenPromptModal,
  handleFocusNewPromptEdit,
};
