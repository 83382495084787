import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import RemoveShareIcon from '../ui_controls/icons/RemoveShareIcon';
import { observer } from 'mobx-react';
import { ingestState } from '../../models/IngestState';
import { IMPORT_TYPE, TFileImport } from './IngestUploadStep';

type Props = {
  fileImports: TFileImport[];
  onRemoveFile?: (fileImport: TFileImport) => void;
};

const IngestFileImports = observer(({ fileImports, onRemoveFile }: Props) => {
  const { isSubmitting } = ingestState;

  function getFileName(fileImport: TFileImport) {
    switch (fileImport.type) {
      case IMPORT_TYPE.LOCAL:
        return fileImport.fileInfo.file.name;
      case IMPORT_TYPE.DRIVE:
      case IMPORT_TYPE.YOUTUBE:
      case IMPORT_TYPE.DROPBOX:
        return fileImport.fileInfo.fileName;
      default:
        throw new Error('Invalid import type');
    }
  }

  return (
    <>
      {fileImports.map((fileImport) => (
        <Stack
          key={getFileName(fileImport)}
          spacing={2}
          margin={'1rem 0'}
          direction="row"
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Typography sx={{ width: '80%', opacity: 0.7 }}>{getFileName(fileImport)}</Typography>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            {isSubmitting && fileImport.type === IMPORT_TYPE.LOCAL && (
              <Box sx={{ backgroundColor: '#3D4C49', padding: '5px 10px', borderRadius: '5px' }}>
                <Typography fontWeight={700} sx={{ whiteSpace: 'nowrap' }}>
                  Processing {fileImport.fileInfo.percentProgress}%
                </Typography>
              </Box>
            )}
            {isSubmitting && fileImport.type === IMPORT_TYPE.LOCAL && (
              <Typography sx={{ opacity: 0.6, whiteSpace: 'nowrap' }}>
                {fileImport.fileInfo.timeRemaining} {fileImport.fileInfo.timeRemaining > 1 ? 'mins' : 'min'} left
              </Typography>
            )}
            {onRemoveFile && !isSubmitting && (
              <IconButton sx={{ padding: 0 }} onClick={() => onRemoveFile(fileImport)}>
                <RemoveShareIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      ))}
    </>
  );
});

export default IngestFileImports;
