import React from 'react';
import './Button.css';
import { Stack, Typography } from '@mui/material';
import DropboxIcon from '../icons/DropboxIcon';

type TProps = {
  isDisabled?: boolean;
};

function DropboxButton(props: TProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        backgroundColor: '#111A19',
        padding: '8px 12px',
        borderRadius: '10px',
        ':hover': {
          backgroundColor: props?.isDisabled ? 'initial' : '#0a100e', // Set hover color
        },
      }}
    >
      <DropboxIcon
        size="xs"
        style={{
          height: 24,
        }}
      />
      <Typography sx={{ color: '#999D9C' }}>Dropbox</Typography>
    </Stack>
  );
}

export default DropboxButton;
