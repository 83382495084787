import { MAX_POLLING_TIME_OUT, serverAddress } from '../../config';
import { getOptions } from '../utils/FetchUtils';

const SLEEP_TIME = 5000;

const pollServer = async (job_id: string, accessToken: string) => {
  const url = `${serverAddress}/api/job-response/${encodeURIComponent(job_id)}`;

  const result = await fetch(url, getOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
    });

  return result;
};

export const pollJob = async (job_id: string, interval: number = SLEEP_TIME) => {
  let job: any = null;
  let waitedTime = 0;
  while (!job) {
    if (waitedTime === MAX_POLLING_TIME_OUT) {
      throw new Error(`Polling Job ID ${job_id} has timed out!`);
    }
    job = await pollServer(job_id, 'token');
    if (!job.job_id || job.job_id !== job_id) {
      job = null;
      console.log('.');
      waitedTime += interval;
      await new Promise((resolve) => setTimeout(resolve, interval));
    }
  }
  return job;
};
