import React, { useEffect } from 'react';
import { List, Box, ListItemButton, Typography, Collapse, Stack } from '@mui/material';
import { Prompt, VideoEditItem } from '../../models/VideoEditItem';
import AIVideoEditItem from './AIVideoEditItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { projectsState } from '../../models/ProjectsState';
import { observer } from 'mobx-react';
import { getPopperSettings } from '../../controllers/projectsCRUD/ProjectPageHandler';
import ProjectProcessingIcon from '../ui_controls/icons/ProjectProcessingIcon';
import { EditStrategies } from '../../models/IngestState';

type Props = {
  prompt: Prompt;
  strategy: string;
  videoEditItems: VideoEditItem[];
  mediaId: number;
  onUpdateSettings: ({ type, settings }) => void;
  isLoading: boolean;
  showCheckIcon?: boolean;
};

const AIVideoEditGroup = observer(
  ({ prompt, videoEditItems, mediaId, strategy, onUpdateSettings, isLoading, showCheckIcon }: Props) => {
    const [isOpen, setIsOpen] = React.useState(() => {
      return getPopperSettings(mediaId, prompt.id, strategy)?.status === 'collapse' ? false : true;
    });
    const { videoEditSelected } = projectsState;
    const shouldDisplayVideoEditFeature = videoEditSelected && !videoEditSelected.nonSupport;
    const isSingleEditStories = strategy === EditStrategies.STORIES && videoEditItems && videoEditItems.length === 1;

    useEffect(() => {
      setIsOpen(getPopperSettings(mediaId, prompt.id, strategy)?.status === 'collapse' ? false : true);
    }, [projectsState.settings?.menu.popper && JSON.stringify(projectsState.settings?.menu.popper)]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onUpdateSettings({
        type: 'popper',
        settings: {
          mediaId,
          strategy,
          promptId: prompt.id,
          status: !isOpen ? 'open' : 'collapse',
        },
      });
      setIsOpen(!isOpen);
    };

    if (isSingleEditStories) {
      return (
        <AIVideoEditItem title={prompt.storyAbout} videoEdit={videoEditItems[0]} videoEditIndex={0} isShowSelected />
      );
    }

    return (
      <Box>
        <ListItemButton onClick={handleClick} sx={{ width: '100%', justifyContent: 'space-between' }}>
          {!isLoading && (
            <Stack direction={'row'} gap={2} justifyContent={'space-between'} width={'100%'}>
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: isOpen ? 'normal' : 'nowrap',
                  textOverflow: isOpen ? 'unset' : 'ellipsis',
                  fontSize: '14px',
                  fontStyle: 'italic',
                }}
              >
                {prompt.storyAbout}
              </Typography>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </Stack>
          )}
          {isLoading && (
            <Box
              sx={{
                height: 20,
              }}
            >
              <ProjectProcessingIcon className={'project-processing-icon-spinning'} />
            </Box>
          )}
        </ListItemButton>
        {videoEditItems && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {shouldDisplayVideoEditFeature &&
                videoEditItems.map((videoEdit, index) => (
                  <>
                    {videoEdit.mediaId === mediaId && (
                      <AIVideoEditItem
                        videoEdit={videoEdit}
                        videoEditIndex={index}
                        showCheckIcon={showCheckIcon}
                        isShowSelected
                      />
                    )}
                  </>
                ))}
            </List>
          </Collapse>
        )}
      </Box>
    );
  },
);

export default AIVideoEditGroup;
