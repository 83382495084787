import { projectsState } from '../../models/ProjectsState';

export function getStrategyOutputs() {
  const mediaStrategyOutputs = Object.values(projectsState.medias).map((media) => media.strategyOutputs) as any;
  const groupStrategyOutputs = mediaStrategyOutputs.reduce((acc, mediaStrategyOutput) => {
    const strategies = Object.keys(mediaStrategyOutput);
    strategies.forEach((strategy) => {
      if (!acc[strategy]) {
        acc[strategy] = Object.values(mediaStrategyOutput[strategy]);
      } else {
        acc[strategy] = [...acc[strategy], ...Object.values(mediaStrategyOutput[strategy])];
      }
    });
    return acc;
  }, {});

  Object.keys(groupStrategyOutputs).forEach((strategy) => {
    groupStrategyOutputs[strategy] = Object.fromEntries(
      groupStrategyOutputs[strategy].map((item, index) => [index, item]),
    );
  });

  return groupStrategyOutputs;
}
