import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { mediaState } from '../../models/MediaState';
import { projectsState } from '../../models/ProjectsState';
import { segmentsState } from '../../models/SegmentsState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { patchBulkSegmentsByProject, patchSegmentByProject } from '../serverClients/ProjectClient';
import { getVideoEditCaptionPresignedUrl } from '../serverClients/VideoIngestClient';
import Broll from '../../models/Broll';
import Segment from '../../models/Segment';
import { VideoEditItem } from '../../models/VideoEditItem';

export const saveSegments = async (segmentIds: number[], brolls?: Broll[]) => {
  const { activeProjectId, setIsCallingUpdateSegment } = projectsState;
  try {
    setIsCallingUpdateSegment(true);
    if (segmentIds.length === 1) {
      const targetSegment = segmentsState.segments.find((item) => item.id === segmentIds[0]);
      await patchSegmentByProject(
        activeProjectId,
        {
          id: targetSegment.id,
          text: targetSegment.text.trim(),
          words: targetSegment.words,
          start: targetSegment.start,
          end: targetSegment.end,
          end_card: targetSegment.end_card,
        },
        '',
        projectsState.videoEditSelected.id,
        projectsState.videoEditSelected.segmentIds,
        brolls,
      );
    } else {
      const newSegments = segmentIds.map((id) => {
        const targetSegment = segmentsState.segments.find((item) => item.id === id);
        return {
          id: targetSegment.id,
          text: targetSegment.text.trim(),
          words: targetSegment.words,
          start: targetSegment.start,
          end: targetSegment.end,
          story_id: targetSegment.story_id,
          strategy: targetSegment.strategy,
          imported_media: targetSegment.media,
          end_card: targetSegment.end_card,
        };
      });
      await patchBulkSegmentsByProject(
        activeProjectId,
        newSegments,
        'token',
        projectsState.videoEditSelected.id,
        projectsState.videoEditSelected.segmentIds,
        brolls,
      );
    }

    const videoEditPresignedUrl = await getVideoEditCaptionPresignedUrl(
      projectsState.activeProjectId,
      projectsState.videoEditSelected.id,
      projectsState.videoEditSelected.strategy,
    ); // fetch new presigned url of captions
    mediaState.setCaptionUrl(videoEditPresignedUrl);
    if (videoPlayerState.overlayCaptionInstance && videoEditPresignedUrl) {
      const newSubContent = await fetch(videoEditPresignedUrl).then((response) => response.text());
      videoPlayerState.replaceSubtitleByAssContent(newSubContent); // reload overlay caption
    } else {
      console.log('Could not update caption overlay: ', err);
    }
  } catch (err) {
    console.log('Fail to update transcript', err);
    const messageSnackKey = enqueueSnackbar('Could not update transcript', {
      variant: 'error',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    throw err;
  } finally {
    setIsCallingUpdateSegment(false);
  }
};

export const handleSaveBulkSegments = async (
  segmentIds,
  videoEditSelected: VideoEditItem,
  segments: Segment[],
  brolls?: Broll[],
) => {
  const { activeProjectId, setIsCallingUpdateSegment } = projectsState;
  try {
    setIsCallingUpdateSegment(true);
    const newSegments = segmentIds.map((id) => {
      const targetSegment = segments.find((item) => item.id === id);
      return {
        id: targetSegment.id,
        text: targetSegment.text.trim(),
        words: targetSegment.words,
        start: targetSegment.start,
        end: targetSegment.end,
        story_id: targetSegment.story_id,
        strategy: targetSegment.strategy,
        imported_media: targetSegment.media,
        end_card: targetSegment.end_card,
      };
    });
    await patchBulkSegmentsByProject(
      activeProjectId,
      newSegments,
      'token',
      videoEditSelected.id,
      videoEditSelected.segmentIds,
      brolls,
    );
  } catch (error) {
    console.log('Fail to save bulk segment', error);
    throw error;
  } finally {
    setIsCallingUpdateSegment(false);
  }
};
