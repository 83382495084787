import { Grid, Typography, Card } from '@mui/material';
import React from 'react';

type Props = {
  category: {
    title: string;
    IconComponent: React.ReactElement;
  };
  isSeleceted: boolean;
  onCardClick: () => void;
};

function CategoryCard({ category, isSeleceted, onCardClick }: Props) {
  return (
    <Grid item xs={5} sx={{ marginBottom: 1, marginRight: 2, cursor: 'pointer' }}>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          gap: 2,
          padding: 2,
          backgroundColor: isSeleceted ? '#fff' : '#ffffffB3',
          border: isSeleceted ? '3px solid #079A7C' : '3px solid',
          transition: 'transform 0.1s ease-in-out',
          '&:hover': {
            backgroundColor: '#fff',
            transform: 'translateY(-5px)',
          },
        }}
        onClick={onCardClick}
      >
        {category.IconComponent}
        <Typography sx={{ fontWeight: 700 }}>{category.title}</Typography>
      </Card>
    </Grid>
  );
}

export default CategoryCard;
