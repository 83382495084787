import { observer } from 'mobx-react';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmButton from '../ui_controls/buttons/ConfirmButton';
import { useAuth0 } from '@auth0/auth0-react';
import CancelButton from '../ui_controls/buttons/CancelButton';
import ProjectItem from '../../models/ProjectItem';
import { deleteProject } from '../../controllers/serverClients/ProjectClient';
import { Box } from '@mui/material';
import CloseModalButton from '../ui_controls/buttons/CloseModalButton';

interface DeleteProjectModalProps {
  projectItem: ProjectItem;
  isActive: boolean;
  toggle: () => void;
}

const DeleteProjectModal = observer((props: DeleteProjectModalProps) => {
  const { user } = useAuth0();
  const { projectItem } = props;

  const onDeleteButtonClick = () => {
    deleteProject(projectItem.id, user.email, 'token');
    props.toggle();
  };
  const onCancelButtonClick = () => {
    props.toggle();
  };

  return (
    <Modal isOpen={props.isActive} toggle={props.toggle}>
      <Box
        style={{
          backgroundColor: '#1E253E',
          color: 'white',
          borderRadius: 5,
        }}
      >
        <ModalHeader style={{ border: 0 }} close={<CloseModalButton onClick={() => props.toggle()} />}>
          Delete project
        </ModalHeader>
        <ModalBody>
          Do you want to delete <b>{projectItem.name}</b>?
        </ModalBody>
        <ModalFooter style={{ border: 0, justifyContent: 'right' }}>
          <CancelButton onClick={onCancelButtonClick} />
          <ConfirmButton label={'Delete'} onClick={onDeleteButtonClick} isDisabled={false} />
        </ModalFooter>
      </Box>
    </Modal>
  );
});

export default DeleteProjectModal;
