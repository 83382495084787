import { serverAddress } from '../../config';
import { TCaptionStyles } from '../../models/CaptionsStyle';
import { projectsState } from '../../models/ProjectsState';
import { mediaState } from '../../models/MediaState';
import { getOptions, patchOptions } from '../utils/FetchUtils';

export async function updateCaptionStyles(projectId, email, captionStyles: TCaptionStyles, accessToken) {
  const url = `${serverAddress}/api/captions/caption-styles?project_id=${projectId}&email=${email}`;
  const captionJob = await fetch(url, patchOptions(url, accessToken, captionStyles))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });
  return captionJob;
}

export const getCaptionStyles = async (projectId: string): Promise<any> => {
  const endpoint = `${serverAddress}/api/captions/caption-styles?project_id=${projectId}`;
  const accessToken = 'token';

  return await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .catch((error) => {
      console.error('There was a problem getting caption styles:', error.message);
      throw error;
    })
    .then((data) => data);
};

export function fetchCaptionStyles(): void {
  getCaptionStyles(projectsState.activeProjectId)
    .then((captionStyle) => {
      console.log(`get caption styles success: `, captionStyle);
      mediaState.setCaptionStyles(captionStyle);
    })
    .catch((error) => {
      console.error('Unable to fetch caption alignment');
      throw error;
    });
}
