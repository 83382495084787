import { Button } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  onClick?: () => void;
  color?: string;
};

function OnboardButton({ title, onClick, color }: Props) {
  return (
    <Button
      sx={{
        width: 'fit-content',
        backgroundColor: color ?? '#079A7C',
        padding: '10px 0',
        color: 'white',
        textTransform: 'none',
        alignSelf: 'start',
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export default OnboardButton;
