import { Popover } from '@mui/material';
import React, { useState } from 'react';
import { observer } from 'mobx-react';

import CaptionStyleButton from '../ui_controls/buttons/CaptionStyleButton';
import CaptionStyles from '../menus/captionStyles';
import '../navigation/Navigation.css';
import { useLocation } from 'react-router-dom';
import { SHARE_PATH } from '../../config/constants';

const CaptionStylesMenu = observer(() => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [updating, setUpdating] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? 'caption-popover' : undefined;
  const { pathname } = useLocation();
  const isShare = pathname.startsWith(SHARE_PATH);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isShare) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CaptionStyleButton onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: 'project-settings-popover settings-popover-dark' }}
      >
        <CaptionStyles closeMenu={setAnchorEl} isUpdating={updating} setUpdating={setUpdating} />
      </Popover>
    </>
  );
});

export default CaptionStylesMenu;
