import { action, makeObservable, observable } from 'mobx';
import { TCaptionStyles, CaptionAlignments, PresetColorsEnum } from './CaptionsStyle';
class MediaState {
  mediaIdToUrl: Record<number, string>;
  captionUrl: string;
  captionStyles: TCaptionStyles = {
    alignment: CaptionAlignments.MIDDLE,
    font: 'Arial Black',
    primaryColor: PresetColorsEnum.light_gray,
    activeColor: PresetColorsEnum.white,
    inactiveColor: PresetColorsEnum.cool_gray,
    outlineColor: PresetColorsEnum.black,
    fontSize: 70,
  };

  constructor() {
    this.mediaIdToUrl = {};
    this.captionUrl = '';
    makeObservable(this, {
      mediaIdToUrl: observable,
      captionUrl: observable,
      captionStyles: observable,
      setMediaIdToUrl: action,
      clearMedia: action,
      setCaptionUrl: action,
      setCaptionStyles: action,
    });
  }

  setMediaIdToUrl = (mediaIdToUrl: Record<number, string>) => {
    this.mediaIdToUrl = mediaIdToUrl;
  };

  setCaptionUrl = (url: string) => {
    this.captionUrl = url;
  };

  clearMedia = () => {
    this.mediaIdToUrl = {};
  };

  setCaptionStyles = (captionStyles: any) => {
    this.captionStyles = captionStyles;
  };
}

export const mediaState = new MediaState();
