import React from 'react';
import { IconButton } from '@mui/material';

const trashBinIcon = require('../../../assets/icons/trash.png');
interface TrashBinButtonProps {
  style?: React.CSSProperties;
  onClick: (e) => void;
  className?: string;
}

const TrashBinButton = (props: TrashBinButtonProps) => (
  <IconButton
    aria-label="delete"
    onClick={(e) => {
      e.stopPropagation();
      props.onClick(e);
    }}
    {...props}
  >
    <img alt="trash" src={trashBinIcon} style={{ color: '#fff', height: 24 }} />
  </IconButton>
);

export default TrashBinButton;
