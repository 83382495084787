import { Button, Typography } from '@mui/material';
import React from 'react';

function SubmitButton({ submitState, onClick }) {
  const shouldDisplaySubmit = !submitState.loading;
  const shouldDisplaySubmitting = submitState.loading;
  return (
    <Button
      disabled={submitState.loading}
      sx={{
        width: '100%',
        marginTop: '1rem',
        backgroundColor: '#3D4C49',
        padding: '10px 0',
        borderRadius: '10px',
        color: 'white',
        textTransform: 'none',
      }}
      onClick={onClick}
    >
      {shouldDisplaySubmitting && <Typography style={{ display: 'inline-block' }}>Submitting...</Typography>}
      {shouldDisplaySubmit && 'Import'}
    </Button>
  );
}

export default SubmitButton;
