import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { projectsState } from '../../models/ProjectsState';
import AIVideoEditItem from '../menus/AIVideoEditItem';
import AIVideoEdits from '../menus/AIVideoEdits';
import UserDropdownMenu from '../menus/UserDropdownMenu';
import MobileNavigation from '../navigation/MobileNavigation';
import VideoPlayer from '../players/VideoPlayer';
import VerticalVideoEditor from '../texteditor/VerticalVideoEditor';
import MenuButton from '../ui_controls/buttons/MenuButton';
import SignUpButton from '../ui_controls/buttons/SignUpButton';
import EditsIcon from '../ui_controls/icons/EditsIcon';
import { MenuItem } from './ProjectPage';
import './page.css';
import {
  calculateTranscriptEditorHeight,
  fetchProject,
  handleChangeMenuItem,
  handleCloneProject,
  handleLogin,
  renderLoadingText,
  toggleDisplayVideoEditList,
} from '../../controllers/projectsCRUD/ProjectPageHandler';
import { combinePunctuation } from '../../controllers/serverClients/ProjectClient';
import { segmentsState } from '../../models/SegmentsState';
import { videoPlayerState } from '../../models/VideoPlayerState';

function MobileProjectPage() {
  const intercomEle = document.querySelector('.intercom-lightweight-app');
  if (intercomEle) {
    intercomEle.setAttribute('style', 'visibility: hidden');
  }

  const navigate = useNavigate();
  const { user } = useAuth0();
  const { projectId } = useParams();
  const playerRef = useRef<any>();
  const containerRef = useRef<any>();
  const { segments } = segmentsState;
  const { videoEditSelected, getSelectedStrategyOutputs, getSelectedPrompt } = projectsState;
  const activeProject = projectsState.activeProject;
  const [isLoadProject, setIsLoadProject] = useState<boolean>(false);
  const [isCloningProject, setIsCloningProject] = useState<boolean>(false);
  const [transcriptHeight, setTranscriptHeight] = useState<number>();

  const shouldDisplayVideoEditFeature =
    videoEditSelected && !videoEditSelected.nonSupport && videoEditSelected.strategy !== null;
  const isNewProjectPage = projectId === 'new';
  const strategyOutputs = getSelectedStrategyOutputs();
  const videoEditIndex = projectsState.getVideoEditSelectedIndex();
  const prompt = getSelectedPrompt();

  if (videoPlayerState.aspectRatio !== '16:9') {
    videoPlayerState.setAspectRatio('16:9');
  }

  const MENU_ITEMS: Array<MenuItem> = [
    {
      title: 'Bin',
      icon: <EditsIcon style={{ width: '24px' }} />,
      disabled: isNewProjectPage,
    },
  ];

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>(
    !isNewProjectPage && {
      ...MENU_ITEMS[0],
      isOpen: true,
    },
  );

  useEffect(() => {
    if (
      projectId &&
      (!projectsState.activeProjectId || projectId !== projectsState.activeProjectId) &&
      !isNewProjectPage
    ) {
      projectsState.setActiveProjectId(projectId);
      fetchProject(projectId, setIsLoadProject);
    }
  }, [projectId]);

  useEffect(() => {
    if (segments.length) {
      segments.forEach((segment) => {
        if (segment?.words) {
          segment.words = combinePunctuation(segment);
        }
      });
    }
  }, [segments]);

  useEffect(() => {
    calculateTranscriptEditorHeight(containerRef, playerRef, setTranscriptHeight);
    return () => {
      setTranscriptHeight(undefined);
    };
  }, [containerRef, playerRef]);

  return (
    <>
      <Helmet title={activeProject ? `${activeProject?.displayName} | Storylines` : 'Storylines'} />
      <Stack overflow={'hidden'}>
        <MobileNavigation onClone={() => handleCloneProject(user, projectId, setIsCloningProject, navigate)} />
        <LoadingOverlay active={isLoadProject || isCloningProject} spinner text={renderLoadingText(isCloningProject)}>
          <Grid item position={'relative'} width={'100vw'} height={'100vh'} overflow={'hidden'}>
            {strategyOutputs && (
              <AIVideoEdits
                menuItem={selectedMenuItem}
                toggle={() => toggleDisplayVideoEditList(setSelectedMenuItem)}
              />
            )}
            <Box
              ref={containerRef}
              style={{
                background: '#202020',
                color: 'white',
                width: '100%',
                height: 'calc(100vh - 130px)',
                marginTop: '65px',
              }}
            >
              <Box
                ref={playerRef}
                style={{
                  padding: '1rem',
                  width: '100vw',
                  height: '30vh',
                }}
              >
                <VideoPlayer isMainPlayer={true} />
              </Box>
              {transcriptHeight && (
                <Box
                  style={{
                    padding: '1rem',
                    maxWidth: '800px',
                    height: `calc(${transcriptHeight}px - 65px)`,
                  }}
                >
                  {shouldDisplayVideoEditFeature && videoEditIndex >= 0 && (
                    <AIVideoEditItem
                      videoEdit={videoEditSelected}
                      videoEditIndex={videoEditIndex}
                      isShowSelected={false}
                      title={videoEditSelected.isShowPrompt && prompt?.prompt.storyAbout}
                    />
                  )}
                  {shouldDisplayVideoEditFeature && <VerticalVideoEditor />}
                </Box>
              )}
            </Box>
          </Grid>
        </LoadingOverlay>
        <Stack className="vertical-editor-page-list-menu-mobile" direction={'row'} justifyContent={'space-between'}>
          {MENU_ITEMS.map((item, index) => (
            <Box
              key={index}
              sx={{
                textAlign: 'center',
                display: item.disabled && 'none',
              }}
            >
              <MenuButton
                key={item.title}
                onClick={() => handleChangeMenuItem(item, setSelectedMenuItem)}
                isActive={selectedMenuItem ? item.title === selectedMenuItem.title && selectedMenuItem.isOpen : false}
                title={item.title}
              >
                {item.icon}
              </MenuButton>
            </Box>
          ))}
          {user ? <UserDropdownMenu /> : <SignUpButton onClick={() => handleLogin(false, projectId, navigate)} />}
        </Stack>
      </Stack>
    </>
  );
}

export default MobileProjectPage;
