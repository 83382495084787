import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROJECTS_PATH } from '../../config/constants';
import { handleChangeEmailInput, handleDeleteAccount } from '../../controllers/admin/AccountHandler';
import { accountState } from '../../models/AccountState';
import Navigation from '../navigation/Navigation';

const AdminPage = observer(() => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const isAdmin = accountState.currentAccount && accountState.currentAccount.isAdmin;

  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  if (isAdmin === false) {
    navigate(PROJECTS_PATH);
  }

  return (
    <Box>
      {isAdmin &&
        <>
          <Navigation />
          <Box style={{ width: '500px', margin: 'auto' }}>
            <TextField
              type="email"
              value={email}
              fullWidth
              label="Email"
              InputLabelProps={{
                style: {
                  color: 'white',
                },
              }}
              InputProps={{
                style: {
                  color: 'white',
                },
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white !important',
                  },
                },
                '& .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
              onChange={(e) => handleChangeEmailInput(e, setError, setEmail)}
              helperText="Invalid email"
              error={error}
              FormHelperTextProps={{
                style: {
                  color: error ? 'red' : '#000A08',
                  fontSize: 14,
                },
              }}
            />
            <Stack width={'100%'} alignItems={'flex-end'}>
              <Button
                variant="contained"
                color="error"
                style={{ textTransform: 'none', width: '80px', height: '36px' }}
                onClick={() => {
                  handleDeleteAccount(email, error, loading, setLoading, accountState.currentAccount, logout);
                }}
              >
                {loading ? <CircularProgress size={18} style={{ color: 'white' }} /> : 'Delete'}
              </Button>
            </Stack>
          </Box>
        </>
      }
    </Box>
  );
});

export default AdminPage;
