import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#fff',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#079a7c',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#079a7c',
  },
}));

export default CustomSwitch;
