import { OutlinedInput, Stack, Typography } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import '../pages/page.css';
import OnboardButton from '../ui_controls/buttons/OnboardButton';

type Props = {
  aiExpect: string;
  handleMoveToNextStep: () => void;
  onChangeAIExpect: (event) => void;
};

function OnboardAIExpectation({ aiExpect, handleMoveToNextStep, onChangeAIExpect }: Props) {
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (error) {
      const messageSnackKey = enqueueSnackbar(error, {
        variant: 'warning',
        SnackbarProps: {
          onClick: () => {
            setError(undefined);
            closeSnackbar(messageSnackKey);
          },
        },
      });
    }
  }, [error]);

  const handleValidate = () => {
    const errors = [];
    if (!aiExpect) errors.push('Please answer each question.');
    if (aiExpect && aiExpect.length < 3) errors.push('Minimum length: 3 characters.');
    if (aiExpect && aiExpect.length > 300) errors.push('Maximum length: 300 characters.');
    if (errors.length > 0) {
      setError(errors[0]);
    } else {
      setError(undefined);
      handleMoveToNextStep();
    }
  };

  return (
    <Stack spacing={2}>
      <Typography className="onboard-page__label">Hi. Welcome to Storylines. We’re excited you’re here!</Typography>
      <Typography className="onboard-page__input-label">
        What's one thing you would absolutely LOVE an AI video editor to do for you?
      </Typography>
      <OutlinedInput className="onboard-page__input" value={aiExpect} onChange={onChangeAIExpect} multiline />
      <OnboardButton title="Next" onClick={handleValidate} />
    </Stack>
  );
}

export default OnboardAIExpectation;
