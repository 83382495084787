import path from 'path-browserify';
import { useRef, useState } from 'react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useAuth0 } from '@auth0/auth0-react';

import {
  notifyNewProjectSubmitted,
  postProjectRetry,
  uploadVideoFromLocalDevice,
} from '../../controllers/serverClients/VideoIngestClient';
import { postVideoIngest } from '../../controllers/serverClients/VideoIngestClient';
import moment from 'moment';
import { ingestState, FormTypes } from '../../models/IngestState';
import { createProject, publishMetric, shareProject } from '../../controllers/serverClients/ProjectClient';
import { projectsState } from '../../models/ProjectsState';
import { accountState } from '../../models/AccountState';
import { useIntercom } from 'react-use-intercom';
import { postVideoIngestStatus } from '../../controllers/serverClients/VideoIngestClient';
import { IngestStatusEnum, TNotifyIngest } from '../../models/Ingest';
import { mediaState } from '../../models/MediaState';
import { filterFileName } from '../../controllers/utils/UploaderUtils';
import { ProjectStatus } from '../../models/ProjectItem';
import { MAX_STORY_ABOUT_LEN, MIN_STORY_ABOUT_LEN } from '../../config';
import { chatbotState } from '../../models/ChatbotState';
import { AI_AUTHOR, MESSAGE_TYPE, TextChatMessage } from '../../models/ChatMessage';
import { v4 as uuidV4 } from 'uuid';
import { createMessageApi } from '../../controllers/serverClients/MessageClient';
import { TSubmitState, TForm, InputFile } from './FormIngestInterface';

export function FormHandlers() {
  const { trackEvent } = useIntercom();
  const { user } = useAuth0();

  const [submitState, setSubmitState] = useState<TSubmitState>({
    loading: false,
    error: null,
    data: null,
  });
  const formElementRef = useRef<any>();
  const { setFormData, setIsSubmitting, setFormFeedbacks, imageUpload, fileUploads, setFileUploads } = ingestState;
  let formData = null as TForm | null;

  const account = accountState.currentAccount;
  const validFileFormats = new Set<string>(['mov', 'mp4']);

  const formatFilename = (filename: string) => {
    const { name, ext } = path.parse(filename);
    const folder = filterFileName(name);
    return {
      folder,
      filename: folder + ext.toLowerCase(),
    };
  };

  const sendMultipleFilesUpload = async (fileIndex: number, formData: any, localUploadCompleteCallback?) => {
    const { folder: currFolder, filename: currFilename } = formatFilename(fileUploads[fileIndex].file.name);
    const filenamePrefix = formData.formType !== FormTypes.HUMAN_CUT_FORM ? 'raw_' : '';
    const s3FileKey = `${formData.projectUUID}/${currFolder}/${filenamePrefix}${currFilename}`;

    notify_ingest_start(fileUploads[fileIndex]);
    fileUploads[fileIndex].status = 'uploading';

    await uploadVideoFromLocalDevice(fileUploads[fileIndex].file, s3FileKey, 'token', (data) =>
      handleUploadCallback(data, async () => {
        if (fileUploads.every((file) => file.status === 'success' || file.status === 'failed')) {
          if (localUploadCompleteCallback) localUploadCompleteCallback();
        } else {
          const nextIndex = fileIndex + 1;
          await sendMultipleFilesUpload(nextIndex, formData, localUploadCompleteCallback);
        }
      }),
    );
  };

  const handleUpload = async (isChatBot: boolean) => {
    try {
      // upload local files
      if (fileUploads.length > 0) {
        const fileUploadIndex = 0;
        const { folder } = formatFilename(fileUploads[fileUploadIndex].file.name);
        if (imageUpload) {
          const imageExt = imageUpload.name.split('.').pop();
          await uploadVideoFromLocalDevice(
            imageUpload,
            `${formData.projectUUID}/${folder}/logo_${folder}.${imageExt}`,
            'token',
            () => {},
          );
        }
        sendMultipleFilesUpload(fileUploadIndex, formData, () => submitIngestForm(isChatBot));
      } else {
        submitIngestForm(isChatBot);
      }
    } catch (error) {
      console.error('File upload failed:', error);
    }
  };

  function validateForm() {
    const feedbacks = [];

    if (!formData.email) {
      feedbacks.push({
        type: 'error',
        message: 'Please enter email',
      });
    }

    switch (formData.formType) {
      case '':
        if (account.isAdmin) {
          feedbacks.push({
            type: 'error',
            message: 'Please select your video output setting.',
          });
        }
        break;
      case FormTypes.SOCIAL_MEDIA_FORM:
        if (!formData.editStrategy) {
          feedbacks.push({
            type: 'error',
            message: 'Please select your video output setting.',
          });
        }
        break;
      case FormTypes.SHARE_PROJECT_FORM:
        if (
          !formData.sharedProjectUUID ||
          !(formData.sharedProjectUUID && checkValidUUID(formData.sharedProjectUUID))
        ) {
          feedbacks.push({
            type: 'error',
            message: 'Please enter valid project ID',
          });
        }
        break;
      default:
        break;
    }

    if (formData.formType !== FormTypes.HUMAN_CUT_FORM && formData.formType !== FormTypes.SHARE_PROJECT_FORM) {
      validatePrompt(feedbacks);
    }

    setFormFeedbacks(feedbacks);
    return feedbacks.length > 0 ? false : true;
  }

  const updateFileUploadingStatus = (
    status: 'uploading' | 'success' | 'failed',
    percent: number,
    timeRemaining: number,
  ) => {
    const fileUploading = fileUploads.find((file) => file.status === 'uploading');
    if (fileUploading) {
      fileUploading.percentProgress = percent;
      fileUploading.timeRemaining = timeRemaining;
      fileUploading.status = status;
    }
  };

  function handleUploadCallback(data, onNextFileCallback?) {
    switch (data.event) {
      case 'onProgress':
        updateFileUploadingStatus('uploading', data.percentage, data.timeRemaining);
        break;
      case 'onComplete':
        publishMetric('ingest_local_upload', 1, formData.email);
        updateFileUploadingStatus("success", 100, 0);
        if (onNextFileCallback) onNextFileCallback();
        break;
      case 'onError':
        publishMetric('ingest_local_upload', 0, formData.email);
        updateFileUploadingStatus("failed", 0, 0);
        setSubmitState((prev) => ({
          ...prev,
          loading: false,
        }));
        setFormFeedbacks([{ type: 'error', message: 'Oops! There was an error, please try again.' }]);
        setIsSubmitting(false);
        break;
      default:
        break;
    }
  }

  async function handleSubmit(event, formState: TForm, isChatBot: boolean) {
    event.preventDefault();
    // auto generate projectName follow by pattern: Project-<date>-<time> (date, time using UTC), eg: Project-Oct 13th 23-4:39:42 pm
    formData = {
      ...formState,
      projectName: `Project-${moment.utc().format('MMM Do YY-h:mm:ss a')}`,
    };
    notifyNewProjectSubmitted(formData, '');

    const isValidFile = handleValidateFile(formData);
    if (!isValidFile) {
      return;
    }

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    setSubmitState((prev) => ({
      ...prev,
      loading: true,
      data: null,
      error: null,
    }));
    setIsSubmitting(true);
    if (formData.formType === FormTypes.SHARE_PROJECT_FORM) {
      submitShareProject(formData);
    } else {
      handleUpload(isChatBot);
    }
  }

  async function handleSubmitRePrompt(event, formState: TForm) {
    event.preventDefault();
    formData = { ...formState };
    const feedbacks = validatePrompt([]);
    setFormFeedbacks(feedbacks);
    if (feedbacks.length > 0) return;
    try {
      setSubmitState((prev) => ({
        ...prev,
        loading: true,
      }));
      setIsSubmitting(true);
      await postProjectRetry(
        projectsState.activeProject.id,
        projectsState.activeProject.displayName,
        'generate-ai-content',
        user.email,
        formState.storyAbout,
        formState.editStrategy,
        formState.formType,
        mediaState.captionStyles,
        formData.excludeCaptions,
        formData.aiGeneratedTitle,
      );
      projectsState.setActiveProject({
        ...projectsState.activeProject,
        originalData: {
          ...projectsState.activeProject.originalData,
          editStrategy: formState.editStrategy,
        },
      });
      projectsState.setActiveProjectStatus(ProjectStatus.RE_PROMPTING);
      setFormFeedbacks([{ type: 'success' }]);
      resetForm();
    } catch {
      setFormFeedbacks([{ type: 'error', message: 'Oops! There was an error, please try again.' }]);
    } finally {
      setIsSubmitting(false);
      setSubmitState((prev) => ({ ...prev, loading: false }));
    }
  }

  function createInputFiles() {
    const inputFiles: InputFile[] = [];

    if (fileUploads.length > 0) {
      fileUploads.forEach((fileUpload) => {
        const { filename } = formatFilename(fileUpload.file.name);
        const folder = path.parse(filename).name;
        inputFiles.push({
          filePath: `${folder}/${filename}`,
          fileName: filename,
          fileURI: `s3://storylines-ingest/${formData.projectUUID}/${folder}/${filename}`,
        });
      });
    }

    formData.inputYoutubeData.forEach((youtubeImport) => {
      const { filename, folder } = formatFilename(youtubeImport.fileName);
      const s3ObjectKey = `${formData.projectUUID}/${folder}/${filename}`;
      inputFiles.push({
        fileName: filename,
        filePath: `${folder}/${filename}`,
        fileURI: `s3://storylines-ingest/${s3ObjectKey}`,
      });
    });

    formData.inputDriveData.forEach((driveImport) => {
      const { fileName } = driveImport;
      const { filename: uploadFilename, folder: folderName } = formatFilename(fileName);
      const finalFileName = formData.formType === FormTypes.HUMAN_CUT_FORM ? uploadFilename : `raw_${uploadFilename}`;
      const s3ObjectKey = `${formData.projectUUID}/${folderName}/${finalFileName}`;
      inputFiles.push({
        fileName: uploadFilename,
        filePath: `${folderName}/${uploadFilename}`,
        fileURI: `s3://storylines-ingest/${s3ObjectKey}`,
      });
    });

    formData.inputDropboxData.forEach((dropboxImport) => {
      const { filename, folder } = formatFilename(dropboxImport.fileName);
      const s3ObjectKey = `${formData.projectUUID}/${folder}/${filename}`;
      inputFiles.push({
        fileName: filename,
        filePath: `${folder}/${filename}`,
        fileURI: `s3://storylines-ingest/${s3ObjectKey}`,
      });
    });

    return inputFiles;
  }

  async function submitIngestForm(isChatBot: boolean) {
    try {
      // create inputFiles
      const inputFiles = createInputFiles();
      const projectItem = await createProject(
        inputFiles.map((inputFile) => inputFile.fileName),
        formData,
      );
      await postVideoIngest(
        {
          ...formData,
          projectName: projectItem.displayName,
          captionStyles: account.captionStyles,
          inputFiles,
        },
        '',
      );
      trackEvent('new-video-edit', {
        projectId: formData.projectUUID,
        projectName: projectItem.displayName,
        strategy: formData.editStrategy,
        storyAbout: formData.storyAbout,
      });
      if (isChatBot) {
        const storyAboutMessage: TextChatMessage = {
          chatId: chatbotState.selectedChatId,
          messageId: uuidV4(),
          content: formData.storyAbout,
          author: AI_AUTHOR,
          type: MESSAGE_TYPE.TEXT,
          timestamp: Date.now(),
        };
        chatbotState.addMessagesToChat([storyAboutMessage]);
        await createMessageApi('token', storyAboutMessage);
      }
      setFormFeedbacks([{ type: 'success' }]);
      projectsState.setProjectItems([projectItem, ...(projectsState.projectItems ?? [])]);
      projectsState.setActiveProject(projectItem);
      resetForm();
    } catch (err) {
      setSubmitState((prev) => ({
        ...prev,
        error: err?.message,
      }));
      const errorNotifyIngestPayload: TNotifyIngest = {
        projectUUID: formData.projectUUID,
        email: formData.email,
        status: IngestStatusEnum.FILE_IMPORT_FAILED,
        message: JSON.stringify({ message: err.message, stack: err.stack }),
        isChatBot: isChatBot,
      };
      postVideoIngestStatus(errorNotifyIngestPayload, 'token');
      setFormFeedbacks([{ type: 'error', message: 'Oops! There was an error, please try again.' }]);
    } finally {
      setSubmitState((prev) => ({
        ...prev,
        loading: false,
      }));
      setIsSubmitting(false);
    }
  }

  function resetForm() {
    formData = null;
    setSubmitState({
      loading: false,
      error: null,
      data: null,
    });
    setFormData({
      storyAbout: '',
      duration: '',
      email: '',
      editStrategy: '',
      editType: '',
      formType: '',
      projectName: '',
      inputYoutubeData: [],
      inputDriveData: [],
      inputDropboxData: [],
      sharedProjectUUID: '',
      pendingJobs: [],
      inputFiles: [],
      excludeCaptions: false,
      aiGeneratedTitle: true,
    });
    setFileUploads([]);
    formElementRef.current?.reset();
  }

  async function submitShareProject(formData: TForm) {
    try {
      const shareProjectRes = await shareProject(formData.sharedProjectUUID, formData.email, '');
      const messageSnackKey = enqueueSnackbar('Ingest from project success', {
        variant: 'success',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      if (shareProjectRes.projectItem) {
        projectsState.setProjectItems([shareProjectRes.projectItem, ...(projectsState.projectItems ?? [])]);
      }
      resetForm();
    } catch (err) {
      setSubmitState((prev) => ({
        ...prev,
        error: err?.message,
      }));
      const messageSnackKey = enqueueSnackbar('Oops! There was an error, please try again.', {
        variant: 'error',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
    } finally {
      setSubmitState((prev) => ({
        ...prev,
        loading: false,
      }));
      setIsSubmitting(false);
    }
  }

  function validatePrompt(feedbacks) {
    if (formData.storyAbout.length < MIN_STORY_ABOUT_LEN || formData.storyAbout.length > MAX_STORY_ABOUT_LEN) {
      feedbacks.push({
        type: 'error',
        message: `Please enter your story about that must have from ${MIN_STORY_ABOUT_LEN} to ${MAX_STORY_ABOUT_LEN} characters`,
      });
    }
    return feedbacks;
  }

  function checkValidUUID(projectId) {
    if (!projectId) {
      return false;
    }
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(projectId);
  }

  const handleValidateFile = (formData: TForm) => {
    const feedbacks = [];
    if (
      fileUploads.length === 0 &&
      formData.inputYoutubeData.length === 0 &&
      formData.inputDropboxData.length === 0 &&
      formData.inputDriveData.length === 0 &&
      formData.formType !== FormTypes.SHARE_PROJECT_FORM
    ) {
      feedbacks.push({
        type: 'error',
        message: 'Please select your video file',
      });
    }

    if (fileUploads.length > 0) {
      for (const fileUpload of fileUploads) {
        const fileExtension = fileUpload.file.name.split('.').pop().toLowerCase();
        if (!validFileFormats.has(fileExtension)) {
          feedbacks.push({
            type: 'error',
            message: 'File format is unsupported. Please upload an .mp4 or .mov file.',
          });
          break;
        }
        if (!fileUpload.file.type.startsWith('video/')) {
          feedbacks.push({
            type: 'error',
            message: 'Video file is invalid',
          });
          break;
        } else {
          const encoder = new TextEncoder();
          const fileNameSize = encoder.encode(fileUpload.file.name).length;

          if (fileNameSize > 255) {
            feedbacks.push({
              type: 'error',
              message: 'Video file name is too long.',
            });
            break;
          }
        }
      }
    }

    setFormFeedbacks(feedbacks);
    return feedbacks.length > 0 ? false : true;
  };

  const notify_ingest_start = (fileUpload?) => {
    if (formData.formType === FormTypes.SHARE_PROJECT_FORM) return;
    const payload: TNotifyIngest = {
      projectUUID: formData.projectUUID,
      email: formData.email,
      status: IngestStatusEnum.FILE_UPLOADING,
      isChatBot: formData.formType === FormTypes.CHATBOT_FORM,
    };
    if (fileUpload) {
      setFormData({ ...formData, fileSize: fileUpload.file.size });
      payload['status'] = `importing local file (${Math.round((fileUpload.file.size / (1024 * 1024)) * 100) / 100} MB)`; // in mb, fixed 2 decimals
    } else if (formData.inputYoutubeData.length > 0) {
      formData.inputYoutubeData.forEach((inputYoutube) => {
        payload['message'] = `<${inputYoutube.fileUrl} | Youtube URL>`;
        postVideoIngestStatus(payload, 'token');
      });
      return;
    } else if (formData.inputDriveData.length > 0) {
      formData.inputDriveData.forEach((inputDrive) => {
        payload['status'] = `importing via Google Drive (${
          Math.round((inputDrive.fileSize / (1024 * 1024)) * 100) / 100
        } MB)`;
        postVideoIngestStatus(payload, 'token');
      });
    }
    postVideoIngestStatus(payload, 'token');
  };

  return {
    handleSubmit,
    handleUpload,
    submitState,
    formElementRef,
    resetForm,
    handleValidateFile,
    handleSubmitRePrompt,
    formatFilename,
  };
}
