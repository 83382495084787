import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import IngestFeedbackBanner from '../ui_controls/banners/IngestFeedbackBanner';
import CloseModalButton from '../ui_controls/buttons/CloseModalButton';

interface FeedbackModalProps {
  isActive: boolean;
  toggle: () => void;
  onClosed?: () => void;
  contentComponent?: () => React.JSX.Element;
}

const FeedbackModal = observer((props: FeedbackModalProps) => {
  return (
    <Modal
      isOpen={props.isActive}
      toggle={props.toggle}
      className="modal-ingest-feedback"
      size="lg"
      onClosed={props.onClosed}
    >
      <Box>
        <ModalHeader
          style={{ border: 0, padding: '0px 12px' }}
          close={<CloseModalButton backgroundColor={'#1D2322'} onClick={props.toggle} />}
        />
        <ModalBody style={{ padding: '0px 12px' }}>
          {props.contentComponent && props.contentComponent()}
          {!props.contentComponent && (
            <>
              <Typography textAlign={'center'} fontSize={16}>
                Woot woot! AI video editing has started. We’ll send you an email when the edit is ready.
              </Typography>
              <Typography textAlign={'center'} fontSize={16}>
                In the meantime, go back to the dashboard to start a new edit.
              </Typography>
            </>
          )}
          <Row style={{ padding: '37px 12px' }}>
            <Col className="col-6 d-flex justify-content-center">
              <Typography textAlign={'center'} alignContent={'center'} fontSize={24}>
                "Editing is this beautiful combination of intuition and logic." Kathryn Bigelow
              </Typography>
            </Col>
            <Col className="col-6 d-flex justify-content-center">
              <IngestFeedbackBanner width={356} />
            </Col>
          </Row>
        </ModalBody>
      </Box>
    </Modal>
  );
});

export default FeedbackModal;
