import React from 'react';
import { Button } from 'reactstrap';

interface SignUpButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

const buttonStyle: React.CSSProperties = {
  border: 'none',
  backgroundColor: 'rgb(68, 151, 126)',
  color: 'white',
  fontWeight: 700,
  borderRadius: 20,
  padding: '5px 20px',
};

const SignUpButton = (props: SignUpButtonProps) => {
  const style = props.style ? props.style : buttonStyle;
  return (
    <Button onClick={props.onClick} color="secondary" style={style}>
      Sign up
    </Button>
  );
};

export default SignUpButton;
