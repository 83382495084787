import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Stack, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react';

type Props = {
  strategy: string;
  status: boolean;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const VideoEditStrategy = observer(({ strategy, status }: Props) => {
  const renderStrategy = (strategy) => {
    switch (strategy) {
      case 'highlights':
        return 'Reels Finder';
      default:
        return strategy.charAt(0).toUpperCase() + strategy.slice(1); // uppercase first letter
    }
  };

  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'} onClick={(e) => e.stopPropagation()}>
      <Typography fontSize={14} fontWeight={700}>
        {renderStrategy(strategy)}
      </Typography>
      {status === false && (
        <HtmlTooltip
          title="Sorry, the AI didn’t find any soundbites. It’s probably us, not you. Engineering has been alerted."
          placement="top-start"
        >
          <InfoIcon style={{ color: 'red' }} />
        </HtmlTooltip>
      )}
    </Stack>
  );
});

export default VideoEditStrategy;
