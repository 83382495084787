import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { mediaState } from '../../models/MediaState';
import VideoEditSegmentsEditor from './VideoEditSegmentsEditor';
import { Box } from '@mui/material';
import { projectsState } from '../../models/ProjectsState';
import { handleSelectedVideoEditChange } from '../../controllers/verticalVideoEditorCRUD/VerticalVideoEditorHandler';

const VerticalVideoEditor = observer(() => {
  const { videoEditSelected } = projectsState;

  useEffect(() => {
    handleSelectedVideoEditChange(videoEditSelected);
  }, [videoEditSelected, JSON.stringify(videoEditSelected?.segmentIds || {}), mediaState.mediaIdToUrl]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="custom-scrollbar"
      sx={{
        padding: '0.5rem',
        background: '#000A08',
        borderRadius: '3px',
        maxHeight: '100%',
        overflowY: 'auto',
      }}
    >
      <VideoEditSegmentsEditor />
    </Box>
  );
});

export default VerticalVideoEditor;
