import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Divider, InputAdornment, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  getMessagesInfinity,
  scrollToChatWindowBottom,
  sendTextMessage,
} from '../../controllers/chat/ChatWindowHandler';
import { accountState } from '../../models/AccountState';
import { chatbotState } from '../../models/ChatbotState';
import { TPagination } from '../../models/ChatItem';
import { CHAT_STATUS, ChatMessage } from '../../models/ChatMessage';
import EddyAILogoImage from '../images/EddyAILogoImage';
import AttachFileButton from '../ui_controls/buttons/AttachFileButton';
import RequestAccessButton from '../ui_controls/buttons/RequestAccessButton';
import ChatMessageView from './ChatMessageView';
import './ChatWindow.css';
import { handleIngestFile } from '../../controllers/chat/ChatbotPageHandler';
import ProcessingMessageView from './ProcessingMessageView';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

type Props = {
  isReadOnly?: boolean;
};

const ChatWindow = observer(({ isReadOnly }: Props) => {
  const [inputText, setInputText] = useState<string>('');
  const { currentAccount } = accountState;
  const { user } = useAuth0();
  const paginationRef = useRef<TPagination>({ isEnd: false, lastEvaluatedKey: null });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const debounceRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const messageListEleRef = useRef<HTMLElement>();
  const endEleRef = useRef<HTMLElement>();

  const isIngestFailed =
    chatbotState.selectedChatId &&
    chatbotState.chatItems.find((i) => i.chatId === chatbotState.selectedChatId)?.status === CHAT_STATUS.INGEST_FAILED;
  const disabledIngestFeature =
    isReadOnly ||
    isIngestFailed ||
    (chatbotState.selectedChatId && chatbotState.disabledIngestChats[chatbotState.selectedChatId]);
  const isSelectedChatProcessing =
    chatbotState.selectedChatId &&
    chatbotState.chatItems.find((i) => i.chatId === chatbotState.selectedChatId)?.status === CHAT_STATUS.PROCESSING;
  const disabledSubmitMessage = isReadOnly || isSelectedChatProcessing || isIngestFailed;
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);

  useEffect(() => {
    if (endEleRef.current && messageListEleRef.current) {
      chatbotState.setChatWindowUIElement({
        messageListElement: messageListEleRef.current,
        endElement: endEleRef.current,
      });
    }
  }, [endEleRef.current, messageListEleRef.current]);

  const getHeaderView = () => (
    <Box>
      <Box className="chat-window-header">
        <EddyAILogoImage styles={{ width: '10rem' }} />
        <RequestAccessButton
          onClick={() => chatbotState.setShouldShowRequestAccessModal(true)}
          sx={{
            textTransform: 'none',
            backgroundColor: '#302355',
            color: 'white',
            padding: '0.3rem',
            width: '10rem',
            height: '100%',
            border: '1px solid #373247',
            margin: '.6rem 0 0 auto',
          }}
        />
      </Box>
      <Divider className="chat-window-header-divider" />
    </Box>
  );

  const fetchMessages = async (appendBottom: boolean, scrollToBottomFlag: boolean = false) => {
    if (chatbotState.selectedChatId && !paginationRef.current.isEnd) {
      await getMessagesInfinity(
        chatbotState.selectedChatId,
        appendBottom,
        setIsFetching,
        paginationRef.current,
        (newPagination) => {
          paginationRef.current = newPagination;
        },
      );
      if (scrollToBottomFlag) {
        scrollToChatWindowBottom();
      }
    }
  };

  const handleClickSendBtn = () => {
    if (!disabledIngestFeature) {
      const messageSnackKey = enqueueSnackbar(`Import a video, then let’s chat`, {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
      return;
    }
    if (disabledSubmitMessage || inputText.length === 0) return;
    if (user) {
      sendTextMessage(chatbotState.selectedChatId, inputText, user.email, setInputText, setIsSendingMessage);
    }
  };

  useEffect(() => {
    paginationRef.current = { isEnd: false, lastEvaluatedKey: null };
    (async () => {
      await fetchMessages(true, true);
      await fetchMoreToScrollUI();
    })();
  }, [chatbotState.selectedChatId]);

  useEffect(() => {
    if (messageListEleRef.current) {
      messageListEleRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messageListEleRef.current) {
        messageListEleRef.current.removeEventListener('scroll', handleScroll);
      }
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, [messageListEleRef, debounceRef]);

  const fetchMoreToScrollUI = async () => {
    if (messageListEleRef.current.scrollHeight <= messageListEleRef.current.clientHeight) {
      await fetchMessages(false, true);
    }
  };

  const handleScroll = () => {
    if (messageListEleRef.current && messageListEleRef.current.scrollTop <= 100) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        fetchMessages(false);
      }, 1000);
    }
  };

  return (
    <Box className="chat-window">
      {isMobile && getHeaderView()}
      <Box className="chat-messages" ref={messageListEleRef}>
        {isFetching && (
          <CircularProgress
            size={30}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', color: '#079A7C' }}
          />
        )}
        {chatbotState.getSelectedChat()?.messages.map((message: ChatMessage) => (
          <ChatMessageView key={message.messageId} message={message} isReadOnly={isReadOnly} />
        ))}
        {isSelectedChatProcessing && <ProcessingMessageView />}
        <Box style={{ float: 'left', clear: 'both' }} ref={endEleRef} />
      </Box>

      <Box className="chat-input-panel">
        <TextField
          variant="outlined"
          fullWidth
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Give Eddy a story to cut"
          disabled={isReadOnly || isSendingMessage}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClickSendBtn();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                disablePointerEvents={disabledIngestFeature}
                sx={{ opacity: disabledIngestFeature && 0.5 }}
              >
                <AttachFileButton color="white" onClick={() => handleIngestFile(currentAccount, isReadOnly)} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                disablePointerEvents={disabledSubmitMessage}
                sx={{ opacity: disabledSubmitMessage && 0.5 }}
              >
                {isSendingMessage ? (
                  <CircularProgress
                    size={20}
                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', color: '#079A7C' }}
                  />
                ) : (
                  <Typography
                    sx={{ cursor: 'pointer', color: '#3D4C49', fontWeight: 500, fontSize: '14px' }}
                    onClick={handleClickSendBtn}
                  >
                    Send
                  </Typography>
                )}
              </InputAdornment>
            ),
            sx: { backgroundColor: '#0A1714', borderRadius: '8px', color: 'white' },
          }}
        />
      </Box>
    </Box>
  );
});

export default ChatWindow;
