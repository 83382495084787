import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import 'videojs-youtube';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css';
import { createOrUpdatePlayer } from '../../controllers/players/VideoJSHandlers';
import { observer } from 'mobx-react';

const VideoJS = observer(
  ({
    setPlayer,
    options,
    onPlay,
    onPause,
    onTimeUpdate,
    onLoadedMetaData,
    onDispose,
    className,
    playerBoxRef,
    setVideoElementAttribute,
    onEnded,
  }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const requestTimeUpdateRef = useRef<boolean>(false); // The flag activates actions in the requestTimeUpdate func call below
    const lastTimeRef = useRef(0);

    useEffect(() => {
      requestTimeUpdateRef.current = false;
      createOrUpdatePlayer(
        options,
        playerRef,
        videoRef,
        setVideoElementAttribute,
        className,
        requestTimeUpdateRef,
        onTimeUpdate,
        lastTimeRef,
        onDispose,
        onPlay,
        onPause,
        setPlayer,
        onLoadedMetaData,
        onEnded,
      );
    }, [options, videoRef, playerRef]);

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef.current]);

    useEffect(() => {
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          playerRef.current?.pause();
        }
      });
    }, []);

    return (
      <Box data-vjs-player className={className} ref={playerBoxRef}>
        <Box ref={videoRef} />
      </Box>
    );
  },
);

export default VideoJS;
