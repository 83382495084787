import React from 'react';
import { IconButton } from '@mui/material';
import RepeatIcon from '@mui/icons-material/RepeatOn';

interface RetryButtonProps {
  style?: any;
  onClick: (e) => void;
  disable: boolean;
}

const ProjectRetryButton = (props: RetryButtonProps) => (
  <IconButton
    aria-label="project-retry"
    disabled={props.disable}
    onClick={(e) => {
      e.stopPropagation();
      props.onClick(e);
    }}
    {...props}
  >
    <RepeatIcon />
  </IconButton>
);

export default ProjectRetryButton;
