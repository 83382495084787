import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { Box } from '@mui/material';
import { projectsState } from '../../models/ProjectsState';
import { createOverlayCaptionInstance, updateCaptionTime } from '../../controllers/captionsCRUD/CaptionOverlayHandler';

interface Props {
  captionUrl: string;
  playerContainerRef: any;
}

const OverlayCaption = observer(({ captionUrl, playerContainerRef }: Props) => {
  const canvasRef = useRef<any>();
  const captionOverlayParentRef = useRef<any>();
  const captionOverlayRef = useRef<any>();
  const { activeProject } = projectsState;
  const { videoElementAttribute } = videoPlayerState;

  useEffect(() => {
    const hasVideoElement = videoElementAttribute?.width && videoElementAttribute?.height;
    if (canvasRef.current && hasVideoElement && playerContainerRef.current && captionUrl) {
      createOverlayCaptionInstance(
        playerContainerRef,
        captionOverlayParentRef,
        captionUrl,
        canvasRef,
        captionOverlayRef,
        videoElementAttribute,
      );
    }
  }, [canvasRef.current, videoElementAttribute, captionUrl, playerContainerRef.current]);

  useEffect(() => {
    if (canvasRef.current) {
      if (!activeProject?.enableCaption) {
        canvasRef.current.style.display = 'none';
      } else {
        canvasRef.current.style.display = 'block';
      }
    }
  }, [canvasRef.current, activeProject?.enableCaption, captionOverlayRef.current]);

  useEffect(() => {
    if (captionOverlayRef.current && videoPlayerState.currentTime >= 0) {
      updateCaptionTime(captionOverlayRef);
    }
  }, [captionOverlayRef.current, videoPlayerState.currentTime]);

  // Cleanup caption component
  useEffect(() => {
    const componentOverlay = captionOverlayRef.current;
    return () => {
      componentOverlay?.dispose();
    };
  }, [captionOverlayRef.current]);

  return (
    <Box id="caption-overlay-parent" style={{ position: 'relative' }} ref={captionOverlayParentRef}>
      <canvas
        ref={canvasRef}
        style={{ position: 'absolute', display: 'block', left: 0, top: 0, pointerEvents: 'none' }}
      />
    </Box>
  );
});

export default OverlayCaption;
