import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Box, Stack, Typography, Button, ListItem, List } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import '../modals/Modal.css';
import { handleAddProjectOwner, handleRemoveProjectOwner } from '../../controllers/projectsCRUD/ProjectUsersHandlers';
import { projectsState } from '../../models/ProjectsState';

const CollaborationMenu = observer(() => {
  const { activeProject } = projectsState;
  const listOwner = activeProject.owner.split(',');
  const [newOwner, setNewOwner] = useState('');

  const handleInputChange = (event) => {
    setNewOwner(event.target.value);
  };

  return (
    <Box className="modal-container-section" sx={{ padding: 3, textAlign: 'left', position: 'relative' }}>
      <Typography sx={{ fontSize: '1em' }}>Add Project Owner</Typography>

      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        width={300}
        sx={{
          marginTop: '10px',
          height: '40px',
          justifyContent: 'center',
          backgroundColor: '#292c2c',
          borderRadius: '5px',
          paddingLeft: '5px !important',
        }}
      >
        <PermIdentityIcon />
        <InputBase
          sx={{
            flex: 1,
            color: 'white',
            '& .MuiInputBase-input::placeholder': { color: '#929796' },
            fontSize: '0.9em',
          }}
          placeholder="Email"
          inputProps={{ 'aria-label': 'email' }}
          value={newOwner}
          onChange={handleInputChange}
        />

        <Button
          onClick={() => handleAddProjectOwner(newOwner, activeProject, setNewOwner)}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: '#3d4d49',
            color: 'white',
            fontSize: '13px',
            fontWeight: 'bold',
            marginRight: '5px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#52766d',
            },
          }}
        >
          Add
        </Button>
      </Stack>

      <List>
        {listOwner.map((item, index) => (
          <ListItem key={index} sx={{ padding: '8px 0 8px 0' }}>
            <Stack
              direction="row"
              alignItems="flex-start"
              gap={1}
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              <Typography
                sx={{ fontSize: '0.9em', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {item}
              </Typography>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography sx={{ fontSize: '0.7em' }}>Project Owner</Typography>
                <HighlightOffIcon onClick={() => handleRemoveProjectOwner(item, activeProject)} />
              </Stack>
            </Stack>
          </ListItem>
        ))}
      </List>
    </Box>
  );
});

export default CollaborationMenu;
