import WarningIcon from '@mui/icons-material/Warning';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { Button } from 'reactstrap';

type TProps = {
  open: boolean;
};

function MobileWarningModal({ open }: TProps) {
  const [isMobileWarningModalOpen, setIsMobileWarningModalOpen] = useState<boolean>(open);

  const handleClose = () => {
    setIsMobileWarningModalOpen(false);
  };

  return (
    <Dialog
      open={isMobileWarningModalOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: 'rgb(30, 37, 62)',
          color: 'white',
        },
      }}
    >
      <DialogTitle>
        <WarningIcon />
        Warning
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={'white'}>
          Site is designed for computers. There are known issues when editing on mobile phones.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Agree</Button>
      </DialogActions>
    </Dialog>
  );
}

export default MobileWarningModal;
