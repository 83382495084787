import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Box, Input } from '@mui/material';
import './Navigation.css';

type Props = {
  value: string;
  onChange: (event, setEditValue) => void;
  onSaveValue: (value) => void;
  style?: any;
  isUpdate?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

const TextEditable = observer(({ value, onChange, onSaveValue, style, isUpdate = false, disabled = false, readOnly = false }: Props) => {
  const inputRef = useRef<any>();
  const contentEditableRef = useRef<any>();
  const [isFocused, setIsFocused] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const onSave = () => {
    if (!isUpdate) {
      contentEditableRef.current?.blur();
      if (editValue !== value) onSaveValue(editValue);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents adding a new line
      onSave();
      return;
    }

    if (event.key === 'Escape') {
      setEditValue(value);
    }
  };

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.scrollTop = 0;
    }
    setIsFocused(false);
    if (editValue !== value) onSaveValue(editValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  return (
    <Box style={{ display: 'flex', alignItems: 'center', ...style, pointerEvents: readOnly ? 'none' : 'auto' }}>
      <LoadingOverlay
        active={isUpdate}
        spinner
        className="nav_texteditable_loading-overlay"
        styles={{ spinner: (base) => ({ ...base, width: '20px' }) }}
      >
        <Input
          inputRef={inputRef}
          ref={contentEditableRef}
          value={editValue}
          onChange={(e) => onChange(e, setEditValue)}
          fullWidth
          defaultValue={editValue}
          disabled={disabled}
          onKeyDown={(e) => onKeyDown(e)}
          className="navigation__text-editatble"
          multiline
          maxRows={1}
          onFocus={handleFocus}
          onBlurCapture={handleBlur}
          disableUnderline
          sx={{
            '& .MuiInputBase-input': !isFocused
              ? {
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                }
              : {
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '1px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                },
          }}
        />
      </LoadingOverlay>
    </Box>
  );
});

export default TextEditable;
