import { v4 as uuidV4 } from 'uuid';
import { chatbotState } from '../../models/ChatbotState';
import {
  AI_AUTHOR,
  CHAT_STATUS,
  MESSAGE_STATUS,
  MESSAGE_TYPE,
  TextChatMessage,
  VideoChatMessage,
} from '../../models/ChatMessage';
import { pollSingleProjectStatus } from '../serverClients/ProjectClient';
import { ProjectStatus } from '../../models/ProjectItem';
import { addVideoMessageListPlayers, getChatProject, scrollToChatWindowBottom } from './ChatWindowHandler';

export const handleIngestFile = (currentAccount, isReadOnly) => {
  if (isReadOnly) {
    return;
  }
  if (currentAccount) {
    chatbotState.setShouldShowIngestModal(true);
  } else {
    chatbotState.setShouldShowRequestAccessModal(true);
  }
};

export async function onChatbotIngest() {
  const chatId = chatbotState.selectedChatId;
  chatbotState.setDisabledIngestChats(chatId, true);
  await new Promise((resolve) => setTimeout(resolve, 2000));
  const messageData: TextChatMessage = {
    chatId,
    messageId: uuidV4(),
    content: "Great I'm analyzing your video now",
    author: AI_AUTHOR,
    type: MESSAGE_TYPE.TEXT,
    timestamp: Date.now(),
  };
  await Promise.all([
    chatbotState.sendMessageToChat(messageData),
    chatbotState.updateChatStatus(chatId, CHAT_STATUS.PROCESSING),
  ]);

  const processingMessage: VideoChatMessage = {
    chatId,
    messageId: uuidV4(),
    author: AI_AUTHOR,
    type: MESSAGE_TYPE.VIDEO,
    status: MESSAGE_STATUS.PROCESSING,
    content: 'proccessing',
    timestamp: Date.now() + 500,
  };

  pollSingleProjectStatus(processingMessage.chatId, true, (status) => {
    handleChatIngestCallback(status, processingMessage);
  });
}

export async function addChatSuccessMessages(processingMessage: VideoChatMessage) {
  try {
    const chatProject = await getChatProject(processingMessage.chatId);

    const messageData = {
      chatId: processingMessage.chatId,
      messageId: uuidV4(),
      type: MESSAGE_TYPE.TEXT,
      author: AI_AUTHOR,
      content: '✅ Finished editing',
      timestamp: Date.now(),
    };
    await chatbotState.sendMessageToChat(messageData);

    const videoEditId = chatProject.videoEdits[0].id; // first videoEdit is processed message
    const successVideoMessage = {
      ...processingMessage,
      status: MESSAGE_STATUS.SUCCESS,
      timestamp: Date.now() + 1000,
      content: videoEditId,
    };
    await chatbotState.sendMessageToChat(successVideoMessage);
    await chatbotState.updateChatStatus(processingMessage.chatId, CHAT_STATUS.SUCCESS);
    addVideoMessageListPlayers(processingMessage.chatId);

    scrollToChatWindowBottom();
  } catch (error) {
    console.error('Error adding ingest success messages', error);
  }
}

async function handleChatIngestCallback(status, messageData) {
  if (status === ProjectStatus.CREATED) {
    await addChatSuccessMessages(messageData);
  } else {
    const chat = chatbotState.chatItems.find((i) => i.chatId === messageData.chatId);
    if (chat) {
      const ingestFailedMessage: TextChatMessage = {
        chatId: chat.chatId,
        messageId: uuidV4(),
        type: MESSAGE_TYPE.TEXT,
        author: AI_AUTHOR,
        content: 'Dang. There was an error importing your video. Please create a new project and try again.',
        timestamp: Date.now(),
      };
      await chatbotState.sendMessageToChat(ingestFailedMessage);
      await chatbotState.updateChatStatus(messageData.chatId, CHAT_STATUS.INGEST_FAILED);
    }
  }
}

export function handleChatNotFoundError(chatId: string) {
  console.debug(`The chat ${chatId} is deleted`);
  throw new Error(`The chat ${chatId} is deleted`);
}
