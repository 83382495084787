import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CategoryCard from './CategoryCard';
import FormInput from '../ingest/FormInput';
import OnboardButton from '../ui_controls/buttons/OnboardButton';
import PersonalUseIcon from '../ui_controls/icons/PersonalUseIcon';
import CreatorIcon from '../ui_controls/icons/CreatorIcon';
import ContentBusinessIcon from '../ui_controls/icons/ContentBusinessIcon';
import VideoProfessionalIcon from '../ui_controls/icons/VideoProfessionalIcon';
import MarketerIcon from '../ui_controls/icons/MarketerIcon';
import EducationIcon from '../ui_controls/icons/EducationIcon';
import OtherIcon from '../ui_controls/icons/OtherIcon';
import VideoEditorIcon from '../ui_controls/icons/VideoEditorIcon';
import DirectorIcon from '../ui_controls/icons/DirectorIcon';
import ProducerIcon from '../ui_controls/icons/ProducerIcon';
import ContentMarketerIcon from '../ui_controls/icons/ContentMarketerIcon';
import SocialMediaIcon from '../ui_controls/icons/SocialMediaIcon';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

const CATEGORIES = [
  { title: 'Personal use', IconComponent: <PersonalUseIcon /> },
  { title: 'Creator', IconComponent: <CreatorIcon /> },
  { title: 'Content business', IconComponent: <ContentBusinessIcon /> },
  { title: 'Video Professional', IconComponent: <VideoProfessionalIcon /> },
  { title: 'Marketer', IconComponent: <MarketerIcon /> },
  { title: 'Education', IconComponent: <EducationIcon /> },
  { title: 'Other', IconComponent: <OtherIcon /> },
];

const VIDEO_SUB_CATEGORIES = [
  { title: 'Video Editor', IconComponent: <VideoEditorIcon /> },
  { title: 'Director', IconComponent: <DirectorIcon /> },
  { title: 'Producer', IconComponent: <ProducerIcon /> },
  { title: 'Other', IconComponent: <OtherIcon /> },
];

const MARKETER_SUB_CATEGORIES = [
  { title: 'Content Marketer', IconComponent: <ContentMarketerIcon /> },
  { title: 'Social Media', IconComponent: <SocialMediaIcon /> },
  { title: 'Other', IconComponent: <OtherIcon /> },
];

const labelStyle = { fontWeight: 700, fontSize: 24 };

type Props = {
  selectedCategory: string;
  selectedSubCategory: string;
  userDescribe: string;
  onChooseCategory: (category) => void;
  onChooseSubCategory: (subCategory) => void;
  onChangeUserDescribe: (value) => void;
  onMoveBack: () => void;
  onFinish: () => void;
};

function OnboardUserDescribe({
  selectedCategory,
  selectedSubCategory,
  userDescribe,
  onChooseCategory,
  onChooseSubCategory,
  onChangeUserDescribe,
  onMoveBack,
  onFinish,
}: Props) {
  const [error, setError] = useState<string>();

  const isShowVideoSubCategory = selectedCategory === 'Video Professional';
  const isShowMarketerSubCategory = selectedCategory === 'Marketer';
  const isShowOtherField = selectedSubCategory === 'Other' || selectedCategory === 'Other';

  useEffect(() => {
    if (error) {
      const messageSnackKey = enqueueSnackbar(error, {
        variant: 'warning',
        SnackbarProps: {
          onClick: () => {
            setError(undefined);
            closeSnackbar(messageSnackKey);
          },
        },
      });
    }
  }, [error]);

  const handleFinish = () => {
    const errors = [];

    if (isShowOtherField) {
      if (!userDescribe) {
        errors.push('Please answer each question.');
      } else if (userDescribe.length < 3) {
        errors.push('Minimum length: 3 characters.');
      }
    } else if (isShowMarketerSubCategory || isShowVideoSubCategory) {
      if (!selectedSubCategory) {
        errors.push('Please answer each question.');
      }
    }

    if (errors.length > 0) {
      setError(errors[0]);
    } else {
      onFinish();
    }
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Typography className="onboard-page__label">Which best describes who you are?</Typography>
        <Typography className="onboard-page__sub-label">We will use this to personalize your experience</Typography>
      </Box>
      <Grid container>
        {CATEGORIES.map((category) => {
          const isSeleceted = selectedCategory === category.title;
          return (
            <CategoryCard
              category={category}
              isSeleceted={isSeleceted}
              onCardClick={() => onChooseCategory(category)}
            />
          );
        })}
      </Grid>
      {(isShowVideoSubCategory || isShowMarketerSubCategory) && (
        <>
          <Typography sx={labelStyle}>Which type of {selectedCategory}?</Typography>
          {isShowVideoSubCategory && (
            <Grid container>
              {VIDEO_SUB_CATEGORIES.map((category) => {
                const isSeleceted = selectedSubCategory === category.title;
                return (
                  <CategoryCard
                    category={category}
                    isSeleceted={isSeleceted}
                    onCardClick={() => onChooseSubCategory(category)}
                  />
                );
              })}
            </Grid>
          )}
          {isShowMarketerSubCategory && (
            <Grid container>
              {MARKETER_SUB_CATEGORIES.map((category) => {
                const isSeleceted = selectedSubCategory === category.title;
                return (
                  <CategoryCard
                    category={category}
                    isSeleceted={isSeleceted}
                    onCardClick={() => onChooseSubCategory(category)}
                  />
                );
              })}
            </Grid>
          )}
        </>
      )}
      {isShowOtherField && (
        <FormInput
          labelStyle={labelStyle}
          onChange={onChangeUserDescribe}
          label="Describe who you are"
          name="other"
          placeholder="Your answers will help us improve your experience."
          type="textarea"
        />
      )}
      <Stack direction="row" justifyContent="space-between">
        <OnboardButton title="Back" color="grey" onClick={onMoveBack} />
        {selectedCategory && <OnboardButton title="Finish" onClick={handleFinish} />}
      </Stack>
    </Stack>
  );
}

export default OnboardUserDescribe;
