import { Box } from '@mui/material';
import React, { useRef } from 'react';
import VideoEditStrategy from './VideoEditStrategy';
import AIVideoEditGroup from './AIVideoEditGroup';
import { projectsState } from '../../models/ProjectsState';
import { observer } from 'mobx-react';
import { PromptResults } from '../../models/VideoEditItem';
import { handleUpdateProjectSettings } from '../../controllers/projectsCRUD/ProjectPageHandler';
import { handleChangeMedia } from '../../controllers/verticalVideoEditorCRUD/VerticalVideoEditorHandler';
import { EditStrategies } from '../../models/IngestState';

type Props = {
  strategy: string;
  mediaId: number;
  showCheckIcon?: boolean;
};

const AIVideoEditStrategy = observer(({ strategy, mediaId, showCheckIcon }: Props) => {
  const { medias } = projectsState;

  const deboundSaveRef = useRef<any>();

  if (medias[mediaId].strategyOutputs[strategy]) {
    const videoEdits = Object.values(medias[mediaId].strategyOutputs[strategy]);
    if (strategy !== EditStrategies.STORIES) {
      videoEdits.reverse();
    }
    return (
      <Box onClick={() => handleChangeMedia(mediaId, null)}>
        <VideoEditStrategy strategy={strategy} status={medias[mediaId].storyGPTStatus[strategy]} />
        {medias[mediaId].storyGPTStatus &&
          medias[mediaId].storyGPTStatus[strategy] !== false &&
          videoEdits.map((promptResults: PromptResults, index: number) => {
            if (medias[mediaId].storyGPTStatus[strategy] !== false && promptResults.videoEditItems?.length !== 0) {
              return (
                <Box key={index}>
                  <AIVideoEditGroup
                    prompt={promptResults.prompt}
                    strategy={strategy}
                    videoEditItems={promptResults.videoEditItems}
                    mediaId={mediaId}
                    onUpdateSettings={(settings) =>
                      handleUpdateProjectSettings(settings.type, settings.settings, deboundSaveRef)
                    }
                    isLoading={
                      medias[mediaId].storyGPTStatus[strategy] === null && promptResults.videoEditItems === null
                    }
                    showCheckIcon={showCheckIcon}
                  />
                </Box>
              );
            }
            return <></>;
          })}
      </Box>
    );
  }
});

export default AIVideoEditStrategy;
