import React from 'react';
import ExportIcon from '../icons/ExportIcon';
import MenuActionButton from './MenuActionButton';

interface ExportButtonProps {
  onClick: () => void;
  showIcon: boolean;
  style?: React.CSSProperties;
}

const ExportButton = (props: ExportButtonProps) => {
  return (
    <MenuActionButton
      onClick={props.onClick}
      text="Export"
      icon={props.showIcon && <ExportIcon color="#fff" />}
      style={props.style}
    />
  );
};

export default ExportButton;
