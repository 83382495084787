import { chatbotState } from '../../models/ChatbotState';
import { PlayerInterval } from '../../models/PlayerInterval';
import { checkJumpNextSegment } from './VideoPlayerHandler';

export const getPlayerOptions = (url, aspectRatio) => ({
  autoplay: false,
  controls: true,
  responsive: false,
  aspectRatio: aspectRatio,
  sources: [
    {
      src: url,
      type: 'application/x-mpegURL',
    },
  ],
  techOrder: ['youtube', 'html5'],
  playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
  canplaythrough: 5,
  controlBar: {
    fullscreenToggle: false,
    pictureInPictureToggle: false,
  },
});

export const getPlayerDimension = (aspecRatio: string) => {
  const [widthRatio, heightRatio] = aspecRatio.split(':').map((item) => Number(item));
  let width, height;
  if (widthRatio / heightRatio > 1) {
    width = 650;
  } else {
    height = 650;
  }

  if (!width) width = (height * widthRatio) / heightRatio;
  if (!height) height = (width * heightRatio) / widthRatio;
  return { width: width + 'px', height: height + 'px' };
};

export const getTotalDurationOfPlayerIntervals = (playerIntervals: PlayerInterval[]) => {
  return playerIntervals.reduce((acc, playerInterval) => {
    acc += (playerInterval.endTimeMs - playerInterval.startTimeMs) / 1000;
    return acc;
  }, 0);
};

export const handleTimeUpdateMessageVideo = (player, messageId, chatId, currentIntervalIndex, setNewIndex) => {
  const chatProject = chatbotState.chatProjects[chatId];
  if (!chatProject) {
    return;
  }
  const playerIntervals = chatProject.messagePlayers[messageId].playerIntervals;
  if (!playerIntervals) {
    return;
  }
  const currentInterval = playerIntervals[currentIntervalIndex];
  const currentPlayerTime = player.currentTime();

  if (currentIntervalIndex === 0 && currentPlayerTime < currentInterval.startTimeMs / 1000) {
    player.currentTime(currentInterval.startTimeMs / 1000);
  }

  const canJumpNextSegment = checkJumpNextSegment(currentPlayerTime, currentInterval);
  if (canJumpNextSegment) {
    const newIdx = currentIntervalIndex + 1;
    if (newIdx >= playerIntervals.length) {
      setNewIndex(0);
      player.currentTime(playerIntervals[0].startTimeMs / 1000);
      player.pause();
      return;
    }
    setNewIndex(newIdx);
    const nextInterval = playerIntervals[newIdx];
    if (currentPlayerTime < nextInterval.startTimeMs / 1000) {
      const deltaTime = nextInterval.startTimeMs / 1000 - currentPlayerTime;
      // check condition for setting player time when jumping to a new segment (if 2 segments are consecutive but they are the one sentence)
      if (deltaTime > 1.5) {
        player.currentTime(nextInterval.startTimeMs / 1000);
      }
    }
    if (currentPlayerTime > nextInterval.endTimeMs / 1000) {
      // check condition for setting player time when jumping to a new segment (if 2 segments are consecutive but they are the one sentence)
      const deltaTime = currentPlayerTime - nextInterval.startTimeMs / 1000;
      if (deltaTime > 1.5) {
        player.currentTime(nextInterval.startTimeMs / 1000);
      }
    }
  }
};
