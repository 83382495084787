import React, { useCallback, useEffect, useState } from 'react';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { projectsState } from '../../models/ProjectsState';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';

type Props = {
  playerContainerRef: React.MutableRefObject<any>;
  isReady: boolean;
  corner: string;
};

const LogoOverlay = observer(({ playerContainerRef, isReady, corner }: Props) => {
  const { logo } = videoPlayerState;
  const { activeProject } = projectsState;

  const [dimensions, setDimensions] = useState({ width: 0, height: 0, ratio: 0 });

  useEffect(() => {
    if (activeProject && isReady && logo !== '') {
      const playerControlBarElement = document.querySelector('.vjs-control-bar');
      if (playerControlBarElement) {
        playerControlBarElement.setAttribute('style', 'z-index: 2');
        const playerElement: any = playerContainerRef.current;
        const { width: originalWidth } = activeProject;
        const scalingRatio = playerElement.offsetWidth / originalWidth;
        const img = new Image();
        img.src = logo;
        img.onload = () => {
          setDimensions({ width: img.width * scalingRatio, height: img.height * scalingRatio, ratio: scalingRatio });
        };
      }
    }
  }, [activeProject, isReady, logo]);

  const renderLogoStyle = useCallback(() => {
    let logoCorner = {};
    switch (corner) {
      case 'Top - Right':
        logoCorner = {
          right: 0,
        };
        break;
      case 'Bottom - Right':
        logoCorner = {
          right: 0,
          bottom: 0,
        };
        break;
      case 'Bottom - Left':
        logoCorner = {
          left: 0,
          bottom: 0,
        };
        break;
      default:
        logoCorner = {
          top: 0,
        };
        break;
    }

    return {
      position: 'absolute',
      zIndex: 1,
      width: `calc(200px * ${dimensions.ratio})`,
      margin: `calc(10px * ${dimensions.ratio})`,
      ...logoCorner,
    } as React.CSSProperties;
  }, [corner, dimensions]);

  return (
    <Box>{dimensions.width !== 0 && logo !== '' && <img alt="logo-ovelay" src={logo} style={renderLogoStyle()} />}</Box>
  );
});

export default LogoOverlay;
