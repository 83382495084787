import { mediaState } from '../../models/MediaState';
import { segmentsState } from '../../models/SegmentsState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { projectsState } from '../../models/ProjectsState';

export function handleSelectedVideoEditChange(videoEditSelected) {
  const { segments } = segmentsState;
  const { setPlayerIntervals, setCurrentIntervalIdx, playerIntervals: currentIntervals } = videoPlayerState;
  let mediaUrl = '';
  let selectedMediaId = 0;
  let playerIntervals;
  let isChangeOnSameMedia = true;
  if (videoEditSelected && videoEditSelected.strategy !== null) {
    if (currentIntervals.length > 0) {
      isChangeOnSameMedia = videoEditSelected.mediaId === Number(currentIntervals[0].mediaId);
    }
    playerIntervals = videoEditSelected.segmentIds.map((segmentId) => {
      const segment = segments.find((item) => item.id === segmentId);
      return {
        startTimeMs: segment.start * 1000,
        endTimeMs: segment.end * 1000,
        mediaId: String(segment.media.id),
        segmentId: segment.id,
        color: segment.color,
        words: segment.words,
      };
    });
    setPlayerIntervals(playerIntervals);
    // set video url of first segment
    const firstSegment = playerIntervals[0];
    if (firstSegment) {
      mediaUrl = mediaState.mediaIdToUrl[firstSegment.mediaId];
      selectedMediaId = Number(firstSegment.mediaId);
    }
  } else {
    mediaUrl = mediaState.mediaIdToUrl[0];
  }
  if (isChangeOnSameMedia) {
    setCurrentIntervalIdx(0, playerIntervals?.[0].startTimeMs / 1000, true);
  } else {
    setCurrentIntervalIdx(0);
    handleChangeMedia(selectedMediaId, null, mediaUrl);
  }
}

export function handleChangeMedia(mediaId: number, setSearchParams?: any, mediaUrl?: string) {
  const { mediaIdToUrl } = mediaState;
  const { setSelectedMediaId, selectedMediaId, setVideoEditSelected } = projectsState;
  const { setVideoUrl } = videoPlayerState;
  if (setSearchParams) {
    setVideoEditSelected({ strategy: null });
    setSearchParams({ mediaId: mediaId.toString() });
  }
  if (mediaId !== selectedMediaId) {
    videoPlayerState.clearSubtitleContent();
    setSelectedMediaId(mediaId);
    if (!mediaUrl) {
      mediaUrl = mediaIdToUrl[mediaId];
    }
    setVideoUrl(mediaUrl);
  }
}
