import videojs from 'video.js';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { PLAYER_TYPE } from '../../views/players/VideoPlayer';

const toggleVisibleProgressBar = (visible: boolean) => {
  const visibility = visible ? 'visible' : 'hidden';
  const progressElement = document.querySelector('.vjs-progress-control');
  const timeElement = document.querySelector('.vjs-remaining-time');
  progressElement?.setAttribute('style', `visibility: ${visibility} !important`);
  timeElement?.setAttribute('style', `visibility: ${visibility} !important`);
};

export const createOrUpdatePlayer = (
  options,
  playerRef,
  videoRef,
  setVideoElementAttribute,
  className,
  requestTimeUpdateRef,
  onTimeUpdate,
  lastTimeRef,
  onDispose,
  onPlay,
  onPause,
  setPlayer,
  onLoadedMetaData,
  onEnded,
) => {
  if (!options) {
    return;
  }
  const isInValidSources = options.sources?.find((source) => !source.src);
  if (isInValidSources) {
    return;
  }

  function setVideoElementSize(videoElement) {
    const rect = videoElement.getBoundingClientRect();
    setVideoElementAttribute({
      width: videoElement.offsetWidth,
      height: videoElement.offsetHeight,
      top: rect.top,
      left: rect.left,
    });
  }

  let videoElement;
  if (!playerRef.current) {
    // Make sure Video.js player is only initialized once
    // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
    videoElement = document.createElement('video-js');
    videoElement.classList.add('vjs-big-play-centered');
    videoRef.current.appendChild(videoElement);
    const player = (playerRef.current = videojs(videoElement, options, () => {
      setVideoElementSize(videoElement);
      videojs.log('player is ready: ', className);
      handlePlayerReady(
        player,
        playerRef,
        className,
        requestTimeUpdateRef,
        onTimeUpdate,
        lastTimeRef,
        onDispose,
        onPlay,
        onPause,
        setPlayer,
        onLoadedMetaData,
        onEnded,
      );

      if (!options.doubleClick) {
        player.tech_?.off('dblclick');
      }

      toggleVisibleProgressBar(options.controlBar?.showProgressBar);
    }));
  } else {
    // You could update an existing player in the `else` block here
    // on prop change, for example:
    const player = playerRef.current;
    player.autoplay(options.autoplay);
    player.src(options.sources);
    player.aspectRatio(options.aspectRatio);
    if (!options.doubleClick) {
      player.tech_?.off('dblclick');
    }
    videoElement = document.querySelector('video-js');
    setVideoElementSize(videoElement);
    toggleVisibleProgressBar(options.controlBar?.showProgressBar);
    onTimeUpdate();
  }
};

const autoIncreasePlaybackSpeed = (player) => {
  player.playbackRate(1 + 0.04); // 4% as requirement

  // Wait 100 milliseconds before updating the text content
  setTimeout(() => {
    document.querySelector('.vjs-playback-rate-value')['textContent'] = '1x';
  }, 100);
};

const requestTimeUpdate = (player, previousPlayerTime, lastTime, requestTimeUpdateRef, lastTimeRef, onTimeUpdate) => {
  if (!requestTimeUpdateRef.current) return;
  const DELAY_TIME = 30; // 30 miliseconds
  const currentTime = player.currentTime();
  const now = performance.now();
  // Throttle the function to execute at most every DELAY_TIME milliseconds
  if (now - lastTimeRef.current >= DELAY_TIME && currentTime !== previousPlayerTime) {
    onTimeUpdate(currentTime);
    lastTimeRef.current = now;
  }

  // Request the next animation frame
  requestAnimationFrame(() =>
    requestTimeUpdate(player, currentTime, lastTime, requestTimeUpdateRef, lastTimeRef, onTimeUpdate),
  );
};

const handlePlayerReady = (
  player,
  playerRef,
  className,
  requestTimeUpdateRef,
  onTimeUpdate,
  lastTimeRef,
  onDispose,
  onPlay,
  onPause,
  setPlayer,
  onLoadedMetaData,
  onEnded,
) => {
  playerRef.current = player;
  autoIncreasePlaybackSpeed(player);
  const lastTime = 0;

  /**
   * By default the 'timeupdate' event is triggered at regular intervals while the video is playing, typically every 250 - 276 (milliseconds) (0.25 - 0,276 seconds)
   * (0.25 - 0,276 seconds) value isn't suitable for videos with fast-paced narration, the spacing between words can be set between 50 - 100 milliseconds (0,05 - 0,01 seconds)
   * This leads to the player sending callback with a delay, causing it to read words outside the segment range
   * So we need to create another function with similar functionality but with a lower delay. However, we only need to apply it to the A-Roll; B-roll can utilize VideoJS's built-in function
   * Note that: we shoudn't using setIntervals, it is not good for low duration of interval. One way to achieve smoother performance is to throttle the event to use "requestAnimationFrame" for more
   *    efficient updates
   */
  if (className !== PLAYER_TYPE.A_ROLL) {
    player.on('timeupdate', () => {
      onTimeUpdate();
    });
  }

  player.on('waiting', () => {
    videojs.log('player is waiting: ', className);
  });

  player.on('dispose', () => {
    videojs.log('player will dispose: ', className);
    requestTimeUpdateRef.current = false; // Stop interval update time event when the player is disposed
    lastTimeRef.current = 0;
    onDispose();
  });

  player.on('play', () => {
    onPlay();
  });

  player.on('pause', () => {
    onPause();
  });

  player.on('loadedmetadata', () => {
    setPlayer(player);
    onLoadedMetaData();
    if (className === PLAYER_TYPE.A_ROLL) {
      requestTimeUpdateRef.current = true;
      const currentTime = videoPlayerState.player?.currentTime();
      requestAnimationFrame(() =>
        requestTimeUpdate(player, currentTime, lastTime, requestTimeUpdateRef, lastTimeRef, onTimeUpdate),
      );
    }
  });

  player.on('ratechange', () => {
    if (document.querySelector('.vjs-tech')['playbackRate'] === 1) {
      autoIncreasePlaybackSpeed(player);
    }
  });

  player.on('ended', (data) => {
    onEnded(data);
  });
};
