import React from 'react';

type Props = {
  style?: React.CSSProperties;
};

function EditsIcon({ style }: Props) {
  return <img src={require('../../../assets/icons/edits.png')} alt="edits" style={style} />;
}

export default EditsIcon;
