import { closeSnackbar, enqueueSnackbar } from "notistack";
import { serverAddress } from "../../config";
import { postOptions } from "../utils/FetchUtils";

export async function requestFeature(feature: string, email?: string) {
  const url = `${serverAddress}/api/feature-request`;
  let result = await fetch(url, postOptions(url, "token", {feature, email }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
    });
  return result;
}

export async function handleSendFeatureRequest(feature: string, email: string) {
  try {
    if (!feature || feature.trim().length === 0) {
      const invalidMessageSnackKey =enqueueSnackbar(
        "Cannot be blank!",
        {
          variant: 'warning',
          SnackbarProps: {
            onClick: () => closeSnackbar(invalidMessageSnackKey)
          }
        }
      );
      return false;
    }
    
    await requestFeature(feature, email);
    
    const successMessageSnackKey = enqueueSnackbar(
      "Thank you for your feedback.",
      {
        variant: 'success',
        SnackbarProps: {
          onClick: () => closeSnackbar(successMessageSnackKey)
        }
      }
    );
    
    return true;
  } catch (error) {
    const errorMessageSnackKey = enqueueSnackbar(
      "An error occurred. Please try again.",
      {
        variant: 'error',
        SnackbarProps: {
          onClick: () => closeSnackbar(errorMessageSnackKey)
        }
      }
    );
    return false;
  }
}
