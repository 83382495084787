import React, { useEffect } from 'react';
import ProjectItemCard from './ProjectItemCard';
import { Grid } from '@mui/material';
import { projectsState } from '../../models/ProjectsState';
import { observer } from 'mobx-react';
import { pollProjectStatusUpdates } from '../../controllers/serverClients/ProjectClient';
import { ProjectStatus } from '../../models/ProjectItem';

const ProjectsTable = observer(() => {
  const projectItems = projectsState?.projectItems;
  const processingProjects = projectItems?.filter((project) => project.displayStatus === ProjectStatus.PROCESSING);

  useEffect(() => {
    const intervalId = pollProjectStatusUpdates();
    return () => clearInterval(intervalId);
  }, [processingProjects]);

  return (
    <>
      {projectItems &&
        projectItems.map((projectItem, idx) => (
          <Grid item xs={4} key={idx} padding={'10px'}>
            <ProjectItemCard projectItem={projectItem} />
          </Grid>
        ))}
    </>
  );
});

export default ProjectsTable;
