import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import MainLayout from './views/MainLayout';
import IntercomProviderWrapper from './views/intercom/IntercomProvider';
import ProjectsDashboardPage from './views/pages/ProjectsDashboardPage';
import ProjectPage from './views/pages/ProjectPage';
import Auth0ProviderWrapper from './views/auth/Auth0ProviderWrapper';
import OnboardingPage from './views/pages/OnboardingPage';
import FeatureRequestPage from './views/pages/FeatureRequestPage';
import PublicLayout from './views/PublicLayout';
import { isMobile } from 'react-device-detect';
import MobileProjectPage from './views/pages/MobileProjectPage';
import { AI_EDIT_PATH, CHATBOT_SHARE_PATH, FEATURE_REQUEST_PATH, ADMIN_PATH } from './config/constants';
import { PROJECTS_PATH } from './config/constants';
import { ONBOARD_PATH } from './config/constants';
import { SHARE_PATH } from './config/constants';
import { CHATBOT_PATH } from './config/constants';
import ChatbotAuthLayout from './views/ChatbotAuthLayout';
import ChatbotPage from './views/pages/ChatbotPage';
import AccountPage from './views/pages/AdminPage';

const AppRouter = createBrowserRouter([
  {
    path: SHARE_PATH,
    element: <Auth0ProviderWrapper component={<IntercomProviderWrapper autoBoot children={<PublicLayout />} />} />,
    children: [
      {
        index: true,
        path: `${SHARE_PATH}/:projectId`,
        element: !isMobile ? <ProjectPage /> : <MobileProjectPage />,
      },
    ],
  },
  {
    path: CHATBOT_PATH,
    element: (
      <Auth0ProviderWrapper component={<IntercomProviderWrapper autoBoot={true} children={<ChatbotAuthLayout />} />} />
    ),
    children: [
      {
        index: true,
        path: CHATBOT_PATH,
        element: <ChatbotPage />,
      },
      {
        path: `${CHATBOT_PATH}/:chatId`,
        element: <ChatbotPage />,
      },
    ],
  },
  {
    path: CHATBOT_SHARE_PATH,
    element: (
      <Auth0ProviderWrapper component={<IntercomProviderWrapper autoBoot={false} children={<PublicLayout />} />} />
    ),
    children: [
      {
        index: true,
        path: `${CHATBOT_SHARE_PATH}/:chatId`,
        element: <ChatbotPage isReadOnly />,
      },
    ],
  },
  {
    path: '/',
    element: <Auth0ProviderWrapper component={<IntercomProviderWrapper autoBoot children={<MainLayout />} />} />,
    children: [
      {
        path: PROJECTS_PATH,
        element: <ProjectsDashboardPage />,
      },
      {
        path: AI_EDIT_PATH,
        element: !isMobile ? <ProjectPage /> : <MobileProjectPage />,
      },
      {
        path: `${AI_EDIT_PATH}/:projectId`,
        element: !isMobile ? <ProjectPage /> : <MobileProjectPage />,
      },
      {
        path: ONBOARD_PATH,
        element: <OnboardingPage />,
      },
      {
        path: FEATURE_REQUEST_PATH,
        element: <FeatureRequestPage />,
      },
      {
        path: ADMIN_PATH,
        element: <AccountPage />,
      },
    ],
  },
]);

export default AppRouter;
