import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FormCustomFileInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  return <VisuallyHiddenInput ref={ref} {...props} />;
});

export default FormCustomFileInput;
