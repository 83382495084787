import { projectsState } from '../../models/ProjectsState';
import { segmentsState } from '../../models/SegmentsState';
import Segment from '../../models/Segment';
import { WORD_CUSTOM_NODE_NAME } from '../../views/transcript/CustomNode/WordCustomNode';
import { brollsState } from '../../models/BrollState';
import { createEditorContent } from '../utils/TranscriptUtils';
import { updateBrolls } from '../serverClients/ProjectClient';
import { getYoutubeID } from '../utils/YoutubeUtils';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { saveSegments } from './VideoEditSegmentsHandler';

export function handleUpdateEditorContent(
  editor,
  cursor,
  previousContentRef,
  onSaveSegments,
  needSaveSegmentData?: boolean,
  trackingNoRenderState?: any,
) {
  const segmentList = getSegmentListOfVideoEditSelected();
  const editorContent = createEditorContent(segmentList, brollsState.brolls, projectsState.getVideoEditSelectedIndex());
  editor.commands.setContent(editorContent);
  editor.chain().focus().setTextSelection(cursor).run();
  if (needSaveSegmentData) {
    const segmentIds = preSaveUpdatedSegment(editor);
    onSaveSegments(segmentIds, null)
      .then(() => {
        previousContentRef.current = JSON.parse(JSON.stringify(editorContent));
        trackingNoRenderState.previousSegments = JSON.parse(JSON.stringify(segmentsState.segments));
        trackingNoRenderState.previousBrolls = JSON.parse(JSON.stringify(brollsState.brolls));
      })
      .catch(() => {
        brollsState.setBrolls(trackingNoRenderState.previousBrolls);
        segmentsState.setSegments(trackingNoRenderState.previousSegments);
        projectsState.setVideoEditSelected({
          ...projectsState.videoEditSelected,
          segmentIds: trackingNoRenderState.previousSelectedSegmentIds,
        });
        editor.commands.setContent(previousContentRef.current);
      });
  } else {
    previousContentRef.current = editorContent;
  }
}

export function preSaveUpdatedSegment(editor) {
  const editorJsonData = editor.getJSON();
  let editorJsonDataFormat = removeExtendWordOfEditorJSON(editorJsonData);
  editorJsonDataFormat = removeNonPrintCharacterOfWord(editorJsonDataFormat);
  const updatedSegment = trackWordsUpdate(editorJsonDataFormat);
  return updatedSegment.map((segment) => segment.id);
}

export async function handleRemoveLink(brollGroupId: string) {
  const targetBRolls = brollsState.brolls.filter((bRoll) => bRoll.brollGroupId === brollGroupId);
  // if bRoll is link by end card segment then remove end card segment
  const segmentList = getSegmentListOfVideoEditSelected();
  const endCardSegment = segmentList.find(
    (segment) => targetBRolls.find((bRoll) => bRoll.segmentId === segment.id) && segment.end_card,
  );
  if (endCardSegment) {
    // play previous segment
    videoPlayerState.setCurrentIntervalIdx(videoPlayerState.playerIntervals.length - 2);
    segmentsState.setSegments(segmentsState.segments.filter((segment) => segment.id !== endCardSegment.id));
    // update segmentIds in videoEdit
    projectsState.videoEditSelected.segmentIds = projectsState.videoEditSelected.segmentIds.filter(
      (segmentId) => segmentId !== endCardSegment.id,
    );
    await saveSegments(projectsState.videoEditSelected.segmentIds);
  }
  const brolls = brollsState.deleteBrollsByGroupId(brollGroupId);
  updateBrolls(projectsState.activeProjectId, brolls, null);
}

export function handleUpdateLink(brollGroupId: string, url: string, start: number) {
  const brolls = brollsState.updateBrollsUrlByGroupId(brollGroupId, url, start, getYoutubeID(url));
  updateBrolls(projectsState.activeProjectId, brolls, null);
}

export function handleUpdateColor(brollGroupId: string, color: string) {
  const brolls = brollsState.updateBrollsColorByGroupId(brollGroupId, color);
  updateBrolls(projectsState.activeProjectId, brolls, null);
}

function trackWordsUpdate(editorJsonData) {
  const updatedSegments = [];
  editorJsonData.content.forEach((editorNodeData) => {
    if (validateSegmentNodeContent(editorNodeData)) {
      updatedSegments.push(trackWordsUpdateOfSegment(editorNodeData));
    }
  });
  projectsState.setSegmentIdsOfVideoEditSelected(updatedSegments.map((item) => item.id));
  return getSegmentListOfVideoEditSelected();
}

function validateSegmentNodeContent(segmentNode) {
  if (!segmentNode.content || segmentNode.content.length === 0) return false;
  if (
    segmentNode.content.length === 1 &&
    (!segmentNode.content[0].content || segmentNode.content[0].content[0].text.trim().length <= 1)
  ) {
    // validate empty segment
    return false;
  } else {
    return true;
  }
}

function trackWordsUpdateOfSegment(segmentNodeJsonData) {
  const segment = segmentsState.segments.find((item) => item.id === segmentNodeJsonData.attrs.segmentId);
  const wordsFormatted = [],
    highlightMarksOfSegment = [];
  segmentNodeJsonData.content.forEach((editorNodeData) => {
    const { startTime, endTime, wordIndex } = editorNodeData.attrs;
    let text = editorNodeData.content.reduce((acc, content) => {
      if (content.type === 'hardBreak') {
        return (acc += '<br>');
      }
      return (acc += content.text);
    }, '');
    text = text.replace(/\s+/g, ' ').trim() + ' '; // remove duplicate space add space for latest word
    if (text.trim() === '') return; // skip empty word

    wordsFormatted.push({ text: text, startTime, endTime });
    const marksOfNode = editorNodeData.content
      .map((i) => i.marks)
      .filter((i) => i)
      .flat(1);
    const highlightLink = marksOfNode.filter((i: any) => i.type === 'link')![0];
    const highlightColor = marksOfNode.filter((i: any) => i.type === 'underline')![0];
    if (highlightLink && highlightColor) {
      const highlightMark = { ...highlightLink, wordIndex: wordIndex };
      highlightMark.attrs.color = highlightColor.attrs.color;
      highlightMark.attrs.brollGroupId = highlightColor.attrs.brollGroupId;
      highlightMarksOfSegment.push(highlightMark);
    }
  });

  const newTextOfSegment = wordsFormatted.reduce((acc, word) => (acc += word.text), '');
  segmentsState.setSegment({ ...segment, text: newTextOfSegment, words: wordsFormatted });
  return segment;
}

function getSegmentListOfVideoEditSelected(): Segment[] {
  return projectsState.videoEditSelected.segmentIds.map((segmentId) =>
    segmentsState.segments.find((segment) => segment.id === segmentId),
  );
}

function removeExtendWordOfEditorJSON(editorJson) {
  editorJson.content = editorJson.content.map((segmentNode) => {
    segmentNode.content = segmentNode.content.filter((wordNode) => wordNode.type === WORD_CUSTOM_NODE_NAME);
    return segmentNode;
  });
  return editorJson;
}

function removeNonPrintCharacterOfWord(editorJson) {
  editorJson.content.forEach((segmentNode) => {
    segmentNode.content.forEach((wordNode) => {
      if (wordNode.type === WORD_CUSTOM_NODE_NAME) {
        wordNode.content[0].text = wordNode.content[0].text.replace(/\u200B/g, '');
      }
    });
  });
  return editorJson;
}

export { trackWordsUpdate, getSegmentListOfVideoEditSelected, removeExtendWordOfEditorJSON };
