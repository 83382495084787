import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

// required by reactstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';
//core
import 'primereact/resources/primereact.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { REACT_APP_SENTRY_PROJECT_ID, REACT_APP_SENTRY_KEY, serverAddress } from './config';

function verifyMobXConfig() {
  if (
    !new (class {
      x;
    })().hasOwnProperty('x')
  ) {
    throw new Error('Transpiler is not configured correctly');
  }
}

verifyMobXConfig();

if (window.location.hostname === 'devs.storylines.run') {
  Sentry.init({
    dsn: `https://${REACT_APP_SENTRY_KEY}.ingest.us.sentry.io/${REACT_APP_SENTRY_PROJECT_ID}`,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [new RegExp(`${serverAddress}`)],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
