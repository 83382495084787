import { observer } from 'mobx-react';
import { FormControlLabel, Checkbox, FormGroup, Stack } from '@mui/material';
import React, { useState } from 'react';
import { accountState } from '../../models/AccountState';
import { FormHandlers } from './FormHandlers';
import FormInput from './FormInput';
import FormRadioGroup from './FormRadioGroup';
import { EditStrategies, FormTypes, IngestFormOptions, ingestState } from '../../models/IngestState';
import SubmitButton from './SubmitButton';
import { projectsState } from '../../models/ProjectsState';
import { MAX_STORY_ABOUT_LEN, MIN_STORY_ABOUT_LEN } from '../../config';
import { chatbotState } from '../../models/ChatbotState';
import { v4 as uuidV4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  isInputExist: boolean;
  rePrompt?: boolean;
  videoSetting?: string;
  isChatBot?: boolean;
};

const labelStyle = {
  color: 'white',
  borderWidth: '1px 0 0 0',
  borderColor: '#272E49',
  display: 'block',
  fontSize: 20,
  marginTop: 20,
};

const NON_INPUT_FILE_OPTIONS = [IngestFormOptions.PROJECT_ID_OPTION];

function getCurrentStrategy(rePrompt) {
  if (!rePrompt) return '';
  const strategyKey = projectsState.activeProject?.originalData?.editStrategy.toUpperCase();
  return IngestFormOptions.hasOwnProperty(strategyKey) ? IngestFormOptions[strategyKey] : '';
}

function getAdminSettingOptions(rePrompt) {
  if (rePrompt) {
    return [IngestFormOptions.HIGHLIGHTS, IngestFormOptions.SOUNDBITES, IngestFormOptions.STORIES];
  } else {
    return Object.values(IngestFormOptions);
  }
}

const IngestInfoStep = observer(({ isInputExist, rePrompt = false, isChatBot }: Props) => {
  const { handleSubmit, handleSubmitRePrompt, submitState } = FormHandlers();
  const { setFormData, formData } = ingestState;
  const isAdmin = (accountState.currentAccount && accountState.currentAccount?.isAdmin) || false;

  const isShowProjectIdInput = formData.formType === FormTypes.SHARE_PROJECT_FORM;
  const [selectedSettingOption, setSelectedSettingOption] = useState<string>(
    getCurrentStrategy(rePrompt) || IngestFormOptions.HIGHLIGHTS,
  );
  const ADMIN_SETTING_OPTIONS = getAdminSettingOptions(rePrompt);
  const { user } = useAuth0();

  function mapIngestOptionToStrategy(value) {
    let formType = '';
    let editStrategy = '';
    switch (value) {
      case IngestFormOptions.SOUNDBITES:
        formType = FormTypes.SOUNDBITES_FORM;
        editStrategy = EditStrategies.SOUNDBITES;
        break;
      case IngestFormOptions.PROJECT_ID_OPTION:
        formType = FormTypes.SHARE_PROJECT_FORM;
        editStrategy = '';
        break;
      case IngestFormOptions.POLISHED_VIDEO_OPTION:
        formType = FormTypes.HUMAN_CUT_FORM;
        editStrategy = EditStrategies.HUMAN_CUTS;
        break;
      case IngestFormOptions.STORIES:
        formType = FormTypes.STORIES_FORM;
        editStrategy = EditStrategies.STORIES;
        break;
      default:
        formType = FormTypes.SOCIAL_MEDIA_FORM;
        editStrategy = EditStrategies.HIGHLIGHTS;
        break;
    }
    return { formType, editStrategy };
  }

  const handleChooseOptions = (value) => {
    const { editStrategy, formType } = mapIngestOptionToStrategy(value);
    setSelectedSettingOption(value);
    setFormData({
      editStrategy: editStrategy,
      formType: formType,
    });
  };

  const handleChangeExcludeCaption = (event) => {
    setFormData({ excludeCaptions: event.target.checked });
  };

  const handleChangeAIGeneratedTitle = (event) => {
    setFormData({ aiGeneratedTitle: event.target.checked });
  };

  async function submitIngestInfo(event, isAdmin: boolean) {
    try {
      let { editStrategy } = mapIngestOptionToStrategy(selectedSettingOption);
      const { formType } = mapIngestOptionToStrategy(selectedSettingOption);
      if (!isAdmin && !rePrompt) {
        editStrategy = EditStrategies.DEFAULT;
      }
      if (isChatBot) {
        editStrategy = EditStrategies.HIGHLIGHTS;
      }
      if (isChatBot) {
        editStrategy = EditStrategies.STORIES;
      }
      const submitData = {
        ...formData,
        projectUUID: isChatBot ? chatbotState.selectedChatId : uuidV4(),
        email: user.email,
        editStrategy: editStrategy,
        formType: isChatBot ? FormTypes.CHATBOT_FORM : formType,
        excludeCaptions: isChatBot ? true : formData.excludeCaptions,
      };

      if (rePrompt) {
        await handleSubmitRePrompt(event, submitData);
      } else {
        await handleSubmit(event, submitData, !!isChatBot);
      }
    } catch (error) {
      console.log('submit Ingest fail', error);
    }
  }

  return (
    <>
      <FormInput
        labelStyle={labelStyle}
        label="What is your story about?"
        name="storyAbout"
        required
        minLength={MIN_STORY_ABOUT_LEN}
        maxLength={MAX_STORY_ABOUT_LEN}
        onChange={(value) => setFormData({ storyAbout: value })}
        type="textarea"
        multiple
      />

      {isAdmin && !isChatBot && (
        <FormRadioGroup
          labelStyle={labelStyle}
          label="Video output setting"
          name="editStrategy"
          required
          onChange={handleChooseOptions}
          options={!isInputExist ? NON_INPUT_FILE_OPTIONS : ADMIN_SETTING_OPTIONS}
          selected={selectedSettingOption}
        />
      )}

      {rePrompt && (
        <>
          <FormGroup>
            <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: formData.aiGeneratedTitle ? 'white' : '#079A7C',
                      },
                    }}
                    checked={formData.aiGeneratedTitle}
                    onChange={handleChangeAIGeneratedTitle}
                    name="aiGeneratedTitle"
                  />
                }
                label="AI generated title"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: formData.excludeCaptions ? 'white' : '#079A7C',
                      },
                    }}
                    checked={formData.excludeCaptions}
                    onChange={handleChangeExcludeCaption}
                    name="excludeCaptions"
                  />
                }
                label="Exclude captions"
              />
            </Stack>
          </FormGroup>
        </>
      )}

      {isShowProjectIdInput && (
        <FormInput
          labelStyle={labelStyle}
          label="Enter project ID"
          name="projectId"
          required
          minLength={36}
          maxLength={36}
          onChange={(value) => setFormData({ sharedProjectUUID: value })}
          placeholder="Enter project ID"
        />
      )}

      <SubmitButton submitState={submitState} onClick={(e) => submitIngestInfo(e, isAdmin)} />
    </>
  );
});

export default IngestInfoStep;
