const APP_CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV ? process.env.REACT_APP_CONFIG_ENV + '.' : '';
export const serverAddress =
  process.env.REACT_APP_USE_PROXY_SERVER === 'true' ? '' : `https://${APP_CONFIG_ENV}storylines.run`;
export const REACT_APP_GOOGLE_DRIVE_CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
export const MAX_POLLING_TIME_OUT = 7200 * 1000; // 2 hours
export const TRANSCRIPT_TIME_TOLERANCE = 2;
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_USE_PROXY_SERVER ? 'devs' : process.env.REACT_APP_CONFIG_ENV;
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const REACT_APP_INTERCOM_API_BASE = 'https://api-iam.intercom.io';
export const REACT_APP_GOOGLE_ANALYTICS_TAG = process.env.REACT_APP_GOOGLE_ANALYTICS_TAG;
export const REACT_APP_INTERCOM_SURVEY_ID = process.env.REACT_APP_INTERCOM_SURVEY_ID;
export const REACT_APP_SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY;
export const REACT_APP_SENTRY_PROJECT_ID = process.env.REACT_APP_SENTRY_PROJECT_ID;
export const REACT_APP_TYPE_FORM_URL = process.env.REACT_APP_TYPE_FORM_URL;
export const REACT_APP_DROPBOX_APP_KEY = process.env.REACT_APP_DROPBOX_APP_KEY;

console.log('client-configuration:');
console.log(serverAddress);

export const ACCESS_TOKEN = 'token';

export const MAX_STORY_ABOUT_LEN = 1000;
export const MIN_STORY_ABOUT_LEN = 20;
