import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { Typography } from '@mui/material';
import './ProfileCard.css';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '../ui_controls/icons/LogoutIcon';

const ProfileCard = () => {
  const { user, logout } = useAuth0();

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="profile-card">
      <Avatar src={user.picture} alt={user.name} className="avatar" />
      <div className="profile-info">
        <Typography fontSize={14} fontWeight={400} color={'#000A08'}>
          {user.name}
        </Typography>
      </div>
      <IconButton className="settings-button" onClick={handleLogout}>
        <LogoutIcon style={{ width: '32px', height: '32px' }} />
      </IconButton>
    </div>
  );
};

export default ProfileCard;
