import { serverAddress } from '../../config';

type OnCompleteFn = ({ objectKey, uploadID }: { objectKey: string; uploadID: string }) => void;
type OnErrorFn = (error: unknown) => void;
type OnProgressFn = (input: { sent: number; total: number; percentage: number; timeRemaining: number }) => void;
type Part = { presignedUrl: string; partNumber: number };

const SIZE_OF_CHUNK = 1024 * 1024 * 10; // 10MB
const MAX_UPLOAD_CONNECTION = 5;

export function filterFileName(fileName) {
  // Replace any character that is not a-z, A-Z, 0-9, or the allowed URI characters
  const filtered = fileName.replace(/[^a-zA-Z0-9\-\_\.\~]/g, ' ');
  return filtered;
}

async function fetchAPI(url: string, accessToken: string, method: string, body?) {
  const baseOptions = {
    method,
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };

  if (body) {
    baseOptions['body'] = JSON.stringify(body);
  }

  const result = await fetch(url, baseOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((rs) => rs.json())
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return result;
}

export class Uploader {
  file: File;
  accessToken: string;
  multipartUpload: any;
  multiPresignedUrls: { presignedUrl: string; partNumber: number }[];
  uploadedParts: { PartNumber: number; ETag: string }[];
  private activeConnections: Record<number, XMLHttpRequest>;
  private progressCache: Record<number, number>;
  private uploadedSize: number;
  onProgressFn: OnProgressFn;
  onErrorFn: OnErrorFn;
  onCompleteFn: OnCompleteFn;

  constructor(file: File, accessToken: string, onProgress: OnProgressFn, onComplete: OnCompleteFn, onError: OnErrorFn) {
    this.file = file;
    this.accessToken = accessToken;
    this.uploadedParts = [];

    this.activeConnections = {};
    this.progressCache = {};
    this.uploadedSize = 0;

    this.onProgressFn = onProgress;
    this.onErrorFn = onError;
    this.onCompleteFn = onComplete;
  }

  async sendExceptionReport(uploadErr) {
    try {
      const url = `${serverAddress}/api/video-ingest/client-exception-report/post`;
      await fetchAPI(url, 'token', 'POST', {
        error: uploadErr?.message,
      });
      this.onErrorFn(uploadErr);
      return;
    } catch (err) {
      this.onErrorFn(err);
      return;
    }
  }

  async doUpload(videoID: string) {
    try {
      this.multipartUpload = await this.createMultipartUpload(videoID);
      const numberOfParts = Math.ceil(this.file.size / SIZE_OF_CHUNK);
      this.multiPresignedUrls = await this.getMultipartPresignUrls(this.multipartUpload, numberOfParts);
    } catch (err) {
      this.sendExceptionReport(err);
      return;
    }

    await this.sendNextChunkFile();
  }

  async sendNextChunkFile() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= MAX_UPLOAD_CONNECTION) {
      console.log('Reached max upload connection limit; sendNextChunkFile returning early');
      return;
    }

    if (!this.multiPresignedUrls.length) {
      if (!activeConnections) {
        this.close();
      } else {
        console.warn('Waiting for all connections to close; sendNextChunkFile returning early');
      }
      return;
    }

    const part: Part = this.multiPresignedUrls.pop();
    if (part) {
      const sentSize = (part.partNumber - 1) * SIZE_OF_CHUNK;
      const chunkFile = this.file.slice(sentSize, sentSize + SIZE_OF_CHUNK);

      this.sendChunkFile(chunkFile, part)
        .then(() => this.sendNextChunkFile())
        .catch(() => {
          this.multiPresignedUrls.push(part);
          console.log('Error: Retry sendNextChunkFile call');
        });
    } else {
      console.warn('Waiting for all chunk parts to finish');
      return;
    }

    this.sendNextChunkFile();
  }

  handleProgress(part: any, event: any) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      const uploadSpeed = 5000000; // 5 MB/s
      const remainingSize = total - sent;
      const remainingTimeInMinutes = remainingSize / (uploadSpeed * 60);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
        timeRemaining: Math.ceil(remainingTimeInMinutes),
      });
    }
  }

  async close() {
    try {
      await this.completeMultipartUpload(this.multipartUpload, this.uploadedParts);
      this.onCompleteFn({ objectKey: this.multipartUpload.Key, uploadID: this.multipartUpload.UploadId } as any);
    } catch (error) {
      this.sendExceptionReport(error);
    }
  }

  async completeMultipartUpload(multipartUpload, uploadedParts): Promise<any> {
    const url = `${serverAddress}/api/video-ingest/complete-s3-multipart-upload/post`;
    return await fetchAPI(url, this.accessToken, 'POST', {
      objectKey: multipartUpload.Key,
      uploadID: multipartUpload.UploadId,
      uploadedParts: uploadedParts,
    });
  }

  async createMultipartUpload(videoID): Promise<any> {
    const url = `${serverAddress}/api/video-ingest/create-s3-multipart-upload/get/${encodeURIComponent(videoID)}`;
    return await fetchAPI(url, this.accessToken, 'GET');
  }

  async getMultipartPresignUrls(
    multipartUpload,
    numberOfParts,
  ): Promise<{ presignedUrl: string; partNumber: number }[]> {
    const url = `${serverAddress}/api/video-ingest/create-s3-multiplart-upload-presigned-urls/post`;
    return await fetchAPI(url, this.accessToken, 'POST', {
      objectKey: multipartUpload.Key,
      uploadID: multipartUpload.UploadId,
      numberOfParts: numberOfParts,
    });
  }

  async sendChunkFile(file, part: Part) {
    return new Promise((resolve, reject) => {
      try {
        const xhr = (this.activeConnections[part.partNumber] = new XMLHttpRequest());
        const progressListener = this.handleProgress.bind(this, part.partNumber - 1);

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);
        xhr.open('PUT', part.presignedUrl);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status >= 200 && xhr.status < 400) {
              // retrieving the ETag parameter from the HTTP headers
              const ETag = xhr.getResponseHeader('ETag');

              if (ETag) {
                const uploadedPart = {
                  PartNumber: part.partNumber,
                  // removing the " enclosing carachters from
                  // the raw ETag
                  ETag: ETag.replace(/"/g, ''),
                };
                this.uploadedParts.push(uploadedPart);
                delete this.activeConnections[part.partNumber];
                resolve(xhr.status);
              }
            } else {
              this.handleHTTPFailure(xhr, file, part, reject);
            }
          }
        };
        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.partNumber];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.partNumber];
        };
        xhr.send(file);
        return;
      } catch (error) {
        console.log('XMLHttpRequest error: ', error);
        delete this.activeConnections[part.partNumber];
        return this.sendChunkFile(file, part);
      }
    });
  }

  async handleHTTPFailure(xhr, file, part, reject) {
    delete this.activeConnections[part.partNumber];

    console.log(`Error in this: partNumber: ${part.partNumber}`, xhr);
    if (xhr.status >= 500) {
      return this.sendChunkFile(file, part);
    }
    if (xhr.status >= 400) {
      this.activeConnections = {};
      this.sendExceptionReport(new Error(xhr.response));
      reject(new Error('Upload canceled by user'));
    }
  }
}
