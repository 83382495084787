export const getDefaultSettings = (activeProject, media, selectedMediaId) => {
  const isValid = activeProject && media && Object.values(media).length > 0;
  return isValid
    ? JSON.parse(
        JSON.stringify({
          medias: Object.values(media),
          mediaSelected: media[selectedMediaId],
          project: activeProject,
        }),
      )
    : null;
};
