import Broll from '../../models/Broll';
import { brollsState } from '../../models/BrollState';
import { projectsState } from '../../models/ProjectsState';
import Segment from '../../models/Segment';
import { segmentsState } from '../../models/SegmentsState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { PLAYER_TYPE } from '../../views/players/VideoPlayer';
import { videoEditorState } from '../../views/videoeditor/VideoEditorState';

const {
  timelineState,
  setDisablePlayCallbacks,
  setDisableSetTimeCallbacks,
  setEnablePauseCallbacks,
  setDisablePauseCallbacks,
  setEnablePlayCallbacks,
  setEnableSetTimeCallbacks,
} = videoEditorState;

export const handleTimeUpdate = (
  currentTimeSec,
  aRollPlayerBoxRef,
  bRollPlayerBoxRef,
  setBRollVideoJsOptions,
  videoRef,
) => {
  videoPlayerState.setCurrentTime(currentTimeSec);
  if (videoPlayerState.playerIntervals.length > 0) {
    const currInterval = videoPlayerState.playerIntervals[videoPlayerState.currentIntervalIdx];
    const currentSegment = getCurrentSegment();
    const canJumpNextSegment = checkJumpNextSegment(currentTimeSec, currInterval);
    if (canJumpNextSegment) {
      const newIdx = (videoPlayerState.currentIntervalIdx + 1) % videoPlayerState.playerIntervals.length;
      if (newIdx === 0) {
        handleMuteBRollUnmuteARoll();
      }
      videoPlayerState.setCurrentIntervalIdx(newIdx, videoPlayerState.playerIntervals[newIdx].startTimeMs / 1000, true);
    }
    if (currentSegment && brollsState.brolls.some((broll) => broll.segmentId === currentSegment.id)) {
      playWithBRoll(currentSegment, currentTimeSec, aRollPlayerBoxRef, bRollPlayerBoxRef, setBRollVideoJsOptions);
    } else {
      displayARollPlayer(aRollPlayerBoxRef, bRollPlayerBoxRef);
      const { videoEditSelected } = projectsState;
      if (videoEditSelected && videoEditSelected.segmentIds) {
        const lastSegmentId = videoEditSelected.segmentIds[videoEditSelected.segmentIds.length - 1];
        if (segmentsState.segments.find((segment) => segment.id === lastSegmentId)?.end_card) {
          preloadBRoll(setBRollVideoJsOptions);
        }
      }
    }
  }

  if (!timelineState) return;

  // remove timeline listener before setting the timeline's time
  setDisableSetTimeCallbacks('VideoPlayer');
  timelineState.setTime(videoRef.current.currentTime);
  setEnableSetTimeCallbacks('VideoPlayer');
};

const playWithBRoll = (
  currentSegment: Segment,
  currentPlayerTime,
  aRollPlayerBoxRef,
  bRollPlayerBoxRef,
  setBRollVideoJsOptions,
) => {
  let currentWordIndex = currentSegment.words.findLastIndex((word) => word.startTime <= currentPlayerTime);
  if (currentWordIndex < 0) {
    // try to detect if the player is playing at startTime of segment is lower than startTime of first word of segment
    currentWordIndex =
      currentSegment.start <= currentPlayerTime * 1000 && currentPlayerTime <= currentSegment.words[0].startTime
        ? 0
        : currentWordIndex;
  }

  const bRollClip = findBRollClip(currentSegment, currentWordIndex);
  if (!bRollClip) {
    preloadBRoll(setBRollVideoJsOptions);
    displayARollPlayer(aRollPlayerBoxRef, bRollPlayerBoxRef);
    return;
  }
  const currentBRollSource = videoPlayerState.bRollPlayer && videoPlayerState.bRollPlayer.currentSource().src;
  const brollUrl = bRollClip.candidates[0].url;
  const bRollStartTime = bRollClip.candidates[0].start;
  // set Caption overlay
  if (currentBRollSource !== brollUrl) {
    setBRollVideoJsOptions((prev) => {
      if (prev && prev.sources[0].src === brollUrl) {
        return prev;
      }
      return {
        muted: !isEndCardBroll(bRollClip),
        controls: true,
        responsive: true,
        aspectRatio: videoPlayerState.aspectRatio,
        sources: [{ type: 'video/youtube', src: brollUrl }],
        techOrder: ['youtube', 'html5'],
        startTime: bRollStartTime,
      };
    });
  }
  displayBrollPlayer(aRollPlayerBoxRef, bRollPlayerBoxRef, bRollClip);
  if (videoPlayerState.bRollPlayer) {
    if (bRollClip.wordIndexRange[0] === currentWordIndex && videoPlayerState.bRollStartTime !== bRollStartTime) {
      videoPlayerState.bRollStartTime = bRollStartTime;
      videoPlayerState.bRollPlayer.currentTime(bRollStartTime);
      videoPlayerState.bRollPlayer.play();
    }
  }
};

export const handleDispose = (requestARollStatusRef) => {
  videoPlayerState.clearVideoPlayerState();
  requestARollStatusRef.current = false;
};

export const handleLoadedMetaData = (requestARollStatusRef, document) => {
  const currInterval = videoPlayerState.playerIntervals[videoPlayerState.currentIntervalIdx];
  const currentTimeSec = videoPlayerState.player?.currentTime();
  videoPlayerState.setCurrentTime(currentTimeSec);
  const startTimeSec = (currInterval && currInterval.startTimeMs / 1000) || 0;
  if (currentTimeSec < startTimeSec && videoPlayerState.currentIntervalIdx === 0) {
    // play first segment
    videoPlayerState.player?.currentTime(startTimeSec);
  }
  requestCheckARollStatus(performance.now(), requestARollStatusRef, document);
};

const displayBrollPlayer = (aRollPlayerBoxRef, bRollPlayerBoxRef, bRollClip?) => {
  if (isEndCardBroll(bRollClip)) {
    projectsState.activeProject.enableCaption = false;
  } else {
    projectsState.activeProject.enableCaption = true;
  }
  if (aRollPlayerBoxRef.current) {
    aRollPlayerBoxRef.current.style.display = 'none';
  }
  if (bRollPlayerBoxRef.current) {
    bRollPlayerBoxRef.current.style.display = 'block';
  }
};

export const displayARollPlayer = (aRollPlayerBoxRef, bRollPlayerBoxRef) => {
  if (aRollPlayerBoxRef.current) {
    aRollPlayerBoxRef.current.style.display = 'block';
  }
  if (bRollPlayerBoxRef.current) {
    bRollPlayerBoxRef.current.style.display = 'none';
  }
  videoPlayerState.bRollStartTime = null;
};

function requestCheckARollStatus(lastTime, requestARollStatusRef, document) {
  /**
   * This function helps synchronize the A-Roll and B-Roll players. When both players are active,
   * the A-Roll player may need time to fetch a new chunk file. During this time, the B-Roll player
   * should be paused until the A-Roll player is ready to resume playback.
   */
  if (!requestARollStatusRef.current) return;
  const DELAY_TIME = 300; // 300 miliseconds
  const now = performance.now();
  // Throttle the function to execute at most every DELAY_TIME milliseconds
  if (now - lastTime >= DELAY_TIME) {
    if (videoPlayerState.player && videoPlayerState.bRollPlayer) {
      const currentBRollClip = findCurrentBRollClip();
      const currentSegment = getCurrentSegment();
      if (currentBRollClip) {
        if (videoPlayerState.player.readyState() !== 4 && !currentSegment.end_card) {
          // If the A-Roll player is not ready to play and the current segment is not the end card, pause the B-Roll player, and show the spinner.
          if (!videoPlayerState.bRollPlayer.paused()) {
            handleBRollPause(true);
            updateVideoPlayerUI(true, document);
          }
        } else {
          if (videoPlayerState.bRollPlayer.paused() && !videoPlayerState.player.paused()) {
            videoPlayerState.bRollPlayer.play();
          }
          updateVideoPlayerUI(false, document);
        }
      }
    }
    lastTime = now;
  }
  requestAnimationFrame(() => requestCheckARollStatus(lastTime, requestARollStatusRef, document));
}

function updateVideoPlayerUI(isFetchingChunkFile: boolean, document) {
  const spinnerElement = document.querySelector('.player-b-roll .vjs-loading-spinner');
  const controlBar = document.querySelector('.player-b-roll .vjs-control-bar') as any;
  if (!spinnerElement || !controlBar) return;
  if (isFetchingChunkFile) {
    spinnerElement.classList.remove('custom-videojs-loading');
    spinnerElement.classList.add('custom-videojs-loading');
    controlBar.style.display = 'none';
  } else {
    controlBar.style.display = 'flex';
    spinnerElement.classList.remove('custom-videojs-loading');
  }
}

export function handleARollPlay() {
  if (videoPlayerState.player) {
    videoPlayerState.player.play();
  }
  if (!timelineState) return;
  setDisablePlayCallbacks('VideoPlayer');
  setDisableSetTimeCallbacks('VideoPlayer');
  timelineState.play({ autoEnd: true });
  timelineState.setTime(videoPlayerState.player?.currentTime());
  setEnablePauseCallbacks('VideoPlayer');
}

export function handleARollPause() {
  if (videoPlayerState.player) {
    videoPlayerState.player.pause();
  }

  if (!timelineState) return;

  setDisablePauseCallbacks('VideoPlayer');
  timelineState.pause();
  setEnablePlayCallbacks('VideoPlayer');
  setEnableSetTimeCallbacks('VideoPlayer');
}

export function handleBRollPause(onlyPauseBRoll?: boolean) {
  videoPlayerState.bRollPlayer.pause();
  if (onlyPauseBRoll) return;
  const currentBRollClip = findCurrentBRollClip();
  if (currentBRollClip && videoPlayerState.player.readyState() === 4 && videoPlayerState.player) {
    videoPlayerState.player.pause();
  }
}

function handleMuteBRoll() {
  if (videoPlayerState.bRollPlayer && !videoPlayerState.bRollPlayer.muted()) {
    videoPlayerState.bRollPlayer.muted(true);
  }
}

export function handleBRollPlay() {
  videoPlayerState.bRollPlayer.play();
  const currentBRollClip = findCurrentBRollClip();
  if (currentBRollClip && videoPlayerState.player) {
    videoPlayerState.player.play();
  }
  if (isEndCardBroll(currentBRollClip)) {
    handleMuteARollUnmuteBRoll();
  } else {
    handleMuteBRoll();
  }
}

export function findCurrentBRollClip() {
  const currentSegment = getCurrentSegment();
  if (!currentSegment) {
    return null;
  }
  const currentPlayerTime = videoPlayerState.player?.currentTime();
  const currentWordIndex = currentSegment.words.findLastIndex((word) => word.startTime <= currentPlayerTime);
  return findBRollClip(currentSegment, currentWordIndex);
}

export const getCurrentSegment = () => {
  // find word in segment
  const currentPlayInterval = videoPlayerState.playerIntervals[videoPlayerState.currentIntervalIdx];
  const currentSegment = segmentsState.segments.find((segment) => segment.id === currentPlayInterval?.segmentId);
  return currentSegment;
};

export function findBRollClip(currentSegment: Segment, currentWordIndex: number) {
  return JSON.parse(JSON.stringify(brollsState.brolls))
    .reverse()
    .filter((broll) => broll.segmentId === currentSegment.id)
    .find((broll) => {
      const wordIndexRange = broll.wordIndexRange;
      return wordIndexRange[0] <= currentWordIndex && currentWordIndex <= wordIndexRange[1];
    });
}

function isEndCardBroll(bRoll) {
  return segmentsState.segments.find((segment) => segment.id === bRoll.segmentId)?.end_card ? true : false;
}

export function findBRollPreload() {
  const currentSegment = getCurrentSegment();
  if (currentSegment) {
    const currentPlayerTime = videoPlayerState.player?.currentTime() || 0;
    const currentWordIndex = currentSegment.words.findLastIndex((word) => word.startTime <= currentPlayerTime);
    let nextBRollClip = findNextBRollClip(currentSegment, currentWordIndex);
    if (!nextBRollClip && videoPlayerState.currentIntervalIdx < videoPlayerState.playerIntervals.length - 1) {
      // try to find bRoll in next segment
      const nextPlayInterval = videoPlayerState.playerIntervals[videoPlayerState.currentIntervalIdx + 1];
      const nextSegment = segmentsState.segments.find((segment) => segment.id === nextPlayInterval?.segmentId);
      const nextSegmentBrolls = brollsState.brolls.filter((broll) => broll.segmentId === nextSegment.id);
      if (nextSegmentBrolls?.length) {
        nextSegmentBrolls.sort((a, b) => a.wordIndexRange[0] - b.wordIndexRange[0]);
        nextBRollClip = nextSegmentBrolls[0];
      }
    }
    return nextBRollClip;
  }
  return null;
}

export function findNextBRollClip(currentSegment: any, currentWordIndex: number) {
  let nextBRollClip;
  let deltaMin = Number.MAX_VALUE;
  const segmentBrolls = brollsState.brolls.filter((broll) => broll.segmentId === currentSegment.id);
  for (let i = 0; i < segmentBrolls.length; i++) {
    const wordIndexRange = segmentBrolls[i].wordIndexRange;
    if (currentWordIndex < wordIndexRange[0] && wordIndexRange[0] - currentWordIndex < deltaMin) {
      deltaMin = wordIndexRange[0] - currentWordIndex;
      nextBRollClip = segmentBrolls[i];
    }
  }
  return nextBRollClip;
}

export function checkJumpNextSegment(currentPlayerTime, currentSegment) {
  /**
   * This function helps determine the timing for transitioning to a next segment
   * Instead of using the end time of the segment, we use the final end time of the segment. It will optimize the player's reading of the next word outside the segment
   * Because the subsequent word after the segment (not within the segment) starts at the end time of that segment.
   */
  const indexOfLastWord = currentSegment.words.length - 1;
  const endTimeSpeakOfSegment = currentSegment.words[indexOfLastWord].endTime;

  /**
   * We have many words that are spoken in less than 100 milliseconds but the player sends update time signals with a minimum of over 30 milliseconds each time.
   * So Math.abs(currentPlayerTime - endTimeSpeakOfSegment) <= 0.015 (half time of update time signals)
   * that it is possible to mitigate cases of fast reading leading to an inability to capture
   * the endpoint of the segment
   */
  return currentPlayerTime >= endTimeSpeakOfSegment || Math.abs(currentPlayerTime - endTimeSpeakOfSegment) <= 0.015
    ? true
    : false;
}

function preloadBRoll(setBRollVideoJsOptions) {
  const bRollPreload: Broll = findBRollPreload();
  if (bRollPreload) {
    const candidate = bRollPreload.candidates[0];
    setBRollVideoJsOptions((prev) => {
      if (prev && prev.sources[0].src === candidate.url) {
        return prev;
      }
      return {
        autoplay: isEndCardBroll(bRollPreload) ? 'play' : 'muted',
        controls: true,
        responsive: true,
        aspectRatio: videoPlayerState.aspectRatio,
        sources: [{ type: 'video/youtube', src: candidate.url }],
        techOrder: ['youtube', 'html5'],
        startTime: candidate.start,
        controlBar: {
          fullscreenToggle: false,
          pictureInPictureToggle: false,
        },
      };
    });
  }
}

function handleMuteARollUnmuteBRoll() {
  // muted A-Roll if bRoll is the [End card]
  if (videoPlayerState.player && !videoPlayerState.player.muted()) {
    videoPlayerState.player.muted(true);
  }
  if (videoPlayerState.bRollPlayer && videoPlayerState.bRollPlayer.muted()) {
    videoPlayerState.bRollPlayer.muted(false);
  }
}

function handleMuteBRollUnmuteARoll() {
  if (videoPlayerState.player && videoPlayerState.player.muted()) {
    videoPlayerState.player.muted(false);
  }
  if (videoPlayerState.bRollPlayer && !videoPlayerState.bRollPlayer.muted()) {
    videoPlayerState.bRollPlayer.muted(true);
  }
}

export function handleEndedPlayer(playerType: PLAYER_TYPE) {
  const currentSegment = getCurrentSegment();
  if (playerType === PLAYER_TYPE.B_ROLL) {
    if (currentSegment.end_card) {
      // Play again
      videoPlayerState.setCurrentIntervalIdx(0);
    }
  }
}
