export const PROJECTS_PATH = '/';
export const ONBOARD_PATH = '/onboard';
export const SHARE_PATH = '/share';
export const NEW_PROJECT_PATH = '/edit/new';
export const PREVIEW_CHATBOT_PATH = '/chatbot';
export const CHATBOT_PATH = '/chatbot';
export const AI_EDIT_PATH = '/edit';
export const CHATBOT_SHARE_PATH = '/chatbot/share';
export const FEATURE_REQUEST_PATH = 'feature-request';
export const ADMIN_PATH = 'admin';
