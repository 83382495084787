import React from 'react';
import './Button.css';
import { Stack, Typography } from '@mui/material';
import DriveIcon from '../icons/DriveIcon';
import { UNPLAYED_WORD_COLOR } from '../../transcript/CustomNode/WordCustomNode';

function DriveButton() {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        backgroundColor: '#111A19',
        padding: '8px 12px',
        borderRadius: '10px',
      }}
    >
      <DriveIcon
        size="xs"
        style={{
          height: 24,
        }}
      />
      <Typography sx={{ color: UNPLAYED_WORD_COLOR }}>Google Drive</Typography>
    </Stack>
  );
}

export default DriveButton;
