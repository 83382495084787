import { serverAddress } from '../../config';
import { deleteOptions, getOptions, patchOptions, postOptions } from '../utils/FetchUtils';

async function getAccount(email: string, accessToken: string) {
  const url = `${serverAddress}/api/account/get?email=${encodeURIComponent(email).replace(/\./g, '%2E')}`;
  const result = await fetch(url, getOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
    });

  return result;
}

async function createAccount(email: string, accessToken: string) {
  const url = `${serverAddress}/api/account/post`;
  const result = await fetch(url, postOptions(url, accessToken, { email }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
  return result;
}

async function updateIntercomProfile(email, newUserData, accessToken) {
  const url = `${serverAddress}/api/account/intercom/patch`;
  const result = await fetch(url, patchOptions(url, accessToken, { email, newUserData }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
  return result;
}

async function saveLogoCornerToProfile(email: string, corner: string, accessToken: string) {
  const url = `${serverAddress}/api/account/logo-corner/post`;
  const result = await fetch(url, postOptions(url, accessToken, { email, corner }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
  return result;
}

export async function saveMiddleBarColor(email: string, middleBarColor: string, accessToken: string) {
  const url = `${serverAddress}/api/account/middle-bar-color/post`;
  const result = await fetch(url, postOptions(url, accessToken, { email, middleBarColor }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error);
    });
  return result;
}

async function deleteAccount(email: string, accessToken: string) {
  const url = `${serverAddress}/api/account/delete-account?email=${encodeURIComponent(email).replace(/\./g, '%2E')}`;
  const result = await fetch(url, deleteOptions(url, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
    });

  return result;
}

export { createAccount, getAccount, updateIntercomProfile, saveLogoCornerToProfile, deleteAccount };
