import { Color } from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import Link from '@tiptap/extension-link';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
/**
 * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
 import {
  getSegmentListOfVideoEditSelected,
  handleRemoveLink,
  handleUpdateColor,
  handleUpdateEditorContent,
  handleUpdateLink,
  preSaveUpdatedSegment,
  removeExtendWordOfEditorJSON,
} from '../../controllers/verticalVideoEditorCRUD/TranscriptEditorHandler';
import { YTLinkActionsMenu } from '../menus/YTLinkActionsMenu';
 */
import {
  getSegmentListOfVideoEditSelected,
  handleUpdateEditorContent,
  preSaveUpdatedSegment,
  removeExtendWordOfEditorJSON,
} from '../../controllers/verticalVideoEditorCRUD/TranscriptEditorHandler';
import { CustomHardBreak } from './CustomNode/CustomHardBreak';
import { CustomUnderline } from './CustomNode/CustomUnderline';
import { SegmentCustomNode } from './CustomNode/SegmentCustomNode';
import { WordCustomNode } from './CustomNode/WordCustomNode';
import { WordExtendMoreNode } from './CustomNode/WordExtendNode';
import { projectsState } from '../../models/ProjectsState';
import './transcriptTextEditor.css';
import BubbleEditor from './BubbleEditor';
import { Box } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { brollsState } from '../../models/BrollState';
import { accountState } from '../../models/AccountState';
import { useAuth0 } from '@auth0/auth0-react';
import { updateBrolls } from '../../controllers/serverClients/ProjectClient';
import { getSegmentNodes, updateBrollsBySegmentNode } from '../../controllers/utils/BrollEditorUtils';
import { segmentsState } from '../../models/SegmentsState';
import { useLocation } from 'react-router-dom';
import { NEW_PROJECT_PATH } from '../../config/constants';
import { SHARE_PATH } from '../../config/constants';
import { deactivateWords } from '../../controllers/verticalVideoEditorCRUD/BubbleEditorHandler';
import { isMobile } from 'react-device-detect';
import { EndCardWordNode } from './CustomNode/EndCardNode';
import Broll from '../../models/Broll';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

type TProps = {
  onSaveSegments: (segmentIndexList: number[], brolls?: Broll[]) => void;
};

const TranscriptEditor = observer(({ onSaveSegments }: TProps) => {
  const debounceUpdateTimeoutRef = useRef<any>();
  const previousContentRef = useRef<any>();
  const [cursor, setCursor] = useState<number>(0);
  const projectId = projectsState.activeProjectId;
  const { displayName } = projectsState.activeProject;
  const account = accountState.currentAccount;
  const isAdmin = account && account.isAdmin;
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const isShare = pathname.startsWith(SHARE_PATH);
  const isNewProjectPage = pathname.startsWith(NEW_PROJECT_PATH);

  const trackingNoRenderState = useMemo(() => {
    return {
      onAddBrolls: false,
      selectionState: undefined,
      previousSegments: null,
      previousBrolls: null,
      previousSelectedSegmentIds: { ...projectsState.videoEditSelected.segmentIds },
    };
  }, []);

  const editor = useEditor(
    {
      extensions: [
        Color,
        Document,
        Highlight.configure({
          multicolor: true,
        }),
        History,
        Strike,
        Text,
        TextStyle,
        CustomUnderline,
        WordCustomNode,
        WordExtendMoreNode,
        SegmentCustomNode,
        EndCardWordNode,
        Link.configure({
          openOnClick: false,
        }),
        CustomHardBreak,
      ],
      content: '',
      editable: !isShare && !isNewProjectPage && !isMobile ? true : false,
      onUpdate: ({ editor }) => {
        setCursor(editor.state.selection.$anchor.pos);
        if (debounceUpdateTimeoutRef.current) {
          clearTimeout(debounceUpdateTimeoutRef.current);
        }
        debounceUpdateTimeoutRef.current = setTimeout(() => {
          try {
            if (!trackingNoRenderState.onAddBrolls) {
              const { $from } = editor.state.selection;
              const { segmentId } = $from.node().attrs;
              const segment = segmentsState.segments.find((segment) => segment.id === segmentId);
              const segmentIds = preSaveUpdatedSegment(editor);
              const editorJsonData = editor.getJSON();
              const editorJsonDataFormat = removeExtendWordOfEditorJSON(editorJsonData);
              const segmentNodes = getSegmentNodes(editorJsonDataFormat);
              if (segmentNodes[segmentId].length !== segment.words.length) {
                const segmentList = getSegmentListOfVideoEditSelected();
                updateBrollsBySegmentNode(segmentNodes[segmentId], segmentList, segmentId);
              }
              (onSaveSegments(segmentIds, brollsState.brolls) as any)
                .then(() => {
                  previousContentRef.current = editor.getJSON();
                  trackingNoRenderState.previousBrolls = JSON.parse(JSON.stringify(brollsState.brolls));
                  trackingNoRenderState.previousSegments = JSON.parse(JSON.stringify(segmentsState.segments));
                })
                .catch(() => {
                  segmentsState.setSegments(trackingNoRenderState.previousSegments);
                  brollsState.setBrolls(trackingNoRenderState.previousBrolls);
                  editor.commands.setContent(previousContentRef.current);
                });
            } else {
              trackingNoRenderState.onAddBrolls = false;
              previousContentRef.current = editor.getJSON();
            }
            // set editor history
          } catch (err) {
            console.log('Error while editing editor: ', err);
            console.log('Rollback previous state');
            editor.commands.setContent(previousContentRef.current);
          }
        }, 1000);
      },
    },
    [],
  );

  useEffect(() => {
    trackingNoRenderState.previousSegments = JSON.parse(JSON.stringify(segmentsState.segments));
    trackingNoRenderState.previousBrolls = JSON.parse(JSON.stringify(brollsState.brolls));
    trackingNoRenderState.previousSelectedSegmentIds = JSON.parse(
      JSON.stringify(projectsState.videoEditSelected.segmentIds),
    );
  }, []);

  useEffect(() => {
    if (!editor) return;
    /**
     * Wrap the function set content of Tiptap in setTimeOut can resolve issue "flushSync gets called on render..."
     * Here is the reference: https://github.com/ueberdosis/tiptap/issues/3764
     */
    setTimeout(() => {
      handleUpdateEditorContent(editor, cursor, previousContentRef, onSaveSegments, false, trackingNoRenderState);
    }, 100);
  }, [editor, JSON.stringify(brollsState.brolls), JSON.stringify(projectsState.videoEditSelected)]);

  const handleAddBrolls = (brolls) => {
    if (brolls.length === 0) {
      const messageSnackKey = enqueueSnackbar('Your selection is out of b-roll duration.', {
        variant: 'warning',
        SnackbarProps: {
          onClick: () => closeSnackbar(messageSnackKey),
        },
      });
      return;
    }
    const segmentList = getSegmentListOfVideoEditSelected();
    const brollGroupId = uuid();
    brolls = brolls.map((broll) => {
      broll.id = uuid();
      broll.brollGroupId = brollGroupId;
      broll.story_id = projectsState.getVideoEditSelectedIndex();
      return broll;
    });

    if (brolls?.length) {
      trackingNoRenderState.onAddBrolls = true;
      const updatedBrollInfo = {
        projectID: projectId,
        projectName: displayName,
        email: user.email,
        youtubeLink: brolls[0].candidates[0].url,
        youtubeStartTime: brolls[0].candidates[0].start,
        isAdmin: isAdmin,
        brolls: brolls.map((broll) => {
          const segment = segmentList.find((segment) => segment.id === broll.segmentId);
          const wordIndexRange = broll.wordIndexRange;
          return {
            words: segment.words.slice(wordIndexRange[0], wordIndexRange[1] + 1),
          };
        }),
      };
      // If the B-Roll links to an end card segment that was previously linked to B-Roll, then replace the old data with the new data.
      let updatedBrolls;
      const targetSegment = segmentList.find((i) => i.id === brolls[0].segmentId)?.end_card;
      const targetBRollIndex = brollsState.brolls.findIndex((bRoll) => bRoll.segmentId === brolls[0].segmentId);
      if (brolls.length === 1 && targetSegment && targetBRollIndex > -1) {
        updatedBrolls = JSON.parse(JSON.stringify(brollsState.brolls)).splice(targetBRollIndex, 1, brolls[0]);
        brollsState.setBrolls(updatedBrolls);
      } else {
        updatedBrolls = brollsState.addBrolls(brolls);
      }

      updateBrolls(projectId, updatedBrolls, updatedBrollInfo);
      const { from, to } = editor.state.selection;
      editor
        .chain()
        .setTextSelection({ from, to })
        .extendMarkRange('link')
        .setLink({ href: brolls[0].candidates[0].url })
        .setUnderline()
        .setMark('underline', { color: brolls[0].color, brollGroupId: brollGroupId })
        .run();
    }
  };

  return (
    <Box id="transcript-editor-box">
      {/*
        [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
        {editor && (
          <YTLinkActionsMenu
            editor={editor}
            onRemoveLink={handleRemoveLink}
            onUpdateLink={handleUpdateLink}
            onUpdateColor={handleUpdateColor}
          />
        )}
      */}
      {editor && (
        <BubbleEditor
          editor={editor}
          onYoutubeSubmit={handleAddBrolls}
          onUpdateEditorContent={() =>
            handleUpdateEditorContent(editor, cursor, previousContentRef, onSaveSegments, true, trackingNoRenderState)
          }
        />
      )}
      <EditorContent
        className="transcript-editor"
        style={{
          fontSize: '1rem',
          padding: 0,
          color: 'black',
        }}
        editor={editor}
        onKeyDownCapture={(e) => {
          if (e.altKey || e.shiftKey || e.ctrlKey || e.metaKey) return;
          const { $from, $to } = editor.state.selection;
          if ($from.node().attrs.segmentId !== $to.node().attrs.segmentId) {
            editor.commands.setContent(previousContentRef.current);
            trackingNoRenderState.selectionState = { from: $from.pos, to: $to.pos };
          }
        }}
        onKeyUp={(e) => {
          if (e.altKey || e.shiftKey || e.ctrlKey || e.metaKey) return;
          if (trackingNoRenderState.selectionState) {
            const { from, to } = trackingNoRenderState.selectionState;
            editor.commands.setTextSelection({ from: from, to: to });
            deactivateWords(editor);
            handleUpdateEditorContent(editor, cursor, previousContentRef, onSaveSegments, true, trackingNoRenderState);
            trackingNoRenderState.selectionState = undefined;
          }
        }}
      />
    </Box>
  );
});

export default TranscriptEditor;
