import React from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { observer } from 'mobx-react';
import '../transcriptTextEditor.css';
import { Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { UNPLAYED_WORD_COLOR } from './WordCustomNode';

export interface SegmentCustomOptions {
  HTMLAttributes: Record<string, any>;
}

const SegmentComponentView = observer((props) => {
  const { startTime, endTime, isEndCard } = props.node.attrs;

  return (
    <NodeViewWrapper>
      {!isEndCard && (
        <Stack
          ref={(node) => {
            if (node) {
              node.style.setProperty('display', 'flex', 'important');
            }
          }}
          style={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: '10px',
            flexDirection: 'row',
          }}
        >
          <Typography color={UNPLAYED_WORD_COLOR} fontSize={12}>
            {moment.utc(startTime * 1000).format('m:ss')} - {moment.utc(endTime * 1000).format('m:ss')}
          </Typography>
        </Stack>
      )}
      <NodeViewContent className={`content`} as="p" style={{ paddingLeft: 2, paddingBottom: 5 }} />
      {!isEndCard && <Divider sx={{ backgroundColor: UNPLAYED_WORD_COLOR, marginBottom: '20px' }} />}
    </NodeViewWrapper>
  );
});

export const SEGMENT_CUSTOM_NODE_NAME = 'segment-custom';

export const SegmentCustomNode = Node.create<SegmentCustomOptions>({
  name: SEGMENT_CUSTOM_NODE_NAME,

  priority: 1000,

  group: 'block',

  content: 'block*',

  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      segmentId: {
        default: 0,
      },
      startTime: {
        default: 0,
      },
      endTime: {
        default: 0,
      },
      isEndCard: {
        default: undefined,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'p' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SegmentComponentView);
  },
});
