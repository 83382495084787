export const getOptions = (url: string, accessToken: string) => ({
  method: 'GET',
  url: url,
  headers: {
    authorization: `Bearer ${accessToken}`,
  },
});

export const postOptions = (url: string, accessToken: string, data: any) => {
  return {
    method: 'POST',
    url: url,
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
};

export const deleteOptions = (url: string, accessToken: string) => ({
  method: 'DELETE',
  url: url,
  headers: {
    authorization: `Bearer ${accessToken}`,
  },
});

export const patchOptions = (url: string, accessToken: string, data: any) => {
  return {
    method: 'PATCH',
    url: url,
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
};
