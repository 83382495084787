import { serverAddress } from '../../config';
import { deleteOptions, getOptions, postOptions } from '../utils/FetchUtils';
import qs from 'qs';

export async function getMessagesApi(accessToken: string, chatId: string, lastEvaluatedKey?: any) {
  let endpoint = `${serverAddress}/api/message?chatId=${chatId}`;
  if (lastEvaluatedKey) {
    endpoint += `&${qs.stringify({ lastEvaluatedKey: lastEvaluatedKey })}`;
  }

  const result = await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the get message listing operation:', error.message);
    });

  return result;
}

export async function createMessageApi(accessToken: string, message) {
  const endpoint = `${serverAddress}/api/message`;

  const result = await fetch(endpoint, postOptions(endpoint, accessToken, message))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the create message operation:', error.message);
    });

  return result;
}

export async function deleteMessageApi(accessToken, messageId: string, chatId: string) {
  const endpoint = `${serverAddress}/api/message/${messageId}?chatId=${chatId}`;
  const result = await fetch(endpoint, deleteOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .then((data) => {
      return JSON.parse(data);
    })
    .catch((error) => {
      console.error('There was a problem with the delete message operation:', error.message);
    });

  return result;
}
