import React from 'react';

type Props = {
  color: string;
};

const ShareIcon = ({ color }: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18009 16.02C7.42009 15.95 6.6701 15.6 6.0901 14.99C4.7701 13.6 4.7701 11.32 6.0901 9.93001L8.2801 7.63002C9.6001 6.24002 11.7701 6.24002 13.1001 7.63002C14.4201 9.02002 14.4201 11.3 13.1001 12.69L12.0101 13.84"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8202 7.97998C16.5802 8.04998 17.3302 8.39998 17.9102 9.00998C19.2302 10.4 19.2302 12.68 17.9102 14.07L15.7202 16.37C14.4002 17.76 12.2302 17.76 10.9002 16.37C9.58016 14.98 9.58016 12.7 10.9002 11.31L11.9902 10.16"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
