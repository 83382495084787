import * as React from 'react';
import Button from '@mui/material/Button';

interface RequestAccessButtonProps {
  onClick: () => void;
  sx: any; // Styling
}

const RequestAccessButton = (props: RequestAccessButtonProps) => {
  return (
    <Button variant="text" onClick={props.onClick} sx={props.sx}>
      Request Access
    </Button>
  );
};

export default RequestAccessButton;
