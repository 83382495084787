import React from 'react';
import CloneIcon from '../icons/CloneIcon';
import MenuActionButton from './MenuActionButton';

interface CloneButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

const CloneButton = (props: CloneButtonProps) => {
  return (
    <MenuActionButton onClick={props.onClick} text="Clone" icon={<CloneIcon color="#fff" />} style={props.style} />
  );
};

export default CloneButton;
