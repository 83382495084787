import { observer } from 'mobx-react';
import { Box, Link, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { FormHandlers } from '../ingest/FormHandlers';
import { ingestState } from '../../models/IngestState';
import IngestInfoStep from '../ingest/IngestInfoStep';
import FeedbackModal from './FeedbackModal';
import {
  handleFocusNewPromptEdit,
  handleOpenPromptModal,
  handleToggleRePromptModal,
  notifyRePromptFeedbacks,
  rePromptCallback,
  showRePromptingModalAndCloseForm,
} from '../../controllers/projectsCRUD/ProjectRePromptHandler';
import { projectsState } from '../../models/ProjectsState';
import { ProjectStatus } from '../../models/ProjectItem';
import { pollSingleProjectStatus } from '../../controllers/serverClients/ProjectClient';
import { useParams, useSearchParams } from 'react-router-dom';
import { getProjectDisplayStatus } from '../../controllers/projectsCRUD/ProjectPageHandler';
import RePromptButton from '../ui_controls/buttons/RePromptButton';

const RePromptModal = observer(() => {
  const [, setSearchParams] = useSearchParams();
  const { resetForm } = FormHandlers();
  const { isSubmitting, formFeedbacks, setFormFeedbacks } = ingestState;
  const { activeProject } = projectsState;
  const { projectId } = useParams();
  const isSuccessFeedback = formFeedbacks[0]?.type === 'success';
  const [isModalActive, setIsModalActive] = useState(false);
  const [showRepromptFeedback, setShowRepromptFeedback] = useState<boolean>(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState<boolean>(false);

  notifyRePromptFeedbacks(formFeedbacks, isSuccessFeedback, setFormFeedbacks);

  useLayoutEffect(() => {
    showRePromptingModalAndCloseForm(
      isSubmitting,
      isSuccessFeedback,
      isModalActive,
      showRepromptFeedback,
      setFormFeedbacks,
      setIsModalActive,
      setShowRepromptFeedback,
    );
  }, [isSubmitting]);

  useEffect(() => {
    let intervalId;
    if (activeProject && getProjectDisplayStatus(activeProject.status) === ProjectStatus.RE_PROMPTING) {
      intervalId = pollSingleProjectStatus(activeProject.id, false, () =>
        rePromptCallback(projectId, setIsShowSuccessModal, setShowRepromptFeedback),
      );
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [activeProject]);

  useEffect(() => {
    if (showRepromptFeedback) {
      const timeout = setTimeout(() => {
        setShowRepromptFeedback(false);
        clearTimeout(timeout);
      }, 10000);
    } else {
      setIsShowSuccessModal(false);
    }
  }, [showRepromptFeedback]);

  const renderRePromptFeedback = () => {
    if (isShowSuccessModal) {
      return (
        <Typography textAlign={'center'} fontSize={16}>
          Reprompt complete: check out the{' '}
          <Link
            onClick={() => handleFocusNewPromptEdit(setSearchParams, setShowRepromptFeedback)}
            sx={{ cursor: 'pointer' }}
          >
            edit
          </Link>
        </Typography>
      );
    }
    return (
      <Typography textAlign={'center'} fontSize={16}>
        Reprompt in progress: the AI is editing your video.
      </Typography>
    );
  };

  return (
    <>
      <FeedbackModal
        isActive={showRepromptFeedback}
        toggle={() => setShowRepromptFeedback(!showRepromptFeedback)}
        contentComponent={renderRePromptFeedback}
      />
      <Modal
        onClosed={resetForm}
        isOpen={isModalActive}
        toggle={() => handleToggleRePromptModal(isSubmitting, isModalActive, setIsModalActive, setFormFeedbacks)}
        zIndex={2}
        size="lg"
        contentClassName="modal-ingest-content"
      >
        <Box
          style={{
            backgroundColor: '#000A08',
            borderRadius: 20,
            padding: 20,
            color: 'white',
          }}
        >
          <IngestInfoStep isInputExist={true} rePrompt={true} />
        </Box>
      </Modal>
      <RePromptButton onClick={() => handleOpenPromptModal(setIsModalActive, activeProject.displayStatus)} />
    </>
  );
});

export default RePromptModal;
