import { Stack, Typography, OutlinedInput, Select, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import OnboardButton from '../ui_controls/buttons/OnboardButton';
import '../pages/page.css';
import ArrowDownIcon from '../ui_controls/icons/ArrowDownIcon';

const SOURCE_OPTIONS = [
  { title: 'Podcast', value: 'Podcast' },
  { title: 'Instagram', value: 'Instagram' },
  { title: 'Google/Bing', value: 'Google/Bing' },
  { title: 'Newsletter/Blogpost', value: 'Newsletter/Blogpost' },
  { title: 'YouTube', value: 'YouTube' },
  { title: 'Twitter', value: 'Twitter' },
  { title: 'LinkedIn', value: 'LinkedIn' },
  { title: 'Other', value: 'Other' },
  { title: 'Facebook', value: 'Facebook' },
  { title: 'Tik Tok', value: 'Tik Tok' },
  { title: 'In the news', value: 'In the news' },
  { title: 'From work', value: 'From work' },
  { title: 'Friends/School', value: 'Friends/School' },
];

type Props = {
  firstname: string;
  selectedOption: string;
  handleChangeFirstname: (e) => void;
  handleChangeOption: (e) => void;
  handleMoveToNextStep: () => void;
  onMoveBack: () => void;
};

function OnboardUserInfo({
  firstname,
  selectedOption,
  handleChangeFirstname,
  handleChangeOption,
  handleMoveToNextStep,
  onMoveBack,
}: Props) {
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (error) {
      const messageSnackKey = enqueueSnackbar(error, {
        variant: 'warning',
        SnackbarProps: {
          onClick: () => {
            setError(undefined);
            closeSnackbar(messageSnackKey);
          },
        },
      });
    }
  }, [error]);

  const handleValidate = () => {
    const errors = [];
    if (!firstname) errors.push('Please answer each question.');
    if (firstname && firstname.length < 3) errors.push('Minimum length: 3 characters.');
    if (!selectedOption || selectedOption === 'Select an option') errors.push('Please answer each question.');
    if (errors.length > 0) {
      setError(errors[0]);
    } else {
      setError(undefined);
      handleMoveToNextStep();
    }
  };

  return (
    <Stack spacing={2}>
      <Typography className="onboard-page__label">Hi. Welcome to Storylines. We’re excited you’re here!</Typography>
      <Typography className="onboard-page__input-label">What's your first name?</Typography>
      <OutlinedInput className="onboard-page__input" value={firstname} onChange={handleChangeFirstname} />
      <Typography className="onboard-page__input-label">How did you hear about us?</Typography>
      <Select
        className="onboard-page__select"
        value={selectedOption}
        onChange={handleChangeOption}
        IconComponent={(props) => {
          return <ArrowDownIcon {...props} />;
        }}
      >
        <MenuItem disabled value="Select an option">
          Select an option
        </MenuItem>
        {SOURCE_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
      <Stack direction="row" justifyContent="space-between">
        <OnboardButton title="Back" color="grey" onClick={onMoveBack} />
        <OnboardButton title="Next" onClick={handleValidate} />
      </Stack>
    </Stack>
  );
}

export default OnboardUserInfo;
