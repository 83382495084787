import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { patchUpdateProjectOwner } from '../serverClients/ProjectClient';

export const handleAddProjectOwner = async (inputEmail, activeProject, setNewOwner) => {
  const users = activeProject.owner.split(',');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const userEmail = inputEmail.trim();
  if (userEmail !== '' && emailRegex.test(inputEmail) && !users.includes(userEmail)) {
    const newUsers = [...users, userEmail];
    activeProject.owner = newUsers.join(',');
    setNewOwner('');
    try {
      await patchUpdateProjectOwner(activeProject.id, newUsers, 'accessToken');
    } catch {
      activeProject.owner = newUsers.slice(0, -1).join(',');
      const messageSnackKey = enqueueSnackbar('User is not valid', {
        variant: 'warning',
        SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
      });
    }
  } else {
    const messageSnackKey = enqueueSnackbar('User is not valid', {
      variant: 'warning',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
  }
};

export const handleRemoveProjectOwner = async (removeUserEmail, activeProject) => {
  const users = activeProject.owner.split(',');
  if (users.length < 2) {
    const messageSnackKey = enqueueSnackbar('User must least 1', {
      variant: 'warning',
      SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
    });
    return;
  }
  const newUsers = users.filter((tag) => tag !== removeUserEmail);
  activeProject.owner = newUsers.join(',');
  try {
    await patchUpdateProjectOwner(activeProject.id, newUsers, 'accessToken');
  } catch (err) {
    console.log('err: ', err);
    activeProject.owner = [...newUsers, removeUserEmail].join(',');
  }
};
