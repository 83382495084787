import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate, useParams } from 'react-router-dom';
import { PROJECTS_PATH } from '../../config/constants';
import { combinePunctuation } from '../../controllers/serverClients/ProjectClient';
import { FormTypes, ingestState } from '../../models/IngestState';
import { mediaState } from '../../models/MediaState';
import { projectsState } from '../../models/ProjectsState';
import { segmentsState } from '../../models/SegmentsState';
import { videoPlayerState } from '../../models/VideoPlayerState';
import AIVideoEdits from '../menus/AIVideoEdits';
import Navigation from '../navigation/Navigation';
import VideoPlayer from '../players/VideoPlayer';
import VerticalVideoEditor from '../texteditor/VerticalVideoEditor';
import './page.css';
import IngestModal from '../modals/IngestModal';
import { FormHandlers } from '../ingest/FormHandlers';
import {
  calculatePlayerDimension,
  calculateResolutionUI,
  fetchProject,
  handleCloneProject,
  handleLogin,
  notifyFeedbacks,
  renderLoadingText,
  updateCaptionOverlay,
  clearProjectState,
} from '../../controllers/projectsCRUD/ProjectPageHandler';
import FeedbackModal from '../modals/FeedbackModal';
import MediaTranscripts from '../mediaTranscripts/MediaTranscripts';
import { ProjectStatus } from '../../models/ProjectItem';

export type MenuItem = {
  title: string;
  icon: React.JSX.Element;
  disabled: boolean;
  isOpen?: boolean;
};

const TRANSCRIPT_WIDTH_LIMIT = 800;

const ProjectPage = observer(() => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { projectId } = useParams();
  const { segments } = segmentsState;
  const { videoEditSelected, selectedMediaId, addProcessingPrompt, medias } = projectsState;
  const { formFeedbacks, setFormFeedbacks, isSubmitting } = ingestState;
  const [playerWidth, setPlayerWidth] = useState(650);
  const [playerHeight, setPlayerHeight] = useState(650);
  const { aspectRatio, setAspectRatio } = videoPlayerState;
  const activeProject = projectsState.activeProject;
  const [isLoadProject, setIsLoadProject] = useState<boolean>(false);
  const [isCloningProject, setIsCloningProject] = useState<boolean>(false);
  const { resetForm } = FormHandlers();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const shouldDisplayVideoEditFeature =
    videoEditSelected && !videoEditSelected.nonSupport && videoEditSelected.strategy !== null;
  const shouldDisplayMediaTranscript =
    typeof selectedMediaId === 'number' && selectedMediaId > -1 && !shouldDisplayVideoEditFeature;
  const isHumanCutEdit = activeProject?.originalData?.formType === FormTypes.HUMAN_CUT_FORM;
  const isNewProjectPage = projectId === 'new';
  const isSuccessFeedback = formFeedbacks[0]?.type === 'success';

  const [isModalActive, setIsModalActive] = useState(false);

  useLayoutEffect(() => {
    if (!projectId) {
      navigate(PROJECTS_PATH);
      return;
    }
  }, []);

  useEffect(() => {
    if (projectsState.videoEditSelected) {
      updateCaptionOverlay(
        projectsState.activeProjectId,
        projectsState.videoEditSelected,
        mediaState,
        videoPlayerState,
      );
    }
  }, [projectsState.videoEditSelected?.id, projectsState.selectedMediaId]);

  useLayoutEffect(() => {
    if (isSubmitting === false) {
      setIsModalActive(false);
    }
  }, [isSubmitting]);

  useLayoutEffect(() => {
    if (activeProject) {
      let width = activeProject.width;
      let height = activeProject.height;
      if (medias && medias[selectedMediaId]) {
        width = medias[selectedMediaId].width;
        height = medias[selectedMediaId].height;
      }
      calculatePlayerDimension(width, height, setPlayerWidth, setPlayerHeight, setAspectRatio);
    }
  }, [activeProject, selectedMediaId]);

  useEffect(() => {
    if (
      projectId &&
      (!projectsState.activeProjectId || projectId !== projectsState.activeProjectId) &&
      !isNewProjectPage
    ) {
      projectsState.setActiveProjectId(projectId);
      fetchProject(projectId, setIsLoadProject);
    }
  }, [projectId]);

  useEffect(() => {
    if (segments.length) {
      segments.forEach((segment) => {
        if (segment?.words) {
          segment.words = combinePunctuation(segment);
        }
      });
    }
  }, [segments]);

  notifyFeedbacks(formFeedbacks, setFormFeedbacks);

  useEffect(() => {
    if (isNewProjectPage) {
      setIsModalActive(true);
    }
  }, [isNewProjectPage]);

  useLayoutEffect(() => {
    if (!isSubmitting && isSuccessFeedback) {
      setShowSuccessMessage(true);
      setFormFeedbacks([]);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (activeProject && medias && activeProject.displayStatus === ProjectStatus.RE_PROMPTING) {
      addProcessingPrompt();
    }
  }, [activeProject, medias]);

  useEffect(() => {
    return () => {
      clearProjectState();
    };
  }, []);

  const onClosedIngestModal = () => {
    resetForm();
  };

  return (
    <>
      <Helmet title={activeProject ? `${activeProject?.displayName} | Storylines` : 'Storylines'} />
      <Navigation
        projectNameEditable={!isNewProjectPage}
        hiddenStoryForm={isHumanCutEdit}
        onLogin={() => handleLogin(false, projectId, navigate)}
        onClone={() => handleCloneProject(user, projectId, setIsCloningProject, navigate)}
        hiddenMenuBar={isNewProjectPage}
      />
      <IngestModal
        isActive={isModalActive}
        onClosed={onClosedIngestModal}
        toggle={() => {
          if (!isSubmitting) {
            setFormFeedbacks([]);
            setIsModalActive(!isModalActive);
          } else {
            const messageSnackKey = enqueueSnackbar('Your form is submitting!', {
              variant: 'warning',
              SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
            });
          }
        }}
      />
      {isNewProjectPage && (
        <FeedbackModal
          isActive={showSuccessMessage}
          toggle={() => setShowSuccessMessage((prev) => !prev)}
          onClosed={() => navigate(PROJECTS_PATH)}
        />
      )}
      <LoadingOverlay
        active={isLoadProject || isCloningProject}
        spinner
        className="main-layout"
        text={renderLoadingText(isCloningProject)}
      >
        <Box style={{ width: '100%', height: '100%' }}>
          {!isNewProjectPage && (
            <Stack direction={'row'}>
              <AIVideoEdits />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={'center'}
                style={{ color: 'white', width: '100%', height: '100%', position: 'relative' }}
              >
                <Box
                  style={{
                    padding: '1rem 0rem 1rem 1rem',
                    width: playerWidth
                      ? `${playerWidth}px`
                      : calculateResolutionUI(playerWidth, playerHeight, aspectRatio).width,
                    height: playerHeight
                      ? `${playerHeight}px`
                      : calculateResolutionUI(playerWidth, playerHeight, aspectRatio).height,
                    minWidth: '350px',
                  }}
                >
                  <VideoPlayer isMainPlayer={true} aspectRatio={aspectRatio} />
                </Box>
                <Box
                  style={{
                    flex: 1,
                    padding: '1rem',
                    maxWidth: `${TRANSCRIPT_WIDTH_LIMIT}px`,
                    maxHeight: '80vh',
                  }}
                  className="vertical-editor-page__transcript-column"
                >
                  {shouldDisplayVideoEditFeature && <VerticalVideoEditor />}
                  {shouldDisplayMediaTranscript && <MediaTranscripts />}
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
      </LoadingOverlay>
    </>
  );
});

export default ProjectPage;
