import React from 'react';
import { ChatItem } from '../../models/ChatItem';
import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { chatbotState } from '../../models/ChatbotState';
import TrashBinButton from '../ui_controls/buttons/TrashBinButton';
import './ChatSidebar.css';
import { deleteChat } from '../../controllers/chat/ChatWindowHandler';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CHATBOT_PATH, CHATBOT_SHARE_PATH } from '../../config/constants';

interface ChatSidebarItemProps {
  isSelected: boolean;
  chatItem: ChatItem;
  isReadOnly?: boolean;
  scrollToTop?: () => void;
}

const ChatSidebarItem = observer((props: ChatSidebarItemProps) => {
  const navigate = useNavigate();
  const { selectedChatId, isPreview, setShouldShowRequestAccessModal } = chatbotState;
  const { user } = useAuth0();
  const isOwner = props.chatItem.owner === user?.email;

  function handleClickDeleteBtn(e) {
    e.stopPropagation();
    if (isPreview) {
      setShouldShowRequestAccessModal(true);
    } else {
      deleteChat(props.chatItem.chatId, user.email, navigate, props.scrollToTop);
    }
  }

  return (
    <Box
      className={`chat-sidebar-item ${props.isSelected ? 'active' : ''}`}
      onClick={() => {
        if (props.chatItem.chatId !== selectedChatId) {
          navigate(`${props.isReadOnly ? CHATBOT_SHARE_PATH : CHATBOT_PATH}/${props.chatItem.chatId}`);
        }
      }}
    >
      <Box>
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={props.isSelected ? 700 : 400}
          color={props.isSelected ? 'white' : '#000A08'}
        >
          🎥 {props.chatItem.title}
        </Typography>
        {!isOwner && (
          <Typography
            fontSize={13}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '180px',
            }}
          >
            {props.chatItem.owner}
          </Typography>
        )}
      </Box>
      {!props.isReadOnly && isOwner && (
        <TrashBinButton onClick={handleClickDeleteBtn} className="chat-sidebar-item__trash-icon" />
      )}
    </Box>
  );
});

export default ChatSidebarItem;
