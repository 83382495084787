import React from 'react';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import AddProjectIcon from '../icons/AddProjectIcon';

interface CreateProjectModalButtonProps {
  onClick: () => void;
}

const CreateProjectButton = styled(Button)({
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  textTransform: 'none',
  borderRadius: '25px',
  backgroundColor: 'rgb(68, 151, 126)',
  '&:hover': {
    backgroundColor: '#0D1615',
  },
  '&:active': {
    backgroundColor: 'rgb(68, 151, 126)',
  },
  width: '100%',
  height: '35vh',
  maxWidth: '420px',
  maxHeight: '321px',
  margin: 'auto',
});

const CreateProjectModalButton = (props: CreateProjectModalButtonProps) => (
  <CreateProjectButton variant="contained" onClick={props.onClick}>
    <AddProjectIcon style={{ height: 104 }} />
    <Typography fontSize={32} fontFamily={'Geist'}>
      New Edit
    </Typography>
  </CreateProjectButton>
);

export default CreateProjectModalButton;
