import { List, ListItem } from '@mui/material';
import React from 'react';
import ArrowRightIcon from '../ui_controls/icons/ArrowRightIcon';
import ProjectNameEditable from './ProjectNameEditable';
import VideoEditBreadcrumb from './VideoEditBreadcrumb';
import { observer } from 'mobx-react';
import { projectsState } from '../../models/ProjectsState';
import './Navigation.css';

const iconStyles = { color: 'white', fontSize: 16 };

const ProjectBreadcrumbs = observer(() => {
  const { videoEditSelected } = projectsState;

  return (
    <List className="nav-item-project-info">
      <ListItem style={{ padding: '0 8px', width: 30 }}>
        <ArrowRightIcon style={iconStyles} />
      </ListItem>
      <ListItem style={{ padding: 0, width: 'auto' }}>
        <ProjectNameEditable />
      </ListItem>
      {videoEditSelected.strategy && (
        <ListItem style={{ padding: '0 8px', width: 30 }}>
          <ArrowRightIcon style={iconStyles} />
        </ListItem>
      )}
      {videoEditSelected.strategy && (
        <ListItem style={{ padding: 0, color: 'white' }}>
          <VideoEditBreadcrumb />
        </ListItem>
      )}
    </List>
  );
});

export default ProjectBreadcrumbs;
