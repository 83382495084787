import React from 'react';
import { Node, mergeAttributes } from '@tiptap/core';
/**
 * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
 * import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
 * import { videoPlayerState } from '../../../models/VideoPlayerState';
 * import { brollsState } from '../../../models/BrollState';
 */
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { observer } from 'mobx-react';
import '../transcriptTextEditor.css';
export interface WordCustomOptions {
  HTMLAttributes: Record<string, any>;
}

/**
 * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
const EndCardComponentView = observer((props) => {
*/
const EndCardComponentView = observer(() => {
  /**
   * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
   * const { segmentId, wordIndex } = props.node.attrs;
   */

  /**
   * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
   * const isLinkedBroll = brollsState.brolls.find((broll) => broll.segmentId === segmentId);
   */

  /**
   * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
    function handleClickWord() {
    const nodePosition = props.getPos() + 1;
      if (!isLinkedBroll) {
        props.editor.commands.setTextSelection({ from: nodePosition, to: nodePosition + props.node.nodeSize });
        return;
      }
      videoPlayerState.setCurrentIntervalIdx(videoPlayerState.playerIntervals.length - 1);
    }
   */

  return (
    <NodeViewWrapper>
      {/* 
        [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
        <NodeViewContent
          contentEditable={false}
          onClick={handleClickWord}
          className={`end-card-node content ${isLinkedBroll ? 'active' : ''} ${wordIndex === 0 ? 'start-word' : ''}`}
          as="p"
        />
       */}
    </NodeViewWrapper>
  );
});

export const END_CARD_NODE_NAME = 'end-card-word';

export const EndCardWordNode = Node.create<WordCustomOptions>({
  name: END_CARD_NODE_NAME,

  priority: 1000,

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [
      {
        tag: 'react-component',
      },
    ];
  },

  addAttributes() {
    return {
      startTime: {
        default: 0,
      },
      endTime: {
        default: 0,
      },
      segmentId: {
        default: 0,
      },
      wordIndex: {
        default: 0,
      },
      endCard: {
        default: true,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['react-component', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(EndCardComponentView);
  },
});
