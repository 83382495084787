import { IconButton, Typography } from '@mui/material';
import React from 'react';
import HomeIcon from '../icons/HomeIcon';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
};

function BackToHomeButton({ onClick, disabled }: Props) {
  return (
    <IconButton
      onClick={onClick}
      style={{
        height: 36,
        borderRadius: 5,
        backgroundColor: '#079A7C',
        color: 'white',
        gap: 5,
        opacity: disabled && 0.5,
      }}
      disabled={disabled}
    >
      <Typography sx={{ whiteSpace: 'nowrap' }}>Back to</Typography>
      <HomeIcon style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      <Typography sx={{ whiteSpace: 'nowrap' }}>Dashboard</Typography>
    </IconButton>
  );
}

export default BackToHomeButton;
