import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, NavItem, Navbar } from 'reactstrap';
import LogoIcon from '../ui_controls/icons/LogoIcon';
import MenuBar from './MenuBar';
import UserDropdownMenu from '../menus/UserDropdownMenu';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from '../ui_controls/buttons/LoginButton';
import { Stack } from '@mui/material';
import CloneButton from '../ui_controls/buttons/CloneButton';
import { SHARE_PATH } from '../../config/constants';
import { AI_EDIT_PATH } from '../../config/constants';
import ProjectBreadcrumbs from './ProjectBreadcrumbs';
import { observer } from 'mobx-react';
import { projectsState } from '../../models/ProjectsState';
import './Navigation.css';

type TProps = {
  hiddenMenuBar?: boolean;
  hiddenStoryForm?: boolean;
  projectNameEditable?: boolean;
  onLogin?: () => void;
  onClone?: () => void;
};

const Navigation = observer(({ hiddenMenuBar, projectNameEditable, hiddenStoryForm, onLogin, onClone }: TProps) => {
  const { user } = useAuth0();
  const { pathname } = useLocation();
  const isShare = pathname.startsWith(SHARE_PATH);
  const { videoEditSelected } = projectsState;
  const isEditPath = pathname.startsWith(AI_EDIT_PATH);
  const displayActionsMenu = !hiddenMenuBar && !isShare && videoEditSelected;

  return (
    <Navbar
      className="main-layout__navigation"
      style={{
        backgroundColor: isEditPath || isShare ? '#0A1714' : 'rgb(2, 10, 8)',
        borderBottom: (isEditPath || isShare) && '#FFFFFF1F solid 1px',
      }}
    >
      <Nav fill pills style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <NavItem className="nav-item-logo-project-name">
          <NavLink className="whiteLink nav-item-logo" to="/">
            <LogoIcon />
          </NavLink>
          {projectNameEditable && videoEditSelected && <ProjectBreadcrumbs />}
        </NavItem>

        {displayActionsMenu && (
          <NavItem className="nav-item-project-actions">
            <MenuBar hiddenStoryForm={hiddenStoryForm} />
          </NavItem>
        )}

        {isShare && (
          <NavItem className="nav-item-clone-project">
            <Stack direction={'row'} gap={2} marginLeft={2} alignItems={'center'}>
              <CloneButton onClick={onClone} />
              {user ? <UserDropdownMenu /> : <LoginButton onClick={onLogin} />}
            </Stack>
          </NavItem>
        )}

        {!isShare && (
          <NavItem className="nav-item-user-profile">
            <UserDropdownMenu />
          </NavItem>
        )}
      </Nav>
    </Navbar>
  );
});

export default Navigation;
