import { Box } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import { mediaState } from '../../models/MediaState';
import { updateProjectEnableCaption } from '../../controllers/serverClients/ProjectClient';
import { videoPlayerState } from '../../models/VideoPlayerState';
import { CaptionFontsEnum, CaptionStylesEnum } from '../../models/CaptionsStyle';
import { accountState } from '../../models/AccountState';
import CustomColorPicker from '../modals/CustomColorPicker';
import LocalUploadImage from './LocalUploadImage';
import { projectsState } from '../../models/ProjectsState';
import './captionStyles.css';
import TrashBinButton from '../ui_controls/buttons/TrashBinButton';
import CustomSwitch from '../ui_controls/switch/CustomSwitch';
import { deleteLogo } from '../../controllers/serverClients/LogoClient';
import { Input } from 'reactstrap';
import {
  handleChooseCorner,
  handleMiddleBarColorChange,
  handleStylesChange,
  handleUploadLogo,
  handleValidateFontSize,
  handleValidateLogo,
} from '../../controllers/captionsCRUD/CaptionStylesHandlers';

type TProps = {
  closeMenu: any;
  isUpdating: boolean;
  setUpdating: any;
};

const cornerOptions = ['Top - Left', 'Top - Right', 'Bottom - Right', 'Bottom - Left'];

const CaptionStyles = observer((props: TProps) => {
  const [imageUpload, setImageUpload] = useState<File>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [enableAnimatedCaption, setEnableAnimatedCaption] = useState<boolean>(true);
  const [fontSize, setFontSize] = useState<string>(String(mediaState.captionStyles.fontSize));
  const { logo, setLogo } = videoPlayerState;
  const minFontSize = 50;
  const maxFontSize = 120;

  const { projectId } = useParams();
  const { userID: email, logoCorner } = accountState.currentAccount;
  const debounceUpdateTimeoutRef = useRef<any>();
  const debounceUpdateCornerTimeoutRef = useRef<any>();
  const debounceValidateFontSizeTimeoutRef = useRef<any>();

  const { activeProject } = projectsState;

  const disableAnimatedColor = !activeProject.enableCaption || !enableAnimatedCaption;

  const toggleCaption = async (e) => {
    props.setUpdating(true);
    projectsState.setEnableCaption(e.target.checked);
    await updateProjectEnableCaption(activeProject.id, e.target.checked);
    props.setUpdating(false);
  };

  const toggleAnimatedCaption = async (e) => {
    setEnableAnimatedCaption(e.target.checked);
    if (!e.target.checked) {
      await handleStylesChange('#disable', CaptionStylesEnum.ACTIVE_COLOR, debounceUpdateTimeoutRef);
      await handleStylesChange('#disable', CaptionStylesEnum.INACTIVE_COLOR, debounceUpdateTimeoutRef);
    }
  };

  useEffect(() => {
    if (imageUpload) {
      const isValid = handleValidateLogo(imageUpload);
      if (isValid) {
        setIsUploading(true);
        handleUploadLogo(imageUpload, projectId, activeProject, setIsUploading);
      }
    }
  }, [imageUpload]);

  const handleRemoveLogo = async () => {
    setImageUpload(undefined);
    setLogo('');
    await deleteLogo(projectId, 'token');
  };

  useEffect(() => {
    if (activeProject) {
      const { activeColor, inactiveColor } = mediaState.captionStyles;
      if (activeColor === '#disable' && inactiveColor === '#disable') {
        setEnableAnimatedCaption(false);
      }
    }
  }, [activeProject]);

  return (
    <>
      <LoadingOverlay active={props.isUpdating} spinner className="custom-loading_overlay_content">
        <Box className="caption-style--container">
          <Grid container wrap="nowrap" display="flow">
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Enable Caption
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomSwitch checked={activeProject.enableCaption} onChange={toggleCaption} />
              </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Enable Animated Caption
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomSwitch checked={enableAnimatedCaption} onChange={toggleAnimatedCaption} />
              </Grid>
            </Grid>

            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Alignment
              </Grid>
              <Grid item className="caption-style--item-value">
                <FormControl sizeUI0-="small" disabled={!activeProject.enableCaption}>
                  <Select
                    value={mediaState.captionStyles.alignment}
                    onChange={(e) =>
                      handleStylesChange(e.target.value, CaptionStylesEnum.ALIGNMENT, debounceUpdateTimeoutRef)
                    }
                    inputProps={{
                      'aria-label': 'Without label',
                      color: !activeProject.enableCaption ? 'red' : 'white',
                    }}
                    className={`caption-style--item-value--select`}
                    disabled={!activeProject.enableCaption}
                  >
                    <MenuItem className="caption-style--item-value--select-item" key="top_center" value={8}>
                      Top
                    </MenuItem>
                    <MenuItem className="caption-style--item-value--select-item" key="midd_center" value={5}>
                      Middle
                    </MenuItem>
                    <MenuItem className="caption-style--item-value--select-item" key="bottom_center" value={2}>
                      Bottom
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Font
              </Grid>
              <Grid item className="caption-style--item-value">
                <FormControl size="small" disabled={!activeProject.enableCaption}>
                  <Select
                    value={mediaState.captionStyles.font}
                    onChange={(e) =>
                      handleStylesChange(e.target.value, CaptionStylesEnum.FONT, debounceUpdateTimeoutRef)
                    }
                    inputProps={{ 'aria-label': 'Without label' }}
                    className={`caption-style--item-value--select`}
                    style={{ fontFamily: mediaState.captionStyles.font }}
                  >
                    {Object.keys(CaptionFontsEnum).map((fontName) => (
                      <MenuItem
                        className="caption-style--item-value--select-item"
                        key={fontName}
                        value={fontName}
                        style={{ fontFamily: fontName }}
                      >
                        {fontName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Font Size
              </Grid>
              <Grid item className="caption-style--item-value">
                <Input
                  className="caption-style--item-input"
                  type="number"
                  value={fontSize}
                  min={minFontSize}
                  max={maxFontSize}
                  onChange={(e) => {
                    if (e.target.value.length > 3) return;
                    handleValidateFontSize(
                      e.target.value,
                      setFontSize,
                      debounceValidateFontSizeTimeoutRef,
                      debounceUpdateTimeoutRef,
                      minFontSize,
                      maxFontSize,
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Primary Color
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomColorPicker
                  disabled={!activeProject.enableCaption}
                  name={CaptionStylesEnum.PRIMARY_COLOR}
                  setColor={(color, name) => handleStylesChange(color, name, debounceUpdateTimeoutRef)}
                  color={mediaState.captionStyles.primaryColor}
                  showDisableOption={false}
                  disableAlpha={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${disableAnimatedColor && 'disable'}`}>
                Active Color
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomColorPicker
                  disabled={disableAnimatedColor}
                  name={CaptionStylesEnum.ACTIVE_COLOR}
                  setColor={(color, name) => handleStylesChange(color, name, debounceUpdateTimeoutRef)}
                  color={mediaState.captionStyles.activeColor}
                  showDisableOption={true}
                  disableAlpha={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${disableAnimatedColor && 'disable'}`}>
                Inactive Color
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomColorPicker
                  disabled={disableAnimatedColor}
                  name={CaptionStylesEnum.INACTIVE_COLOR}
                  setColor={(color, name) => handleStylesChange(color, name, debounceUpdateTimeoutRef)}
                  color={mediaState.captionStyles.inactiveColor}
                  showDisableOption={true}
                  disableAlpha={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Outline Color
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomColorPicker
                  disabled={!activeProject.enableCaption}
                  name={CaptionStylesEnum.OUTLINE_COLOR}
                  setColor={(color, name) => handleStylesChange(color, name, debounceUpdateTimeoutRef)}
                  color={mediaState.captionStyles.outlineColor}
                  showDisableOption={false}
                  disableAlpha={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Middle Bar Color
              </Grid>
              <Grid item className="caption-style--item-value">
                <CustomColorPicker
                  disabled={!activeProject.enableCaption}
                  setColor={(color) => handleMiddleBarColorChange(color, email, debounceUpdateTimeoutRef)}
                  color={accountState.currentAccount.middleBarColor}
                  showDisableOption={false}
                  disableAlpha={true}
                />
              </Grid>
            </Grid>
            <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6} alignItems={'center'}>
              <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                Logo overlay
              </Grid>
              <Grid item className="caption-style--item-value" style={{ maxWidth: '100px' }}>
                {!logo && (
                  <FormControl size="small">
                    <LocalUploadImage isUploading={isUploading} setFileUpload={setImageUpload} />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            {logo && (
              <>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={0}
                  columns={2}
                  height={40}
                  marginTop={3}
                  marginBottom={3}
                  alignItems={'center'}
                >
                  <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                    <img alt="logo" src={logo} style={{ height: '100px', width: '100px', objectFit: 'contain' }} />
                  </Grid>
                  <Grid item className="caption-style--item-value">
                    <TrashBinButton onClick={handleRemoveLogo} />
                  </Grid>
                </Grid>
                <Grid container wrap="nowrap" spacing={0} columns={2} height={40} marginTop={0.6} alignItems={'center'}>
                  <Grid item className={`caption-style--item-label ${!activeProject.enableCaption && 'disable'}`}>
                    Logo corner
                  </Grid>
                  <Grid item className="caption-style--item-value">
                    <FormControl size="small">
                      <Select
                        value={logoCorner ?? 'Top - Left'}
                        inputProps={{ 'aria-label': 'Without label' }}
                        className={`caption-style--item-value--select`}
                        onChange={(e) => handleChooseCorner(e.target.value, email, debounceUpdateCornerTimeoutRef)}
                      >
                        {cornerOptions.map((value) => (
                          <MenuItem className="caption-style--item-value--select-item" key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </LoadingOverlay>
    </>
  );
});

export default CaptionStyles;
