import { brollsState } from '../../models/BrollState';
import { projectsState } from '../../models/ProjectsState';
import Segment from '../../models/Segment';
import { segmentsState } from '../../models/SegmentsState';
import { SEGMENT_CUSTOM_NODE_NAME } from '../../views/transcript/CustomNode/SegmentCustomNode';
import { WORD_EXTEND_NODE_NAME } from '../../views/transcript/CustomNode/WordExtendNode';
import {
  findWordAndSegmentByAnchor,
  formatTranscriptsFromSegmentWords,
  getExtendWordsOfTranscriptEditor,
} from '../utils/TranscriptUtils';
import { activateBrollsWords, deactivateBrollsWords } from '../utils/BrollEditorUtils';
import { getVideoEditDuration } from '../serverClients/ProjectClient';
import { WORD_CUSTOM_NODE_NAME } from '../../views/transcript/CustomNode/WordCustomNode';

export function handleSelectedWords(editor) {
  const { $from } = editor.state.selection as any;
  /* Tiptap editor has "path" of selection that include nodes in text selection range
   for our current case: "segment custom node", "word custom node" (it will optional if selection range hasn't active words) 
   and "extend word custom" (it will optional if selection range hasn't inactive words) 
   */
  const extendNode = $from.path.find((node: any) => node.type?.name === WORD_EXTEND_NODE_NAME);
  if (extendNode) {
    activateWords(extendNode, editor);
  } else {
    deactivateWords(editor);
  }
  updateVideoEditDuration();
  editor.commands.blur();
}

function activateWords(extendNode, editor) {
  const { from, to, $from } = editor.state.selection;
  const editorTextSelected = editor.state.doc.textBetween(from, to, ' ').trim().replace('\u200B', '');
  const segmentId = extendNode.attrs.segmentId;
  const segment = segmentsState.segments.find((i) => i.id === segmentId);
  const { wordBeginList, wordEndList } = getExtendWordsOfTranscriptEditor(segmentId);
  const extendText = extendNode.textContent.replace('\u200B', '');
  const extendWordsSelected = findTextInTranscriptBySelectionText(extendText, editorTextSelected);

  const segmentNode = $from.path.find((node: any) => node.type?.name === SEGMENT_CUSTOM_NODE_NAME);
  if (!segmentNode) return;
  const isBegin = isSelectionBeginningOfEditor(editor, wordBeginList, wordEndList);
  const { words, fromIndex, endIndex } = findWordsFromOriginTranscript(
    extendWordsSelected,
    isBegin ? wordBeginList : wordEndList,
  );
  if (!words || words.length < 1) return;

  // Check if the words can be merged with the currently activated words.
  const canMergeWords = isBegin ? endIndex === wordBeginList.length - 1 : fromIndex === 0;

  if (canMergeWords) {
    segment.words = isBegin
      ? formatTranscriptsFromSegmentWords(words.concat(segment.words))
      : formatTranscriptsFromSegmentWords(segment.words.concat(words));
    segment.text = segment.words.reduce((acc, word) => (acc += word.text), '');
    if (isBegin) {
      segment.start = segment.words[0].startTime;
    } else {
      segment.end = segment.words[segment.words.length - 1].endTime;
    }
    const videoEditSelectedIndex = projectsState.getVideoEditSelectedIndex();
    activateBrollsWords(segmentId, words, isBegin, videoEditSelectedIndex);
  } else {
    // create new segment
    const newSegment: Segment = {
      id: segmentsState.segments.length,
      strategy: segment.strategy,
      story_id: segment.story_id,
      importedMediaId: segment.importedMediaId,
      color: segment.color,
      media: segment.media,
      start: words[0].startTime,
      end: words[words.length - 1].endTime,
      text: words.reduce((acc, word) => (acc += word.text), ''),
      words,
    };
    segmentsState.setSegments([...segmentsState.segments, newSegment]);
    const currentVideoEdit = projectsState.videoEditSelected;
    const currentIndexOfSegmentId = projectsState.videoEditSelected.segmentIds.findIndex(
      (segmentId) => segmentId === segment.id,
    );
    currentVideoEdit.segmentIds.splice(currentIndexOfSegmentId + (isBegin ? 0 : 1), 0, newSegment.id);
  }
}

function isSelectionBeginningOfEditor(editor, wordBeginList, wordEndList) {
  const { $from } = editor.state.selection;
  const extendWordsNode = $from.path.find((node: any) => node.type?.name === WORD_EXTEND_NODE_NAME);
  if (!extendWordsNode) {
    throw new Error("The selected words haven't extended node");
  }
  const extendWordsContent = extendWordsNode.textContent.replace('\u200B', '');
  const extendWordsBeginOfSegment = wordBeginList.reduce((acc, value) => (acc += value.text), '');
  const extendWordsEndfSegment = wordEndList.reduce((acc, value) => (acc += value.text), '');
  if (extendWordsBeginOfSegment.includes(extendWordsContent.trim())) {
    return true;
  } else if (extendWordsEndfSegment.includes(extendWordsContent.trim())) {
    return false;
  } else {
    throw new Error("Couldn't check words selected");
  }
}

export function deactivateWords(editor) {
  const { $from, $to } = editor.state.selection;
  const { segmentId, wordIndex: wordIndexFrom } = findWordAndSegmentByAnchor(editor, $from);
  const { segmentId: segmentIdTo } = findWordAndSegmentByAnchor(editor, $to);
  let { wordIndex: wordIndexTo } = findWordAndSegmentByAnchor(editor, $to);
  if (wordIndexFrom === -1 || segmentId < 0) return;
  const segment = segmentsState.segments.find((i) => i.id === segmentId);

  if (segmentId !== segmentIdTo && segmentIdTo >= 0) {
    // delete across segments
    const segmentTo = segmentsState.segments.find((i) => i.id === segmentIdTo);

    // For case: a user select from a deactivate words to end of the segment
    if (wordIndexTo === -1) {
      wordIndexTo = segmentTo.words.length - 1;
    }

    const segmentIdsRemove = [];
    if (wordIndexFrom === 0) {
      segmentIdsRemove.push(segment.id);
    } else {
      deactivateWordsOfSegment(editor, segment, wordIndexFrom, segment.words.length - 1);
    }
    if (wordIndexTo === segmentTo.words.length - 1) {
      segmentIdsRemove.push(segmentIdTo);
    } else {
      deactivateWordsOfSegment(editor, segmentTo, 0, wordIndexTo);
    }
    // find segments between start and end
    const indexOfSegmentIdFrom = projectsState.videoEditSelected.segmentIds.findIndex((id) => id === segmentId);
    const indexOfSegmentIdTo = projectsState.videoEditSelected.segmentIds.findIndex((id) => id === segmentIdTo);
    segmentIdsRemove.push(
      ...projectsState.videoEditSelected.segmentIds.slice(indexOfSegmentIdFrom + 1, indexOfSegmentIdTo),
    );
    const currentVideoEdit = projectsState.videoEditSelected;
    currentVideoEdit.segmentIds = currentVideoEdit.segmentIds.filter(
      (id) => segmentIdsRemove.findIndex((i) => i === id) === -1,
    );
    projectsState.videoEditSelected.segmentIds = currentVideoEdit.segmentIds;
    removeSegmentAndUpdateBRolls(editor, segmentIdsRemove);
    return;
  }

  // For case: a user select from a activate words to end of the segment
  if (wordIndexFrom > -1 && wordIndexTo === -1) {
    wordIndexTo = segment.words.length - 1;
  }

  if (wordIndexFrom === 0 && wordIndexTo === segment.words.length - 1) {
    // selection of all deactivated words in the segment.
    const currentVideoEdit = projectsState.videoEditSelected;
    currentVideoEdit.segmentIds = currentVideoEdit.segmentIds.filter((id) => id !== segmentId);
    projectsState.videoEditSelected.segmentIds = currentVideoEdit.segmentIds;
    removeSegmentAndUpdateBRolls(editor, [segmentId]);
    return;
  }
  deactivateWordsOfSegment(editor, segment, wordIndexFrom, wordIndexTo);
}

function removeSegmentAndUpdateBRolls(editor, segmentIdsRemove) {
  for (const segment of segmentsState.segments) {
    const indexOfSegment = segmentIdsRemove.findIndex((id) => id === segment.id);
    if (indexOfSegment > -1) {
      const indexOfBRoll = brollsState.brolls.findIndex(
        (bRoll) => bRoll.story_id === segment.story_id && bRoll.segmentId === segment.id,
      );
      if (indexOfBRoll > -1) {
        brollsState.brolls.splice(indexOfBRoll, 1);
      }
    }
  }
  updateMarkStateEditor(editor);
}

function deactivateWordsOfSegment(editor, segment, wordIndexFrom, wordIndexTo) {
  const videoEditSelectedIndex = projectsState.getVideoEditSelectedIndex();
  deactivateBrollsWords(editor, brollsState.brolls, segment, videoEditSelectedIndex, wordIndexFrom, wordIndexTo);

  if (wordIndexFrom === 0) {
    // deactivate beginning words
    segment.words = segment.words?.slice(
      wordIndexTo === segment.words.length - 1 ? wordIndexTo : wordIndexTo + 1,
      segment.words.length,
    );
    segment.start = segment.words[0].startTime;
    segment.text = segment.words.reduce((acc, word) => (acc += word.text), '');
    updateMarkStateEditor(editor);
    return;
  }

  if (wordIndexTo === segment.words.length - 1) {
    // deactivate ending words
    segment.words = segment.words.slice(0, wordIndexFrom);
    segment.end = segment.words[segment.words.length - 1].endTime;
    segment.text = segment.words.reduce((acc, word) => (acc += word.text), '');
    updateMarkStateEditor(editor);
    return;
  }

  // create new segment
  const newSegment: Segment = {
    id: segmentsState.segments.length,
    strategy: segment.strategy,
    start: segment.words[wordIndexTo + 1].startTime,
    end: segment.words[segment.words.length - 1].endTime,
    story_id: segment.story_id,
    words: segment.words.slice(wordIndexTo + 1, segment.words.length),
    importedMediaId: segment.importedMediaId,
    media: segment.media,
    text: segment.words.slice(wordIndexTo + 1, segment.words.length).reduce((acc, word) => (acc += word.text), ''),
  };

  // modify current segment
  segment.words = segment.words.slice(0, wordIndexFrom);
  segment.text = segment.text = segment.words.reduce((acc, word) => (acc += word.text), '');
  segment.end = segment.words[segment.words.length - 1].endTime;

  // add new segment into segments
  segmentsState.setSegments([...segmentsState.segments, newSegment]);
  const currentVideoEdit = projectsState.videoEditSelected;
  const currentIndexOfSegmentId = projectsState.videoEditSelected.segmentIds.findIndex(
    (segmentId) => segmentId === segment.id,
  );
  const newSegmentIds = JSON.parse(JSON.stringify(currentVideoEdit)).segmentIds;
  newSegmentIds.splice(currentIndexOfSegmentId + 1, 0, newSegment.id);
  if (segment.strategy) {
    const currentVideoEditOfStrategyOutput = projectsState.videoEditSelected;
    currentVideoEditOfStrategyOutput.segmentIds = newSegmentIds;
  }
  currentVideoEdit.segmentIds = newSegmentIds;
  projectsState.setVideoEditSelected(currentVideoEdit);
  updateMarkStateEditor(editor);
}

function updateMarkStateEditor(editor) {
  const { from, to } = editor.state.selection;
  editor.chain().setTextSelection({ from, to }).unsetMark('link').unsetUnderline().run();
}

/**
 * The transcript is "is more important than salary. Smiles are more" and a user selected "is more im"
 * the "is more im" value is not expected so we need to complete the text selected with the expected value of "is more important"
 */
function findTextInTranscriptBySelectionText(extendText, editorTextSelected) {
  const index = extendText.indexOf(editorTextSelected);
  if (index !== -1) {
    let endIndex = index + editorTextSelected.length;
    let startIndex = index;
    if (extendText[startIndex] == ' ') {
      startIndex++;
    } else {
      while (startIndex > 0 && extendText[startIndex - 1] != ' ') {
        startIndex--;
      }
    }
    if (extendText[endIndex] != ' ' && endIndex < extendText.length - 1) {
      while (extendText[endIndex] != ' ') {
        endIndex++;
      }
    }
    return extendText.substring(startIndex, endIndex);
  } else {
    throw new Error("Couldn't completion the selected text");
  }
}

function findWordsFromOriginTranscript(textSelected, originWords: any[]) {
  const splitWords = textSelected.split(' ');
  let foundFromIndex = -1;
  let lastMatchIndex = -1;
  for (let i = 0; i < originWords.length; i++) {
    const fromIndex = splitWords.findIndex((text) => originWords[i].text.includes(text.trim()));

    if (fromIndex !== 0) {
      continue;
    } else {
      for (let j = i; j < originWords.length; j++) {
        const text = originWords.slice(i, j).reduce((acc, value) => (acc += value.text), '');
        if (textSelected.includes(text)) {
          lastMatchIndex = j;
        } else {
          break;
        }
      }
      if (lastMatchIndex > -1) {
        foundFromIndex = i;
        break;
      }
    }
  }
  if (foundFromIndex > -1) {
    return {
      fromIndex: foundFromIndex,
      endIndex: lastMatchIndex,
      words: originWords.slice(foundFromIndex, lastMatchIndex + 1),
    };
  } else {
    return { words: null, fromIndex: foundFromIndex, endIndex: lastMatchIndex };
  }
}

export function trackInActiveWordsStatus(editor) {
  const { $from, $to } = editor.state.selection;
  const extendNode = $from.path.find((node: any) => node.type?.name === WORD_EXTEND_NODE_NAME);
  const wordNode = $to.path.find((node: any) => node.type?.name === WORD_CUSTOM_NODE_NAME);
  if (wordNode) return false;
  return extendNode ? true : false;
}

function updateVideoEditDuration() {
  const newDuration = getVideoEditDuration(projectsState.videoEditSelected, segmentsState.segments);
  projectsState.videoEditSelected.duration = newDuration;
  const videoEdits = projectsState.getVideoEditsOfStrategyOutputs();
  for (let i = 0; i < videoEdits.length; i++) {
    if (videoEdits[i].id === projectsState.videoEditSelected.id) {
      videoEdits[i].duration = newDuration;
      break;
    }
  }
}
