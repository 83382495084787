import Segment from '../../models/Segment';
import { segmentsState } from '../../models/SegmentsState';
import { WORD_CUSTOM_NODE_NAME } from '../../views/transcript/CustomNode/WordCustomNode';
import { SEGMENT_CUSTOM_NODE_NAME } from '../../views/transcript/CustomNode/SegmentCustomNode';
import Broll from '../../models/Broll';
import { projectsState } from '../../models/ProjectsState';
import { WORD_EXTEND_NODE_NAME } from '../../views/transcript/CustomNode/WordExtendNode';
import { END_CARD_NODE_NAME } from '../../views/transcript/CustomNode/EndCardNode';
import { accountState } from '../../models/AccountState';
const PUNCTUATIONS = ['.', ',', '?', '!', '...'];

export function secondsToTimeString(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function isTimeCode(value: string) {
  return /^\d{0,2}:[0-5]?[0-9]?:[0-5]?[0-9]?$/.test(value);
}

export function timeStringToSeconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

export function getStartTimeFromYoutubeLink(youtubeLink) {
  const match = youtubeLink && youtubeLink.match(/&t=([\d.]+)s/);
  return match ? parseInt(match[1], 10) : 0;
}

export function formatYoutubeLink(link: string, timeString?: string, timeSecond?: number) {
  let seconds;
  if (timeString) {
    seconds = timeStringToSeconds(timeString);
  } else if (timeSecond) {
    seconds = timeSecond;
  }
  if (typeof seconds !== 'number' || seconds < 0) {
    throw new Error("Couldn't get seconds");
  }

  // Check if the &t=<number>s exists in the originalLink
  const timeRegex = /&t=\d+s/;
  const hasTimeParam = timeRegex.test(link);
  if (hasTimeParam) {
    // Replace the existing &t=<number>s with the newTimeValue
    link = link.replace(timeRegex, `&t=${seconds}s`);
  } else {
    // If &t=<number>s doesn't exist, add it to the end of the link
    link = `${link}&t=${seconds}s`;
  }
  return link;
}

export function convertYtbShortUrlToNormalUrl(shortUrl) {
  try {
    const url = new URL(shortUrl);

    // Ensure the URL contains "shorts/"
    if (!url.pathname.includes('/shorts/')) {
      return shortUrl;
    }

    // Extract the video ID from the path
    const videoId = url.pathname.split('/shorts/')[1];

    // Construct the long URL
    const longUrl = new URL(`https://www.youtube.com/watch`);
    longUrl.searchParams.set('v', videoId);

    // Copy only 't' query parameter if present
    if (url.searchParams.has('t')) {
      longUrl.searchParams.set('t', url.searchParams.get('t'));
    }

    return longUrl.toString();
  } catch {
    throw new Error('Invalid URL format');
  }
}

export function findWordAndSegmentByAnchor(editor, $anchor?: any) {
  if (!$anchor) {
    $anchor = editor.state.selection.$anchor;
  }
  let wordIndex = -1,
    segmentId = -1;
  const wordCustomNode = $anchor.path.find((node: any) => node.type?.name === WORD_CUSTOM_NODE_NAME);
  if (wordCustomNode && wordCustomNode.attrs) {
    wordIndex = wordCustomNode.attrs.wordIndex;
    segmentId = wordCustomNode.attrs.segmentId;
  }
  return { wordIndex, segmentId };
}

export function createEditorContent(segments: Segment[], brolls: Broll[], videoEditIndex: number) {
  const segmentNodes = createSegmentNodes(segments, brolls, videoEditIndex);
  return {
    type: 'doc',
    content: segmentNodes,
  };
}

function createSegmentNodes(segments: Segment[], brolls: Broll[], videoEditIndex: number) {
  const segmentNodes = segments
    .filter((segment) => !segment.end_card)
    .map((segment) => ({
      type: SEGMENT_CUSTOM_NODE_NAME,
      attrs: {
        segmentId: segment.id,
        startTime: segment.start,
        endTime: segment.end,
        isEndCard: segment.end_card,
      },
      content: createEditorNodeBySegment(segment, brolls, videoEditIndex),
    }));

  if (accountState.currentAccount?.isAdmin) {
    const endCardSegment = segments.find((segment) => segment.end_card);
    const segmentNode = createOrGetEndCardNode(endCardSegment);
    segmentNodes.push(segmentNode);
    if (endCardSegment) {
      updateNodesMarks(segmentNode.content, endCardSegment.id, brolls, videoEditIndex);
    }
  }
  return segmentNodes;
}

function createEditorNodeBySegment(segment: Segment, brolls: Broll[], videoEditIndex: number) {
  const editorNodes = createNodes(segment);
  updateNodesMarks(editorNodes, segment.id, brolls, videoEditIndex);
  addExtendWordsNodes(editorNodes, segment.id);
  return editorNodes;
}

function createNodes(segment: Segment) {
  return segment.words.map((word, index) => {
    if (word.text.includes('<br>')) {
      const content: any = [
        {
          type: 'text',
          text: word.text.replace(/<br>/g, ''),
        },
      ];
      if (word.text.startsWith('<br>')) {
        content.splice(0, 0, { type: 'hardBreak' });
      } else {
        content.push({ type: 'hardBreak' });
      }

      return {
        type: WORD_CUSTOM_NODE_NAME,
        content: content,
        attrs: {
          startTime: word.startTime,
          endTime: word.endTime,
          wordIndex: index,
          segmentId: segment.id,
        },
      };
    }
    return {
      type: WORD_CUSTOM_NODE_NAME,
      content: [
        {
          type: 'text',
          text: '\u200B' + word.text, // add non-print character at the beginning that prevent jump previous word node if user delete the first character of a word node
        },
      ],
      attrs: {
        startTime: word.startTime,
        endTime: word.endTime,
        wordIndex: index,
        segmentId: segment.id,
      },
    };
  });
}

function updateNodesMarks(editorNodes, segmentId: number, brolls: Broll[], videoEditIndex: number) {
  const segmentBrolls = brolls.filter((broll) => broll.segmentId === segmentId && broll.story_id === videoEditIndex);
  for (const broll of segmentBrolls) {
    for (let i = broll.wordIndexRange[0]; i <= broll.wordIndexRange[1]; i++) {
      const marks = [
        { type: 'link', attrs: { href: broll.candidates[0].url } },
        {
          type: 'underline',
          attrs: {
            color: broll.color,
            wordIndex: i,
            segmentId: broll.segmentId,
            brollGroupId: broll.brollGroupId,
          },
        },
      ];
      if (editorNodes[i]) {
        editorNodes[i].content[0].marks = marks;
      }
    }
  }
}

function addExtendWordsNodes(editorNodes, segmentId) {
  const originalTranscripts = projectsState.getSelectedOriginalTranscript();
  if (originalTranscripts) {
    const { wordBeginList, wordEndList } = getExtendWordsOfTranscriptEditor(segmentId);
    if (wordBeginList && wordBeginList.length > 0) {
      editorNodes.unshift({
        type: WORD_EXTEND_NODE_NAME,
        attrs: { segmentId: segmentId },
        content: [
          {
            type: 'text',
            text: wordBeginList.reduce((acc, item, index) => {
              if (index === 0) return acc + `\u200B${item.text}`; // \u200B: zero-width space
              return acc + item.text;
            }, ''),
          },
        ],
      });
    }

    if (wordEndList && wordEndList.length > 0) {
      editorNodes.push({
        type: WORD_EXTEND_NODE_NAME,
        attrs: { segmentId: segmentId },
        content: [
          {
            type: 'text',
            text: wordEndList.reduce((acc, item, index) => {
              if (index === 0) return acc + `\u200B${item.text}`; // \u200B: zero-width space
              return acc + item.text;
            }, ''),
          },
        ],
      });
    }
  }
}

export function getExtendWordsOfTranscriptEditor(segmentId) {
  const segment = segmentsState.segments.find((segment) => segment.id === segmentId);
  const currentSegmentIndexOfSegmentIds = projectsState.videoEditSelected.segmentIds.findIndex((i) => i === segmentId);
  let { wordBeginList, wordEndList } = findWordExtendMoreOfSegment(
    segment.words[0],
    segment.words[segment.words.length - 1],
    10,
  );
  // the wordBeginList of the next segment
  let wordEndListOfPreviousSegment;
  if (currentSegmentIndexOfSegmentIds > 0) {
    const previousSegmentId = projectsState.videoEditSelected.segmentIds[currentSegmentIndexOfSegmentIds - 1];
    const previousSegment = segmentsState.segments.find((segment) => segment.id === previousSegmentId);
    wordEndListOfPreviousSegment = findWordExtendMoreOfSegment(
      previousSegment.words[0],
      previousSegment.words[previousSegment.words.length - 1],
      10,
    ).wordEndList;
  }
  const extendWordListFiltered = filterOverlapWordsInExtendedWords(
    wordBeginList,
    wordEndList,
    segmentId,
    wordEndListOfPreviousSegment,
  );
  wordBeginList = extendWordListFiltered.wordBeginList;
  wordEndList = extendWordListFiltered.wordEndList;
  return { wordBeginList, wordEndList };
}

function findWordExtendMoreOfSegment(startWord, endWord, selectionCount) {
  const mediaWords = projectsState.getSelectedOriginalTranscript();
  const startWordIndex = mediaWords.findIndex(
    (word) => word.startTime === startWord.startTime && word.endTime === startWord.endTime,
  );
  const endWordIndex = mediaWords.findIndex(
    (word) => word.startTime === endWord.startTime && word.endTime === endWord.endTime,
  );
  let wordBeginList =
    startWordIndex >= selectionCount
      ? mediaWords.slice(startWordIndex - selectionCount, startWordIndex)
      : mediaWords.slice(0, startWordIndex);
  let wordEndList =
    endWordIndex <= mediaWords.length - 1 - selectionCount
      ? mediaWords.slice(endWordIndex + 1, endWordIndex + selectionCount)
      : mediaWords.slice(endWordIndex + 1, mediaWords.length - 1);
  // filter punctuation  and format words
  if (wordBeginList && wordBeginList.length > 0) {
    wordBeginList = formatTranscriptsFromOriginWords(JSON.parse(JSON.stringify(wordBeginList)));
  }

  if (wordEndList && wordEndList.length > 0) {
    wordEndList = formatTranscriptsFromOriginWords(JSON.parse(JSON.stringify(wordEndList)));
  }

  return { wordBeginList, wordEndList };
}

function filterOverlapWordsInExtendedWords(wordBeginList, wordEndList, segmentId, wordEndListOfPreviousSegment) {
  const currentSegmentIndexOfSegmentIds = projectsState.videoEditSelected.segmentIds.findIndex((i) => i === segmentId);
  if (currentSegmentIndexOfSegmentIds > 0) {
    const previousSegmentId = projectsState.videoEditSelected.segmentIds[currentSegmentIndexOfSegmentIds - 1];
    const previousSegment = segmentsState.segments.find((segment) => segment.id === previousSegmentId);
    let overlapToIndex;
    for (let i = 0; i < wordBeginList.length; i++) {
      const extendWord = wordBeginList[i];
      const foundWord = previousSegment.words.find(
        (word) => extendWord.startTime === word.startTime && extendWord.endTime === word.endTime,
      );
      if (foundWord) {
        overlapToIndex = i;
      }
    }
    if (overlapToIndex >= 0) {
      wordBeginList =
        overlapToIndex < wordBeginList.length - 1
          ? wordBeginList.slice(overlapToIndex + 1, wordBeginList.length - 1)
          : [];
    }
  }

  if (currentSegmentIndexOfSegmentIds < projectsState.videoEditSelected.segmentIds.length - 1) {
    const nextSegmentId = projectsState.videoEditSelected.segmentIds[currentSegmentIndexOfSegmentIds + 1];
    const nextSegment = segmentsState.segments.find((segment) => segment.id === nextSegmentId);
    let overlapFromIndex;
    for (let i = 0; i < wordEndList.length; i++) {
      const extendWord = wordEndList[i];
      const foundWord = nextSegment.words.find(
        (word) => extendWord.startTime === word.startTime && extendWord.endTime === word.endTime,
      );
      if (foundWord) {
        overlapFromIndex = i;
        break;
      }
    }
    if (overlapFromIndex >= 0) {
      wordEndList = overlapFromIndex > 0 ? wordEndList.slice(0, overlapFromIndex) : [];
    }
  }
  if (wordEndListOfPreviousSegment) {
    let overlapToIndex;
    for (let i = 0; i < wordBeginList.length; i++) {
      const extendWord = wordBeginList[i];
      const foundWord = wordEndListOfPreviousSegment.find(
        (word) => extendWord.startTime === word.startTime && extendWord.endTime === word.endTime,
      );
      if (foundWord) {
        overlapToIndex = i;
      }
    }
    if (overlapToIndex >= 0) {
      wordBeginList =
        overlapToIndex < wordBeginList.length - 1
          ? wordBeginList.slice(overlapToIndex + 1, wordBeginList.length - 1)
          : [];
    }
  }

  return { wordBeginList, wordEndList };
}

/**
 * Format the capitalization of original word as appropriate
 * @param words (words json in origin transcript)
 * @returns
 */
export function formatTranscriptsFromOriginWords(words) {
  const PUNC_MAKE_NEXT_WORD_CAPS = PUNCTUATIONS.filter((punc) => punc !== ',');
  return words.reduce((acc, word, index) => {
    if ((index === 0 || index === words.length - 1) && PUNCTUATIONS.includes(word.text.trim())) {
      return acc;
    }
    word.text += ' ';
    if (index > 0 && PUNCTUATIONS.includes(word.text.trim())) {
      acc[acc.length - 1].text = acc[acc.length - 1].text.trim() + word.text; // merge punctuation with previous word
      if (index < words.length - 1 && PUNC_MAKE_NEXT_WORD_CAPS.includes(word.text.trim())) {
        words[index + 1].text = words[index + 1].text.charAt(0).toUpperCase() + words[index + 1].text.slice(1);
      }
      return acc;
    }

    acc.push(word);
    return acc;
  }, []);
}

/**
 * Format the capitalization of segment word as appropriate
 * @param words (words json in segment)
 * @returns
 */
export function formatTranscriptsFromSegmentWords(words) {
  const PUNC_MAKE_NEXT_WORD_CAPS = PUNCTUATIONS.filter((punc) => punc !== ',');
  return words.map((word, index) => {
    if (index !== 0) {
      const previousWord = words[index - 1];
      const wordTrimmed = previousWord.text.trim();
      if (PUNC_MAKE_NEXT_WORD_CAPS.includes(wordTrimmed.charAt(wordTrimmed.length - 1))) {
        word.text = word.text.charAt(0).toUpperCase() + word.text.slice(1);
      } else {
        word.text = word.text.charAt(0).toLowerCase() + word.text.slice(1);
      }
    }
    return word;
  });
}

function createOrGetEndCardNode(endCardSegment?) {
  const endCardNode = {
    type: END_CARD_NODE_NAME,
    content: [
      {
        type: 'text',
        text: '[End card]',
      },
    ],
    attrs: {
      wordIndex: 0,
      segmentId: endCardSegment?.id || -1,
      endCardNode: true,
    },
  };
  const dummySegment = {
    type: SEGMENT_CUSTOM_NODE_NAME,
    attrs: {
      segmentId: endCardSegment?.id || -1,
      startTime: 0,
      endTime: 0,
      isEndCard: true,
    },
    content: [endCardNode],
  };
  return dummySegment;
}
