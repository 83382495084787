import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './radio.css';

function IngestRadio({ label, value, name, optionSelected, setSelected }) {
  const isSelected = optionSelected === value;

  return (
    <FormGroup check style={{ opacity: isSelected ? 1 : 0.6 }}>
      <Input
        className="ingest-radio"
        name={name}
        type="radio"
        check
        value={value}
        checked={isSelected}
        onChange={setSelected}
      />
      <Label check>{label}</Label>
    </FormGroup>
  );
}

export default IngestRadio;
