import { Box, IconButton, Tooltip, Zoom } from '@mui/material';
import { BubbleMenu } from '@tiptap/react';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import {
  handleSelectedWords,
  trackInActiveWordsStatus,
} from '../../controllers/verticalVideoEditorCRUD/BubbleEditorHandler';
/**
 * [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
import YoutubeHighlightMenu from '../menus/YoutubeHighlightMenu';
import YouTubeIcon from '@mui/icons-material/YouTube';
 */
import PlusCircleIcon from '../ui_controls/icons/PlusCircleIcon';
import RemoveCircleIcon from '../ui_controls/icons/RemoveCircleIcon';

type TProps = {
  editor: any;
  onYoutubeSubmit?: (...args) => void;
  onUpdateEditorContent: (...args) => void;
};

const useHover = () => {
  const [isHovered, setIsHovered] = useState(false);
  const toggleHover = () => {
    setIsHovered((prev) => !prev);
  };
  return {
    isHovered,
    toggleHover,
  };
};

const RemoveText = () => {
  const { isHovered, toggleHover } = useHover();
  return (
    <Box display={'flex'} padding={'8px'} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      <RemoveCircleIcon style={{ width: 16, height: 16, filter: isHovered ? 'none' : 'grayscale(1)' }} />
    </Box>
  );
};

const AddText = () => {
  const { isHovered, toggleHover } = useHover();
  return (
    <Box display={'flex'} padding={'8px'} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      <PlusCircleIcon style={{ width: 16, height: 16, filter: isHovered ? 'none' : 'grayscale(1)' }} />
    </Box>
  );
};

const BubbleEditor = observer((props: TProps) => {
  const [selectedYoutubeHighlight, setSelectedYoutubeHighlight] = useState<boolean>(false);
  const isActiveWordsStatus = trackInActiveWordsStatus(props.editor);
  const { $from } = props.editor.state.selection;
  const bubbleEndCard = $from?.parent?.attrs?.endCard || false;

  const onPressHandleWords = () => {
    handleSelectedWords(props.editor);
    props.onUpdateEditorContent();
  };

  useEffect(() => {
    /**
     * Bubble plugin of Tiptap editor hasn't expose any API for hidden or destroy event
     * So we need to create a hidden event for "bubble" event
     * For our case: when user select "youtube" icon then the bubble changes UI and after the "bubble" hidden then it should revert previous UI
     */
    const bubbleMenu = document.getElementById('transcript-editor-box');
    const observerBubble = new MutationObserver(function (mutations_list) {
      mutations_list.forEach(function (mutation) {
        if (mutation.removedNodes && mutation.removedNodes.length > 0) {
          setSelectedYoutubeHighlight(false);
        }
      });
    });

    if (bubbleMenu) {
      if (observerBubble) {
        observerBubble.observe(bubbleMenu, { subtree: false, childList: true });
      }
    }

    return () => {
      if (observerBubble) {
        observerBubble.disconnect();
      }
    };
  }, []);

  return (
    <BubbleMenu editor={props.editor} tippyOptions={{ duration: 100 }}>
      {/* 
        [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
        {selectedYoutubeHighlight && <YoutubeHighlightMenu editor={props.editor} onSubmit={props.onYoutubeSubmit} />}
      */}
      {!selectedYoutubeHighlight && (
        <Box style={{ backgroundColor: '#1d253e', padding: '0 8px', display: 'flex', borderRadius: 2 }}>
          {/* 
            [STRV1-2008] Temporarily Disabling YouTube Import for Urgent Maintenance
            <IconButton disabled={isActiveWordsStatus}>
              <YouTubeIcon
                style={{ color: isActiveWordsStatus ? 'black' : 'red' }}
                onClick={() => setSelectedYoutubeHighlight(true)}
              />
            </IconButton> 
          */}
          {!bubbleEndCard && (
            <Tooltip
              arrow
              sx={{ padding: 0 }}
              TransitionComponent={Zoom}
              title={isActiveWordsStatus ? 'add text' : 'remove text'}
            >
              <IconButton onClick={onPressHandleWords}>{isActiveWordsStatus ? <AddText /> : <RemoveText />}</IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </BubbleMenu>
  );
});

export default BubbleEditor;
