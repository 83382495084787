import Underline from '@tiptap/extension-underline';
import { mergeAttributes } from '@tiptap/core';

export const CustomUnderline = Underline.extend({
  renderHTML({ HTMLAttributes }) {
    const attr = { style: `text-decoration-color: ${HTMLAttributes.color}; text-decoration-thickness: 2px` };
    return ['u', mergeAttributes(this.options.HTMLAttributes, attr), 0];
  },

  addAttributes() {
    return {
      color: {
        default: null,
      },
      wordIndex: {
        default: null,
      },
      segmentId: {
        default: null,
      },
      brollGroupId: {
        default: null,
      },
    };
  },

  addCommands() {
    return {
      setUnderlineWithColor:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      setUnderline:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleUnderline:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetUnderline:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },
});
