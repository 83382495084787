import { Box, Typography } from '@mui/material';
import React, { useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import path from 'path-browserify';
import MediaUploadIcon from '../ui_controls/icons/MediaUploadIcon';
import {
  isValidFileNameLength,
  MAX_FILE_NAME_LENGTH,
  MIN_FILE_NAME_LENGTH,
} from '../../controllers/ingest/UploadHandlers';
import { publishMetric } from '../../controllers/serverClients/ProjectClient';
import { IMPORT_TYPE } from './IngestUploadStep';

type Props = {
  setFileUpload: (files) => void;
  disabled?: boolean;
};

const LocalUpload = observer(({ setFileUpload, disabled }: Props) => {
  const { user } = useAuth0();
  const fileUploadRef = useRef<any>();

  const notifyInvalidFile = useCallback(() => {
    const messageSnackKey = enqueueSnackbar('Storylines can only process .MP4 and .MOV. Please try another file.', {
      variant: 'error',
      SnackbarProps: {
        onClick: () => closeSnackbar(messageSnackKey),
      },
    });
  }, []);

  const checkIsValidFiles = (files) => {
    return files.every((file) => {
      const splitName = file?.name?.split('.');
      if (!['mov', 'mp4'].includes(splitName[splitName?.length - 1]?.toLowerCase())) {
        publishMetric('ingest_local_import_supported_file', 0, user.email);
        notifyInvalidFile();
        return false;
      } else {
        publishMetric('ingest_local_import_supported_file', 1, user.email);
      }
      if (!isValidFileNameLength(path.parse(file.name).name, IMPORT_TYPE.LOCAL, user.email)) {
        return false;
      }
      return true;
    });
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (!acceptedFiles || acceptedFiles.length === 0) {
        notifyInvalidFile();
        return;
      }
      const isValid = checkIsValidFiles(acceptedFiles);
      if (isValid) {
        setFileUpload(acceptedFiles);
      }
    },
    [setFileUpload],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'video/*': [],
    },
    disabled: disabled,
  });

  const onButtonClick = () => {
    if (!disabled) {
      fileUploadRef?.current.click();
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'rgb(15, 22, 21)',
        height: '50%',
        borderRadius: '10px',
        border: '1px dashed #1F2726',
        margin: '1rem 0',
        padding: '3rem 0',
        cursor: disabled ? 'auto' : 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      {...getRootProps()}
      onClick={onButtonClick}
    >
      <input
        {...getInputProps()}
        type="file"
        accept="video/*"
        ref={fileUploadRef}
        style={{ display: 'none' }}
        onChange={(e: any) => {
          if (checkIsValidFiles(Object.values(e.target.files))) {
            setFileUpload(Object.values(e.target.files));
            fileUploadRef.current.value = '';
          }
        }}
      />
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          opacity: 0.6,
        }}
      >
        <MediaUploadIcon />
        <Typography>Drag or upload your video</Typography>
      </Box>
    </Box>
  );
});

export default LocalUpload;
