export enum SegmentColor {
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  RED = 'RED',
  WHITE = 'WHITE',
  PURPLE = 'PURPLE',
  BLUE = 'BLUE',
}

// Map the segments' project.json colors to a font color
export const SEGMENT_COLOR_MAP = {
  [SegmentColor.ORANGE]: '#000000', // Black
  [SegmentColor.YELLOW]: '#555500', // Olive
  [SegmentColor.GREEN]: '#006600', // Green
  [SegmentColor.RED]: '#880000', // Red
  [SegmentColor.WHITE]: '#444444', // Gray
  [SegmentColor.PURPLE]: '#770077', // Purple
  [SegmentColor.BLUE]: '#006666', // Teal
};
