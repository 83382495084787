import { useAuth0 } from '@auth0/auth0-react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav, NavItem, Navbar } from 'reactstrap';
import { projectsState } from '../../models/ProjectsState';
import CloneButton from '../ui_controls/buttons/CloneButton';
import LogoWithoutNameIcon from '../ui_controls/icons/LogoWithoutNameIcon';
import './Navigation.css';
import { SHARE_PATH } from '../../config/constants';
import { UNPLAYED_WORD_COLOR } from '../transcript/CustomNode/WordCustomNode';

type TProps = {
  hiddenMenuBar?: boolean;
  hiddenStoryForm?: boolean;
  projectNameEditable?: boolean;
  onLogin?: () => void;
  onClone?: () => void;
};

const MobileNavigation = ({ onClone }: TProps) => {
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();
  const isShare = pathname.startsWith(SHARE_PATH);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Navbar className="main-layout__navigation-mobile">
      <Nav fill pills style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <NavItem>
          <NavLink className="whiteLink" to="/">
            <LogoWithoutNameIcon />
          </NavLink>
        </NavItem>
        <NavItem>
          <Typography
            color={'white'}
            maxWidth={'60vw'}
            textOverflow={'ellipsis'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
          >
            {projectsState.activeProject && projectsState.activeProject.displayName}
          </Typography>
        </NavItem>
        <NavItem style={{ minWidth: 30 }}>
          {user && (
            <>
              <IconButton aria-label="expand" onClick={toggleDrawer}>
                <MenuIcon style={{ color: UNPLAYED_WORD_COLOR }} />
              </IconButton>
              <Drawer open={open} onClose={toggleDrawer} anchor="right">
                <Box
                  sx={{ backgroundColor: '#0A1714', color: 'white', height: '100%', padding: 2, minWidth: 150 }}
                  role="presentation"
                  onClick={toggleDrawer}
                >
                  {isShare && <CloneButton onClick={onClone} />}
                </Box>
              </Drawer>
            </>
          )}
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default MobileNavigation;
