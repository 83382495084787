import HardBreak from '@tiptap/extension-hard-break';

export const CustomHardBreak = HardBreak.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Enter': () => this.editor.commands.setHardBreak(),
      'Shift-Enter': () => this.editor.commands.setHardBreak(),
      Enter: () => this.editor.commands.setHardBreak(),
    };
  },
});
