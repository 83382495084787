import { IconButton } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ProjectInfoButton = (props) => {
  return (
    <IconButton {...props} style={{ color: 'white', padding: 0 }} onClick={props.onClick}>
      <MoreVertIcon style={{ verticalAlign: 'middle', borderRadius: '5px' }} />
    </IconButton>
  );
};

export default ProjectInfoButton;
