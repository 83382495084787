import { serverAddress } from '../../config';
import { getOptions, postOptions } from '../utils/FetchUtils';
import { Uploader } from '../utils/UploaderUtils';
import { TNotifyIngest } from '../../models/Ingest';

export const getVideoPresignedUrl = async (s3Key: string): Promise<string> => {
  const encodedS3Key = encodeURIComponent(s3Key).replace(/\./g, '%2E');
  const endpoint = `${serverAddress}/api/video-playback/get/${encodedS3Key}`;
  const accessToken = 'token';
  return await fetch(endpoint, getOptions(endpoint, accessToken))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    });
};

export const getVideoEditCaptionPresignedUrl = async (
  projectId: string,
  videoEditId?: string,
  strategy?: string,
): Promise<string> => {
  const endpoint = `${serverAddress}/api/captions/get-video-edit-caption`;
  const accessToken = 'token';
  return await fetch(endpoint, postOptions(endpoint, accessToken, { projectId, videoEditId, strategy }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    })
    .then((rs) => rs);
};

export const getMediaFullCaptionPresignedUrl = async (
  projectId: string,
  mediaId: number,
  captionStyles: any,
): Promise<string> => {
  const endpoint = `${serverAddress}/api/captions/get-media-full-caption`;
  const accessToken = 'token';

  return await fetch(endpoint, postOptions(endpoint, accessToken, { projectId, mediaId, captionStyles }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    })
    .catch((error) => {
      console.error('There was a problem getting the presigned url:', error.message);
      throw error;
    })
    .then((rs) => rs);
};

export const postVideoIngestStatus = async (notifyIngest: TNotifyIngest, accessToken) => {
  const url = `${serverAddress}/api/video-ingest/notify-ingest-status`;
  await fetch(url, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(notifyIngest),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .catch((error) => {
      console.error('Error notifying ingest started.:', error.message);
      throw error;
    });
};

export const postVideoIngest = async (videoIngestPayload, accessToken) => {
  const url = `${serverAddress}/api/video-ingest/notify-ingest-complete`;

  const storyGptReponse = await fetch(url, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(videoIngestPayload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });

  return storyGptReponse;
};

export const notifyNewProjectSubmitted = async (payload, accessToken) => {
  const url = `${serverAddress}/api/video-ingest/notify-new-project-submitted`;

  await fetch(url, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .catch((error) => {
      console.error('Error notifying ingest started.:', error.message);
      throw error;
    });
};

export async function uploadVideoFromLocalDevice(file, videoID: string, accessToken: string, callback: (arg) => void) {
  const onProgress = ({ percentage, timeRemaining }: any) => {
    callback({ event: 'onProgress', percentage, timeRemaining });
  };

  const onComplete = ({ objectKey, uploadID }) => {
    callback({ event: 'onComplete', objectKey, uploadID });
  };

  const onError = (error: any) => {
    callback({ event: 'onError', error });
  };

  const uploader = new Uploader(file, accessToken, onProgress, onComplete, onError);
  uploader.doUpload(videoID);
}

export async function youtubeVideoInfo(youtubeUrl: string, accessToken: string) {
  const url = `${serverAddress}/api/video-ingest/youtube-import/get-video-info?url=${encodeURIComponent(youtubeUrl)}`;
  const baseOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, baseOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((rs) => rs.json())
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });
}

export async function getIngestProjectJSON(projectId: string, accessToken: string) {
  const url = `${serverAddress}/api/video-ingest/ingest-json/get?projectId=${projectId}`;
  const baseOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return fetch(url, baseOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((rs) => rs.json())
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });
}

export const postProjectRetry = async (
  projectId: string,
  projectName: string,
  runType: 'retry' | 'rerun' | 'generate-ai-content',
  email: string,
  newStoryAbout?: string,
  editStrategy?: string,
  formType?: string,
  captionStyles?: any,
  excludeCaptions?: boolean,
  aiGeneratedTitle?: boolean,
  isChatbot?: boolean,
) => {
  const url = `${serverAddress}/api/video-ingest/project-retry`;

  const storyGptReponse = await fetch(url, {
    method: 'POST',
    headers: {
      authorization: `Bearer {token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      projectId,
      projectName,
      runType,
      newStoryAbout,
      email,
      editStrategy,
      formType,
      captionStyles,
      excludeCaptions,
      aiGeneratedTitle,
      isChatbot,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error('There was a problem with the project retry:', error.message);
      throw error;
    });

  return storyGptReponse;
};

export function downloadOriginalVideo(projectId: string, mediaName: string, accessToken: string) {
  const s3ObjectKey = `${projectId}/${mediaName}/raw_${mediaName}.mp4`;
  const url = `${serverAddress}/api/video-ingest/original-video/get?s3ObjectKey=${s3ObjectKey}`;
  const baseOptions = {
    method: 'GET',
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return fetch(url, baseOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then((rs) => rs.json())
    .catch((error) => {
      console.error('There was a problem with the fetch operation:', error.message);
      throw error;
    });
}
