import { Box, Grid, LinearProgress, Stack, Typography, linearProgressClasses, styled } from '@mui/material';
import React, { useState } from 'react';
import OnboardUserInfo from '../onboard/OnboardUserInfo';
import LogoIcon from '../ui_controls/icons/LogoIcon';
import OnboardUserDescribe from '../onboard/OnboardUserDescribe';
import './page.css';
import { PROJECTS_PATH } from '../../config/constants';
import { useNavigate } from 'react-router-dom';
import { updateIntercomProfile } from '../../controllers/serverClients/AccountClient';
import { useAuth0 } from '@auth0/auth0-react';
import OnboardAIExpectation from '../onboard/OnboardAIExpectation';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#FFFFFF1F',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#079A7C',
  },
}));

function OnboardingPage() {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [progress, setProgress] = useState<number>(0);
  const [firstname, setFirstname] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<string>('Select an option');

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>();
  const [userDescribe, setUserDescribe] = useState<string>('');
  const [aiExpectation, setAIExpectation] = useState<string>('');

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChangeFirstname = (event) => {
    setFirstname(event.target.value);
  };

  const handleMoveToNextStep = () => {
    setProgress((prev) => prev + 1);
  };

  const handleBackToPreviousStep = () => {
    setProgress((prev) => prev - 1);
  };

  const handleChooseCategory = (category) => {
    setSelectedCategory(category.title);
    setSelectedSubCategory(undefined);
    setUserDescribe('');
  };

  const handleChooseSubCategory = (category) => {
    setSelectedSubCategory(category.title);
    setUserDescribe('');
  };

  const handleChangeDescribe = (describe) => setUserDescribe(describe);

  const handleChangeAIExpect = (event) => {
    setAIExpectation(event.target.value);
  };

  const handleFinishOnboard = () => {
    const subCategory = selectedSubCategory ?? '';

    const newUserData = {
      option: selectedOption,
      category: selectedCategory,
      subCategory: subCategory,
      describe: userDescribe,
      firstname,
      aiExpectation,
    };
    updateIntercomProfile(user.email, newUserData, 'token');
    navigate(PROJECTS_PATH);
  };

  return (
    <Grid container className="onboard-page">
      <Grid item xs={8} className="onboard-page__left-pane">
        <Box sx={{ width: '100%' }}>
          <Stack spacing={3} sx={{ marginBottom: 3 }}>
            <Typography className="onboard-page__label">Storylines</Typography>
            <BorderLinearProgress variant="determinate" value={progress * 35} />
          </Stack>
          {progress === 0 && (
            <OnboardAIExpectation
              aiExpect={aiExpectation}
              handleMoveToNextStep={handleMoveToNextStep}
              onChangeAIExpect={handleChangeAIExpect}
            />
          )}
          {progress === 1 && (
            <OnboardUserInfo
              firstname={firstname}
              selectedOption={selectedOption}
              handleChangeFirstname={handleChangeFirstname}
              handleChangeOption={handleChangeOption}
              handleMoveToNextStep={handleMoveToNextStep}
              onMoveBack={handleBackToPreviousStep}
            />
          )}
          {progress === 2 && (
            <OnboardUserDescribe
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              userDescribe={userDescribe}
              onChangeUserDescribe={handleChangeDescribe}
              onChooseCategory={handleChooseCategory}
              onChooseSubCategory={handleChooseSubCategory}
              onMoveBack={handleBackToPreviousStep}
              onFinish={handleFinishOnboard}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        <LogoIcon />
      </Grid>
    </Grid>
  );
}

export default OnboardingPage;
