import { Radio } from '@mui/material';
import React from 'react';

function ExportRadio(props) {
  return (
    <Radio
      sx={{
        color: 'white',
        '&.Mui-checked': {
          color: '#079A7C',
        },
      }}
      {...props}
    />
  );
}

export default ExportRadio;
