import { useAuth0 } from '@auth0/auth0-react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { getShareChat, handleSelectChat } from '../../controllers/chat/ChatWindowHandler';
import { chatbotState } from '../../models/ChatbotState';
import ChatSidebar from '../chat/ChatSidebar';
import ChatWindow from '../chat/ChatWindow';
import RequestAccessModal from '../modals/RequestAccessModal';
import './ChatbotPage.css';
import IngestModal from '../modals/IngestModal';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { ingestState } from '../../models/IngestState';
import { onChatbotIngest } from '../../controllers/chat/ChatbotPageHandler';
import { CHATBOT_PATH, CHATBOT_SHARE_PATH } from '../../config/constants';

type Props = {
  isReadOnly?: boolean;
};

const ChatbotPage = observer(({ isReadOnly }: Props) => {
  const { chatId } = useParams();
  const { chatItems, selectedChatId } = chatbotState;
  const { isSubmitting, setFormFeedbacks } = ingestState;
  const navigate = useNavigate();
  const { user } = useAuth0();

  useEffect(() => {
    if (!chatId && chatItems.length > 0) {
      navigate(`${isReadOnly ? CHATBOT_SHARE_PATH : CHATBOT_PATH}/${chatItems[0].chatId}`);
      return;
    }

    if (chatId && chatId !== selectedChatId && chatItems.length > 0) {
      handleSelectChat(chatId);
      return;
    }

    // for share page
    if (isReadOnly && chatId && chatId !== selectedChatId) {
      getShareChat(chatId);
    }
  }, [chatId, user?.email, selectedChatId, chatItems.length]);

  return (
    <Box className="chatbot-page">
      <Helmet>
        <title>Eddy, your AI video editor</title>
      </Helmet>
      {!isMobile && <ChatSidebar isReadOnly={isReadOnly} />}
      <ChatWindow isReadOnly={isReadOnly} />
      <RequestAccessModal />
      <IngestModal
        isActive={chatbotState.shouldShowIngestModal}
        toggle={() => {
          if (!isSubmitting) {
            setFormFeedbacks([]);
            chatbotState.setShouldShowIngestModal(!chatbotState.shouldShowIngestModal);
          } else {
            const messageSnackKey = enqueueSnackbar('Your form is submitting!', {
              variant: 'warning',
              SnackbarProps: { onClick: () => closeSnackbar(messageSnackKey) },
            });
          }
        }}
        onClosed={() => {}}
        isChatBot
        onChatbotIngest={onChatbotIngest}
      />
    </Box>
  );
});

export default ChatbotPage;
